import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'no-result-dialog',
    templateUrl: './no-result-dialog.component.html',
    styleUrls: ['no-result-dialog.component.scss']
})

export class NoResultDialogComponent {

    constructor(public dialogRef: MatDialogRef<NoResultDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }
}