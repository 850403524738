import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'action-dialog-box',
    templateUrl: './action-dialog.component.html',
    styleUrls: ['action-dialog.component.scss']
})

export class ActionDialogComponent {
    action: any;
    constructor(public dialogRef: MatDialogRef<ActionDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any) {
        this.action = data;
        console.log(this.action)
    }
}