import { Component, OnInit, AfterViewInit, OnDestroy, Input, HostListener, OnChanges, SimpleChanges } from '@angular/core';
import { GridOptions, GridApi } from 'ag-grid-community';
import { Router } from '@angular/router';
import { GridCellRendererComponent } from '../../grid-cell-renderer/grid-cell-renderer.component';
import { ExpandGridCellRendererComponent } from '../shared-module/expand-grid-cell-renderer/expand-grid-cell-renderer.component';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoaderService } from '../../services/loader.service';
import { CustomToolTipComponent } from '../../custom-tooltip/custom-tooltip.component';
import { interval, Observable, Subscription } from 'rxjs';
import { HomeService } from '../../home/home.service';
import { ErrorDialogComponent } from '../../error-dialog-box/error-dialog.component';
import { MonitoringService } from '../monitoring.service';
import 'ag-grid-enterprise';
import * as moment from 'moment';
import { ConfimDialogComponent } from 'src/app/confirm-dialog-box/confirm-dialog.component';
import { IndividualPickAlertComponent } from '../shared-module/individual-pick-alert/individual-pick-alert';
import { CompanyPickAlertComponent } from '../shared-module/company-pick-alert/company-pick-alert';
import { ManuallPickAlertComponent } from '../shared-module/manuall-pick-alert/company-pick-alert';
import { DetailCellRenderer } from '../shared-module/master-data-grids/cell-renderer.component';
import { GridLoaderComponent } from '../shared-module/loader/loader.component';
import { NotificationService } from '../shared-module/toast/toast.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { InfoDialogComponent } from '../shared-module/info-dialog/info-dialog.component';
import { MonitorAllRecordDataCellRenderer } from './monitor-all-record-data/monitor-all-record-data.component';
import { FilterPopUpComponent } from '../monitor/filter-popup/filter-popup.component';
import { CommonDialogComponent } from '../shared-module/dialogs/confirm-dialog.component';
import { MyNoRowsComponent } from '../shared-module/search-result/no-overlay.component';
import * as XLSX from 'xlsx';
import { ConfimDeleteDialogComponent } from '../shared-module/confirm-dialog-box/confirm-dialog.component';
import { E } from '@angular/cdk/keycodes';

@Component({
	selector: 'app-monitor-records',
	templateUrl: './monitor-records.component.html',
	styleUrls: ['./monitor-records.component.scss']
})

export class MonitorRecordsComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
	isMobile: boolean = false;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if (event.target.innerWidth > 1000) {
			this.isMobile = false;
			this.getColDefs();
		} else {
			this.isMobile = true;
			this.getColDefs();
		}
	}
	$langEventSubscription: Subscription;
	$getDataSubscription: Subscription;
	@Input() changeLangEvent: Observable<void>;
	@Input() addedRecord: any;
	columnDefs: any = [];
	records: any = [];
	gridOptions: any;
	api: any;
	gridColumnApi: any;
	editEntity: any;
	icons: any;
	tooltipShowDelay: any;
	blob: any;
	uncollectedCount = 0;
	mySubscription: Subscription
	private paginationPageSize = 5;
	private totalPages = 0;
	initialFlag: boolean = false;
	domLayout: any = "autoHeight";
	detailCellRenderer: any;
	getRowStyle: any;
	loadNew: boolean = false;
	pagesCount: number = 1;
	defaultRowsCount: number = 1000;
	pageNumber: number = 1;
	totalRecords: number;
	filterValue: string = '';
	loadingOverlayComponent: any;
	loadingOverlayComponentParams: any;
	loadMessage: string = "Loading Next 1000 Records..."
	showing: any;
	detailRowHeight: number;
	avalilableResults: number = 0;
	minimize: any = true;
	countryList: any;
	exportData: any;
	count: any;
	isSubscriptionEqual: boolean = false;
	allExpand: boolean = false;
	gridId: String;
	expandCollapseButtonValue: String = "Expand all rows"
	confirmDialogRef: MatDialogRef<ConfimDeleteDialogComponent>;
	infoDialogRef: MatDialogRef<InfoDialogComponent>;
	get PaginationPageSize(): number {
		return this.paginationPageSize;
	}

	get gridAPI(): GridApi {
		return this.gridOptions.api;
	}

	get TotalPages(): number {
		return this.totalPages;
	}

	constructor(public monitoringService: MonitoringService, public dialog: MatDialog,
		public loaderService: LoaderService, public translateService: TranslateService,
		private homeService: HomeService, public _notificationservice: NotificationService, public router: Router,
		public iconRegistry: MatIconRegistry, public sanitizer: DomSanitizer) {
		if (window.screen.width > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
		iconRegistry
			.addSvgIcon('reupload', sanitizer.bypassSecurityTrustResourceUrl('assets/img/export.svg'))
			.addSvgIcon('deleted', sanitizer.bypassSecurityTrustResourceUrl('assets/img/previously-deleted.svg'))
		this.getColDefs();
		// window.scroll(0, 0);
		this.gridOptions = <GridOptions>{
			onGridReady: () => {
				setTimeout(() => {
					this.gridOptions.api?.sizeColumnsToFit();
				}, 1000);
				this.totalPages = this.gridOptions.api?.paginationGetTotalPages();
			},
			onGridSizeChanged: () => {
				this.gridOptions.api.sizeColumnsToFit();
			},
			noRowsOverlayComponent: 'myNoRowsComponent',
			noRowsOverlayComponentParams: {
				"component": "previously-deleted"
			},
			rowSelection: 'multiple',
			pagination: true,
			paginationPageSize: 10,
			animateRows: true,
			suppressCellSelection: true,
			enableCellTextSelection: true,
			suppressContextMenu: true,
			defaultColDef: {
				flex: 1,
				wrapText: true,
				resizable: true,
				autoHeight: true,
				unSortIcon: true,
				sortable: true,
				suppressMenu: true,
				lockPosition: true,
				tooltipComponent: 'customTooltip'
			}
		};
		this.detailCellRenderer = 'myDetailCellRenderer';
		this.detailRowHeight = 150;
		this.getRowStyle = (params: any) => {
			if (params.node.rowIndex % 2 !== 0) {
				return { background: '#F8F8F8' };
			}
		};
		this.gridOptions.frameworkComponents = {
			"cellRenderer": GridCellRendererComponent,
			"customTooltip": CustomToolTipComponent,
			"expandCellRenderer": ExpandGridCellRendererComponent,
			myDetailCellRenderer: MonitorAllRecordDataCellRenderer,
			customLoadingOverlay: GridLoaderComponent,
			myNoRowsComponent: MyNoRowsComponent
		};

		this.loadingOverlayComponent = 'customLoadingOverlay';
		this.loadingOverlayComponentParams = {
			loadingMessage: this.loadMessage
		};
		this.tooltipShowDelay = 0;

		this.icons = {
			sortUnSort: '<img src="../../../assets/img/sort.svg">',
			sortAscending: '<img src="../../../assets/img/sort-asc.svg">',
			sortDescending: '<img src="../../../assets/img/sort-desc.svg">'
		};
		this.monitoringService.setSid(localStorage.getItem('sessionID'));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.addedRecord?.currentValue) {
			this.refreshGrid();
		}
	}

	onGridReady(params: any) {
		this.gridColumnApi = params.columnApi;
		this.api = params.api;
		this.gridOptions.api.sizeColumnsToFit();
		this.loadRecords(this.defaultRowsCount, this.pageNumber);
	}

	ngOnInit(): void {
		this.$langEventSubscription = this.changeLangEvent.subscribe(() => this.changeLanguage());
	}

	refreshGrid() {
		this.defaultRowsCount = 1000;
		this.pageNumber = 1;
		this.filterValue = " ";
		this.loadRecords(this.defaultRowsCount, this.pageNumber);
	}

	ngAfterViewInit(): void {
		this.gridAPI.resetRowHeights();
	}

	onFilterChanged(event: any) {
		if (this.gridOptions.api.getDisplayedRowCount() == 0) {
			this.gridAPI.showNoRowsOverlay();
		} else {
			this.gridAPI.hideOverlay();
		}
		this.gridOptions.api.deselectAll();
	}

	getColDefs() {
		this.translateService.get(['monitor.select', 'monitor.entityIdentification', 'monitor.monitorRecords.availableAlerts', 'monitor.monitorRecords.entityStatus',
			'monitor.clientFileRef', 'monitor.action']).subscribe(value => {
				this.columnDefs = [
					// {
					// 	field: 'select',
					// 	headerName: value['monitor.select'],
					// 	filter: true,
					// 	width: 60,
					// 	maxWidth: 100,
					// 	sortable: false,
					// 	checkboxSelection: true,
					// 	headerCheckboxSelection: false,
					// 	headerCheckboxSelectionFilteredOnly: true,
					// 	cellStyle: { 'overflow': 'visible', 'justify-content': 'center'}
					// },
					{
						field: 'entityName',
						headerName: value['monitor.entityIdentification'],
						filter: "agTextColumnFilter",
						width: this.isMobile ? 200 : 375,
						maxWidth: this.isMobile ? 800 : 500,
						cellRenderer: function (param: any) {
							if (window.screen.width > 1000) {
								if (param.data.entityType == "I") {
									if (param.data.isRecentlyAdded == 'Y') {
										var row = "NRIC / Passport No. " + param.data.entityId + "<span style='float: right; position: absolute; top: 10px; font-size: 13px; right: 20px; padding: 1px 8px 2px 8px; background: #EAEAEA; border-radius: 12px;'>Recently Added</span>" + "<br>";
										if (param.data.entityName)
											row = row + "<span style='padding-right: 140px;display: block;'>" + param.data.entityName + "</span>";
										return row;
									} else {
										var row = "NRIC / Passport No. " + param.data.entityId + "</br>";
										if (param.data.entityName)
											row = row + "<span style='padding-right: 140px;display: block;'>" + param.data.entityName;
										return row;
									}
								} else {
									if (param.data.isRecentlyAdded == 'Y') {
										var row = "Entity Number. " + param.data.entityId + "<span style='float: right; position: absolute; top: 10px; font-size: 13px; right: 20px; padding: 1px 8px 2px 8px; background: #EAEAEA; border-radius: 12px;'>Recently Added</span>" + "<br>";
										if (param.data.entityName)
											row = row + "<span style='padding-right: 140px;display: block;'>" + param.data.entityName + "</span>";
										return row;
									} else {
										var row = "Entity Number. " + param.data.entityId + "</br>";
										if (param.data.entityName)
											row = row + "<span style='padding-right: 140px;display: block;'>" + param.data.entityName + "</span>";
										return row;
									}
								}
							} else {
								if (param.data.entityType == "I") {
									if (param.data.isRecentlyAdded == 'Y') {
										var row = "NRIC / Passport No. " + param.data.entityId;
										if (param.data.entityName)
											row = row + "<span style='display: block;'>" + param.data.entityName + "</span>" + "</br>" + "<span style='font-size: 13px; right: 20px; padding: 1px 8px 2px 8px; background: #EAEAEA; border-radius: 12px;'>Recently Added</span>";
										return row;
									} else {
										var row = "NRIC / Passport No. " + param.data.entityId + "</br>";
										if (param.data.entityName)
											row = row + "<span style='display: block;'>" + param.data.entityName + "</span>";
										return row;
									}
								} else {
									if (param.data.isRecentlyAdded == 'Y') {
										var row = "Entity Number. " + param.data.entityId;
										if (param.data.entityName)
											row = row + "<span style='display: block;'>" + param.data.entityName + "</span>" + "</br>" + "<span style='font-size: 13px; right: 20px; padding: 1px 8px 2px 8px; background: #EAEAEA; border-radius: 12px;'>Recently Added</span>";
										return row;
									} else {
										var row = "Entity Number. " + param.data.entityId + "</br>";
										if (param.data.entityName)
											row = row + "<span style='display: block;'>" + param.data.entityName + "</span>";
										return row;
									}
								}
							}

						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '9px 18px', 'font-size': '14px' }
					},
					{
						field: 'alertType',
						headerName: value['monitor.monitorRecords.availableAlerts'],
						width: 180,
						minWidth: 180,
						hide: this.isMobile,
						sortable: false,
						cellRenderer: "cellRenderer",
						cellRendererParams: {
							label: 'monitor-alerts'
						},
						cellStyle: { 'padding-bottom': '3px' }
					},
					{

						field: 'entityStatus',
						headerName: value['monitor.monitorRecords.entityStatus'],
						tooltipField: 'address',
						width: 110,
						minWidth: 110,
						hide: this.isMobile,
						filter: "agTextColumnFilter",
						suppressMenu: false,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '9px 18px' }
					},
					{
						field: 'clientFileReference',
						headerName: value['monitor.clientFileRef'],
						tooltipField: 'clientFileRef',
						sortable: false,
						tooltipComponentParams: { type: 'fileRef' },
						width: 150,
						minWidth: 150,
						hide: this.isMobile,
						cellRenderer: "cellRenderer",
						cellRendererParams: {
							label: 'monitor-alert-clientfileref'
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '9px 18px' }
					},
					{
						width: this.isMobile ? 60 : 120,
						maxWidth: this.isMobile ? 60 : 130,
						sortable: false,
						headerName: this.isMobile ? " " : value['monitor.action'],
						cellRenderer: "expandCellRenderer",
						cellRendererParams: {
							onClick: this.onActionClick.bind(this),
							label: 'monitor-all-records'
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '6px 18px' }
					}
				]
			});
	}

	ngOnDestroy(): void {
		if (this.mySubscription) {
			this.mySubscription.unsubscribe();
		}
		if (this.$getDataSubscription) {
			this.$getDataSubscription.unsubscribe();
		}
	}

	changeLanguage() {
		this.getColDefs();
		this.gridOptions.api.refreshHeader({ forrce: true });
		setTimeout(() => {
			this.gridOptions.api?.sizeColumnsToFit();
		}, 100);
	}

	removeFilter() {
		this.defaultRowsCount = 1000;
		this.pageNumber = 1;
		this.loadNew = false;
		this.filterValue = "";
		this.loadRecords(this.defaultRowsCount, this.pageNumber);
	}

	ApplyFilter() {
		this.loadNew = false;
		this.pageNumber = 1;
		this.loadRecords(1000, this.pageNumber);
	}

	loadRecords(defaultRowsCount: number, pageNumber: number) {
		this.api.showLoadingOverlay();
		this.$getDataSubscription = this.monitoringService.getAllMonitoringRecords(defaultRowsCount, pageNumber, this.filterValue).subscribe((data: any) => {
			if (data?.success) {
				var newData = data.data;
				if (newData.records.length > 0) {
					var oldPage = this.loadNew ? this.gridOptions.api?.paginationGetTotalPages() : null;
					var arr = this.records;
					this.count = data.data.recordCount;
					if (this.loadNew) {
						newData.records.map((row: any, index: number) => {
							if (row.entityType == "I") {
								row.entityStatus = "N.A";
							}
							arr.push(row);
							if (index == newData.records.length - 1) {
								this.records = arr.map((record: any) => ({
									...record,
									expanded: false,
								}));
								this.gridOptions.api.setRowData(this.records);
								this.loadNew = false;
								this.avalilableResults = this.records.length;
								this.gridAPI.refreshCells();
							}
						});
					} else {
						newData?.records.map((alert: any) => {
							if (alert.entityType == "I") {
								alert.entityStatus = "N.A";
							}
						});
						this.records = newData.records.map((record: any) => ({
							...record,
							expanded: false,
						}));
						this.totalRecords = newData.recordCount;

					}
					this.gridId = "monitorListGrid";
					this.avalilableResults = this.records.length;
					this.pagesCount = Math.floor(this.totalRecords / this.defaultRowsCount);
					if ((this.totalRecords / this.defaultRowsCount) % 1 > 0) {
						this.pagesCount = this.pagesCount + 1;
					}
					var from = (pageNumber - 1) * defaultRowsCount + 1;
					var to = pageNumber * defaultRowsCount;
					if (pageNumber == this.pagesCount) {
						to = this.totalRecords;
					}
					this.showing = {
						'from': from,
						'to': to,
						'loadPage': oldPage
					}
					this.records.map((data: any) => {
						data.isAllMonitored = true
					});
					if (localStorage.getItem('ACTION') == 'ADDED') {
						localStorage.removeItem('ACTION');
						this.translateService.get(['monitor.action_added']).subscribe(value => {
							this._notificationservice.success(value['monitor.action_added']);
						});
					} else if (localStorage.getItem('ACTION') == 'EDITED') {
						localStorage.removeItem('ACTION');
						this.translateService.get(['monitor.action_edited']).subscribe(value => {
							this._notificationservice.success(value['monitor.action_edited']);
						});
					} else if (localStorage.getItem('ACTION') == 'RESTORE') {
						localStorage.removeItem('ACTION');
						this.translateService.get(['monitor.action_restored']).subscribe(value => {
							this._notificationservice.restore(value['monitor.action_restored']);
						});
					}
					this.api.hideOverlay();
				} else {
					this.records = [];
					this.gridId = "monitorListGridMobile"
					this.api.hideOverlay();
				}
			} else {
				this.records = [];
				this.gridId = "monitorListGridMobile"
				this.api.hideOverlay();
				this.openTryAgainPopup();
			}
		}, (err: any) => {
			this.api.hideOverlay();
			this.openTryAgainPopup();
		});
	}

	openTryAgainPopup() {
		this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
			let dialogRef = this.dialog.open(ErrorDialogComponent, {
				disableClose: true,
				width: this.isMobile ? 'auto' : '37vw',
				data: {
					errorMessage: value['errorMsg.pleaseTryAgain']
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					this.loadRecords(this.defaultRowsCount, this.pageNumber);
				}
			});
		})
	}

	loadNextRecords() {
		this.loadNew = true;
		this.loadRecords(this.defaultRowsCount, ++this.pageNumber);
	}

	loadPreviousRecords() {
	}

	goToPreviouslyDeleted() {
		this.router.navigate(['/monitor/deleted']);
	}


	deleteEntity(ev: any) {
		this.loaderService.isLoading.next(true);
		this.monitoringService.deleteMonitoringRecord(ev.data.entityId).subscribe((data: any) => {
			data = JSON.parse(data);
			if (data.success) {
				this.loaderService.isLoading.next(false);
				this.records = this.records.filter((record: any) => record.entityId !== ev.data.entityId);
				this.avalilableResults = this.records.length;
				this.totalRecords = this.totalRecords - 1;
				this.showing.to = this.totalRecords;
				this.gridAPI.redrawRows();
				this.translateService.get(['monitor.action_deleted']).subscribe(value => {
					this._notificationservice.delete(value['monitor.action_deleted']);
				});
			} else {
				this.loaderService.isLoading.next(false);
				this.openDeleteAgainPopup(ev);
			}
		}, (err: any) => {
			this.loaderService.isLoading.next(false);
			this.openDeleteAgainPopup(ev);
		});
	}

	openDeleteAgainPopup(ev: any) {
		this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
			let dialogRef = this.dialog.open(ErrorDialogComponent, {
				disableClose: true,
				width: '37vw',
				data: {
					errorMessage: value['errorMsg.pleaseTryAgain']
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					this.deleteEntity(ev);
				}
			});
		})
	}

	deleteUnrestrictedAlerts(ev: any) {
		this.loaderService.isLoading.next(true);
		this.monitoringService.deleteMonitoringRecord(ev.data.entityId).subscribe((data: any) => {
			data = JSON.parse(data);
			if (data.success) {
				this.loaderService.isLoading.next(false);
				ev.data.availableAlerts.map((alertList: any) => {
					if (alertList.monitoringType != "LMS" && alertList.monitoringType != "NBB" && alertList.monitoringType != "DNSA") {
						alertList.isSubscribed = 0;
					}
				});
				this.gridAPI.redrawRows();
			} else {
				this.loaderService.isLoading.next(false);
				this.openDeleteUnrestrictedAgainPopup(ev);
			}
		}, (err: any) => {
			this.loaderService.isLoading.next(false);
			this.openDeleteUnrestrictedAgainPopup(ev);
		});
	}

	openDeleteUnrestrictedAgainPopup(ev: any) {
		this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
			let dialogRef = this.dialog.open(ErrorDialogComponent, {
				disableClose: true,
				width: '37vw',
				data: {
					errorMessage: value['errorMsg.pleaseTryAgain']
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					this.deleteUnrestrictedAlerts(ev);
				}
			});
		})
	}

	onActionClick(ev: any) {
		this.gridAPI.refreshCells(ev);
		if (ev.label === 'update') {
			this.gridAPI.refreshCells(ev);
			if (ev.data.entityType == 'C') {
				this.onSubmitCompany(ev.data);
			} else {
				this.onSubmitIndividual(ev.data);
			}
		}

		if (ev.label === 'expand') {
			ev.data.expanded = !ev.node.expanded
			ev.node.setExpanded(!ev.node.expanded);
			this.gridAPI.refreshCells(ev);
		}

		if (ev.label === 'delete') {
			this.gridAPI.refreshCells(ev);
			this.isSubscriptionEqual = false;
			ev.data.availableAlerts.map((res: any) => {
				ev.data.availableAlerts.map((validate: any) => {
					if (res.monitoringType != validate.monitoringType) {
						if (res.subscriptionId == validate.subscriptionId)
							this.isSubscriptionEqual = true;
					}
				});
			});
			var subscribedAlerts = ev.data.availableAlerts.filter((data: any) => data.isSubscribed == true || data.isSubscribed == '1');
			var subscriptions: any = {
				"BWS": false,
				"CMS": false,
				"LMS": false,
				"NBB": false,
				"DNSA": false
			}
			var deleteMessage: any = "Please note that upon deletion, all alert types for this entity will also be unsubscribed.";
			subscribedAlerts.map((res: any) => {

				switch (res.monitoringType) {
					case 'BWS':
						subscriptions.BWS = true;
						break;
					case 'CMS':
						subscriptions.CMS = true;
						break;
					case 'LMS':
						subscriptions.LMS = true;
						break;
					case 'NBB':
						subscriptions.NBB = true;
						break;
					case 'DNSA':
						subscriptions.DNSA = true;
						break;
				}
			});

			switch (true) {
				case subscribedAlerts.length > 0 && !subscriptions.LMS && !subscriptions.NBB && !subscriptions.DNSA:

					deleteMessage = "Please note that upon deletion, all alert types for this entity will also be unsubscribed.";
					this.confirmDialogRef = this.dialog.open(ConfimDeleteDialogComponent, {
						disableClose: true,
						width: this.isMobile ? 'auto' : '37vw',
					});
					this.confirmDialogRef.componentInstance.confirmMessage = deleteMessage;
					this.confirmDialogRef.afterClosed().subscribe(res => {
						this.gridAPI.refreshCells(ev);
						if (res) {
							this.deleteEntity(ev);
						}
					});
					break;
				case subscribedAlerts.length > 1 && !subscriptions.LMS && subscriptions.NBB && !subscriptions.DNSA:

					deleteMessage = "Please note that upon deletion, all alert types for this entity, except Payment alert will be unsubscribed.";
					this.confirmDialogRef = this.dialog.open(ConfimDeleteDialogComponent, {
						disableClose: true,
						width: this.isMobile ? 'auto' : '37vw',
					});
					this.confirmDialogRef.componentInstance.confirmMessage = deleteMessage;
					this.confirmDialogRef.afterClosed().subscribe(res => {
						this.gridAPI.refreshCells(ev);
						if (res) {
							this.deleteUnrestrictedAlerts(ev);
						}
					});
					break;
				case subscribedAlerts.length > 1 && subscriptions.LMS && !subscriptions.NBB && !subscriptions.DNSA:

					deleteMessage = "Please note that upon deletion, all alert types for this entity, except Legal Monitoring Service alert will be unsubscribed.";
					this.confirmDialogRef = this.dialog.open(ConfimDeleteDialogComponent, {
						disableClose: true,
						width: this.isMobile ? 'auto' : '37vw',
					});
					this.confirmDialogRef.componentInstance.confirmMessage = deleteMessage;
					this.confirmDialogRef.afterClosed().subscribe(res => {
						this.gridAPI.refreshCells(ev);
						if (res) {
							this.deleteUnrestrictedAlerts(ev);
						}
					});
					break;
				case subscribedAlerts.length == 1 && !subscriptions.LMS && subscriptions.NBB && !subscriptions.DNSA:

					deleteMessage = "Deleting this entity is not allowed.";
					this.infoDialogRef = this.dialog.open(InfoDialogComponent, {
						disableClose: true,
						width: this.isMobile ? 'auto' : '30vw',
					});
					this.translateService.get(['confirmAction.action', 'confirmAction.deleteNotAllowed']).subscribe(value => {
						this.infoDialogRef.componentInstance.action = encodeURIComponent((value['confirmAction.action'])).replace(/%20/g, " ");
						this.infoDialogRef.componentInstance.confirmMessage = deleteMessage;
					});
					this.infoDialogRef.afterClosed().subscribe(res => {
						this.gridAPI.refreshCells(ev);
					});
					break;
				case subscribedAlerts.length == 1 && !subscriptions.LMS && !subscriptions.NBB && subscriptions.DNSA:

					deleteMessage = "Deleting this entity is not allowed.";
					this.infoDialogRef = this.dialog.open(InfoDialogComponent, {
						disableClose: true,
						width: this.isMobile ? 'auto' : '30vw',
					});
					this.translateService.get(['confirmAction.action', 'confirmAction.deleteNotAllowed']).subscribe(value => {
						this.infoDialogRef.componentInstance.action = encodeURIComponent((value['confirmAction.action'])).replace(/%20/g, " ");
						this.infoDialogRef.componentInstance.confirmMessage = deleteMessage;
					});
					this.infoDialogRef.afterClosed().subscribe(res => {
						this.gridAPI.refreshCells(ev);
					});
					break;
				case subscribedAlerts.length == 3 && subscriptions.LMS && subscriptions.NBB && subscriptions.DNSA:

					deleteMessage = "Deleting this entity is not allowed.";
					this.infoDialogRef = this.dialog.open(InfoDialogComponent, {
						disableClose: true,
						width: this.isMobile ? 'auto' : '30vw',
					});
					this.translateService.get(['confirmAction.action', 'confirmAction.deleteNotAllowed']).subscribe(value => {
						this.infoDialogRef.componentInstance.action = encodeURIComponent((value['confirmAction.action'])).replace(/%20/g, " ");
						this.infoDialogRef.componentInstance.confirmMessage = deleteMessage;
					});
					this.infoDialogRef.afterClosed().subscribe(res => {
						this.gridAPI.refreshCells(ev);
					});
					break;
				case subscribedAlerts.length == 2 && subscriptions.LMS && subscriptions.NBB && !subscriptions.DNSA:

					deleteMessage = "Deleting this entity is not allowed.";
					this.infoDialogRef = this.dialog.open(InfoDialogComponent, {
						disableClose: true,
						width: this.isMobile ? 'auto' : '30vw',
					});
					this.translateService.get(['confirmAction.action', 'confirmAction.deleteNotAllowed']).subscribe(value => {
						this.infoDialogRef.componentInstance.action = encodeURIComponent((value['confirmAction.action'])).replace(/%20/g, " ");
						this.infoDialogRef.componentInstance.confirmMessage = deleteMessage;
					});
					this.infoDialogRef.afterClosed().subscribe(res => {
						this.gridAPI.refreshCells(ev);
					});
					break;
				case subscribedAlerts.length == 2 && subscriptions.LMS && !subscriptions.NBB && subscriptions.DNSA:

					deleteMessage = "Deleting this entity is not allowed.";
					this.infoDialogRef = this.dialog.open(InfoDialogComponent, {
						disableClose: true,
						width: this.isMobile ? 'auto' : '30vw',
					});
					this.translateService.get(['confirmAction.action', 'confirmAction.deleteNotAllowed']).subscribe(value => {
						this.infoDialogRef.componentInstance.action = encodeURIComponent((value['confirmAction.action'])).replace(/%20/g, " ");
						this.infoDialogRef.componentInstance.confirmMessage = deleteMessage;
					});
					this.infoDialogRef.afterClosed().subscribe(res => {
						this.gridAPI.refreshCells(ev);
					});
					break;
				case subscribedAlerts.length == 1 && subscriptions.LMS && !subscriptions.NBB && !subscriptions.DNSA:

					deleteMessage = "You are not allowed to delete your Legal Monitoring Service alert. Legal Monitoring Service alert will be automatically be removed 90 days after adding.";
					let dialogRef = this.dialog.open(CommonDialogComponent, {
						width: this.isMobile ? 'auto' : '34vw',
						height: '270px',
						data: {
							"key": "lmsUnsuscribes"
						}
					});
					dialogRef.afterClosed().subscribe(res => {
						this.gridAPI.refreshCells(ev);
					});
					break;
				case subscribedAlerts.length > 1 && !subscriptions.LMS && !subscriptions.NBB && subscriptions.DNSA:

					deleteMessage = "Please note that upon deletion, all alert types for this entity, except Daily New Suit alert will be unsubscribed.";
					this.confirmDialogRef = this.dialog.open(ConfimDeleteDialogComponent, {
						disableClose: true,
						width: this.isMobile ? 'auto' : '37vw',
					});
					this.confirmDialogRef.componentInstance.confirmMessage = deleteMessage;
					this.confirmDialogRef.afterClosed().subscribe(res => {
						this.gridAPI.refreshCells(ev);
						if (res) {
							this.deleteUnrestrictedAlerts(ev);
						}
					});
					break;
				case subscriptions.BWS && !subscriptions.CMS && subscriptions.LMS && subscriptions.NBB && subscriptions.DNSA:

					deleteMessage = "Please note that upon deletion, only Business Watch Service alert will be unsubscribed.";
					this.confirmDialogRef = this.dialog.open(ConfimDeleteDialogComponent, {
						disableClose: true,
						width: this.isMobile ? 'auto' : '37vw',
					});
					this.confirmDialogRef.componentInstance.confirmMessage = deleteMessage;
					this.confirmDialogRef.afterClosed().subscribe(res => {
						this.gridAPI.refreshCells(ev);
						if (res) {
							this.deleteUnrestrictedAlerts(ev);
						}
					});
					break;
				case subscriptions.BWS && subscriptions.LMS && subscriptions.NBB && !subscriptions.DNSA:

					deleteMessage = "Please note that upon deletion, only Business Watch Service alert will be unsubscribed.";
					this.confirmDialogRef = this.dialog.open(ConfimDeleteDialogComponent, {
						disableClose: true,
						width: this.isMobile ? 'auto' : '37vw',
					});
					this.confirmDialogRef.componentInstance.confirmMessage = deleteMessage;
					this.confirmDialogRef.afterClosed().subscribe(res => {
						this.gridAPI.refreshCells(ev);
						if (res) {
							this.deleteUnrestrictedAlerts(ev);
						}
					});
					break;
				case subscriptions.LMS && !subscriptions.NBB && subscriptions.DNSA && subscriptions.BWS:

					deleteMessage = "Please note that upon deletion, only Business Watch Service alert will be unsubscribed.";
					this.confirmDialogRef = this.dialog.open(ConfimDeleteDialogComponent, {
						disableClose: true,
						width: this.isMobile ? 'auto' : '37vw',
					});
					this.confirmDialogRef.componentInstance.confirmMessage = deleteMessage;
					this.confirmDialogRef.afterClosed().subscribe(res => {
						this.gridAPI.refreshCells(ev);
						if (res) {
							this.deleteUnrestrictedAlerts(ev);
						}
					});
					break;
				case !subscriptions.LMS && subscriptions.NBB && subscriptions.DNSA && subscriptions.BWS || subscriptions.CMS:

					deleteMessage = "Please note that upon deletion, all alert types for this entity, except Payment and Daily New Suit alerts will be unsubscribed."
					this.confirmDialogRef = this.dialog.open(ConfimDeleteDialogComponent, {
						disableClose: true,
						width: this.isMobile ? 'auto' : '37vw',
					});
					this.confirmDialogRef.componentInstance.confirmMessage = deleteMessage;
					this.confirmDialogRef.afterClosed().subscribe(res => {
						this.gridAPI.refreshCells(ev);
						if (res) {
							this.deleteUnrestrictedAlerts(ev);
						}
					});
					break;
				default:
					deleteMessage = "Deleting this entity is not allowed.";
					this.infoDialogRef = this.dialog.open(InfoDialogComponent, {
						disableClose: true,
						width: this.isMobile ? 'auto' : '30vw',
					});
					this.translateService.get(['confirmAction.action', 'confirmAction.deleteNotAllowed']).subscribe(value => {
						this.infoDialogRef.componentInstance.action = encodeURIComponent((value['confirmAction.action'])).replace(/%20/g, " ");
						this.infoDialogRef.componentInstance.confirmMessage = deleteMessage;
					});
					this.infoDialogRef.afterClosed().subscribe(res => {
						this.gridAPI.refreshCells(ev);
					});
			}
		}
	}

	onSubmitIndividual(res: any) {
		let dialogRef = this.dialog.open(IndividualPickAlertComponent, {
			disableClose: true,
			width: this.isMobile ? "auto" : "70vw",
			panelClass: 'advanced-search-dialog',
			data: {
				data: res,
				isEdit: true
			}
		});
		dialogRef.afterClosed().subscribe(edited => {
			if (edited) {
				var isEdited: boolean = false;
				res?.availableAlerts.map((mainAlert: any) => {
					edited.map((editedAlert: any) => {
						if (editedAlert.monitoringType == mainAlert.monitoringType) {
							mainAlert.isSubscribed = editedAlert.isSubscribed;
							if (editedAlert.isSubscribed == true) {
								isEdited = true;
							}
						}
					});
				});
				if (isEdited) {
					this.gridAPI.redrawRows();
					this.translateService.get(['monitor.action_edited']).subscribe(value => {
						this._notificationservice.edit(value['monitor.action_edited']);
					});
				} else {
					this.records = this.records.filter((record: any) => record.entityId !== res.entityId);
					this.avalilableResults = this.records.length;
					this.totalRecords = this.totalRecords - 1;
					this.showing.to = this.totalRecords;
					this.gridAPI.redrawRows();
					this.translateService.get(['monitor.action_deleted']).subscribe(value => {
						this._notificationservice.delete(value['monitor.action_deleted']);
					});
				}
			} else {
				this.gridAPI.redrawRows();
			}
		});
	}

	onSubmitCompany(res: any) {
		let payload = {
			countryCode: res.countryCode,
			name: res.entityName,
			number: res.entityId
		}
		this.editEntity = res;
		this.loaderService.isLoading.next(true);
		this.monitoringService.searchCompanies(payload).subscribe((data: any) => {
			this.loaderService.isLoading.next(false);
			if (typeof data == 'string' && JSON.parse(data)?.success) {
				var searchResults = JSON.parse(data);
				if (searchResults?.data?.length > 0) {
					var isExist = false;
					searchResults.data.map((result: any, index: number) => {
						if (result.entNo == res.entityId) {
							isExist = true;
							res['edit'] = true;
							let dialogRef = this.dialog.open(CompanyPickAlertComponent, {
								disableClose: true,
								width: this.isMobile ? "auto" : "70vw",
								panelClass: 'advanced-search-dialog',
								data: {
									results: res
								}
							});
							dialogRef.afterClosed().subscribe(edited => {
								if (edited) {
									var isEdited: boolean = false;
									res?.availableAlerts.map((mainAlert: any) => {
										edited.map((editedAlert: any) => {
											if (editedAlert.monitoringType == mainAlert.monitoringType) {
												mainAlert.isSubscribed = editedAlert.check;
												if (editedAlert.check == true) {
													isEdited = true;
												}
											}
										});
									});
									if (isEdited) {
										this.gridAPI.redrawRows();
										this.translateService.get(['monitor.action_edited']).subscribe(value => {
											this._notificationservice.edit(value['monitor.action_edited']);
										});
									} else {
										this.records = this.records.filter((record: any) => record.entityId !== res.entityId);
										this.avalilableResults = this.records.length;
										this.totalRecords = this.totalRecords - 1;
										this.showing.to = this.totalRecords;
										this.gridAPI.redrawRows();
										this.translateService.get(['monitor.action_deleted']).subscribe(value => {
											this._notificationservice.delete(value['monitor.action_deleted']);
										});
									}
								} else {
									this.gridAPI.redrawRows();
								}
							});
						} else if (searchResults.data?.length - 1 == index && isExist == false) {
							res['edit'] = true;
							let dialogRef = this.dialog.open(ManuallPickAlertComponent, {
								disableClose: true,
								width: this.isMobile ? "auto" : "70vw",
								panelClass: 'advanced-search-dialog',
								data: {
									results: res
								}
							});
							dialogRef.afterClosed().subscribe(edited => {
								if (edited) {
									var isEdited: boolean = false;
									res?.availableAlerts.map((mainAlert: any) => {
										edited.map((editedAlert: any) => {
											if (editedAlert.monitoringType == mainAlert.monitoringType) {
												mainAlert.isSubscribed = editedAlert.check;
												if (editedAlert.check == true) {
													isEdited = true;
												}
											}
										});
									});
									if (isEdited) {
										this.gridAPI.redrawRows();
										this.translateService.get(['monitor.action_edited']).subscribe(value => {
											this._notificationservice.edit(value['monitor.action_edited']);
										});
									} else {
										this.records = this.records.filter((record: any) => record.entityId !== res.entityId);
										this.avalilableResults = this.records.length;
										this.totalRecords = this.totalRecords - 1;
										this.showing.to = this.totalRecords;
										this.gridAPI.redrawRows();
										this.translateService.get(['monitor.action_deleted']).subscribe(value => {
											this._notificationservice.delete(value['monitor.action_deleted']);
										});
									}
								} else {
									this.gridAPI.redrawRows();
								}
							});
						}
					})
				} else {
					res['edit'] = true;
					let dialogRef = this.dialog.open(ManuallPickAlertComponent, {
						disableClose: true,
						width: this.isMobile ? "auto" : "70vw",
						panelClass: 'advanced-search-dialog',
						data: {
							results: res
						}
					});
					dialogRef.afterClosed().subscribe(edited => {
						if (edited) {
							var isEdited: boolean = false;
							res?.availableAlerts.map((mainAlert: any) => {
								edited.map((editedAlert: any) => {
									if (editedAlert.monitoringType == mainAlert.monitoringType) {
										mainAlert.isSubscribed = editedAlert.check;
										if (editedAlert.check == true) {
											isEdited = true;
										}
									}
								});
							});
							if (isEdited) {
								this.gridAPI.redrawRows();
								this.translateService.get(['monitor.action_edited']).subscribe(value => {
									this._notificationservice.edit(value['monitor.action_edited']);
								});
							} else {
								this.records = this.records.filter((record: any) => record.entityId !== res.entityId);
								this.avalilableResults = this.records.length;
								this.totalRecords = this.totalRecords - 1;
								this.showing.to = this.totalRecords;
								this.gridAPI.redrawRows();
								this.translateService.get(['monitor.action_deleted']).subscribe(value => {
									this._notificationservice.delete(value['monitor.action_deleted']);
								});
							}
						} else {
							this.gridAPI.redrawRows();
						}
					}, (err: any) => {

					});
				}
			} else {
				res['edit'] = true;
				let dialogRef = this.dialog.open(ManuallPickAlertComponent, {
					disableClose: true,
					width: this.isMobile ? "auto" : "70vw",
					panelClass: 'advanced-search-dialog',
					data: {
						results: res
					}
				});
				dialogRef.afterClosed().subscribe(edited => {
					if (edited) {
						var isEdited: boolean = false;
						res?.availableAlerts.map((mainAlert: any) => {
							edited.map((editedAlert: any) => {
								if (editedAlert.monitoringType == mainAlert.monitoringType) {
									mainAlert.isSubscribed = editedAlert.check;
									if (editedAlert.check == true) {
										isEdited = true;
									}
								}
							});
						});
						if (isEdited) {
							this.gridAPI.redrawRows();
							this.translateService.get(['monitor.action_edited']).subscribe(value => {
								this._notificationservice.edit(value['monitor.action_edited']);
							});
						} else {
							this.records = this.records.filter((record: any) => record.entityId !== res.entityId);
							this.avalilableResults = this.records.length;
							this.totalRecords = this.totalRecords - 1;
							this.showing.to = this.totalRecords;
							this.gridAPI.redrawRows();
							this.translateService.get(['monitor.action_deleted']).subscribe(value => {
								this._notificationservice.delete(value['monitor.action_deleted']);
							});
						}
					} else {
						this.gridAPI.redrawRows();
					}
				});
			}
		});
	}

	export() {
		if (this.count < 1000) {
			this.exportData = this.records;
			setTimeout(() => {
				let element = document.getElementById('excel-table');
				const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
				var wscols = [
					{ wch: 30 },
					{ wch: 30 },
					{ wch: 30 },
					{ wch: 30 },
					{ wch: 30 },
					{ wch: 30 }
				];
				ws['!cols'] = wscols;
				const wb: XLSX.WorkBook = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, 'Monitored_Names');
				XLSX.writeFile(wb, 'Monitored_names.xlsx');
				this.loaderService.isLoading.next(false);
			}, 3000);
		} else {
			this.loaderService.isLoading.next(true);
			this.monitoringService.exportRecords(this.count).subscribe((data: any) => {
				if (data.success) {
					this.exportData = data.data.records;
					// this.exportData.map((alert: any) => {
					// 	alert.availableAlerts = JSON.parse(alert.availableAlerts);
					// });
					setTimeout(() => {
						let element = document.getElementById('excel-table');
						const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
						var wscols = [
							{ wch: 30 },
							{ wch: 30 },
							{ wch: 30 },
							{ wch: 30 },
							{ wch: 30 },
							{ wch: 30 }
						];
						ws['!cols'] = wscols;
						const wb: XLSX.WorkBook = XLSX.utils.book_new();
						XLSX.utils.book_append_sheet(wb, ws, 'Monitored_Names');
						XLSX.writeFile(wb, 'Monitored_names.xlsx');
						this.loaderService.isLoading.next(false);
					}, 3000);
				} else {
					this.loaderService.isLoading.next(false);
					this.openExportTryAgainPopup();
				}
			},
				(err: any) => {
					this.loaderService.isLoading.next(false);
					this.openExportTryAgainPopup();
				});
		}

	}

	openExportTryAgainPopup() {
		this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
			let dialogRef = this.dialog.open(ErrorDialogComponent, {
				disableClose: true,
				width: '37vw',
				data: {
					errorMessage: value['errorMsg.pleaseTryAgain']
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					this.export();
				}
			});
		});
	}

	openEditTryAgainPopup() {
		this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
			let dialogRef = this.dialog.open(ErrorDialogComponent, {
				disableClose: true,
				width: this.isMobile ? 'auto' : '37vw',
				data: {
					errorMessage: value['errorMsg.pleaseTryAgain']
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					this.onSubmitCompany(this.editEntity);
				}
			});
		})
	}
	openFilterPopup() {
		let dialogRef = this.dialog.open(FilterPopUpComponent, {
			disableClose: true,
			width: this.isMobile ? 'auto' : '37vw',
			height: 'auto',
			panelClass: 'search-result-pop-up',
			data: {
				label: "monitor-all-record-filter-popup"
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res.data) {
				this.filterValue = res.data;
			} else {
				this.filterValue = '';
			}
			this.ApplyFilter();
		})
	}
	ExpandAll(data: any) {
		if (!data) {
			this.gridOptions.api.forEachNode((node: any) => {
				node.data.expanded = true;
				node.expanded = true;
			});
			this.allExpand = true;
			this.expandCollapseButtonValue = "Collapse all rows"
		} else {
			this.gridOptions.api.forEachNode((node: any) => {
				node.data.expanded = false;
				node.expanded = false;
			});
			this.allExpand = false;
			this.expandCollapseButtonValue = "Expand all rows"
		}
		this.gridOptions.api.onGroupExpandedOrCollapsed();
	}
}
