import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentViewComponent } from '../document-view/document-view.component';
import { HomeService } from '../home/home.service';
import { LoaderService } from '../services/loader.service';

@Component({
	selector: 'app-key-products',
	templateUrl: './key-products.component.html',
	styleUrls: ['./key-products.component.scss']
})

export class KeyProductsComponent {
	cellWidth:any = "100%"
	constructor(private dialog: MatDialog, private homeService: HomeService,
		private loaderService: LoaderService) { }

	sampleReport(offeringId: any) {
		this.loaderService.isLoading.next(true);
		this.homeService.sampleReport(offeringId).subscribe(res => {
			this.dialog.open(DocumentViewComponent, {
				width: '80vw',
				data: {
					response: res,
					format: 'PDF'
				}
			});
			this.loaderService.isLoading.next(false);
		}, err => {
			this.loaderService.isLoading.next(false);
		});
	}
	sampleReportForMoile(offeringId: any) {
		
		this.loaderService.isLoading.next(true);
		this.homeService.sampleReport(offeringId).subscribe(res => {
			const blob = new Blob([res], { type: "application/pdf" });
			let url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.setAttribute('download', 'Report.pdf');
			a.setAttribute('href', url);
			a.click();
			// var target: any = document.getElementById('blob-src-test-mobile');
			// target.src = (window.URL || window.webkitURL).createObjectURL(new Blob([res], { type: 'application/pdf' }));
			this.loaderService.isLoading.next(false);
		}, err => {
			this.loaderService.isLoading.next(false);
		});
	}
	userManualReport() {
		this.loaderService.isLoading.next(true);
		let orderSource = localStorage.getItem('orderSource');
		let lang = localStorage.getItem('lang');
		this.homeService.userManualReport(orderSource,lang).subscribe(res => {
			this.dialog.open(DocumentViewComponent, {
				width: '80vw',
				data: {
					response: res,
					format: 'PDF'
				}
			});
			this.loaderService.isLoading.next(false);
		}, err => {
			this.loaderService.isLoading.next(false);
		});
	}
}