<div class="footer">
    <p>
        &copy; {{year}} {{ 'app.footerPara1' | translate }}<br />
        {{ 'app.footerPara2' | translate }}
        <a target="_blank" href="https://uat.questnet.sg/terms.asp">
            {{ 'app.footerTerms&Condition' | translate }}
        </a>
        <a target="_blank" href="https://www.experian.com.sg/privacy-statement">
            {{ 'app.footerPrivacyStatement' | translate }}
        </a> <br />
        {{ 'app.footerPara3' | translate }}
    </p>
</div>