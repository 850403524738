import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { interval, Subscription } from 'rxjs';
import { ErrorDialogComponent } from 'src/app/error-dialog-box/error-dialog.component';
import { MonitoringService } from 'src/app/monitoring/monitoring.service';
import { LoaderService } from 'src/app/services/loader.service';
import { BulkUploadGridService } from '../bulk-upload-grid.service';

@Component({
    selector: 'upload-complete-dialog-box',
    templateUrl: './upload-complete-dialog.component.html',
    styleUrls: ['upload-complete-dialog.component.scss']
})

export class UploadCompleteDialogComponent implements OnDestroy {
    public confirmMessage: any = '';
    report: any;
    bulkUploadStatus: any;
    minutes: number = 0;
    loader: number = 0;
    mySubscription: Subscription;
    constructor( public dialogRef: MatDialogRef<UploadCompleteDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public uploadService: BulkUploadGridService, public service: MonitoringService, public loaderService: LoaderService, public translateService: TranslateService,
    private dialog: MatDialog ) { 
        this.report = data;
        this.bulkUploadStatus = data.data;
        this.loader = ( this.bulkUploadStatus.CurrentCount / this.bulkUploadStatus.TotalCount ) * 100;
        var val = this.bulkUploadStatus?.EstimatedTime / 60;
        this.minutes = Math.round(val);
        if(this.report.status == 'cancel') {
            this.getStatus();
        } else if(this.report.status == 'monitor-cancel') {
          this.getMonitoringStatus();
        }
    }

    getStatus() {
        this.mySubscription = interval(10000).subscribe((x => {
          this.uploadService.bulkUploadProcessingStatus(this.data.bulkOrderNumber).subscribe( 
            (res: any) => {
              if(res.success == true && res.data != null){
                this.bulkUploadStatus = JSON.parse(res.data.statusJson);
                this.loader = ( this.bulkUploadStatus.CurrentCount / this.bulkUploadStatus.TotalCount ) * 100;
                var val = this.bulkUploadStatus?.EstimatedTime / 60;
                this.minutes = Math.round(val);
                if(this.bulkUploadStatus.CurrentCount == this.bulkUploadStatus.TotalCount) {
                  this.mySubscription.unsubscribe();
                  this.dialogRef.updateSize('600px', '260px');
                  this.report.status = 'complete';
                }
              }
            });
        }));
      }

      getMonitoringStatus() {
        this.mySubscription = interval(10000).subscribe((x => {
          this.service.bulkUploadProcessingStatus(this.data.bulkAlertUploadNumber).subscribe( 
            (res: any) => {
              if(res.success == true && res.data != null){
                this.bulkUploadStatus = JSON.parse(res.data.statusJson);
                this.loader = ( this.bulkUploadStatus.CurrentCount / this.bulkUploadStatus.TotalCount ) * 100;
                var val = this.bulkUploadStatus?.EstimatedTime / 60;
                this.minutes = Math.round(val);
                if(this.bulkUploadStatus.CurrentCount == this.bulkUploadStatus.TotalCount) {
                  this.mySubscription.unsubscribe();
                  this.dialogRef.updateSize('600px', '260px');
                  this.report.status = 'complete';
                }
              }
            });
        }));
      }

      
      runInBackground() {
        this.dialogRef.close(false);
      }

      cancelProcessing() {
        this.loaderService.isLoading.next(true);
        this.uploadService.cancelProcessing(this.data.bulkOrderNumber).subscribe(
          (res: any) => {
            this.loaderService.isLoading.next(false);
            if(res.success == true) {
              this.bulkUploadStatus.bulkOrderStatus = "Cancelled";
              this.dialogRef.close(this.bulkUploadStatus);
            } else {
              this.tryAgainCancelProcessing()
            }
          },
          (err: any) => {
            this.tryAgainCancelProcessing()
          }
        );
      }

      tryAgainCancelProcessing() {
        this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
          let dialogRef = this.dialog.open(ErrorDialogComponent, {
            disableClose: true,
            width: '37vw',
            data: {
              errorMessage: value['errorMsg.pleaseTryAgain']
            }
          });
          dialogRef.afterClosed().subscribe(res => {
            if (res) {
              this.cancelProcessing();
            }
          });
        })
      }

      monitorcancelProcessing() {
        this.loaderService.isLoading.next(true);
        this.service.cancelProcessing(this.data.bulkAlertUploadNumber).subscribe(
          (res: any) => {
            this.loaderService.isLoading.next(false);
            if(res.success == true) {
              this.bulkUploadStatus.bulkOrderStatus = "Cancelled";
              this.dialogRef.close(this.bulkUploadStatus);
            } else {
              this.tryAgainMonitorcancelProcessing()
            }
          },
          (err: any) => {
            this.tryAgainMonitorcancelProcessing()
          }
        );
      }

      tryAgainMonitorcancelProcessing() {
        this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
          let dialogRef = this.dialog.open(ErrorDialogComponent, {
            disableClose: true,
            width: '37vw',
            data: {
              errorMessage: value['errorMsg.pleaseTryAgain']
            }
          });
          dialogRef.afterClosed().subscribe(res => {
            if (res) {
              this.tryAgainMonitorcancelProcessing();
            }
          });
        })
      }

      ngOnDestroy() {
        if(this.mySubscription)
        this.mySubscription.unsubscribe();
      }
}