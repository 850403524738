
<ng-template #deleteUpdatePopup>
	<ul>
		<li class="update-delete-popover" (click)="onUpdate($event)" [ngClass]="(params.data.entityType == 'C' || params.data.entityType == 'I' ) ? '' : 'disable-option'">
			<mat-icon class="download-icon">
				create
			</mat-icon>
			<a class="alignment">{{ 'gridActions.updateEntity' | translate }}</a>
		</li>
		<li class="update-delete-popover" (click)="onDelete($event)" [ngClass]="(params.data.entityType == 'C' || params.data.entityType == 'I' ) ? '' : 'disable-option'">
			<mat-icon class="download-icon">
				delete_forever
			</mat-icon>
			<a class="alignment">{{ 'gridActions.deleteEntity' | translate }}</a>
		</li>
	</ul>
</ng-template>

<div style="display: flex;" *ngIf="params.label == 'monitor-records'">
	<!-- <div style="margin-right: 12px;">
		<mat-icon class="shopping-icon" svgIcon="shopping">
		</mat-icon>
	</div> -->
	<!-- <div>
		<img class="icon-dots" src="/assets/img/icon-dots.svg"
			container="body" [ngbPopover]="deleteUpdatePopup"
			placement="bottom-right">
	</div> -->
	<div style="margin-right: 12px;" (click)="expand($event)">
		<mat-icon class="download-icon">remove_red_eye</mat-icon>
	</div>
	<div style="margin-right: 12px;" (click)="expandData()" *ngIf="isMobile">
		<mat-icon class="download-icon" *ngIf="!params.data.expanded">keyboard_arrow_down</mat-icon>
		<mat-icon class="download-icon" *ngIf="params.data.expanded">keyboard_arrow_up</mat-icon>
	</div>
</div>

<div style="display: flex;" *ngIf="params.label == 'monitor-all-records'">
	<!-- <div style="margin-right: 12px;">
		<mat-icon class="shopping-icon" svgIcon="shopping">
		</mat-icon>
	</div> -->
	<div class="desktop-view">
		<img class="icon-dots" src="/assets/img/icon-dots.svg"
			container="body" [ngbPopover]="deleteUpdatePopup"
			placement="bottom-right">
	</div>
	<!-- <div class="mobile-view">
		<img class="icon-dots" src="/assets/img/icon-dots.svg"
			container="body" (click)="openBottomSheet('deleteUpdatePopup')"
			placement="bottom-right">
	</div> -->
	<div style="margin-right: 12px;" (click)="expand($event)" class="align-icon" *ngIf="isMobile">
		<mat-icon class="expand-icon" *ngIf="!params.data.expanded">keyboard_arrow_down</mat-icon>
		<mat-icon class="expand-icon" *ngIf="params.data.expanded">keyboard_arrow_up</mat-icon>
	</div>
</div>

<div *ngIf="isMobile && params.label == 'bws-alert-action'">	
	<div (click)="expand($event)" class="align-icon">
		<mat-icon class="expand-icon" *ngIf="!params.data.expanded">keyboard_arrow_down</mat-icon>
		<mat-icon class="expand-icon" *ngIf="params.data.expanded">keyboard_arrow_up</mat-icon>
	</div>
</div>
<div *ngIf="isMobile && params.label == 'bws-gaz-action'">	
	<div (click)="expand($event)" class="align-icon">
		<mat-icon class="expand-icon" *ngIf="!params.data.expanded">keyboard_arrow_down</mat-icon>
		<mat-icon class="expand-icon" *ngIf="params.data.expanded">keyboard_arrow_up</mat-icon>
	</div>
</div>
<div *ngIf="isMobile && params.label == 'cms-alert-action'">	
	<div (click)="expand($event)" class="align-icon">
		<mat-icon class="expand-icon" *ngIf="!params.data.expanded">keyboard_arrow_down</mat-icon>
		<mat-icon class="expand-icon" *ngIf="params.data.expanded">keyboard_arrow_up</mat-icon>
	</div>
</div>

<div *ngIf="isMobile && params.label == 'lms-alert-action'">	
	<div (click)="expand($event)" class="align-icon">
		<mat-icon class="expand-icon" *ngIf="!params.data.expanded">keyboard_arrow_down</mat-icon>
		<mat-icon class="expand-icon" *ngIf="params.data.expanded">keyboard_arrow_up</mat-icon>
	</div>
</div>
<div *ngIf="isMobile && params.label == 'daily-new-suits-alert-action'">	
	<div (click)="expand($event)" class="align-icon">
		<mat-icon class="expand-icon" *ngIf="!params.data.expanded">keyboard_arrow_down</mat-icon>
		<mat-icon class="expand-icon" *ngIf="params.data.expanded">keyboard_arrow_up</mat-icon>
	</div>
</div>
