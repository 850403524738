import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
  year: any = new Date().getFullYear();
  constructor(private translateService: TranslateService) { }

  ngOnInit(): void {
  }

}