<div class="dialog-content-wrapper">
    <div class="dialog-content-wrapper-mobile">
        <div class="close-dialog">
            <mat-icon class="close-btn" (click)="matDialogRef.close(false)">close</mat-icon>
        </div> 
        <div class="cross-header m-b-0">
            <span class="confirm-title" *ngIf="!isMobile">
                <mat-icon class="download-icon">get_app</mat-icon>
                {{ 'monitor.exportAllAlerts.title' | translate }}
            </span>
            <span class="confirm-title" *ngIf="isMobile">
              <mat-icon class="download-icon-mobile">remove_red_eye</mat-icon>
              {{ 'monitor.export_view_mobile' | translate }}
          </span>
        </div>   
        <div>
        <div *ngIf="!isMobile && showTip">   
            <div class="toast sticky">
                <div class="success-color">
                    <mat-icon class="icon-success">
                      info_outline
                    </mat-icon>
                </div>
                <div class="text"> <strong class="tip">{{ 'monitor.action_tip' | translate }}:</strong> <span>Select alert type and the timeframe of alerts you would like to export or view.</span></div><mat-icon class="close" (click)="hideTip()">close</mat-icon>
            </div>
        </div>       
            <div class="zanna_div row">
                <div class="d_12_12 col p-0" style="padding-left: 0px;">
                    <div class="zanna_div table-head gradient" style="margin-top: 20px"></div>
                    <div class="zanna_div ymal-table row m-b-0" style="padding: 20px;margin-bottom: 30px;">
                        <div style="width: 55%; margin-top: 20px;" class="zanna_div row width-100 fs-2">
                            <label>Select Alert Type</label><br />
                            <ng-select class="fs-2" name="alerts" [items]="alertList" [(ngModel)]="alertType" [placeholder]="'placeholder.select_alert' | translate"
                                bindLabel="monitoringName" 
                                bindValue="monitoringType"
                                [virtualScroll]= true
                                appendTo="body"(change)="alertTypeChanged($event);">
                            </ng-select>
                        </div>
                        <div style="width: 55%; margin-top: 20px;" class="zanna_div row width-100 fs-2">
                            <label *ngIf="!isMobile">Export / View Settings <br /></label>
                            <label *ngIf="isMobile">View Settings <br /></label>
                            <ng-select class="fs-2" name="time" [items]="options" [(ngModel)]="export" 
                                bindLabel="value" 
                                bindValue="key"
                                [virtualScroll]= true
                                appendTo="body">
                            </ng-select>
                            <!-- <span style="margin-top:10px" *ngIf="!isMobile && export == currentDate">Export Alerts on: {{ currentDate }} </span> -->
                            <span style="margin-top:10px" *ngIf="!isMobile && export == options[0].key">Export Alerts on: {{ export }} </span>
                            <span style="margin-top:10px" *ngIf="!isMobile && export != currentDate && export != options[0].key">Export Alerts from: {{ export }}<span > - {{ currentDate }}</span></span>
                            <!-- <span style="margin-top:10px" *ngIf="isMobile && export == currentDate">View Alerts on: {{ currentDate }} </span> -->
                            <span style="margin-top:10px" *ngIf="isMobile && export == options[0].key">View Alerts on: {{ export }} </span>
                            <span style="margin-top:10px" *ngIf="isMobile && export != currentDate && export != options[0].key">View Alerts from: {{ export }}<span > - {{ currentDate }}</span></span>
                        </div>
                        <div fxLayout="col" fxLayoutAlign="space-around start" *ngIf="export =='Month'" style="margin-top: 20px;" class="desktop-view">
                            <div fxFlex="48" class="margin-30">
                                <label>{{ 'monitor.exportAllAlerts.year' | translate }}</label><br />
                                <ng-select [items]="years" [(ngModel)]="currentYear" [virtualScroll]= true
                                appendTo="body">
                                </ng-select>
                            </div>
                            <div fxFlex="4" class="margin-30"></div>
                            <div fxFlex="48" class="margin-30">
                                <label>{{ 'monitor.exportAllAlerts.month' | translate }}</label><br />
                                <ng-select  [items]="month" [(ngModel)]="currentMonth" [virtualScroll]= true
                                appendTo="body">
                                </ng-select>
                            </div>
                        </div>
                        <div class=" row" style="margin-top: 40px;">
                            <div class="d_6_12 col" style="padding-left: 0px;">
                                <button type="button" (click)="view()" class="button btn button__basic">
                                    View
                                </button>
                                <button *ngIf=!isMobile type="submit" class="button btn ml-22 button__primary" (click)="exportAlerts()">
                                    {{ 'monitor.exportAllAlerts.export' | translate }}
                                </button>
                            </div>
                        </div>
                        <div style="margin-top: 10px; color: red" *ngIf="noAlerts"><label> No Alerts Found! </label></div>
                    </div>
                </div>
            </div>
    </div>

    <!-- DNSA export template -->

    <div style="display: none;">   
        <table id="dnsa-excel-table">
          <tr>
            <th> User ID </th>
            <th> Search Date </th>
            <th> Search Type </th>
            <th> Search Name </th>
            <th> Search ID </th>
            <th> New Suit(s) Found </th>
            <th> Last Updated in Experian database </th>
          </tr>
          <tr *ngFor="let item of DNSAExportList">
            <td> {{ item.UserId }} </td>
            <td> {{ item.SearchDate }} </td>
            <td> {{ item.SearchType }} </td>
            <td> {{ item.SearchName }} </td>
            <td> {{ item.SearchId }} </td>
            <td> {{ item.NewSuitNo }} </td>
            <td> {{ item.LastUpdatedDate }} </td>
          </tr>
        </table>
      </div>
      <!-- Basic Export template -->
      <div style="display: none;">   
        <table id="excel-table">
            <tr>
                <th> Entity Number </th>
                <th> Entity Name </th>
                <th> Client File Ref. </th>
                <th> Entity Type </th>
                <th> BWS Alert Triggers </th>
                <th> Date of Alert </th>
            </tr>
          <tr *ngFor="let item of BWSExportList">
            <td> {{ item.EntityNumber }} </td>
            <td> {{ item.EntityName }} </td>
            <td> {{ item.ClientFileReference }} </td>
            <td> <span *ngIf="item.EntityType == 'C'"> Company</span>  <span *ngIf="item.EntityType == 'I'"> Individual</span> </td>
            <td> {{ item.BwsAlertTrigger }} </td>
            <td> {{ item.DateOfAlert | date: 'dd/MM/yyyy'}} </td>
          </tr>
        </table>
      </div>

      <!-- Export Other Information Template -->

      <div style="display: none;">   
        <table id="other-info-excel-table">
            <tr>
                <th> Entity Number </th>
                <th> Entity Name </th>
                <th> Client File Ref. </th>
                <th> Entity Type </th>
                <th> Subject </th>
                <th> Date (last day of receiving proofs) </th>
                <th> Location of Meeting </th>
                <th> Time of Meeting </th>
                <th> Amount Per Centum </th>
                <th> First and Final or Otherwise </th>
                <th> When Payable </th>
                <th> Where Payable </th>
                <th> Occurence Date </th>
                <th> Status </th>
                <th> Suit No </th>
                <th> Name of Judicial Manager / Liquidators </th>
                <th> Address of Judicial Manager / Liquidators </th>
                <th> Date of Notice </th>
                <th> Change Date </th>
            </tr>
          <tr *ngFor="let item of exportOtherInfoList">
            <td> {{ item.EntityNumber }} </td>
            <td> {{ item.EntityName }} </td>
            <td> {{ item.ClientFileReference }} </td>
            <td> <span *ngIf="item.EntityType == 'C'"> Company</span>  <span *ngIf="item.EntityType == 'I'"> Individual</span> </td>
            <td> {{ item.Subject }} </td>
            <td> {{ item.ProofDate }} </td>
            <td> {{ item.MeetingLocation }} </td>
            <td> {{ item.MeetingTime }} </td>
            <td> {{ item.AmountPerCentum }} </td>
            <td> {{ item.FirstFinal }} </td>
            <td> {{ item.PayableDate }} </td>
            <td> {{ item.PayableLocation }} </td>
            <td> {{ item.OccurrenceDate }} </td>
            <td> {{ item.Status }} </td>
            <td> {{ item.SuitNo }} </td>
            <td> {{ item.LiquidatorName }} </td>
            <td> {{ item.LiquidatorAddress }} </td>
            <td> {{ item.NoticeDate }} </td>
            <td> {{ item.DateOfAlert | date: 'dd/MM/yyyy'}} </td>
          </tr>
        </table>
      </div>

     
      <!-- PAYMENT TEMPLATES-->

      <div style="display: none;">   
        <table id="excel-table-nbb">
          <tr>
            <th> Record Type </th>
            <th> Entity Name </th>
            <th> Identification / Entity Number  </th>
            <th> Entity Type </th>
            <th> Credit Type </th>
            <th> Debt Turn Cash (DTC) </th>
            <th> Credit Payment Grade </th>
            <th> Alternative Consumer Grade </th>
            <th> Known Credit Exposure Balance O/S $</th>
            <th> Known Credit Exposure Balance O/S Date** </th>
          </tr>
          <tr *ngFor="let item of exportNBBList">
            <td> {{ item.RecordType }} </td>
            <td> {{ item.CustomerName }} </td>
            <td> {{ item.EntityId }} </td>
            <td> <span *ngIf="item.EntityType == 'C'"> Company</span>  <span *ngIf="item.EntityType == 'I'"> Individual</span> </td>
            <td> {{ item.CreditType }} </td>
            <td> {{ item.DebtsTurnCash }} </td>
            <td> {{ item.CreditPaymentGrade }} </td>
            <td> {{ item.AlternativeConsumerGrade }} </td>
            <td> {{ item.KnownCreditExposure }} </td>
            <td> {{ item.OutstandingBalanceDate }} </td>
          </tr>
        </table>
      </div>
    
      <div style="display: none;" >   
        <table id="excel-table-nbl">
            <tr>
              <th> Record Type </th>
              <th> Entity Name </th>
              <th> Identification / Entity Number  </th>
              <th> Entity Type </th>
              <th> Credit Type </th>
              <th> Debt Turn Cash (DTC) </th>
              <th> Credit Payment Grade </th>
              <th> Alternative Consumer Grade </th>
              <th> Balance O/S $ </th>
              <th> Balance O/S $ Date** </th>
              <th> Last Paid Amount</th>
              <th> Last Paid Amount Date** </th>
            </tr>
            <tr *ngFor="let item of exportNBLList">
              <td> {{ item.RecordType }} </td>
              <td> {{ item.CustomerName }} </td>
              <td> {{ item.EntityId }} </td>
              <td> <span *ngIf="item.EntityType == 'C'"> Company</span>  <span *ngIf="item.EntityType == 'I'"> Individual</span> </td>
              <td> {{ item.CreditType }} </td>
              <td> {{ item.DebtsTurnCash }} </td>
              <td> {{ item.CreditPaymentGrade }} </td>
              <td> {{ item.AlternativeConsumerGrade }} </td>
              <td> {{ item.OutstandingBalance }} </td>
              <td> {{ item.OutstandingBalanceDate }} </td>
              <td> {{ item.LastPaidAmount }} </td>
              <td> {{ item.LastKnownDate }} </td>
            </tr>
        </table>
      </div>
    
      <div style="display: none;">   
        <table id="excel-table-basic">
          <tr>
            <th> Record Type </th>
            <th> Entity Name </th>
            <th> Entity Number </th>
          </tr>
          <tr *ngFor="let item of exportNBBList">
            <td> {{ item.RecordType }} </td>
            <td> {{ item.CustomerName }} </td>
            <td> {{ item.EntityId }} </td>
          </tr>
        </table>
      </div>

    <!-- CMS PAYMENT TEMPLATE -->
<div style="display: none;" >   
  <table id="excel-table-cms-payment">
    <tr>
      <th> Entity Number </th>
      <th> Entity Name </th>
      <th> Client File Ref. </th>
      <th> Entity Type </th>
      <th> Record Type </th>
      <th> Date of Alert </th>
    </tr>
    <tr *ngFor="let item of exportCmsPayment">
      <td> {{ item.EntityId }} </td>
      <td> {{ item.EntityName }} </td>
      <td> {{ item.ClientFileReference }} </td>
      <td> <span *ngIf="item.EntityType == 'C'"> Company</span>  <span *ngIf="item.EntityType == 'I'"> Individual</span> </td>
      <td> {{ item.RecordType }} </td>
      <td> {{ item.AlertDate | date: 'dd/MM/yyyy' }} </td>
    </tr>
  </table>
</div>

<div style="display: none;" >   
  <table id="excel-table-cms-basic">
    <tr>
      <th> Entity Number </th>
      <th> Entity Name </th>
      <th> Client File Ref. </th>
      <th> Entity Type </th>
      <th> Alert Trigger</th>
      <th> Court Type </th>
      <th> Year </th>
      <th> Filling Date </th>
      <th> Case No </th>
      <th> Case Status </th>
      <th> Transfer </th>
      <th> Party Nature </th>
      <th> Defendant/Plaintiff/Claimant Name </th>
      <th> Date of Alert </th>
    </tr>
    <tr *ngFor="let item of exportCmsLitigationBasic">
      <td> {{ item.EntityId }} </td>
      <td> {{ item.EntityName }} </td>
      <td> {{ item.FileReference }} </td>
      <td> <span *ngIf="item.EntityType == 'C'"> Company</span>  <span *ngIf="item.EntityType == 'I'"> Individual</span> </td>
      <td> {{ item.ChangeType }} </td>
      <td> {{ item.CourtType }} </td>
      <td> {{ item.CaseYear }} </td>
      <td> {{ item.FilingDate }} </td>
      <td> {{ item.CaseNo }} </td>
      <td> {{ item.CaseStatus }} </td>
      <td> {{ item.Transfer }} </td>
      <td> {{ item.PartyNature }} </td>
      <td> {{ item.PartyName }} </td>
      <td> {{ item.ChangeDate }} </td>
    </tr>
  </table>
</div>

<div style="display: none;" >   
  <table id="excel-table-cms-enhanced">
    <tr>
      <th> Entity Number </th>
      <th> Entity Name </th>
      <th> Client File Ref. </th>
      <th> Entity Type </th>
      <th> Alert Trigger</th>
      <th> Court Type </th>
      <th> Year </th>
      <th> Filling Date </th>
      <th> Case No </th>
      <th> Case Status </th>
      <th> Transfer </th>
      <th> Cause </th>
      <th> Party Nature </th>
      <th> Amount </th>
      <th> Defendant/Plaintiff Name </th>
      <th> Date of Alert </th>
    </tr>
    <tr *ngFor="let item of exportCmsLitigationEnhanced">
      <td> {{ item.EntityId }} </td>
      <td> {{ item.EntityName }} </td>
      <td> {{ item.FileReference }} </td>
      <td> <span *ngIf="item.EntityType == 'C'"> Company</span>  <span *ngIf="item.EntityType == 'I'"> Individual</span> </td>
      <td> {{ item.ChangeType }} </td>
      <td> {{ item.CourtType }} </td>
      <td> {{ item.CaseYear }} </td>
      <td> {{ item.FilingDate }} </td>
      <td> {{ item.CaseNo }} </td>
      <td> {{ item.CaseStatus }} </td>
      <td> {{ item.Transfer }} </td>
      <td> {{ item.Cause }} </td>
      <td> {{ item.PartyNature }} </td>
      <td> {{ item.Amount }} </td>
      <td> {{ item.PartyName }} </td>
      <td> {{ item.ChangeDate }} </td>
    </tr>
  </table>
</div>



<!-- LMS TEMPLATE-->

<div style="display: none;" >   
  <table id="excel-table-lms">
    <tr>
      <th> Entity Number </th>
      <th> Entity Name </th>
      <th> Client File Ref. </th>
      <th> Entity Type </th>
      <th> Alert Trigger</th>
      <th> Court Type </th>
      <th> Year </th>
      <th> Case No </th>
      <th> Transfer </th>
      <th> Cause </th>
      <th> Party Nature </th>
      <th> Amount </th>
      <th> Defendant/Plaintiff/Claimant Name </th>
      <th> Date of Alert </th>
    </tr>
    <tr *ngFor="let item of exportLmsAlerts">
      <td> {{ item.EntityId }} </td>
      <td> {{ item.EntityName }} </td>
      <td> {{ item.FileReference }} </td>
      <td> <span *ngIf="item.EntityType == 'C'"> Company</span>  <span *ngIf="item.EntityType == 'I'"> Individual</span> </td>
      <td> {{ item.ChangeType }} </td>
      <td> {{ item.CourtType }} </td>
      <td> {{ item.CaseYear }} </td>
      <td> {{ item.CaseNo }} </td>
      <td> {{ item.Transfer }} </td>
      <td> {{ item.Cause }} </td>
      <td> {{ item.PartyNature }} </td>
      <td> {{ item.Amount }} </td>
      <td> {{ item.PartyName }} </td>
      <td> {{ item.ChangeDate }} </td>
    </tr>
  </table>

  <!-- SUMMARY EXPORT TEMPLATE -->
 
 <div style="display: none;" *ngIf="summary">   
  <table id="summary-excel-table">
      <tr>
          <th>Total No of Names Subscribed</th>
          <th>No of Names Monitored</th>
          <th>Remaining Balance</th>
          <th>Last Revision of Names</th>
          <th>Info Date</th>
      </tr>
      <tr>
          <td> {{ summary?.totalNamesSubscribed}} </td>
          <td> {{ summary?.monitoredNames}} </td>
          <td> {{ summary?.remainingBalance}} </td>
          <td> {{ summary?.lastRevisionOfNames | date: 'dd/MM/yyyy' }} </td>
          <td> {{ summary?.infoDate | date: 'dd/MM/yyyy' }} </td> 
      </tr>
  </table>
</div>
</div>