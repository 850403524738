<div id="error">
    <h1 matDialogTitle class="error-title">
        <mat-icon class="icon-error">
            info
        </mat-icon>
        {{ 'errorDialog.title' | translate }}
    </h1>
    <div matDialogContent class="content-msg">{{ data.errorMessage }}
        <br>
        <span *ngIf="data.balanceInfo">
            {{ 'errorDialog.balance' | translate }} : {{ data.balanceInfo.balance }}
        </span> <br>
        <span *ngIf="data.balanceInfo">
            {{ 'errorDialog.total' | translate }} : {{ formatNumber(data.balanceInfo.totalPrice) }}
        </span>
    </div>
    <div mat-dialog-actions style="justify-content: center;">
        <button *ngIf="!isOk" (click)="dialogRef.close(false)" style="font-weight: 500;" class="button button__basic">
            {{ 'errorDialog.cancel' | translate }}
        </button>
        <button *ngIf="isOk" (click)="dialogRef.close(false)" style="font-weight: 500;" class="button button__basic">
            OK
        </button>
        <button *ngIf="tryAgain" (click)="dialogRef.close(true)" class="button button__primary ml-22" style="margin-left: 20px;">
            {{ 'errorDialog.tryAgain' | translate }}
        </button>
    </div>
</div>