<div class="dialog-content-wrapper" id="search-result">
    <div mat-dialog-content>
        
        <div class="cross-header">
            <span class="title-text" *ngIf="!manualSearch && !offineReport">
                {{ 'searchResults' | translate }}
            </span>
            <span class="title-text" style="text-transform: inherit;" *ngIf="manualSearch || offineReport">
               <span *ngIf="!getIsROCCondition()"> {{ 'manualSearchResultsGDN' | translate }} </span>
               <span *ngIf="getIsROCCondition()"> {{ 'manualSearchResultsROC' | translate }} </span>
            </span>
            <mat-icon class="close-btn" (click)="matDialogRef.close()">close</mat-icon>
        </div>

        <div *ngIf="!offineReport">
            <div class="contain-records" *ngIf="!isMobile">
                <span class="comp-count">{{ searchResultList.length }}</span>
                <span class="comp-format fs-2">
                    {{ 'searchResults.companiesFound' | translate }}
                </span>
                <span class="unable-find fs-2">
                    {{ 'searchResults.unableFind' | translate }}
                </span>
                <span class="refine-search fs-2" (click)="matDialogRef.close()">
                    {{ 'searchResults.refineSearch' | translate }}
                </span>
                <!-- <span class="unable-find" style="margin-left: 5px;">
                    or
                </span>
                <span class="refine-search" style="margin-left: 5px;" (click)="goToOffline()">
                    Submit a request for a manual search
                </span> -->
            </div>
            <div class="contain-records" *ngIf="isMobile">
                <span class="comp-count">{{ searchResultList.length }}</span>
                <span class="comp-format fs-2">
                    {{ 'searchResults.companiesFound' | translate }}
                </span>
                <div class="unable-find fs-2">
                    {{ 'searchResults.unableFind' | translate }}
                    <span class="refine-search fs-2" (click)="matDialogRef.close()">
                        {{ 'searchResults.refineSearch' | translate }}
                    </span>
                </div>
            </div>
            
            <div class="data-grid-strip">
                <span *ngIf="isRowSelected">1 {{ 'grid.rowsSelected' | translate }}</span>
            </div>
            
            <ag-grid-angular 
                class="ag-theme-alpine"
                id="searchResultGrid"
                [rowData]="searchResultList"
                [columnDefs]="searchColumnDefs"
                [gridOptions]="gridOptions"
                [icons]="icons"
                [domLayout]="domLayout"
                (filterChanged)="onFilterChanged($event)"
                (selectionChanged)="onSelectionChanged($event)"
                (rowSelected)="onRowSearchSelected($event)"
                [masterDetail]="true"
                [detailRowHeight]="detailRowHeight"
                [detailCellRenderer]="detailCellRenderer">
            </ag-grid-angular>
            <span class="error" *ngIf="errorMsgFlag">{{ 'searchResults.errMsg' | translate }}</span>

            <div style="margin-top: 20px; color: #8C8C8C;" class="fs-2">
               <span *ngIf="!getIsROCCondition()"> {{ 'offlineSearchResult.offlineLinkInfoGDN' | translate }}</span>
               <span *ngIf="getIsROCCondition()"> {{ 'offlineSearchResult.offlineLinkInfoROC' | translate }}</span>
                <span class="refine-search fs-2" style="margin-left: 0px; font-size: 14px;" (click)="goToOffline()">
                    {{ 'offlineSearchResult.offlineLinkHere' | translate }}.
                </span>
            </div>
            
            <div class="col btn-grp">
                <button (click)="matDialogRef.close()" class="button button__basic">
                    {{ 'searchResults.btn.cancel' | translate }}
                </button>
                <button [disabled]="disabledFlag" (click)="resultSelect()" class="button button__primary ml-22">
                    {{ 'searchResults.btn.select' | translate }}
                </button>
            </div>
        </div>

        <div *ngIf="offineReport" [ngStyle]="isMobile?{'width':'100%'}:{'width':'90%'}">
            <p class="fs-2" style="margin-top: 15px;" *ngIf="!manualSearch">
                {{ 'offlineSearchResult.noResultFound' | translate }}
            </p>

            <p class="fs-2" style="margin-top: 15px;" *ngIf="manualSearch">
                {{ 'offlineSearchResult.previousSearchFor' | translate }}
            </p>

            <table class="table table-variation" style="width: 100%;" *ngIf="!isMobile">
                <tr>
                    <th class="table-border-header" width="100">
                        {{ 'offlineSearchResult.country' | translate }}
                    </th>
                    <th class="table-border-header" width="125">
                        {{ 'offlineSearchResult.product' | translate }}
                    </th>
                    <th class="table-border-header" width="125" style="border-right: 1px solid #D0D0D0;">
                        {{ 'offlineSearchResult.entityName' | translate }}
                    </th>
                </tr>
                <tr class="fs-2">
                    <td class="table-border-data">
                        {{ formData.country }}
                    </td>
                    <td class="table-border-data">
                        {{ formData.productName }}
                    </td>
                    <td class="table-border-data">
                        {{ formData.name }}
                    </td>
                </tr>
            </table>
            
            <div class="custom-table-details" *ngIf="isMobile">
                <div class="data-container border-t-n">
                    <label for="">{{ 'offlineSearchResult.country' | translate }}</label>
                    <div class="details">
                        {{ formData.country }}
                    </div>
                </div>
                <div class="data-container">
                    <label for="">{{ 'offlineSearchResult.product' | translate }}</label>
                    <div class="details">
                        {{ formData.productName }}
                    </div>
                </div>
                <div class="data-container">
                    <label for="">{{ 'offlineSearchResult.entityName' | translate }}</label>
                    <div class="details">
                        {{ formData.name }}
                    </div>
                </div>
            </div>

            <p class="fs-2 m-b-0" style="margin-top: 36px;">
                <span *ngIf="!getIsROCCondition()" >{{ 'offlineSearchResult.disclaimer' | translate }}<br></span>
                {{ 'offlineSearchResult.disclaimer1' | translate }}
            </p>

            <form [formGroup]="searchForm" (submit)="searchResults(searchForm.value)" [ngStyle]="isMobile?{'margin-top':'36px'}:{'margin-top':'45px'}">
                <div class="zanna_div row" style="margin-bottom: 20px;">
                    <div class="d_6_12 col" style="padding-left: 0px;">
                        <label>{{ 'offlineSearchForm.entityName' | translate }}*</label><br />
                        <input formControlName="entityName" id="myInput" type="text" (blur)="checkValidation()" [placeholder]="'placeholder.enter_entity_name' | translate">
                        
                        <span class="error" *ngIf="entityNameRequired && !max120Characters">
                            *{{ 'formError.fieldRequired' | translate }}
                        </span>
    
                        <span class="error" *ngIf="max120Characters">
                            *{{ 'formError.max200CharsRequired' | translate }}
                        </span>

                        <span class="error" *ngIf="entityEnglishValidation">
                            *{{ 'additionalRemark.allowOnlyEnglish' | translate }}
                        </span>
                    </div>

                    <div class="d_6_12 col p-t-12" style="padding-left: 0px;">
                        <label>{{ 'offlineSearchForm.city' | translate }}*</label><br />
                        <input formControlName="city" type="text" (blur)="checkValidation()" [placeholder]="'placeholder.enter_city' | translate">
                        <span class="error" *ngIf="cityRequired && !max50Characters && !allowOnlyAlphabets">
                            *{{ 'formError.fieldRequired' | translate }}
                        </span>
                        <span class="error" *ngIf="max50Characters">
                            *{{ 'formError.max50Characters' | translate }}
                        </span>
                        <span class="error" *ngIf="allowOnlyAlphabets">
                            *{{ 'additionalRemark.allowOnlyEnglish' | translate }}
                        </span>
                    </div>

                </div>

                <div class="zanna_div row" style="margin-bottom: 20px;">
                    <div class="d_12_12 col" style="padding-left: 0px;">
                        <label>{{ 'offlineSearchForm.addressLine' | translate }}*</label><br />
                        <input formControlName="entityAddressLine" type="text" (blur)="checkValidation()" [placeholder]="'placeholder.enter_address' | translate">
                        <span class="error" *ngIf="addressRequired">
                            *{{ 'formError.fieldRequired' | translate }}
                        </span>
                        <span class="error" *ngIf="max200Characters">
                            *{{ 'formError.max200CharsRequired' | translate }}
                        </span>
                        <span class="error" *ngIf="addressEnglishValidation">
                            *{{ 'additionalRemark.allowOnlyEnglish' | translate }}
                        </span>
                    </div>
                </div>

                <div class="zanna_div row" style="margin-bottom: 20px;">
                    <div class="d_6_12 col" style="padding-left: 0px;">
                        <label>{{ 'offlineSearchForm.entityContactNumber' | translate }}</label><br />
                        <input formControlName="entityPhoneNumber" type="text" (blur)="checkValidation()" [placeholder]="'placeholder.contact_number' | translate">
                        <span class="error" *ngIf="max20Numbers">
                            *{{ 'formError.max20Numbers' | translate }}
                        </span>
                        <span class="error" *ngIf="isValidNumber">
                            *{{ 'formError.numbersRequired' | translate }}
                        </span>
                    </div>

                    <div class="d_6_12 col p-t-12" style="padding-left: 0px;">
                        <label>{{ 'offlineSearchForm.clientRefNo' | translate }}</label><br />
                        <input formControlName="fileRef" type="text" (blur)="checkValidation()" [placeholder]="'placeholder.file_ref' | translate">
                        <span class="error" *ngIf="max20Characters">
                            *{{ 'formError.max20Characters' | translate }}
                        </span>
                        <span class="error" *ngIf="fileRefAllowOnlyAlphabets">
                            *{{ 'additionalRemark.allowOnlyEnglish' | translate }}
                        </span>
                    </div>
                </div>

                <div class="zanna_div row" style="margin-top: 40px;">
                    <div class="d_6_12 col" style="padding-left: 0px;">
                        <button type="button" (click)="matDialogRef.close()" class="button btn button__basic">
                            {{ 'searchResults.btn.cancel' | translate }}
                        </button>
                        <button type="submit" class="button btn ml-22 button__primary ml-22"
                            [ngClass]="{'button__disabled': max120Characters || max200Characters || searchForm.invalid }">
                            {{ 'searchResults.btn.select' | translate }}
                        </button>
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>