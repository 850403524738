import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'bulk-grid-cell-renderer',
	template: `<div class="text-size" *ngFor="let alert of  params.data.AlertList">
					{{alert}}
				</div>`,
	styles: ['.link { color:#426DA9; text-decoration: underline; cursor: pointer;}', '.green { background-color: #5CD08E; color: white;}', '.yellow { background-color: #FEBA00; color: white;}', '.red { background-color: #F66C86; color: white;}', '.text-size {font-size: 14px;}']
})

export class AlertTriggerCellRendererComponent implements ICellRendererAngularComp {

	public params: any;
	option: string | undefined;
	showTranslation: boolean = false;

	constructor() { }

	agInit(params: any): void {
		this.params = params;
	}

	refresh(): boolean {
		return false;
	}

	checkValid(data: any) {
		return data.translation;
	}

	
	onDelete(ev: any) {
		this.params['label'] = 'delete';
		this.params.onClick(this.params);
	}

	addToCart(ev: any) {
		this.params['label'] = 'addToCart';
		this.params.onClick(this.params);
	}
	
	viewMatches(ev: any) {
		this.params['label'] = 'viewMatches';
		this.params.onClick(this.params);
	}

}
