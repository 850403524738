import { Component, OnInit, AfterViewInit, OnDestroy, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { GridOptions, GridApi } from 'ag-grid-community';
import { Router } from '@angular/router';
import { BulkUploadGridService } from '../../bulk-upload-grid/bulk-upload-grid.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoaderService } from '../../services/loader.service';
import { interval, Subscription } from 'rxjs';
import { ErrorDialogComponent } from '../../error-dialog-box/error-dialog.component';
import { UploadCompleteDialogComponent } from 'src/app/bulk-upload-grid/upload-complete-dialog-box/upload-complete-dialog.component';
import { OfflineSearchComponent } from 'src/app/bulk-upload-grid/offline-search/offline-search';
import { BulkGridCellRendererComponent } from 'src/app/bulk-upload-grid/bulk-grid-cell-renderer/bulk-grid-cell-renderer.component';
import { ManuallPickAlertComponent } from '../shared-module/manuall-pick-alert/company-pick-alert';
import { IndividualPickAlertComponent } from '../shared-module/individual-pick-alert/individual-pick-alert';
import { CommonDialogComponent } from '../shared-module/dialogs/confirm-dialog.component';
import { MonitoringService } from '../monitoring.service';
import { LmsSubscriptionComponent } from '../shared-module/lms-subscription-bulk/lms-subscription-bulk.component';
import * as XLSX from 'xlsx';

@Component({
	selector: 'app-monitor-bulk-upload-grid',
	templateUrl: './bulk-upload-grid.component.html',
	styleUrls: ['./bulk-upload-grid.component.scss']
})

export class MonitoringBulkUploadGridComponent implements OnInit, AfterViewInit, OnDestroy {
	columnDefs: any = [];
	noMatchCompany: any = [];
	noMatchIndividual: any = [];
	duplicateRecords: any = [];
	limitExceedRecords: any = [];
	lmsSubscriptionRecords: any = [];

	noMatchCompanyCount: number = 0;
	lmsSubscriptionCount: number = 0; 
	noMatchIndividualCount: number = 0;
	duplicateRecordsCount: number = 0;
	limitExceedRecordsCount: any = 0;

	noMatchCompanyLoader: boolean = true;
	lmsLoader: boolean = true;
	NoMatchLoader: boolean = true;
	showLms: boolean = false;
	gridOptions: any;
	gridColumnApi: any;
	isCompletedBulkUpload: string = 'Processing';
	sharedData: any;
	api: any;
	selectedStatusBar: any = 'Match_Not_Found_Company';
	bulkData: any = [];
	@Input() validatedData: any;
	@Output() completeStatus = new EventEmitter<any>();
	@Output() addedToNamelist = new EventEmitter<any>();
	icons: any;
	tooltipShowDelay: any;
	blob: any;
	loader: any = 0;
	uncollectedCount = 0;
	minutes: number = 0;
	mySubscription: Subscription;
	loadDataSubscription: Subscription;
	bulkAlertUploadNumber: any;
	private paginationPageSize = 5;
	private totalPages = 0;
	initialFlag: boolean = false;
	domLayout: any = "autoHeight";
	loadFirstTime:boolean=true;
	bulkUploadStatus: any;
	findButton: boolean = false;
	selectedRecords: any;
	alertType: any;
	companyIndividual: any = [];
	
	get PaginationPageSize(): number {
		return this.paginationPageSize;
	}

	get gridAPI(): GridApi {
		return this.gridOptions.api;
	}

	get TotalPages(): number {
		return this.totalPages;
	}

	constructor(public uploadService: MonitoringService, public dialog: MatDialog,
		public loaderService: LoaderService, public translateService: TranslateService, public router: Router) {
		this.loaderService.isLoading.next(true);
		this.getColDefs();
		this.gridOptions = <GridOptions>{
			onGridReady: () => {
				setTimeout(() => {
					this.gridOptions.api?.sizeColumnsToFit();
				}, 1000);
				this.totalPages = this.gridOptions.api?.paginationGetTotalPages();
			},
			onGridSizeChanged: () => {
				this.gridOptions.api.sizeColumnsToFit();
			},
			overlayNoRowsTemplate: '<h4> No record found. </h4>',
			rowSelection: 'multiple',
			pagination: true,
			paginationPageSize: 10,
			animateRows: true,
			suppressCellSelection: true,
			suppressContextMenu:true,
			enableCellTextSelection: true,
			defaultColDef: {
				flex: 1,
				wrapText: true,
				resizable: true,
				autoHeight: true,
				unSortIcon: true,
				sortable: true,
				suppressMenu: true,
				lockPosition: true,
				tooltipComponent: 'customTooltip'
			}
		};

		this.gridOptions.frameworkComponents = {
			"cellRenderer": BulkGridCellRendererComponent,
		};

		this.tooltipShowDelay = 0;

		this.icons = {
			sortUnSort: '<img src="../../assets/img/sort.svg">',
			sortAscending: '<img src="../../assets/img/sort-asc.svg">',
			sortDescending: '<img src="../../assets/img/sort-desc.svg">'
		};
		this.uploadService.setSid(localStorage.getItem('sessionID')); 
	}

	
	onGridReady(params: any) {
		this.gridColumnApi = params.columnApi;
		this.api = params.api;
	}

	ngOnInit(): void {
	}

	getIsBulkUpload() {
			this.mySubscription = interval(30000).subscribe((x => {
				this.bulkUpladProcessingStatus();
				if(this.selectedStatusBar == 'Match_Not_Found_Company') {
					this.loadData();
				} else if(this.selectedStatusBar == 'Match_Not_Found_Individual') {
					this.loadNoMatchData();
				}
			}));
	}

	ngOnChanges(changes: SimpleChanges) {
		if(changes?.validatedData?.currentValue) {
			this.selectedStatusBar = 'Match_Not_Found_Company';
			this.isCompletedBulkUpload = this.validatedData.bulkAlertStatus; 
			this.bulkAlertUploadNumber = this.validatedData?.bulkAlertUploadNumber; 
			this.NoMatchLoader = true;
			this.noMatchCompanyLoader = true;
			this.noMatchCompany = [];
			this.noMatchIndividual = [];
			this.duplicateRecords = [];
			this.limitExceedRecords = [];
			this.limitExceedRecordsCount = 0;
			this.duplicateRecordsCount = 0;
			this.noMatchIndividualCount = 0;
			this.noMatchCompanyCount = 0;
			if(this.validatedData.bulkAlertStatus == 'Processing' || this.validatedData.bulkAlertStatus == 'Uploaded') {
				this.bulkUpladProcessingStatus();
				this.getColDefs();
				this.loadData();
				this.getIsBulkUpload();
			} else {
				this.bulkUpladProcessingStatus();
				this.getColDefs();
				this.loadData();
			}
			
		}
	}

	ngAfterViewInit(): void {
		this.gridAPI.resetRowHeights();
	}

	onFilterChanged(event: any) {
		if (this.gridOptions.api.getDisplayedRowCount() == 0) {
			this.gridAPI.showNoRowsOverlay();
		} else {
			this.gridAPI.hideOverlay();
		}
		this.gridOptions.api.deselectAll();
	}

	checkProcessingRecords() {
		let present = this.bulkData.some((item: any) => ((item.itemStatus).toLowerCase() == "processing"));
		if (present) {
		}
	}

	exportToExcel() {
		this.api.exportDataAsExcel({ onlySelected: true });
	}

	getParams() {
		return {
		  allColumns: true,
		};
	}

	getColDefsLmsSubscription() {
		this.translateService.get(['bulk.upload.identification','monitor.alertType', 'bulk.upload.country_address', 'variationTable.priceInSGD', 'bulk.upload.score',
			'bulk.upload.action']).subscribe(value => {
				this.columnDefs = [
					{
						field: 'RowSelect',
						headerName: ' ',
						checkboxSelection: true,
						headerCheckboxSelection: true,
						headerCheckboxSelectionFilteredOnly: true,
						sortable: false,
						width: 60,
						maxWidth: 60,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding-top': '4px'}
					},
					{
						field: 'userSearchTerm',
						headerName: value['bulk.upload.identification'],
						tooltipField: 'entityName',
						tooltipComponentParams: { type: 'fileRef' },
						width: 200,
						minWidth: 200,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellRenderer: function(param: any){							
							var entityText = "Entity Number. "+ param.data?.userIdentityNumber + "</br>";
							if(param.data?.userSearchTerm != null )
							{
								entityText = entityText + param.data?.userSearchTerm+ '<br/>'
							}
							return entityText;	
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'userAlertType',
						headerName: value['monitor.alertType'],
						width: 140,
						minWidth: 140,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'countryName',
						headerName: value['bulk.upload.country_address'],
						tooltipField: 'address',
						width: 150,
						minWidth: 150,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellRenderer: function (param: any) {
							var row = param.data?.countryName + "</br>";
							if(param.data?.searchStreet)
								row = row + param.data?.searchStreet+"</br>";
							if(param.data?.searchCity)
								row = row + param.data?.searchCity + " ";
							if(param.data?.searchPostCode) 
								row = row + param.data?.searchPostCode;
							return row;
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'price',
						headerName: value['variationTable.priceInSGD'],
						width: 140,
						minWidth: 140,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellRenderer: function(param: any){
							return "SGD 4.00"+"<br>"+"(Not including GST)";
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						width: 140,
						maxWidth: 140,
						sortable: false,
						headerName: value['bulk.upload.action'],
						cellRenderer: "cellRenderer",
						cellRendererParams: {
							onClick: this.onActionClick.bind(this),
							label: 'bulk-lms-subscription'
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '6px 18px' }
					},
				]
			});
	}

	getColDefs() {
		this.translateService.get(['bulk.upload.identification','monitor.alertType', 'bulk.upload.issues', 'bulk.upload.country_address', 'bulk.upload.turnAroundTime', 'bulk.upload.score',
			'bulk.upload.action']).subscribe(value => {
				this.columnDefs = [
					{
						field: 'RowSelect',
						headerName: ' ',
						checkboxSelection: true,
						headerCheckboxSelection: true,
						headerCheckboxSelectionFilteredOnly: true,
						sortable: false,
						width: 60,
						maxWidth: 60,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding-top': '4px'}
					},
					{
						field: 'userSearchTerm',
						headerName: value['bulk.upload.identification'],
						tooltipField: 'entityName',
						tooltipComponentParams: { type: 'fileRef' },
						width: 200,
						minWidth: 200,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellRenderer: function(param: any){
							var entityText = "Entity Number. "+ param.data?.userIdentityNumber + "</br>";
							if(param.data?.userSearchTerm!=null)
							{
								entityText = entityText + param.data?.userSearchTerm+ '<br/>'
							}
							return entityText;							
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'userAlertType',
						headerName: value['monitor.alertType'],
						width: 140,
						minWidth: 140,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'countryName',
						headerName: value['bulk.upload.country_address'],
						tooltipField: 'address',
						width: 150,
						minWidth: 150,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellRenderer: function (param: any) {
							var row = param.data?.countryName + "</br>";
							if(param.data?.searchStreet)
								row = row + param.data?.searchStreet+"</br>";
							if(param.data?.searchCity)
								row = row + param.data?.searchCity + " ";
							if(param.data?.searchPostCode) 
								row = row + param.data?.searchPostCode;
							return row;
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'error',
						headerName: value['bulk.upload.issues'],
						width: 140,
						minWidth: 140,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellRenderer: function(param: any){
							return "Record not found";
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						width: 130,
						maxWidth: 130,
						sortable: false,
						headerName: value['bulk.upload.action'],
						cellRenderer: "cellRenderer",
						cellRendererParams: {
							onClick: this.onActionClick.bind(this),
							label: 'bulk-no-match-company'
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '6px 18px' }
					},
				]
			});
	}


	getColDefsNoMatch() {
		this.translateService.get(['bulk.upload.identification','monitor.alertType', 'bulk.upload.issues', 'bulk.upload.country_address', 'bulk.upload.turnAroundTime', 'bulk.upload.score',
		'bulk.upload.action']).subscribe(value => {
			this.columnDefs = [
				{
					field: 'RowSelect',
					headerName: ' ',
					checkboxSelection: true,
					headerCheckboxSelection: true,
					headerCheckboxSelectionFilteredOnly: true,
					sortable: false,
					width: 60,
					maxWidth: 60,
					cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding-top': '4px'}
				},
				{
					field: 'userSearchTerm',
					headerName: value['bulk.upload.identification'],
					tooltipField: 'entityName',
					tooltipComponentParams: { type: 'fileRef' },
					width: 200,
					minWidth: 200,
					floatingFilter: true,
					filter: 'agTextColumnFilter',
					filterParams: {
						trimInput: true
					},
					cellRenderer: function(param: any){						
						var entityText = "NRIC / Passport No. "+ param.data?.userIdentityNumber + "</br>";
								if(param.data?.userSearchTerm!=null)
								{
									entityText = entityText+ param.data?.userSearchTerm+ '<br/>'
								}
								return entityText;
					},
					cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
				},
				{
					field: 'userAlertType',
					headerName: value['monitor.alertType'],
					width: 140,
					minWidth: 140,
					floatingFilter: true,
					filter: 'agTextColumnFilter',
					filterParams: {
						trimInput: true
					},
					cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
				},
				{
					field: 'countryName',
					headerName: value['bulk.upload.country_address'],
					tooltipField: 'address',
					width: 150,
					minWidth: 150,
					floatingFilter: true,
					filter: 'agTextColumnFilter',
					filterParams: {
						trimInput: true
					},
					cellRenderer: function (param: any) {
						var row = param.data?.countryName + "</br>";
						if(param.data?.searchStreet)
							row = row + param.data?.searchStreet+"</br>";
						if(param.data?.searchCity)
							row = row + param.data?.searchCity + " ";
						if(param.data?.searchPostCode) 
							row = row + param.data?.searchPostCode;
						return row;
					},
					cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
				},
				{
					field: 'error',
					headerName: value['bulk.upload.issues'],
					width: 140,
					minWidth: 140,
					floatingFilter: true,
					filter: 'agTextColumnFilter',
					filterParams: {
						trimInput: true
					},
					cellRenderer: function(param: any){
						return "Record not found";
					},
					cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
				},
				{
					width: 130,
					maxWidth: 130,
					sortable: false,
					headerName: value['bulk.upload.action'],
					cellRenderer: "cellRenderer",
					cellRendererParams: {
						onClick: this.onActionClick.bind(this),
						label: 'bulk-no-match-individual'
					},
					cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '6px 18px' }
				},
			]
		});
	}

	getColDefsDuplicate() {
		this.translateService.get(['bulk.upload.identification','monitor.alertType', 'bulk.upload.issues', 'bulk.upload.country_address', 'bulk.upload.turnAroundTime', 'bulk.upload.score',
			'bulk.upload.action']).subscribe(value => {
				this.columnDefs = [
					{
						field: 'RowSelect',
						headerName: ' ',
						checkboxSelection: true,
						headerCheckboxSelection: true,
						headerCheckboxSelectionFilteredOnly: true,
						sortable: false,
						width: 60,
						maxWidth: 60,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding-top': '4px'}
					},
					{
						field: 'entityName',
						headerName: value['bulk.upload.identification'],
						tooltipField: 'entityName',
						tooltipComponentParams: { type: 'fileRef' },
						width: 220,
						minWidth: 220,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellRenderer: function(param: any){							
							var entityText = "Entity Number. "+ param.data?.entityNumberOrNric + "</br>";
								if(param.data?.entityName!=null)
								{
									entityText = entityText + param.data?.entityName+ '<br/>'
								}
								return entityText;	
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'alertType',
						headerName: value['monitor.alertType'],
						width: 140,
						minWidth: 140,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'country',
						headerName: value['bulk.upload.country_address'],
						tooltipField: 'address',
						width: 150,
						minWidth: 150,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellRenderer: function (param: any) {
							var row = param.data?.country + "</br>";
							if(param.data?.searchStreet)
								row = row + param.data?.searchStreet+"</br>";
							if(param.data?.searchCity)
								row = row + param.data?.searchCity + " ";
							if(param.data?.searchPostCode) 
								row = row + param.data?.searchPostCode;
							return row;
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'error',
						headerName: value['bulk.upload.issues'],
						width: 140,
						minWidth: 140,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellRenderer: function(param: any){
							if(param.data.duplicateCount == 0) {
								return 1 + " duplicate record(s) found on the same subject";
							} else {
								return param.data.duplicateCount + " duplicate record(s) found on the same subject";
							}
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						width: 110,
						maxWidth: 110,
						sortable: false,
						headerName: value['bulk.upload.action'],
						// cellRenderer: "cellRenderer",
						// cellRendererParams: {
						// 	onClick: this.onActionClick.bind(this),
						// 	label: 'bulk-upload'
						// },
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '6px 18px' }
					},
				]
			});
	}

	getColDefsLimitExceed() {
		this.translateService.get(['bulk.upload.identification','monitor.alertType', 'bulk.upload.issues', 'bulk.upload.country_address', 'bulk.upload.turnAroundTime', 'bulk.upload.score',
			'bulk.upload.action']).subscribe(value => {
				this.columnDefs = [
					{
						field: 'RowSelect',
						headerName: ' ',
						checkboxSelection: true,
						headerCheckboxSelection: true,
						headerCheckboxSelectionFilteredOnly: true,
						sortable: false,
						width: 60,
						maxWidth: 60,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding-top': '4px'}
					},
					{
						field: 'entityNumberOrNric',
						headerName: value['bulk.upload.identification'],
						tooltipField: 'entityName',
						tooltipComponentParams: { type: 'fileRef' },
						width: 220,
						minWidth: 220,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellRenderer: function(param: any){
							if(param.data?.entityType == 'Individual') {
								var entityText = "NRIC / Passport No. "+ param.data?.entityNumberOrNric + "</br>";
								if(param.data?.entityName!=null)
								{
									entityText = entityText + param.data?.entityName+ '<br/>'
								}
								return entityText;
							} else {
								var entityText = "Entity Number. "+ param.data?.entityNumberOrNric + "</br>";
								if(param.data?.entityName!=null)
								{
									entityText = entityText + param.data?.entityName+ '<br/>'
								}
								return entityText;	
							}							
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'alertType',
						headerName: value['monitor.alertType'],
						width: 140,
						minWidth: 140,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'countryName',
						headerName: value['bulk.upload.country_address'],
						tooltipField: 'address',
						width: 150,
						minWidth: 150,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellRenderer: function (param: any) {
							var row = param.data?.country + "</br>";
							if(param.data?.searchStreet)
								row = row + param.data?.searchStreet+"</br>";
							if(param.data?.searchCity)
								row = row + param.data?.searchCity + " ";
							if(param.data?.searchPostCode) 
								row = row + param.data?.searchPostCode;
							return row;
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'error',
						headerName: value['bulk.upload.issues'],
						width: 140,
						minWidth: 140,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellRenderer: function(param: any){
							return "Limit Exceeded";
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						width: 110,
						minWidth: 110,
						sortable: false,
						headerName: value['bulk.upload.action'],
						cellRenderer: "cellRenderer",
						cellRendererParams: {
							onClick: this.onActionClick.bind(this),
							label: 'bulk-limit-exceed'
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '6px 18px' }
					},
				]
			});
	}

	ngOnDestroy(): void {
		if(this.mySubscription)
		this.mySubscription.unsubscribe();
	}

	changeLanguage(ev: any) {
		this.getColDefs();
		this.gridOptions.api.refreshHeader({ forrce: true });
		setTimeout(() => {
			this.gridOptions.api?.sizeColumnsToFit();
		}, 100);
	}

	loadLmsData() {
			if(this.lmsLoader)
			this.loaderService.isLoading.next(true);
			this.lmsLoader = false;
			var fetchFrom = this.lmsSubscriptionRecords.length;
			if(this.loadDataSubscription)
			this.loadDataSubscription.unsubscribe();
			this.loadDataSubscription = this.uploadService.bulkUploadList(this.lmsSubscriptionRecords.length, this.bulkAlertUploadNumber, this.selectedStatusBar).subscribe( 
			(res: any) => {
				if(this.lmsSubscriptionRecords.length == 0)
				this.loaderService.isLoading.next(false);
				if(res.success == true){
					if(fetchFrom != 0) {
						this.gridAPI.applyTransaction({
							add: res.data?.result
						});
						res.data?.result?.map((record: any) => {
							this.lmsSubscriptionRecords.push(record);
						});
					} else {
						this.lmsSubscriptionRecords = res.data?.result;
						this.alertType = res.data.alertType;
					}

					res.data?.countDetails.map((count: any, index: number) => {
						if(count.status == 'Already_Exists') {
							this.duplicateRecordsCount = count.count;
						}
						if(count.status == 'Match_Not_Found_Individual') {
							this.noMatchIndividualCount = count.count;
						}
						if(count.status == 'Match_Not_Found_Company') {
							this.noMatchCompanyCount = count.count;
						}
					});

					if(this.lmsSubscriptionRecords)
					this.lmsSubscriptionCount = this.lmsSubscriptionRecords.length;
				}
			},
			(err: any) => {
				this.loaderService.isLoading.next(false);
			}
		); 
	}

	loadData() {
		if(this.noMatchCompanyLoader)
		this.loaderService.isLoading.next(true);
			this.noMatchCompanyLoader = false;
			var fetchFrom = this.noMatchCompany.length;
			if(this.loadDataSubscription)
			this.loadDataSubscription.unsubscribe();
			this.loadDataSubscription = this.uploadService.bulkUploadList(this.noMatchCompany.length, this.bulkAlertUploadNumber, this.selectedStatusBar).subscribe( 
			(res: any) => {
				if(this.noMatchCompany.length == 0)
				this.loaderService.isLoading.next(false);
				if(res.success == true){
					if(fetchFrom != 0) {
						this.gridAPI.applyTransaction({
							add: res.data?.result
						});
						res.data?.result?.map((record: any) => {
							this.noMatchCompany.push(record);
						});
					} else {
						this.noMatchCompany = res.data?.result;
						this.alertType = res.data.alertType;
					}

					res.data?.countDetails.map((count: any, index: number) => {
						if(count.status == 'Match_Not_Found_Individual') {
							this.noMatchIndividualCount = count.count;
						}
					});

					if(res.data?.limitExceedCount) {
						this.limitExceedRecordsCount = res.data?.limitExceedCount;
					}
					if(res.data?.duplicateCount) {
						this.duplicateRecordsCount = res.data?.duplicateCount;
					}
					if(this.noMatchCompany)
					this.noMatchCompanyCount = this.noMatchCompany.length;
				}
			},
			(err: any) => {
				this.loaderService.isLoading.next(false);
			}
		); 
	}

	loadNoMatchData() {
		if(this.NoMatchLoader)
			this.loaderService.isLoading.next(true);
			this.NoMatchLoader = false;
			var fetchFrom = this.noMatchIndividual.length;
			this.uploadService.bulkUploadList(this.noMatchIndividual.length,  this.bulkAlertUploadNumber, this.selectedStatusBar).subscribe(
			(res: any) => {
				if(this.noMatchIndividual.length == 0)
				this.loaderService.isLoading.next(false);
				if(res.success == true){
					if(fetchFrom != 0) {
						this.gridAPI.applyTransaction({
							add: res.data?.result
						});
						res.data?.result?.map((record: any) => {
							this.noMatchIndividual.push(record);
						});
					} else {
						this.noMatchIndividual = res.data?.result;
					}

					res.data?.countDetails.map((count: any, index: number) => {
						if(count.status == 'Match_Not_Found_Company') {
							this.noMatchCompanyCount = count.count;
						}
					});
					if(res.data?.limitExceedCount) {
						this.limitExceedRecordsCount = res.data?.limitExceedCount;
					}
					if(res.data?.duplicateCount) {
						this.duplicateRecordsCount = res.data?.duplicateCount;
					}
					if(this.noMatchIndividual)
					this.noMatchIndividualCount = this.noMatchIndividual.length;
					
				} else{

				}
			},
			(err: any) => {
				this.loaderService.isLoading.next(false);
			}
		); 
	}

	loadDuplicateData() {
		if(this.duplicateRecords.length == 0) {
			this.loaderService.isLoading.next(true);
				this.uploadService.bulkUploadList(this.duplicateRecords.length, this.bulkAlertUploadNumber , this.selectedStatusBar).subscribe(
				(res: any) => {
					this.loaderService.isLoading.next(false);
					if(res.success == true){
						this.duplicateRecords = res.data?.duplicateList;
						if(this.duplicateRecords)
						this.duplicateRecordsCount = res.data?.duplicateCount;
						if(res.data?.limitExceedCount) {
							this.limitExceedRecordsCount = res.data?.limitExceedCount;
						}
					}
				},
				(err: any) => {
					this.loaderService.isLoading.next(false);
				}
			); 
		}
	}

	loadLimitExceddedData() {
		if(this.limitExceedRecords.length == 0) {
			this.loaderService.isLoading.next(true);
				this.uploadService.bulkUploadList(this.limitExceedRecords.length, this.bulkAlertUploadNumber , "Capacity_Exceeds").subscribe(
				(res: any) => {
					this.loaderService.isLoading.next(false);
					if(res.success == true){
						this.limitExceedRecords = res.data?.capacityExceedsList;
						if(this.limitExceedRecords)
						this.limitExceedRecordsCount = this.limitExceedRecords.length;
						if(res.data?.duplicateCount) {
							this.duplicateRecordsCount = res.data?.duplicateCount;
						}
					}
				},
				(err: any) => {
					this.loaderService.isLoading.next(false);
				}
			); 
		}
	}

	bulkUpladProcessingStatus() {
		this.uploadService.bulkUploadProcessingStatus(this.bulkAlertUploadNumber).subscribe(
		(res: any) => {
			if(res.success == true && res.data != null){
				this.bulkUploadStatus = JSON.parse(res.data.statusJson);
				this.loader = ( this.bulkUploadStatus?.CurrentCount / this.bulkUploadStatus?.TotalCount ) * 100;
				var val = Number(this.bulkUploadStatus?.EstimatedTime) / 60;
				this.minutes = Math.round(val);
				this.sharedData = {
					"fileName": res.data.bulkReportFileName,
					"bulkAlertUploadNumber": this.bulkAlertUploadNumber,
					"status": "complete",
					"data": this.bulkUploadStatus
				};
				if(this.bulkUploadStatus.CurrentCount == this.bulkUploadStatus.TotalCount) {
					this.isCompletedBulkUpload = "Processed";
					if(this.mySubscription)
					this.mySubscription.unsubscribe();
					var obj: any = { 
						"bulkAlertUploadNumber": this.bulkAlertUploadNumber,
						"bulkAlertStatus": "Processed"
					};
					if(this.mySubscription)
					this.mySubscription.unsubscribe();
					let reviewed  = localStorage.getItem('MonitoringbulkProcessComplete');
					if(reviewed != 'reviewed' ) {
						var status: any = {
							"fileName": res.data.bulkReportFileName,
							"bulkAlertUploadNumber": this.bulkAlertUploadNumber,
							"status": "monitor-complete",
							"data": this.bulkUploadStatus
						}
						let dialogRef = this.dialog.open(UploadCompleteDialogComponent, {
							disableClose: true,
							width: "600px",
							// height: "260px",
							panelClass: 'advanced-search-dialog',
							data: status
						});
						dialogRef.afterClosed().subscribe(res => {
							localStorage.setItem('MonitoringbulkProcessComplete', 'reviewed');
							// this.completeStatus.emit(obj);
						});	
					}
				}
			} else {
				if(this.mySubscription)
				this.mySubscription.unsubscribe();
			}
		});
	}

	cancelProcessing() {
		this.sharedData.status = 'monitor-cancel';
		var obj: any = { 
			"bulkAlertUploadNumber": this.bulkAlertUploadNumber,
			"bulkAlertStatus": "Cancelled"
		};
		let dialogRef = this.dialog.open(UploadCompleteDialogComponent, {
			disableClose: true,
			width: "600px",
			height: "320px",
			panelClass: 'advanced-search-dialog',
			data: this.sharedData
		});
		dialogRef.afterClosed().subscribe(res => {
			if(res) {
				if(this.mySubscription)
				this.mySubscription.unsubscribe();
				this.isCompletedBulkUpload = res.bulkAlertStatus;
				this.bulkAlertUploadNumber = res.bulkAlertUploadNumber; 
				localStorage.setItem('MonitoringbulkProcessComplete', 'reviewed');
				// this.completeStatus.emit(obj);
				this.bulkUpladProcessingStatus();
				this.loadData();
			}
		});
	}

	changeStatusBar(status: any) {
		this.selectedStatusBar = status;
		if(this.selectedStatusBar == 'LMS_Subscription') {
			this.findButton = false;
			this.getColDefsLmsSubscription();
			this.loadLmsData();
		} else if(this.selectedStatusBar == 'Match_Not_Found_Company') {
			this.findButton = false;
			this.getColDefs();
			this.loadData();
		} else if(this.selectedStatusBar == 'Match_Not_Found_Individual') {
			this.findButton = false;
			this.getColDefsNoMatch()
			this.loadNoMatchData();
		} else if(this.selectedStatusBar == 'Duplicate') {
			this.findButton = false;
			this.getColDefsDuplicate()
			this.loadDuplicateData();
		} else if(this.selectedStatusBar == 'Limit_Exceeded') {
			this.findButton = false;
			this.getColDefsLimitExceed()
			this.loadLimitExceddedData();
		}
	}

	offline() {
		let selectedNodes = this.api.getSelectedNodes();
		var obj = selectedNodes.map((node: any) => node.data);
		let dialogRef = this.dialog.open(OfflineSearchComponent, {
			disableClose: true,
			width: "50vw",
			panelClass: 'search-result-dialog',
			data: {
				"data": obj
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if(res) {
				this.api.getSelectedNodes().map((record: any) => {
					if(record.data.productAvailabilityJson != null) {
						var options: any = JSON.parse(record.data.productAvailabilityJson);
						options.map((option: any) => {
							if(option.SpeedDescription == res.SpeedDescription && option.reportType == res.reportType) {
								record.data.availabilityReportType = res.reportType;
								record.data.availabilitySpeedDescription = res.SpeedDescription;
								record.data.translation = res.translation;
								this.gridAPI.refreshCells();
							}
						})
						
					}
				})
			}
		});
	}

	checkButtonsStatus() {
		var notAdded: any = [];
		notAdded = this.api.getSelectedNodes().filter((data: any) => data.data.status != "Added_To_Cart_Offline");
		if(notAdded.length > 0 && this.api.getSelectedNodes().length > 1) {
			this.findButton = true;
		} else {
			this.findButton = false;
		}
	}

	findInBulkIndividual() {
		var array: any = [];
		this.api.getSelectedNodes().map((data: any) => {
			array.push(data.data.id);
		});
		let dialogRef = this.dialog.open(CommonDialogComponent, {
			width: '34vw',
			height: '240px',
			data: {
				"data": array.length,
				"key": "addMultiple"
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if(res) {
				this.loaderService.isLoading.next(true);
				this.uploadService.addToMonitoring(array).subscribe(
					(res: any) => {
						if(res.success) {
							this.loaderService.isLoading.next(false);
							this.api.getSelectedNodes().map((data: any) => {
								// data.data.status = "AddedToNamelist"
								res.data.map((id: any) => {
									if(data.data.id == id.bulkItemId) {
										data.data.status = id.flag;
										this.gridAPI.refreshCells(data);
									}
								});
							});
							this.gridAPI.refreshCells();
							this.addedToNamelist.emit("added");
						} else {
							this.tryAgainBulkAdd(array);
							this.loaderService.isLoading.next(false);
						}
					},
					(err: any) => {
						this.tryAgainBulkAdd(array);
						this.loaderService.isLoading.next(false);
					}
				);
			} else {
				this.gridAPI.refreshCells();
				this.loaderService.isLoading.next(false);
			}
		});
	}

	tryAgainBulkAdd(array: any) {
		this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
			let dialogRef = this.dialog.open(ErrorDialogComponent, {
				width: '37vw',
				data: {
					errorMessage: value['errorMsg.pleaseTryAgain']
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					this.loaderService.isLoading.next(true);
					this.uploadService.addToMonitoring(array).subscribe(
						(res: any) => {
							if(res.success) {
								this.loaderService.isLoading.next(false);
								this.api.getSelectedNodes().map((data: any) => {
									// data.data.status = "AddedToNamelist"
									res.data.map((id: any) => {
										if(data.data.id == id.bulkItemId) {
											data.data.status = id.flag;
											this.gridAPI.refreshCells(data);
										}
									});
								});
								this.gridAPI.refreshCells();
								this.addedToNamelist.emit("added");
							} else {
								this.tryAgainBulkAdd(array);
								this.loaderService.isLoading.next(false);
							}
						},
						(err: any) => {
							this.tryAgainBulkAdd(array);
							this.loaderService.isLoading.next(false);
						}
					);
				}
			});
		});
	}

	findInBulkCompany() {
		var array: any = [];
		this.api.getSelectedNodes().map((data: any) => {
			array.push(data.data.id);
		});
		let dialogRef = this.dialog.open(CommonDialogComponent, {
			width: '34vw',
			height: '240px',
			data: {
				"data": array.length,
				"key": "addMultiple"
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if(res) {
				this.loaderService.isLoading.next(true);
				this.uploadService.addToMonitoring(array).subscribe(
					(res: any) => {
						if(res.success) {
							this.loaderService.isLoading.next(false);
							this.api.getSelectedNodes().map((data: any) => {
								// data.data.status = "AddedToNamelist"
								res.data.map((id: any) => {
									if(data.data.id == id.bulkItemId) {
										data.data.status = id.flag;
										this.gridAPI.refreshCells(data);
										console.log("data.data:", data.data)
									}
								});
							});
							this.gridAPI.refreshCells();
							this.addedToNamelist.emit("added");
						} else {
							this.tryAgainBulkAdd(array);
							this.loaderService.isLoading.next(false);
						}
					},
					(err: any) => {
						this.tryAgainBulkAdd(array);
						this.loaderService.isLoading.next(false);
					}
				);
			} else {
				this.gridAPI.refreshCells();
				this.loaderService.isLoading.next(false);
			}
		});	
	}

	removeMultipleEntities() {
		this.loaderService.isLoading.next(true);
		var ids: any = [];
		this.api.getSelectedNodes().map((record: any) => {
			ids.push(record.data.id);
		});
		this.uploadService.removeRecords(ids).subscribe(
			(res: any) => {
				this.loaderService.isLoading.next(false);
				if(res.success == true) {
					this.noMatchIndividual = []; 
					this.selectedStatusBar = 'Match_Not_Found_Individual';
					this.NoMatchLoader = true;
					this.findButton = false;
					this.changeStatusBar(this.selectedStatusBar);
				} else {
					this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
						let dialogRef = this.dialog.open(ErrorDialogComponent, {
							width: '37vw',
							data: {
								errorMessage: value['errorMsg.pleaseTryAgain']
							}
						});
						dialogRef.afterClosed().subscribe(res => {
							if (res) {
								this.removeMultipleEntities();
							}
						});
					});	
				}
			},
			(err: any) => {
				this.loaderService.isLoading.next(false);
				this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
					let dialogRef = this.dialog.open(ErrorDialogComponent, {
						width: '37vw',
						data: {
							errorMessage: value['errorMsg.pleaseTryAgain']
						}
					});
					dialogRef.afterClosed().subscribe(res => {
						if (res) {
							this.removeMultipleEntities();
						}
					});
				});	
			}
		); 
	}

	addLmsBulk() {
		var array: any = [];		
		this.api.getSelectedNodes().map((data: any) => {
			array.push(data.data);
		});	
		let dialogRef = this.dialog.open(LmsSubscriptionComponent, {
			disableClose: true,
			width: "70vw",
			panelClass: 'advanced-search-dialog',
			data: {
				results: array,
				isBulk: true
			}
		});
		dialogRef.afterClosed().subscribe(edited => {
			this.gridAPI.refreshCells();
			if(edited) {
				this.addedToNamelist.emit("added");	
				this.api.getSelectedNodes().map((data: any) => {
					data.data.status = "AddedToNamelist";
				});
				this.gridAPI.refreshCells();
			}
		});
	}

	tryAgainNoFoundRecords(array: any, ev: any) {
		this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
			let dialogRef = this.dialog.open(ErrorDialogComponent, {
				width: '37vw',
				data: {
					errorMessage: value['errorMsg.pleaseTryAgain']
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					this.loaderService.isLoading.next(true);
					this.uploadService.addToMonitoring(array).subscribe(
						(res: any) => {
							if(res.success) {
								this.loaderService.isLoading.next(false);
								ev.data.status = res?.data[0]?.flag;;
								this.gridAPI.refreshCells();
								this.addedToNamelist.emit("added");
							} else {
								this.tryAgainNoFoundRecords(array, ev);
								this.gridAPI.refreshCells();
								this.loaderService.isLoading.next(false);
							}
						},
						(err: any) => {
							this.tryAgainNoFoundRecords(array, ev);
							this.loaderService.isLoading.next(false);
						}
					);
				}
			});
		});
	}

	onActionClick(ev: any) {

		if (ev.label === 'upgrade') {
			this.gridAPI.refreshCells(ev);
		}

		if (ev.label === 'company') {
			var array: any = [];
			array.push(ev.data.id);
			let dialogRef = this.dialog.open(CommonDialogComponent, {
				width: '34vw',
				height: '240px',
				data: {
					"data": ev.data,
					"key": "addSingle"
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if(res) {
					this.loaderService.isLoading.next(true);
					this.uploadService.addToMonitoring(array).subscribe(
						(res: any) => {
							if(res.success) {
								this.loaderService.isLoading.next(false);
								ev.data.status = res?.data[0]?.flag;	
								this.gridAPI.refreshCells(ev);
								this.addedToNamelist.emit("added");
							} else {
								this.gridAPI.refreshCells();
								this.loaderService.isLoading.next(false);
								this.tryAgainNoFoundRecords(array, ev);
							}
						},
						(err: any) => {
							this.gridAPI.refreshCells();
							this.tryAgainNoFoundRecords(array, ev);
							this.loaderService.isLoading.next(false);
						}
					);
				} else {
					this.gridAPI.refreshCells();
					this.loaderService.isLoading.next(false);
				}
			});
		}

		if (ev.label === 'lms') {
			var array: any = [];		
			
			ev.data['edit'] = false;
			ev.data['manuall'] = true;
			ev.data['add'] = true;
			array.push(ev.data);
			let dialogRef = this.dialog.open(LmsSubscriptionComponent, {
				disableClose: true,
				width: "70vw",
				panelClass: 'advanced-search-dialog',
				data: {
					results: array,
					isBulk: true
				}
			});
			dialogRef.afterClosed().subscribe(edited => {
				this.gridAPI.refreshCells(ev);
				if(edited) {
					this.addedToNamelist.emit("added");	
					ev.data.status = "AddedToNamelist";
					this.gridAPI.refreshCells(ev);
				}
			});	
		}

		if (ev.label === 'individual') {
			var array: any = [];
			array.push(ev.data.id);
			this.loaderService.isLoading.next(true);
			this.uploadService.addToMonitoring(array).subscribe(
				(res: any) => {
					this.loaderService.isLoading.next(false);
					ev.data.status = res.data.flag;
					this.gridAPI.refreshCells(ev);
					this.addedToNamelist.emit("added");	
				},
				(err: any) => {
					this.loaderService.isLoading.next(false);
				}
			);
		}

		if (ev.label === 'undo') {
			// this.loaderService.isLoading.next(true);
			// 	this.uploadService.deleteMonitoringRecord(ev.data.id).subscribe(
			// 	(res: any) => {
			// 		this.loaderService.isLoading.next(false);
			// 		if(res.success == true){
			// 			ev.data.isDeleted = false;
			// 			this.gridAPI.refreshCells(ev);
			// 		}
			// 	},
			// 	(err: any) => {
			// 		this.loaderService.isLoading.next(false);
			// 	}
			// ); 
		}
		
		
	}

	finishBulkRecords() {
		if(this.alertType == "LMS") {
			let dialogRef = this.dialog.open(LmsSubscriptionComponent, {
				width: '70vw',
				panelClass: 'advanced-search-dialog',
				data: {
					"data": this.bulkAlertUploadNumber,
					"key": "completeBulkUpload"
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if(res) {
					this.completeStatus.emit(res);
				}
			});
		} else {
			let dialogRef = this.dialog.open(CommonDialogComponent, {
				width: '50vw',
				height: '250px',
				data: {
					"data": this.bulkAlertUploadNumber,
					"key": "completeBulkUpload"
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if(res) {
					this.completeStatus.emit(res);
				}
			});
		}
	}

	export() {
		this.gridAPI.exportDataAsExcel();
	}

	exportCompany() {
		let element = document.getElementById('excel-table-company');
			const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
			var wscols = [
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30}
			];	
			ws['!cols'] = wscols;
			const wb: XLSX.WorkBook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, 'company');
			XLSX.writeFile(wb, 'company_ExcelSheet.xlsx');
	}

	exportIndividual() {
		let element = document.getElementById('excel-table-individual');
			const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
			var wscols = [
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30}
			];	
			ws['!cols'] = wscols;
			const wb: XLSX.WorkBook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, 'individual');
			XLSX.writeFile(wb, 'individual_ExcelSheet.xlsx');
	}


	exportDuplicate() {
		let element = document.getElementById('excel-table-duplicate');
			const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
			var wscols = [
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30}
			];	
			ws['!cols'] = wscols;
			const wb: XLSX.WorkBook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, 'Duplicates');
			XLSX.writeFile(wb, 'Duplicate_ExcelSheet.xlsx');
	}

	exportExceed() {
		let element = document.getElementById('excel-table-exceed');
			const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
			var wscols = [
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30}
			];	
			ws['!cols'] = wscols;
			const wb: XLSX.WorkBook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, 'Excced');
			XLSX.writeFile(wb, 'Exceed_ExcelSheet.xlsx');
	}
}
