<div class="master-deatils" *ngIf="!isMobile">
   <div class="alignment row">
      <div> <label> {{ 'monitor.dnsa.subtitle1' | translate }}</label> </div>
      <div style="padding-top: 10px;"> {{ cellData.SuitNo }} {{ 'monitor.dnsa.experian_database' | translate }} {{ cellData.LastUpdatedDate }}</div>
   </div>
</div>
   
<div class="overflow-y" *ngIf="isMobile">
   <div fxLayout="col" class="f-s-adjustment">
       <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
           <b>{{'monitor.dnsa.search_date' | translate}}</b>
       </div>
       <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
          {{cellData.SearchDate}}
       </div>
   </div>    
   <div fxLayout="col" class="f-s-adjustment">
       <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
           <b>{{'monitor.dnsa.search_name' | translate}}</b>
       </div>
       <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;" >
          {{cellData.SearchName}}
       </div>
   </div>    
   <div fxLayout="col" class="f-s-adjustment">
       <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
           <b>{{'monitor.dnsa.search_id' | translate}}</b>
       </div>
       <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
          {{cellData.SearchId}}
       </div>
   </div>
   <div fxLayout="col" class="f-s-adjustment">
      <div class="p-t-2 left-section">
         <div> <label> {{ 'monitor.dnsa.subtitle1' | translate }}</label> </div>
         <div style="padding-top: 10px;white-space: normal;"> {{ cellData.LastUpdatedDate }} {{ 'monitor.dnsa.experian_database' | translate }} {{ cellData.SuitNo }}</div>
      </div>
   </div>
</div>