import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AutoLogoutService } from './autoLogoutService';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})

export class AppComponent {
	title = 'IRR';

	constructor(public http: HttpClient, private translateService: TranslateService, private autoLogoutService:AutoLogoutService) {
		let lang : any = localStorage.getItem("lang");
		if(lang != undefined && lang != null) {
			this.translateService.use(lang);
		} else {
			this.translateService.setDefaultLang('en');
		}
	}

	makeHttpCall() {
		this.http.get('https://jsonplaceholder.typicode.com/comments').subscribe((r) => {
		});
	}
}
