    <section class="p1">
        <article>
            <div class="zanna_div row">
                <h1 class="M(0) Lh(1) eds-heading">
                    {{ 'bulk.monitor_upload' | translate }}
                </h1>
                <div class="loading-screen pt-7" *ngIf="bulkUploadStatus">
                    <div>
                        <span class="loading-screen-bar">
                            <span [ngStyle]="{'width': loader+'%'}"></span>
                        </span>
                    </div>
                </div>
                <p class="p-subTitle pt-7" *ngIf="isCompletedBulkUpload =='Processed'">
                    {{ 'bulk.upload.monitor_complete' | translate }}. <span *ngIf="bulkUploadStatus?.TotalCount">({{ bulkUploadStatus?.TotalCount }} / {{ bulkUploadStatus?.TotalCount }} reports) </span>
                </p>
                <p class="p-subTitle pt-7" *ngIf="isCompletedBulkUpload =='Cancelled'">
                    {{ 'bulk.upload.monitor_cancel' | translate }}. <span *ngIf="bulkUploadStatus?.TotalCount">({{ bulkUploadStatus?.CurrentCount }} of {{ bulkUploadStatus?.TotalCount }} reports) </span>
                </p>
                <div class="pt-7" *ngIf="isCompletedBulkUpload =='Processed' || isCompletedBulkUpload =='Cancelled' ">
                    <div class="pt-7 p-subTitle">
                        Once you have completed reviewing your bulk import, 
                    </div>
                    <div class="p-subTitle">
                        You can proceed by clicking on the complete bulk import below.
                    </div>
                </div>
                <div style="padding-top: 15px" *ngIf="isCompletedBulkUpload =='Processed' || isCompletedBulkUpload =='Cancelled'">
                    <button class="button button__primary pt-7" style="height: 34px; cursor: pointer;" (click)="finishBulkRecords()">
                        <label>{{ 'bulk.upload.finish' | translate }}</label>
                    </button>
                </div>
                <span *ngIf="isCompletedBulkUpload =='Processing' || isCompletedBulkUpload =='Uploaded'"> 
                    <p class="p-subTitle pt-7" *ngIf="bulkUploadStatus">
                        {{ 'bulk.upload.finding' | translate }} {{ bulkUploadStatus?.EntityName }} ({{ bulkUploadStatus?.CurrentCount }} of {{ bulkUploadStatus?.TotalCount }} reports) 
                    </p>   
                    <p class="p-subTitle pt-7" *ngIf="bulkUploadStatus">
                        Entities Found: {{ bulkUploadStatus?.CurrentCount }} / {{ bulkUploadStatus?.TotalCount }} Entities Found
                    </p>   
                    <p class="p-subTitle pt-7" *ngIf="bulkUploadStatus">
                        {{ 'bulk.upload.time_left' | translate }}: {{ minutes }} 
                        <span *ngIf=" 1 >= minutes"> {{ 'bulk.upload.minute' | translate }}</span>
                        <span *ngIf=" minutes > 1 "> {{ 'bulk.upload.minutes' | translate }}</span>
                    </p>
                    <p class="p-subTitle pt-7" *ngIf="bulkUploadStatus">
                        <a class="link" (click)="cancelProcessing()">{{ 'bulk.upload.cancel_processing' | translate }}</a>
                    </p>
                </span>
            </div>
            <div class="zanna_div row">
                <div class="col" style="padding: 0px; display: inline-flex;">
                    <div class="order-status-bar">
                        <!-- <a data-filter="LMS_Subscription" 
                            [ngClass]="selectedStatusBar == 'LMS_Subscription' ? 'selected' : ''" 
                            style="cursor: pointer;" (click)="changeStatusBar('LMS_Subscription')">
                            <span>
                                LMS Subscription
                                <span>{{ lmsSubscriptionCount }}</span>
                            </span>
                        </a> -->
                        <a data-filter="Match_Not_Found_Company" 
                            [ngClass]="selectedStatusBar == 'Match_Not_Found_Company' ? 'selected' : ''" 
                            style="cursor: pointer;" (click)="changeStatusBar('Match_Not_Found_Company')">
                            <span>
                                Not Found (Corporate)
                                <span class="count" [ngStyle]="{'width': noMatchCompanyCount.toString().length * 10 + 8 + 'px'}">{{ noMatchCompanyCount }}</span>
                            </span>
                        </a>
                        <a  data-filter="Match_Not_Found_Individual" 
                            [ngClass]="selectedStatusBar == 'Match_Not_Found_Individual' ? 'selected' : ''" 
                            style="cursor: pointer;" (click)="changeStatusBar('Match_Not_Found_Individual')">
                            <span>
                                Not Found (Individual)
                                <span class="count" [ngStyle]="{'width': noMatchIndividualCount.toString().length * 10 + 8 + 'px'}">{{ noMatchIndividualCount }}</span> <!-- [ngStyle]="{'width': noMatchIndividualCount.toString().length * 10 + 10 + 'px'}"-->
                            </span> 
                        </a>
                        <a  data-filter="Duplicate" 
                            [ngClass]="selectedStatusBar == 'Duplicate' ? 'selected' : ''" 
                            style="cursor: pointer;" (click)="changeStatusBar('Duplicate')">
                            <span>{{ 'bulk.upload.duplicate' | translate }}
                                <span class="count" [ngStyle]="{'width': duplicateRecordsCount.toString().length * 10 + 8 + 'px'}">{{ duplicateRecordsCount }}</span>
                            </span>
                        </a>
                        <a  data-filter="Limit_Exceeded" 
                            [ngClass]="selectedStatusBar == 'Limit_Exceeded' ? 'selected' : ''" 
                            style="cursor: pointer;" (click)="changeStatusBar('Limit_Exceeded')">
                            <span> Limit Exceeded
                                <span class="count" [ngStyle]="{'width': limitExceedRecordsCount.toString().length * 10 + 8 + 'px'}">{{ limitExceedRecordsCount }}</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="zanna_div row" *ngIf="selectedStatusBar == 'LMS_Subscription'">
                <div class="info">
                    Take note that LMS Subscriptions are chargeable at SGD 4.00 per entity (GST not included), you will be billed at the end of the month. These entities will only be subscribed for 90 days, after which it will automatically removed from your monitoring name list.
                </div>
                <div class="data-grid-strip">
                    <div *ngIf="gridOptions?.api?.getSelectedNodes().length > 1" class="first">
                        {{ gridOptions?.api?.getSelectedNodes().length }} {{ 'grid.rowsSelected' | translate }}
                    </div>
                    <div *ngIf="gridOptions?.api?.getSelectedNodes().length > 1" class="middle">
                    </div>
                    <div *ngIf="findButton" class="middle-button" (click)="addLmsBulk()">
                        Add to list
                    </div>
                    <!-- <div *ngIf="gridOptions?.api?.getSelectedNodes().length > 1" class="middle-button" (click)="removeMultipleOnlineEntities()">
                        {{ 'bulk.upload.remove' | translate }}
                    </div> -->
                </div>
                <ag-grid-angular 
                    class="ag-theme-alpine"
                    style="width: 100%; height: 100%;"
                    id="bulkData"
                    [rowData]="lmsSubscriptionRecords"
                    [columnDefs]="columnDefs"
                    (gridReady)="onGridReady($event)"
                    [gridOptions]="gridOptions"
                    [tooltipShowDelay]="tooltipShowDelay"
					(filterChanged)="onFilterChanged($event)"
                    (selectionChanged)="checkButtonsStatus()"
                    [domLayout]="domLayout"
                    [icons]="icons">
                </ag-grid-angular>
                <app-pagination 
                    [pageSize]="PaginationPageSize" [gridOptions]="gridOptions" *ngIf="lmsSubscriptionRecords?.length > 0"
                    [noOfPages]="gridOptions.api?.paginationGetTotalPages()" [totalRows]="gridOptions?.api?.getDisplayedRowCount()">
                </app-pagination>
            </div>
            
            <div class="zanna_div row" *ngIf="selectedStatusBar == 'Match_Not_Found_Company'">
                <div class="info">
                    The items listed below could not be found, you may still add these entities into your monitored names using our manual search. If no actions are done these entities will not be added to your monitoring list. 
                </div>
                <div class="data-grid-strip">
                    <div *ngIf="gridOptions?.api?.getSelectedNodes().length > 1" class="first">
                        {{ gridOptions?.api?.getSelectedNodes().length }} {{ 'grid.rowsSelected' | translate }}
                    </div>
                    <div *ngIf="gridOptions?.api?.getSelectedNodes().length > 1" class="middle">
                    </div>
                    <div *ngIf="findButton" class="middle-button" (click)="findInBulkCompany()">
                        Manual Search
                    </div>
                    <div class="end" *ngIf="noMatchCompany?.length > 0" (click)="exportCompany()">
                        <mat-icon >get_app</mat-icon><span style="color: #fff !important;float: right;"> &nbsp;Export Entities</span>
                    </div>
                    <!-- <div *ngIf="gridOptions?.api?.getSelectedNodes().length > 1" class="middle-button" (click)="removeMultipleOnlineEntities()">
                        {{ 'bulk.upload.remove' | translate }}
                    </div> -->
                </div>
                <ag-grid-angular 
                    class="ag-theme-alpine"
                    style="width: 100%; height: 100%;"
                    id="bulkData"
                    [rowData]="noMatchCompany"
                    [columnDefs]="columnDefs"
                    (gridReady)="onGridReady($event)"
                    [gridOptions]="gridOptions"
                    [tooltipShowDelay]="tooltipShowDelay"
					(filterChanged)="onFilterChanged($event)"
                    (selectionChanged)="checkButtonsStatus()"
                    [domLayout]="domLayout"
                    [icons]="icons">
                </ag-grid-angular>
                <app-pagination 
                    [pageSize]="PaginationPageSize" [gridOptions]="gridOptions" *ngIf="noMatchCompany?.length > 0"
                    [noOfPages]="gridOptions.api?.paginationGetTotalPages()" [totalRows]="gridOptions?.api?.getDisplayedRowCount()">
                </app-pagination>
            </div>
            <div class="zanna_div row" *ngIf="selectedStatusBar == 'Match_Not_Found_Individual'">
                <div class="info">
                    The items listed below could not be found, you may still add these entities into your monitored names using our manual search. If no actions are done these entities will not be added to your monitoring list. 
                </div>
                <div class="data-grid-strip">
                    <div *ngIf="gridOptions?.api?.getSelectedNodes().length > 1" class="first">
                        {{ gridOptions?.api?.getSelectedNodes().length }} {{ 'grid.rowsSelected' | translate }}
                    </div>
                    <div *ngIf="gridOptions?.api?.getSelectedNodes().length > 1" class="middle">
                    </div>
                    <!-- <div *ngIf="bulkUploadSettingButton" class="middle-button" (click)="offline()">
                        {{ 'bulk.upload.no_match.bulk_select' | translate }}
                    </div> -->
                    <div *ngIf="findButton" class="middle-button" (click)="findInBulkIndividual()">
                        Manual Search
                    </div>
                    <div *ngIf="noMatchIndividual?.length > 0" class="end" (click)="exportIndividual()">
                        <mat-icon >get_app</mat-icon><span style="color: #fff !important;float: right;"> &nbsp;Export Entities</span>
                    </div>
                    <!-- <div *ngIf="gridOptions?.api?.getSelectedNodes().length > 1" class="middle-button" (click)="removeMultipleEntities()">
                        {{ 'bulk.upload.remove' | translate }}
                    </div> -->
                </div>
                <ag-grid-angular 
                    class="ag-theme-alpine"
                    style="width: 100%; height: 100%;"
                    id="bulkData"
                    [rowData]="noMatchIndividual"
                    [columnDefs]="columnDefs"
                    (gridReady)="onGridReady($event)"
                    [gridOptions]="gridOptions"
                    [tooltipShowDelay]="tooltipShowDelay"
                    (selectionChanged)="checkButtonsStatus()"
					(filterChanged)="onFilterChanged($event)"
                    [domLayout]="domLayout"
                    [icons]="icons">
                </ag-grid-angular>
                <app-pagination *ngIf="noMatchIndividual?.length > 0"
                    [pageSize]="PaginationPageSize" [gridOptions]="gridOptions" 
                    [noOfPages]="gridOptions.api?.paginationGetTotalPages()" [totalRows]="gridOptions?.api?.getDisplayedRowCount()">
                </app-pagination>
            </div>
            <div class="zanna_div row" *ngIf="selectedStatusBar == 'Duplicate'">
                <div class="info">
                    The items listed below are duplicate records.
                </div>
                <div class="data-grid-strip">
                    <div class="end" *ngIf="duplicateRecords?.length > 0" (click)="exportDuplicate()">
                        <mat-icon >get_app</mat-icon><span style="color: #fff !important;float: right;"> &nbsp;Export Entities</span>
                    </div>
                </div>
                <ag-grid-angular 
                    class="ag-theme-alpine"
                    style="width: 100%; height: 100%;"
                    id="bulkData"
                    [rowData]="duplicateRecords"
                    [columnDefs]="columnDefs"
                    (gridReady)="onGridReady($event)"
                    [gridOptions]="gridOptions"
                    [tooltipShowDelay]="tooltipShowDelay"
					(filterChanged)="onFilterChanged($event)"
                    [domLayout]="domLayout"
                    [icons]="icons">
                </ag-grid-angular>
                <app-pagination *ngIf="duplicateRecords?.length > 0"
                    [pageSize]="PaginationPageSize" [gridOptions]="gridOptions" 
                    [noOfPages]="gridOptions.api?.paginationGetTotalPages()" [totalRows]="gridOptions?.api?.getDisplayedRowCount()">
                </app-pagination>
            </div>

            <div class="zanna_div row" *ngIf="selectedStatusBar == 'Limit_Exceeded'">
                <div class="info">
                    The items listed below could not be found, you may still add these entities into your monitored names using our manual search. If no actions are done these entities will not be added to your monitoring list. 
                </div>
                <div class="data-grid-strip">
                    <div class="end" *ngIf="limitExceedRecords?.length > 0" (click)="exportExceed()">
                        <mat-icon >get_app</mat-icon><span style="color: #fff !important;float: right;"> &nbsp;Export Entities</span>
                    </div>
                </div>
                <ag-grid-angular 
                    class="ag-theme-alpine"
                    style="width: 100%; height: 100%;"
                    id="bulkData"
                    [rowData]="limitExceedRecords"
                    [columnDefs]="columnDefs"
                    (gridReady)="onGridReady($event)"
                    [gridOptions]="gridOptions"
                    [tooltipShowDelay]="tooltipShowDelay"
					(filterChanged)="onFilterChanged($event)"
                    [domLayout]="domLayout"
                    [icons]="icons">
                </ag-grid-angular>
                <app-pagination *ngIf="limitExceedRecords?.length > 0"
                    [pageSize]="PaginationPageSize" [gridOptions]="gridOptions" 
                    [noOfPages]="gridOptions.api?.paginationGetTotalPages()" [totalRows]="gridOptions?.api?.getDisplayedRowCount()">
                </app-pagination>
            </div>

        </article>

    </section>

<!-- COMPANY NOT FOUND TEMPLATE-->

<div style="display: none;">   
    <table id="excel-table-company">
        <tr>
            <th> Entity Number </th>
            <th> Entity Name </th>
            <th> Entity Type </th>
            <th> Alert Type </th>
            <th> Country </th>
            <th> Issue </th>
        </tr>
      <tr *ngFor="let item of noMatchCompany">
        <td> {{ item.userIdentityNumber }} </td>
        <td> {{ item.userSearchTerm }} </td>
        <td> Company </td>
        <td> {{ item.userAlertType }} </td>
        <td> {{ item.countryName }} </td>
        <td> Record not found </td>
      </tr>
    </table>
</div>

<!-- INDIVIDUAL NOT FOUND TEMPLATE-->

<div style="display: none;">   
    <table id="excel-table-individual">
        <tr>
            <th> Entity Number </th>
            <th> Entity Name </th>
            <th> Entity Type </th>
            <th> Alert Type </th>
            <th> Country </th>
            <th> Issue </th>
        </tr>
      <tr *ngFor="let item of noMatchIndividual">
        <td> {{ item.userIdentityNumber }} </td>
        <td> {{ item.userSearchTerm }} </td>
        <td> Individual </td>
        <td> {{ item.userAlertType }} </td>
        <td> {{ item.countryName }} </td>
        <td> Record not found </td>
      </tr>
    </table>
</div>

<!-- DUPLICATE NOT FOUND TEMPLATE-->

<div style="display: none;">   
    <table id="excel-table-duplicate">
        <tr>
            <th> Entity Number </th>
            <th> Entity Name </th>
            <th> Entity Type </th>
            <th> Alert Type </th>
            <th> Country </th>
            <th> Issue </th>
        </tr>
      <tr *ngFor="let item of duplicateRecords">
        <td> {{ item.entityNumberOrNric }} </td>
        <td> {{ item.entityName }} </td>
        <td> {{ item.entityType }} </td>
        <td> {{ item.alertType }} </td>
        <td> {{ item.country }} </td>
        <td> Duplicate Record </td>
      </tr>
    </table>
</div>

<!-- EXCEED NOT FOUND TEMPLATE-->

<div style="display: none;">   
    <table id="excel-table-exceed" >
        <tr>
            <th> Entity Number </th>
            <th> Entity Name </th>
            <th> Entity Type </th>
            <th> Alert Type </th>
            <th> Country </th>
            <th> Issue </th>
        </tr>
      <tr *ngFor="let item of limitExceedRecords">
        <td> {{ item.entityNumberOrNric }} </td>
        <td> {{ item.entityName }} </td>
        <td> {{ item.entityType }} </td>
        <td> {{ item.alertType }} </td>
        <td> {{ item.country }} </td>
        <td> Limit Exceeded </td>
      </tr>
    </table>
</div>