import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/authGuard.service';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent {
	hideMenu: boolean = false;
	homeService: boolean;
	monitoringService: boolean;
	globalMonitoringService: any;
	constructor(private router: Router, public auth: AuthService) {
		router.events.forEach((event) => {
			if (event instanceof NavigationEnd) {
				let sidebar: any = document.getElementById('sidebar');
				if (event.url == '/session-expired') {
					this.hideMenu = true;
					sidebar.classList.add('hoverClass');
				} else {
					this.hideMenu = false;
					sidebar.classList.remove('hoverClass');
				}
			}
		});

		auth.iIRMonitoringAccess.subscribe((res: any) => {
			this.monitoringService = res;
		});

		auth.iIRROCReportAccess.subscribe((res: any) => {
			this.homeService = res;
		});
		auth.iirGlobalMonitoringAccess.subscribe((res: any) => {
			this.globalMonitoringService = res;
		});
	}

	gotToHome() {
		this.router.navigate(['/home']);
	}

	tabChange(event: any) {
		document.getElementsByClassName("selected")[0].classList.remove("selected")
		event.target.classList.add('selected')
	}
}
