    <section class="p1">
        <article>
            <div class="zanna_div row">
                <h1 class="M(0) Lh(1) eds-heading">
                    {{ 'bulk.upload' | translate }}
                </h1>
                <div class="loading-screen pt-7" *ngIf="bulkUploadStatus">
                    <div>
                        <span class="loading-screen-bar">
                            <span [ngStyle]="{'width': loader+'%'}"></span>
                        </span>
                    </div>
                </div>
                <p class="p-subTitle pt-7" *ngIf="isCompletedBulkUpload =='Processed'">
                    {{ 'bulk.upload.complete' | translate }}. <span *ngIf="bulkUploadStatus?.TotalCount">({{ bulkUploadStatus?.TotalCount }} / {{ bulkUploadStatus?.TotalCount }} reports)</span>
                </p>
                <p class="p-subTitle pt-7" *ngIf="isCompletedBulkUpload =='Cancelled'">
                    {{ 'bulk.upload.cancel' | translate }}.<span *ngIf="bulkUploadStatus?.TotalCount">({{ bulkUploadStatus?.CurrentCount }} of {{ bulkUploadStatus?.TotalCount }} reports)</span>
                </p>
                <span *ngIf="isCompletedBulkUpload =='Processing' || isCompletedBulkUpload =='Uploaded'"> 
                    <p class="p-subTitle pt-7" *ngIf="bulkUploadStatus">
                        {{ 'bulk.upload.finding' | translate }}  {{ bulkUploadStatus?.EntityName }} ({{ bulkUploadStatus?.CurrentCount }} of {{ bulkUploadStatus?.TotalCount }} reports)
                    </p>   
                    <p class="p-subTitle pt-7" *ngIf="bulkUploadStatus">
                        {{ 'bulk.upload.time_left' | translate }}: {{ minutes }} 
                        <span *ngIf=" 1 >= minutes">{{ 'bulk.upload.minute' | translate }}</span>
                        <span *ngIf=" minutes > 1 ">{{ 'bulk.upload.minutes' | translate }}</span>
                    </p>
                    <p class="p-subTitle pt-7" *ngIf="bulkUploadStatus">
                        <a class="link" (click)="cancelProcessing()">{{ 'bulk.upload.cancel_processing' | translate }}</a>
                    </p>
                </span>
            </div>
            <div class="zanna_div row">
                <div class="col" style="padding: 0px; display: inline-flex;">
                    <div class="order-status-bar">
                        <a data-filter="Match_Found" 
                            [ngClass]="selectedStatusBar == 'Match_Found' ? 'selected' : ''" 
                            style="cursor: pointer;" (click)="changeStatusBar('Match_Found')">
                            <span>
                                {{ 'bulk.upload.reports' | translate }}
                                <span class="count" [ngStyle]="{'width': foundRecordsCount.toString().length * 10 + 8 + 'px'}">{{ foundRecordsCount }}</span>
                            </span>
                        </a>
                        <a  data-filter="Match_Not_Found" 
                            [ngClass]="selectedStatusBar == 'Match_Not_Found' ? 'selected' : ''" 
                            style="cursor: pointer;" (click)="changeStatusBar('Match_Not_Found')">
                            <span>{{ 'bulk.upload.no_match' | translate }}
                                <span class="count" [ngStyle]="{'width': noMatchRecordsCount.toString().length * 10 + 8 + 'px'}"> {{ noMatchRecordsCount }}</span>
                            </span> 
                        </a>
                        <a  data-filter="Duplicate" 
                            [ngClass]="selectedStatusBar == 'Duplicate' ? 'selected' : ''" 
                            style="cursor: pointer;" (click)="changeStatusBar('Duplicate')">
                            <span>{{ 'bulk.upload.duplicate' | translate }}
                                <span class="count" [ngStyle]="{'width': duplicateRecordsCount.toString().length * 10 + 8 + 'px'}">{{ duplicateRecordsCount }}</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="zanna_div row" *ngIf="selectedStatusBar == 'Match_Found'">
                <div class="data-grid-strip">
                    <div *ngIf="gridOptions?.api?.getSelectedNodes().length > 1" class="first">
                        {{ gridOptions?.api?.getSelectedNodes().length }} {{ 'grid.rowsSelected' | translate }}
                    </div>
                    <div *ngIf="gridOptions?.api?.getSelectedNodes().length > 1" class="middle">
                    </div>
                    <div *ngIf="addToCartButton" class="middle-button" (click)="bulkAddToCart('Added_To_Cart')">
                        {{ 'bulk.upload.addToCart' | translate }}
                    </div>
                    <div *ngIf="gridOptions?.api?.getSelectedNodes().length > 1" class="middle-button" (click)="removeMultipleOnlineEntities()">
                        {{ 'bulk.upload.remove' | translate }}
                    </div>
                </div>
                <ag-grid-angular 
                    class="ag-theme-alpine"
                    style="width: 100%; height: 100%;"
                    id="bulkData"
                    [rowData]="foundRecords"
                    [columnDefs]="columnDefs"
                    (gridReady)="onGridReady($event)"
                    [gridOptions]="gridOptions"
                    [tooltipShowDelay]="tooltipShowDelay"
					(filterChanged)="onFilterChanged($event)"
                    (selectionChanged)="onOnlineSelectionChanged($event)"
                    [domLayout]="domLayout"
                    [icons]="icons">
                </ag-grid-angular>
                <app-pagination 
                    [pageSize]="PaginationPageSize" [gridOptions]="gridOptions" *ngIf="foundRecords.length > 0"
                    [noOfPages]="gridOptions.api?.paginationGetTotalPages()" [totalRows]="gridOptions?.api?.getDisplayedRowCount()">
                </app-pagination>
            </div>
            <div class="zanna_div row" *ngIf="selectedStatusBar == 'Match_Not_Found'">
                <div class="data-grid-strip">
                    <div *ngIf="gridOptions?.api?.getSelectedNodes().length > 1" class="first">
                        {{ gridOptions?.api?.getSelectedNodes().length }} {{ 'grid.rowsSelected' | translate }}
                    </div>
                    <div *ngIf="gridOptions?.api?.getSelectedNodes().length > 1" class="middle">
                    </div>
                    <div *ngIf="bulkUploadSettingButton" class="middle-button" (click)="offline()">
                        {{ 'bulk.upload.no_match.bulk_select' | translate }}
                    </div>
                    <div *ngIf="addToCartButton" class="middle-button" (click)="bulkAddToCart('Added_To_Cart_Offline')">
                        {{ 'bulk.upload.addToCart' | translate }}
                    </div>
                    <div *ngIf="gridOptions?.api?.getSelectedNodes().length > 1" class="middle-button" (click)="removeMultipleEntities()">
                        {{ 'bulk.upload.remove' | translate }}
                    </div>
                </div>
                <ag-grid-angular 
                    class="ag-theme-alpine"
                    style="width: 100%; height: 100%;"
                    id="bulkData"
                    [rowData]="noMatchRecords"
                    [columnDefs]="columnDefs"
                    (gridReady)="onGridReady($event)"
                    [gridOptions]="gridOptions"
                    [tooltipShowDelay]="tooltipShowDelay"
                    (selectionChanged)="onSelectionChanged($event)"
					(filterChanged)="onFilterChanged($event)"
                    [domLayout]="domLayout"
                    [icons]="icons">
                </ag-grid-angular>
                <app-pagination *ngIf="noMatchRecords.length > 0"
                    [pageSize]="PaginationPageSize" [gridOptions]="gridOptions" 
                    [noOfPages]="gridOptions.api?.paginationGetTotalPages()" [totalRows]="gridOptions?.api?.getDisplayedRowCount()">
                </app-pagination>
            </div>
            <div class="zanna_div row" *ngIf="selectedStatusBar == 'Duplicate'">
                <div class="data-grid-strip">
                </div>
                <ag-grid-angular 
                    class="ag-theme-alpine"
                    style="width: 100%; height: 100%;"
                    id="bulkData"
                    [rowData]="duplicateRecords"
                    [columnDefs]="columnDefs"
                    (gridReady)="onGridReady($event)"
                    [gridOptions]="gridOptions"
                    [tooltipShowDelay]="tooltipShowDelay"
					(filterChanged)="onFilterChanged($event)"
                    [domLayout]="domLayout"
                    [icons]="icons">
                </ag-grid-angular>
                <app-pagination *ngIf="duplicateRecords.length > 0"
                    [pageSize]="PaginationPageSize" [gridOptions]="gridOptions" 
                    [noOfPages]="gridOptions.api?.paginationGetTotalPages()" [totalRows]="gridOptions?.api?.getDisplayedRowCount()">
                </app-pagination>
            </div>

        </article>
    </section>