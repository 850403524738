import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-name-renderer',
  template: `
      <div style="padding-top: 10px;padding-bottom: 10px;">
        <div>Entity Number. {{ param.data.entNo}} </div> {{ param.data.nam}} 
        <span *ngIf="param.data?.oldNames?.length > 0"> 
          <div *ngFor = "let name of param.data.oldNames"> 
            <div *ngIf="name.isFmrNam == 'N'" style="color: #929292;">  N.K.A {{ name.nam }} </div>
            <div *ngIf="name.isFmrNam == 'Y'" style="color: #929292;">  F.K.A {{ name.nam }} </div>
          </div>
        </span>
      </div>
  `,
  styles: [
  ]
})
export class NameRendererComponent  implements ICellRendererAngularComp {
  public param: any;

  constructor() { }
  
  agInit(params: ICellRendererParams): void {
    this.param = params;
  }

  ngOnInit(): void {
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
