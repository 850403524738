import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
	selector: 'app-bulk-grid-cell-renderer',
	templateUrl: './bulk-grid-cell-renderer.component.html',
	styleUrls: ['./bulk-grid-cell-renderer.component.scss']
})

export class BulkGridCellRendererComponent implements ICellRendererAngularComp {

	public params: any;
	option: string | undefined;
	showTranslation: boolean = false;

	constructor(
		public iconRegistry: MatIconRegistry,
		public sanitizer: DomSanitizer
	) { 
		iconRegistry
		.addSvgIcon('payment', sanitizer.bypassSecurityTrustResourceUrl('assets/img/payment.svg'))
		.addSvgIcon('shopping', sanitizer.bypassSecurityTrustResourceUrl('assets/img/shopping.svg'))
		.addSvgIcon('payment-green', sanitizer.bypassSecurityTrustResourceUrl('assets/img/payments-green.svg'))
		.addSvgIcon('payment-grey', sanitizer.bypassSecurityTrustResourceUrl('assets/img/payments-grey.svg'));

	}

	agInit(params: any): void {
		this.params = params;
		if (!this.params.data.parentCartSeqNo) {
			this.showTranslation = this.params.api.getModel().rowsToDisplay.some((obj: any) => (obj.data.parentCartSeqNo == this.params.data.cartSeqNo));
		}
	}

	refresh(): boolean {
		return false;
	}

	checkValid(data: any) {
		return data.translation;
	}

	validJSONCopy(data: any) {
		var text = (data.productDescription).split(" ").splice(-1);
		if (text == 'Translation') {
			return false;
		} else {
			return true;
		}
	}

	onDelete(ev: any) {
		this.params['label'] = 'delete';
		this.params.onClick(this.params);
	}

	onRemove(ev: any) {
		this.params['label'] = 'removeNoMatch';
		this.params.onClick(this.params);
	}

	upgradePlane() {
		this.params['label'] = 'upgrade';
		this.params.onClick(this.params);
	}

	searchNoMatchCompany() {
		this.params['label'] = 'company';
		this.params.onClick(this.params);
	}
	
	searchNoMatchIndividual() {
		this.params['label'] = 'company';
		this.params.onClick(this.params);
	}

	addLms() {
		this.params['label'] = 'lms';
		this.params.onClick(this.params);
	}

	expand(ev: any) {
		this.params['label'] = 'expand';
		this.params.onClick(this.params);
	}

	searchMaually(ev: any) {
		this.params['label'] = 'search';
		this.params.onClick(this.params);
	}

	requestToJp() {
		this.params['label'] = 'jp_translation';
		this.params.onClick(this.params);
	}

	addNoMatchToCart() {
		this.params['label'] = 'addNoMatchToCart';
		this.params.onClick(this.params);
	}

	downloadReport(event: any) {
		this.params['actionEvent'] = event;
		this.params.onClick(this.params);
	}

	reportSample() {
		this.params['label'] = 'sample';
		this.params.onClick(this.params);
	}

	undo() {
		this.params['label'] = 'undo';
		this.params.onClick(this.params);
	}

	delete() {
		this.params['label'] = 'delete';
		this.params.onClick(this.params);
	}
}