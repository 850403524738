
<ng-template #deleteUpdatePopup>
	<ul>
		<li class="update-delete-popover" (click)="onDelete($event)">
			<mat-icon class="download-icon">
				delete
			</mat-icon>
			<a class="alignment">{{ 'gridActions.deleteEntity' | translate }}</a>
		</li>
	</ul>
</ng-template>

<ng-template #manualSearchPopup>
	<ul>
		<li class="update-delete-popover" (click)="addNoMatchToCart()">
			<a class="alignment">{{ 'home.addToCartBtn' | translate }}</a>
		</li>
		<li class="update-delete-popover" (click)="onRemove($event)">
			<a class="alignment">{{ 'bulk.upload.remove' | translate }}</a>
		</li>
	</ul>
</ng-template>
<div style="display: flex;" *ngIf="params.label == 'bulk-duplicate'">
	<div style="margin-right: 12px;" *ngIf="!params.data.isDeleted">
		<mat-icon class="shopping-icon" (click)="delete()">
			delete
		</mat-icon>
	</div>
	<div style="margin-right: 12px;" *ngIf="params.data.isDeleted">
		<div>
			{{ 'bulk.upload.removed' | translate }}
		</div>
		<div>
			<a class="link" (click)="undo()"> {{ 'bulk.upload.undo' | translate }} </a>
		</div>
	</div>
</div>

<div style="display: flex;" *ngIf="params.label == 'bulk-no-match'">
	<div>
		<img class="img-dots" src="/assets/img/icon-dots.svg" container="body" [ngbPopover]="manualSearchPopup" placement="bottom-right" *ngIf="params.data.status != 'Added_To_Cart_Offline'"> 
		<div *ngIf="params.data.status == 'Added_To_Cart_Offline'">
			{{ 'bulk.upload.addedToCart' | translate }}
		</div>
		<div *ngIf="params.data.status == 'Added_To_Cart_Offline'" class="link" (click)="onRemove($event)">
			{{ 'bulk.upload.remove' | translate }}
		</div>
	</div>
</div>

<div style="display: flex;" *ngIf="params.label == 'bulk-no-match-company'">
	<div *ngIf="params.data.status == 'Match_Not_Found_Company'">
		<mat-icon matSuffix class="search" (click)="searchNoMatchCompany()">playlist_add</mat-icon>
	</div>
	<div *ngIf="params.data.status == 'Already_Exists'">
		{{ 'bulk.upload.already_exist' | translate }}
	</div>
	<div *ngIf="params.data.status == 'Ccs_Fail_Add_Company'">
		{{ 'bulk.upload.failed_to_add' | translate }}
	</div>
	<div *ngIf="params.data.status == 'Added_To_Namelist'">
		{{ 'bulk.upload.addedToList' | translate }}
	</div>
</div>

<div style="display: flex;" *ngIf="params.label == 'bulk-no-match-individual'">
	<div *ngIf="params.data.status == 'Match_Not_Found_Individual'">
		<mat-icon matSuffix class="search" (click)="searchNoMatchIndividual()">playlist_add</mat-icon>
	</div>
	<div *ngIf="params.data.status == 'Already_Exists'">
		{{ 'bulk.upload.already_exist' | translate }}
	</div>
	<div *ngIf="params.data.status == 'Ccs_Fail_Add_Individual'">
		{{ 'bulk.upload.failed_to_add' | translate }}
	</div>
	<div *ngIf="params.data.status == 'Added_To_Namelist'">
		{{ 'bulk.upload.addedToList' | translate }}
	</div>
</div>

<div style="display: flex;" *ngIf="params.label == 'bulk-lms-subscription'">
	<div *ngIf="params.data.status != 'AddedToNamelist'">
		<mat-icon matSuffix class="search" (click)="addLms()">add</mat-icon>
	</div>
	<div *ngIf="params.data.status == 'AddedToNamelist'">
		{{ 'bulk.upload.addedToCart' | translate }}
	</div>
</div>

<div style="display: flex;" *ngIf="params.label == 'bulk-limit-exceed'">
	<div>
		<a class="link" href="mailto:cs@experian.com"  (click)="upgradePlane()"> {{ 'monitor.individualPicAlert.smeNetworkScoreWatchMessage' | translate }}  </a>
	</div>
</div>


