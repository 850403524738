import {Component, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActionDialogComponent } from '../action-dialog/action-dialog.component';
import { Notification, NotificationType } from "./toast.model";  
import { NotificationService } from "./toast.service";

@Component({
    selector: 'app-toast', 
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss']
})
export class Toast implements OnInit {
    isMobile: boolean = false;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if(event.target.innerWidth > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
	}
    notifications: Notification[] = [];
    actionDialogRef: MatDialogRef<ActionDialogComponent>;
    
    constructor(public _notificationService: NotificationService, public dialog: MatDialog) {  
        if(window.screen.width > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}    
    }
  
    ngOnInit() {
		this._notificationService.subject.subscribe((alert: Notification) => { 
            this.notifications = [];  
            if (!alert) {  
                this.notifications = [];  
                return;  
            }  
            this.notifications.push(alert);  
            if(alert.type != 3) {
                setTimeout(() => {  
                    this.notifications = this.notifications.filter(x => x !== alert);  
                }, 10000);
            }
        });  
	}

    cssClass(notification: Notification) {  
        if (!notification) {  
            return;  
        }  
        switch (notification.type) {  
            case NotificationType.Success:  
                return 'toast-success';  
            case NotificationType.Error:  
                return 'toast-error';  
            case NotificationType.Info:  
                return 'toast-info';  
            case NotificationType.Warning:  
                return 'toast-warning';  
        }  
    }

    removeNotification(notification: Notification) {  
        if(notification.type == 3) {
            localStorage.setItem('upgrade_plan_show', 'false');
        }
        this.notifications = this.notifications.filter(x => x !== notification);  
    } 
}