<app-header [title]="title"></app-header>
<main class="p1">
    <section>
        <article>
            <div class="zanna_div row mt-60">
                <a routerLink="/order-history" style="cursor: pointer;" class="back-order-history">
                    Order History
                </a>
            </div>

            <div class="zanna_div row">
                <h1 class="M(0) Lh(1) eds-heading heading-xl-caps">
                    {{ 'billing.title' | translate }}
                </h1>
                <p class="p-subTitle">
                    {{ 'billing.subInfo' | translate }}
                    <a href="mailto:cs@experian.com" class="email">cs@experian.com</a>
                </p>
            </div>

            <div class="zanna_div row">
                <p class="fs-2">
                    <strong>
                        {{ 'billing.desc' | translate }}
                    </strong> <br />
                    <strong>
                        {{ 'billing.coEntity' | translate }}: 198302653E
                    </strong>
                </p>
            </div>

            <div class="zanna_div row">
                <p class="fs-2">10 Kallang Avenue <br />
                #05-18 Aperia Tower 2<br />
                Singapore 339510</p>
            </div>

            <div class="zanna_div row"  style="margin-top: -10px;">
                <span class="fs-2">{{ 'billing.datePrinted' | translate }}: {{ datePrinted | date: 'd MMM y' }}</span>
            </div>

            <div class="zanna_div row">
                <div class="data-grid-strip">
                </div>
                <table class="table table-variation" style="width: 100%;">
                    <tr>
                        <th class="table-border-header desktop-view" width="100">
                            {{ 'billing.table.searchDate' | translate }}
                        </th>
                        <th class="table-border-header desktop-view" width="125">
                            {{ 'billing.table.product' | translate }}
                        </th>
                        <th class="table-border-header fs-2 border-r" [style.width]="isMobile ? '50%' : '280px'">
                            {{ 'billing.table.identification' | translate }}
                        </th>
                        <th class="table-border-header desktop-view" width="100">
                            {{ 'billing.table.price' | translate }} ({{ currency }})
                        </th>
                        <th class="table-border-header desktop-view" width="100">
                            {{ 'billing.table.orderItemId' | translate }}
                        </th>
                        <th class="table-border-header desktop-view border-r-only" width="200">
                            {{ 'billing.table.fileRef' | translate }}
                        </th>
                    </tr>
                    <tr>
                        <td class="table-border-data desktop-view">
                            {{ billingData.orderDate | date: 'd MMM y' }}
                        </td> 
                        <td class="table-border-data desktop-view">
                            {{ billingData.productDescription }}
                        </td>
                        <td class="table-border-data border-r">
                            {{ billingData.entityName }}
                        </td>
                        <td class="table-border-data desktop-view" style="text-align: left;">
                            {{ billingData.sellingPrice | currency:'':'' }}
                        </td>
                        <td class="table-border-data desktop-view">
                            {{ billingData.itemId }}
                        </td>
                        <td class="table-border-data-last desktop-view">
                            <span *ngIf="billingData.fileRef != null">{{ billingData.fileRef }}</span>
                            <span *ngIf="billingData.fileRef == null">N.A.</span>
                        </td>
                    </tr>
                </table>
                <div class="table-details mobile-view">
                    <div class="detail-section">
                        <label for="">{{ 'billing.table.searchDate' | translate }}</label>
                        <div class="details">
                            {{ billingData.orderDate | date: 'd MMM y' }}
                        </div>
                    </div>
                    <div class="detail-section">
                        <label for="">{{ 'billing.table.product' | translate }}</label>
                        <div class="details">
                            {{ billingData.productDescription }}
                        </div>
                    </div>
                    <div class="detail-section">
                        <label for="">{{ 'billing.table.price' | translate }} ({{ currency }})</label>
                        <div class="details">
                            {{ billingData.sellingPrice | currency:'':'' }}
                        </div>
                    </div>
                    <div class="detail-section">
                        <label for="">{{ 'billing.table.orderItemId' | translate }}</label>
                        <div class="details">
                            {{ billingData.itemId }}
                        </div>
                    </div>
                    <div class="detail-section">
                        <label for="">{{ 'billing.table.fileRef' | translate }}</label>
                        <div class="details">
                            <span *ngIf="billingData.fileRef != null">{{ billingData.fileRef }}</span>
                            <span *ngIf="billingData.fileRef == null">N.A.</span>
                        </div>
                    </div>
                </div>
            </div>

        </article>
    </section>
</main>
<app-footer></app-footer>
