<div class="custom-tooltip" *ngIf="data.type != 'fileRef'">
    <p>
        <span>Address: </span><br/><br/>
        {{ data.address }} <span *ngIf="data.city">, {{data.city}}</span>
    </p>
</div>

<div class="custom-tooltip" *ngIf="data.type == 'fileRef' && data.fileRef">
    <p>
        <span>Client File Ref.</span><br/><br>
        {{ data.fileRef}}
    </p>
</div>