import { Component, OnInit, AfterViewInit, OnDestroy, Output, EventEmitter, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { IndividualPickAlertComponent } from '../shared-module/individual-pick-alert/individual-pick-alert';
import { NotificationService } from "../shared-module/toast/toast.service";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ExportAllAlertComponent } from '../shared-module/export-all-alert/export-all-alert.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MonitoringService } from '../monitoring.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ErrorDialogComponent } from 'src/app/error-dialog-box/error-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/home/home.service';
import { DocumentViewComponent } from 'src/app/document-view/document-view.component';
@Component({
	selector: 'app-monitor',
	templateUrl: './monitor.component.html',
	styleUrls: ['./monitor.component.scss']
})
export class MonitorComponent implements OnInit {
	isMobile: boolean = false;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if(event.target.innerWidth > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
	}
	@Output() tabsEvent = new EventEmitter();
	changeLanEvent: Subject<void> = new Subject<void>();
	alertSlide: boolean = true;
	prioritySlide: boolean = false;
	showToast: boolean = true;
	tooltipdata: any;
	recordsSlide: boolean = false
	title: any = "Monitoring";
	summuryData: any;
	addedRecord: any;
	selectedFilter: any = 'ALL';
	highPriority: string = "<app-high-priority> </app-high-priority> // high priority component selector"
	uncollectedCount: number = 0; 
	alerts: any = [];
	alertDetails: any = "ALL";
	bulkUploadDetails:any = null;
	bulkuploadLinkStatus: any;
	options: any;
	option: any ="ALL";
	expandButtonValue: any = "Expand all rows";
	buttonExpandValue: any = false;
	monitoringTypeClassification: any;
	
	constructor(
		public _notificationservice: NotificationService,
		public router: ActivatedRoute,
		public iconRegistry: MatIconRegistry,
		public sanitizer: DomSanitizer,
		private dialog: MatDialog,
		private service: MonitoringService,
		private homeService: HomeService,
		public loaderService: LoaderService,
		public translateService: TranslateService,
	) {
		if(window.screen.width > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
		if(localStorage.getItem('upgrade_plan_show') == null) {
			localStorage.setItem('upgrade_plan_show', "true");
		}
		iconRegistry
			.addSvgIcon('payment', sanitizer.bypassSecurityTrustResourceUrl('assets/img/payment.svg'))
			.addSvgIcon('percentage', sanitizer.bypassSecurityTrustResourceUrl('assets/img/percentage.svg'));

		this.router.queryParams.subscribe((route) => {
			if (route.recordSlide == 'true') {
				this.changeSlide("true");
			}
		});
		if(this.alertSlide){
			this.getAlertsData();
		}

	}

	ngOnInit() {	
			
	}

	getAlertsData() {
		this.loaderService.isLoading.next(true);
		this.service.loadRecords().subscribe((loaded: any) => {
			if(loaded.success){
				this.service.getAlertsSummary().subscribe((data: any) => {
					if(data.success) {
						this.summuryData = data;
						this.alerts = data?.data?.summaryDetails;
						if(this.alerts) {
							localStorage.setItem("summary", JSON.stringify(this.alerts));
						} else {
							localStorage.removeItem("summary");
						}
						this.monitoringTypeClassification = data?.data?.monitoringTypeClassification;
						this.loaderService.isLoading.next(false);
						this.alerts?.map((res: any) => {
							this.uncollectedCount = this.uncollectedCount + res.unReadCount;
						});
					} else {
						this.loaderService.isLoading.next(false);
						this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
							let dialogRef = this.dialog.open(ErrorDialogComponent, {
								disableClose: true,
								width: this.isMobile? 'auto':'37vw',
								data: {
									errorMessage: value['errorMsg.pleaseTryAgain']
								}
							});
							dialogRef.afterClosed().subscribe(res => {
								if (res) {
									this.getAlertsData();
								}
							});
						})
					}	
				},(err: any) => {
					this.loaderService.isLoading.next(false);
				});
			}else{
				this.loaderService.isLoading.next(false);
				this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
					let dialogRef = this.dialog.open(ErrorDialogComponent, {
						disableClose: true,
						width: this.isMobile? 'auto':'37vw',
						data: {
							errorMessage: value['errorMsg.pleaseTryAgain']
						}
					});
					dialogRef.afterClosed().subscribe(res => {
						if (res) {
							this.getAlertsData();
						}
					});
				})
			}
			
		},(err: any) => {
			this.loaderService.isLoading.next(false);
		});
	}

	changeLanguageGrid(ev: any) {
		this.changeLanEvent.next();
	}

	changeSelectedFilter(filter: any) {
		this.selectedFilter = filter;
		this.alertDetails = filter;
	}

	setDefault() {
		this.selectedFilter = "ALL";
	}

	changeSlide(event: any) {
		this.alertSlide = false;
		this.prioritySlide = false;
		this.recordsSlide = true;
		if(!this.bulkuploadLinkStatus || !this.bulkUploadDetails) {
			this.getBulkUploadDetails();
		}
	}

	userManualReport() {
		this.loaderService.isLoading.next(true);
		let orderSource = localStorage.getItem('orderSource');
		let lang = localStorage.getItem('lang');
		this.service.userManualReport().subscribe(res => {
			this.dialog.open(DocumentViewComponent, {
				width: '80vw',
				data: {
					response: res,
					format: 'PDF'
				}
			});
			this.loaderService.isLoading.next(false);
		}, err => {
			this.loaderService.isLoading.next(false);
		});
	}

	description() {

	}

	hovered(alert: any) {
		this.tooltipdata = alert;
	}
	

	getCommpleteStatus(event: any) {
		this.bulkuploadLinkStatus = event.data;
		this.bulkUploadDetails = event.data;
		if(event && event.redirect) {
		this.changeSlide(event);
		}
	}

	addedToNameList(event: any) {
		this.addedRecord = this.addedRecord + "added";
	}

	getBulkUploadDetails() {
		this.service.getBulkUploadDetails().subscribe((resp: any) => {
			if (resp.success) {
				this.bulkUploadDetails = resp.data;
				this.bulkuploadLinkStatus = resp.data;
			}
		}, err => {
		});
	}

	exportAllAlerts() {
		let dialogRef = this.dialog.open(ExportAllAlertComponent, {
			disableClose: true,
			data: this.alerts,
			width: this.isMobile?"auto":"60vw",
			panelClass: 'advanced-search-dialog'
		});
		dialogRef.afterClosed().subscribe(res => {
		});
	}
}
