import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'app-monitor-all-record-data',
  templateUrl: './monitor-all-record-data.component.html',
  styleUrls: ['./monitor-all-record-data.component.scss']
})
export class MonitorAllRecordDataCellRenderer implements ICellRendererAngularComp {
  public cellData: any;
  agInit(params: any): void {
    this.cellData = params.data;
    console.log('checkdata',params)
  }

  refresh(params: any): boolean {
    return false;
  }
}