<div class="dialog-content-wrapper">
    <div *ngIf="reportTypeSetting">
        <div class="cross-header zanna_div">
            <span class="title-text">
                {{ 'bulk.upload.no_match.bulk_select' | translate }}
            </span>
            <mat-icon class="close-btn" (click)="matDialogRef.close(false)">close</mat-icon>
        </div>

        
            <div class="zanna_div row">
                <div class="d_12_12 col" style="padding-left: 0px;">
                    <div class="zanna_div table-head gradient" style="margin-top: 50px"></div>
                    <div class="zanna_div ymal-table row" style="padding: 20px;margin-bottom: 30px;">
                        <div style="width: 55%; margin-top: 20px;" class="zanna_div row">
                            <label>{{ 'bulk.upload.ReportType' | translate }}</label><br />
                            <ng-select name="product" [items]="productList" [(ngModel)]="reportType" (change)="changeReportType($event)" [placeholder]="'placeholder.select_product' | translate"
                                bindLabel="reportTypeAndTAT" 
                                bindValue="reportTypeAndTAT"
                                [virtualScroll]= true
                                appendTo="body">
                            </ng-select>
                        </div>
                        <div style="width: 55%; margin-top: 20px;" class="zanna_div row" *ngIf="distributionCode == 'JPN'">
                            <label>{{ 'bulk.upload.translation_language' | translate }}</label><br />
                            <ng-select name="language" [items]="selectLanguage" [(ngModel)]="language" (change)="changeTranslation($event)"  [placeholder]="'placeholder.select_lang' | translate"
                                bindLabel="languageName" 
                                bindValue="languageName"
                                [virtualScroll]= true
                                appendTo="body">
                            </ng-select>
                        </div>
                        <div class=" row" style="margin-top: 40px;">
                            <div class="d_6_12 col" style="padding-left: 0px;">
                                <button type="button" (click)="matDialogRef.close(false)" class="button btn button__basic">
                                    {{ 'searchResults.btn.cancel' | translate }}
                                </button>
                                <button type="submit" class="button btn ml-22 button__primary" (click)="apply()">
                                        {{ 'bulk.upload.apply' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>


    <!--  records do not have report type setting -->

    <div *ngIf="!reportTypeSetting">
            <div class="cross-header">
                <span class="title-text">
                    {{ 'bulk.upload.take_note' | translate }}
                </span>
                <mat-icon class="close-btn" (click)="close()">close</mat-icon>
            </div>
            <div class="mb-36 pt-15">
                <p class="p-subTitle">
                    {{ 'bulk.upload.entites_do_not_have_setting' | translate }}
                </p>
            </div>
            <div class="row">
                <div class="data-grid-strip">
                    <span>
        
                    </span>
                    <span class="end" (click)="exportToExcel()">
                        <mat-icon class="pt-15">get_app</mat-icon>
                        <span class="font-14 float-right pt-15">{{ 'bulk.upload.export_list' | translate }}</span>
                    </span>
        
                </div>
                <ag-grid-angular 
                    class="ag-theme-alpine"
                    id="NoMatchReportTypeGrid"
                    [rowData]="records"
                    [columnDefs]="columnDefs"
                    (gridReady)="onGridReady($event)"
                    [gridOptions]="gridOptions"
                    [icons]="icons">
                </ag-grid-angular> <!-- [domLayout]="domLayout" -->
                <app-pagination *ngIf="records.length > 0" [pageSize]="paginationPageSize" [gridOptions]="gridOptions"
                    [noOfPages]="gridOptions.api?.paginationGetTotalPages()"
                    [totalRows]="gridOptions?.api?.getDisplayedRowCount()">
                </app-pagination>
            </div>
            <div class="row">
                <div class="d_6_12 col" style="padding-left: 0px;">
                    <button type="submit" (click)="close()" class="button btn ml-22 button__primary">
                            {{ 'bulk.upload.proceed' | translate }}
                    </button>
                </div>
            </div>        
    </div>
</div>
