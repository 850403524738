<div class="dialog-content-wrapper">
    <div mat-dialog-content>
        <div class="cross-header" style="margin-top: 36px;">
            <span class="title-text" >
                Complete Bulk Import
            </span>
        </div>

        <div class="zanna_div row">
            <div class="grey-color" style="margin-bottom: 20px;"> Are you sure you want to complete your bulk import ? You have added {{totalCount}} entities under your Legal Monitoring Service Subscription, some of these entities will be subjected to a ${{lmsCost}} fee per entity (GST not included), you will be billed at the end of the month. These entities will only be subscribed for 90 days, after which it will automatically removed from your monitoring name list. Completing your bulk import will remove the bulk import results. Any of the results that were not assigned will be removed.</div><br />
        </div>
        <div class="zanna_div row" style="margin-bottom: 36px;" >
            <div class="d_12_12 col">
                <button (click)="matDialogRef.close(false)" class="button btn button__basic" style="margin-left: -10px;">
                    {{ 'monitor.lms.back' | translate }}
                </button>  
                <button (click)="complete()" class="button btn ml-22 ml-22" [disabled]="disabled" [ngClass]="disabled? 'button__basic': 'button__primary'">
                    {{ 'bulk.upload.confirm' | translate }}
                </button> 
            </div>
        </div>
        <div class="zanna_div row" style="margin-bottom: 36px;">
            <span class="summary">Summary for Legal Monitoring Service Subscription</span>
            <div class="summary-format">
                <div class="cart-price-format">
                    <span class="total-cart-price">
                        Number of chargeable entities
                    </span>
                    <span class="cart-price">
                        {{chargeableCount}}
                    </span>
                </div>
                <div class="cart-price-format">
                    <span class="total-cart-price">
                        Price per entity in SGD
                    </span>
                    <span class="cart-price">
                        ${{lmsCost}}
                    </span>
                </div>
                <div class="cart-price-format">
                    <span class="total-cart-price">
                        Total price in SGD
                    </span>
                    <span class="cart-price">
                        ${{totalPrice}}
                    </span>
                </div>
                <div style="text-align: right;" *ngIf="paymentMethod == 'Prepaid'">
                    {{ 'cart.prepaidPriceTax' | translate }}
                </div>
                <div style="text-align: right;" *ngIf="paymentMethod == 'Postpaid'">
                    {{ 'cart.postpaidPriceTax' | translate }}
                </div>
                <hr>
                <div class="cart-price-format">
                    <span class="total-cart-price">
                        {{ 'cart.paymentMethod' | translate }}
                    </span>
                    <span>{{ paymentMethod }}</span>
                </div>
            </div>
        </div>
        
    </div>
</div>