import { Component } from '@angular/core';
import { ICellRendererAngularComp, AgFilterComponent } from 'ag-grid-angular';
import { IDoesFilterPassParams, IFilterParams, RowNode } from '@ag-grid-community/core';

@Component({
	selector: 'custom-dropdown-filter',
	templateUrl: './custom-dropdown-filter.html',
	styleUrls: ['./custom-dropdown-filter.scss']
})

export class CustomDropDownFilter {

    params?: IFilterParams;

    agInit(param: IFilterParams):void {
        this.params = param;
    }

    isFilterActive(): boolean {
        return false;
    }
}