import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keepOrder'
})
export class KeepOrderPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (typeof value == 'object') {
      return Object.keys(value).map(key => ({ key, value: value[key] }));
    }
  }

}
