<span id="print-section">
    <app-header (langChange)="changeLanguageGrid($event)" [title]="title"></app-header>
    <app-toast class="position"></app-toast>
    <main class="p1">
        <section>
            <article>
                <div class="zanna_div row mt-60">
                    <h1 class="M(0) Lh(1) eds-heading heading-xl-caps print">
                        {{ 'global_monitor.alert.title' | translate }}
                        <button type="button" class="button button__primary userManual-btn" [useExistingCss]="true"
                            printSectionId="print-section" ngxPrint *ngIf="!isMobile">
                            <mat-icon class="material-icon icon-color">printer</mat-icon>
                            &nbsp;{{ 'global_monitor.alert.print_page' | translate }}
                        </button>
                    </h1>
                    <p class="p-subTitle">
                        {{ 'global_monitor.alert.subtitle' | translate }} {{ count }} {{
                        'global_monitor.alert.subtitle1' | translate }}
                    </p>
                </div>
                <div class="expand-btn-area" *ngIf="isMobile && (newDefendantList.length>1 )">
                    <button type="button" class="expand-btn btn btn-primary" (click)="ExpandAll(allExpand)"
                        [disabled]="btnDisabled">
                        <mat-icon *ngIf="!allExpand">keyboard_arrow_down</mat-icon>
                        <mat-icon *ngIf="allExpand">keyboard_arrow_up</mat-icon>
                        {{expandCollapseButtonValue}}
                    </button>
                </div>
                <div *ngFor="let item of Data; let i = index">
                    <div class="data-grid-strip">
                        <span style="color: #fff !important;">
                            {{item.Title }}
                        </span>
                    </div>

                    <div style="border:1px solid #ccc">
                        <app-global-new-defendant-renderer [cellData]="item"></app-global-new-defendant-renderer>
                    </div><br><br>
                </div>
                <div>
                    <b>Note:</b><br><br>
                    <div>N.A. value in the Previous column to be interpreted as the field value been added in the new
                        report</div>
                    <div>N.A. value in the New column to be interpreted as the field value been removed in the new
                        report.</div>
                </div>
            </article>
        </section>
    </main>
</span>
<app-footer></app-footer>