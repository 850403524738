<header class="h-100 desktop-sidebar" id="sidebar">
    <section>
        <nav (click)="tabChange($event)" *ngIf="!hideMenu">
            <a (click)="gotToHome()" class="selected icon-home" *ngIf="homeService">
                <span>{{ 'app.sidebar.report' | translate }}</span>
            </a>
            <!-- Staging Monitoring tab -->
            <a routerLink="/global-monitor" class="icon-global-monitoring" *ngIf="globalMonitoringService">
                <span>{{ 'app.sidebar.globalMonitoring' | translate }}</span>
            </a>
            <a routerLink="/monitor" class="icon-monitoring" *ngIf="monitoringService">
                <span>{{ 'app.sidebar.monitoring' | translate }}</span>
            </a>
            <!-- UAT Monitoring tab -->
            <!--<a class="icon-monitoring">
                <span>{{ 'app.sidebar.monitoring' | translate }}</span>
            </a> -->
            <a routerLink="/order-history" class="icon-order-history" *ngIf="homeService">
                <span>{{ 'app.sidebar.orderHistory' | translate }}</span>
            </a>
            <a routerLink="/page-not-found-component" class="icon-setting">
                <span>{{ 'app.sidebar.setting' | translate }}</span>
            </a>
        </nav>
        <nav *ngIf="hideMenu">
            <a class="selected icon-home">
            </a>
        </nav>
    </section>
</header>