
<mat-form-field appearance="fill" [ngStyle]="{'width.px': width}" *ngIf="params.label == 'reportType'">
    <mat-label>{{ 'variationTable.selectReportType' | translate}}</mat-label>
    <mat-select [(value)]="selected">
        <mat-option *ngFor="let availability of availabilityOptions" [value]="availability.reportTypeAndTAT" (click)="availabilitySelected(availability)">
            <span style = "color: #426DA9">{{ availability.reportTypeAndTAT }}</span>
        </mat-option>
    </mat-select>
</mat-form-field>

<mat-form-field appearance="fill" [ngStyle]="{'width.px': width}" *ngIf="params.label == 'language'">
    <mat-label>{{ 'bulk.upload.select_language' | translate}}</mat-label>
    <mat-select [(value)]="params.data.translation">
        <mat-option value="N" (click)="selectTranslation('N')">
            <span style = "color: #426DA9"> {{ 'bulk.upload.none' | translate}} </span>
        </mat-option>
        <mat-option value="Y" (click)="selectTranslation('Y')">
            <span style = "color: #426DA9"> {{ 'bulk.upload.lang' | translate}} </span>
        </mat-option>
    </mat-select>
</mat-form-field>
