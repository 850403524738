<app-header (langChange)="changeLanguage($event)" [title]="title"></app-header>
<main class="p1">
    <section>
        <article>
            <div class="zanna_div row mt-60">
                <h1 class="M(0) Lh(1) eds-heading heading-xl-caps">
                    {{ 'thankYou.title' | translate }}
                </h1>
                <p class="p-subTitle">
                    {{ 'thankYou.subInfo' | translate }}
                    <span (click)="goToOrderHistory()" class="order-link">
                        {{ 'thankYou.viewOrderHistory' | translate }}
                    </span>
                </p>
            </div>
            <div class="zanna_div row">
                <div class="order-summary-title">
                    {{ 'thankYou.orderSummary' | translate }}
                </div>
                <div class="order-summary-table">
                    <div class="table-row">
                        <span class="table-key">{{ 'thankYou.orderNo' | translate }}</span>
                        <span>{{ orderResponse[0]?.orderId }}</span>
                    </div>
                    <div class="table-row">
                        <span class="table-key">{{ 'thankYou.searchDate' | translate }}</span>
                        <span>{{ orderResponse[0]?.orderDate | date: 'dd MMM y' }}</span>
                    </div>
                    <div class="table-row">
                        <span class="table-key">
                            {{ 'thankYou.totalPrice' | translate }} ({{ currency }})
                        </span>
                        <span>{{ totalPrice | currency:'':'' }}</span>
                    </div>
                    <div class="table-row" *ngIf="balancePoints && paymentMethod == 'Prepaid'">
                        <span class="table-key">
                            {{ 'thankYou.balancePoints' | translate }} ({{ currency }})
                        </span>
                        <span>{{ balancePoints | currency:'':'' }}</span>
                    </div>
                    <div class="table-row" style="border-bottom: none;">
                        <span class="table-key">{{ 'thankYou.paymentMethod' | translate }}</span>
                        <span>{{ paymentMethod }}</span>
                    </div>
                </div>
                <p>
                    <a class="a-link" (click)="goToHome()">
                        {{ 'thankYou.makeAnotherPurchase' | translate }}
                    </a>
                </p>
                <div class="data-grid-strip"></div>
                <ag-grid-angular *ngIf="!isMobile"
                    class="ag-theme-alpine"
                    id="thankYouGrid"
                    [rowData]="cartList"
                    [columnDefs]="columnDefs"
                    [tooltipShowDelay]="tooltipShowDelay"
                    [icons]="icons"
                    [domLayout]="domLayout"
                    (filterChanged)="onFilterChanged($event)"
                    (gridReady)="onGridReady($event)"
                    [gridOptions]="gridOptions"
                    [masterDetail]="true"
                    [detailRowHeight]="detailRowHeight"
                    [detailCellRenderer]="detailCellRenderer">
                </ag-grid-angular>
                <ag-grid-angular *ngIf="isMobile"
                class="ag-theme-alpine"
                id="thankYouGrid"
                [rowData]="cartList"
                [columnDefs]="columnDefs"
                [tooltipShowDelay]="tooltipShowDelay"
                [icons]="icons"
                [domLayout]="domLayout"
                (filterChanged)="onFilterChanged($event)"
                (gridReady)="onGridReady($event)"
                [gridOptions]="gridOptions"
                [masterDetail]="true"
                [detailRowHeight]="detailRowHeight"
                (firstDataRendered)="onFirstDataRendered($event)"
                [detailCellRenderer]="detailCellRenderer">
            </ag-grid-angular>
                <app-pagination *ngIf="cartList.length > 0" [totalRows]="gridOptions?.api?.getDisplayedRowCount()" [pageSize]="PaginationPageSize" [gridOptions]="gridOptions" [noOfPages]="gridOptions.api?.paginationGetTotalPages()"></app-pagination>
            </div>
        </article>
    </section>
</main>
<app-footer></app-footer>