<div class="dialog-content-wrapper">
    <div mat-dialog-content>
        <div class="close-dialog">
            <mat-icon class="close-btn" (click)="matDialogRef.close(false)">close</mat-icon>
        </div>
        <div class="cross-header">
            <mat-icon>search</mat-icon>
            <span class="title-text">
                {{ 'orderHistory.advancedSearch' | translate }}
            </span>
            <span class="clear">{{ 'advancedSearch.clear' | translate }}</span>
        </div>

        <div class="zanna_div row" style="margin-bottom: 36px;">
            <form [formGroup]="searchForm" (submit)="searchResults(searchForm.value)">
                <article class="zanna_table search-table" style="margin-bottom: 0px !important;">
                    <div class="zanna_div table-head gradient"></div>
                    <div class="zanna_div row">
                        <div class="d_6_12 col">
                            <label>Client Reference No.</label><br />
                            <input formControlName="clientRefNo" type="text" placeholder="Optional">
                        </div>
                    </div>
                    <div class="zanna_div row">
                        <div class="d_6_12 col">
                            <label>Order No.</label><br />
                            <input formControlName="orderNo" type="text" placeholder="Optional">
                        </div>

                        <div class="d_6_12 col">
                            <label>Item No.</label><br />
                            <input formControlName="itemNo" type="text" placeholder="Optional">
                        </div>
                    </div>
                    <div class="zanna_div row">
                        <div class="d_6_12 col">
                            <label>Search Date</label><br />
                            <input formControlName="startDate" type="text" placeholder="Start Date">
                        </div>

                        <div class="d_6_12 col">
                            <label style="color: #fff;">End Date</label><br />
                            <input formControlName="endDate" type="text" placeholder="End Date">
                        </div>
                    </div>

                    <div class="zanna_div row" style="margin-bottom: 10px;">
                        <div class="d_6_12 col">
                            <button (click)="matDialogRef.close(false)" class="button btn button__basic">
                                {{ 'searchResults.btn.cancel' | translate }}
                            </button>
                            <button type="submit" class="button btn ml-22 button__primary ml-22">
                                {{ 'searchResults.btn.select' | translate }}
                            </button>
                        </div>
                    </div>
                </article>
            </form>
        </div>
    </div>
</div>