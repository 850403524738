import { Component, HostListener } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'app-daily-suits',
  templateUrl: './cell-renderer.component.html',
  styleUrls: ['./cell-renderer.component.scss']
})
export class DNSADetailCellRenderer implements ICellRendererAngularComp {
  public cellData: any;
  isMobile: boolean = false;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if(event.target.innerWidth > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
	}
  constructor(){
      if(window.screen.width > 1000) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
  }
  agInit(params: any): void {
    this.cellData = params.data;
  }

  refresh(params: any): boolean {
    return false;
  }
}