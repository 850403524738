<div id="confirm">
    <h1 matDialogTitle class="confirm-title ml-18">
        {{ 'monitor.addManually.AddManually' | translate }}
    </h1>
    <!-- <div matDialogContent class="content-msg row pl-22 pr-22 pt-11">
        <div class="fs-2">{{ 'monitor.addManually.tip' | translate }} </div>
        <div class="fs-2">{{ 'monitor.addManually.tip2' | translate }} </div>
    </div> -->
    <div>
        <form [formGroup]="searchFormCountry">
                <div class="row pl-22 pr-22 pt-11 p-l-r-0">
                    <div class="d_12_12">
                        <label class="labels-left">{{ 'monitor.country' | translate }}*</label><br />
                        <ng-select formControlName="countryCode" >
                        </ng-select>
                        <!-- <input type="text" value="Singapore" disabled> -->
                    </div>
                </div>
                
                <div class="row pl-22 pr-22 pt-11 p-l-r-0">
                    <div class="d_12_12">
                        <label class="labels-left">{{ 'monitor.entity_name' | translate }}</label><br />
                        <input formControlName="entity_name" type="text" [placeholder]="'placeholder.enter_entity_name' | translate">
                        <div class="error" *ngIf="searchFormCountry.get('entity_name')?.hasError('maxlength')">
                            {{ 'formError.fix100_charactersRequired' | translate }}
                        </div>
                    </div>
                </div>

                <div fxLayout="col" class="pl-22 pr-22 pt-11" *ngIf="!isMobile">
                    <div fxFlex="48">
                        <label class="labels-left">{{ 'monitor.entityNo' | translate }}*</label><br />
                        <input formControlName="entity_no" type="text" [placeholder]="'placeholder.entity_number' | translate"> <!-- (blur)="checkValidation()"-->
                        <div class="error"
                            *ngIf="searchFormCountry.get('entity_no')?.hasError('maxlength')">
                            {{ 'formError.fix15_charactersRequired' | translate }}
                        </div>
                    </div>
                    <div fxFlex="4"></div>
                    <div fxFlex="48">
                        <label class="labels-left">{{ 'monitor.file' | translate }}</label><br />
                        <input formControlName="file" type="text" [placeholder]="'placeholder.file_ref' | translate"> <!-- (blur)="checkValidation()"-->
                        <div class="error"
                            *ngIf="searchFormCountry.get('file')?.hasError('maxlength')">
                            {{ 'formError.fix40_charactersRequired' | translate }}
                        </div>
                    </div>
                </div>
                <div *ngIf="isMobile">
                    <div class="pt-11">
                        <label class="labels-left">{{ 'monitor.entityNo' | translate }}*</label><br />
                        <input formControlName="entity_no" type="text" [placeholder]="'placeholder.entity_number' | translate"> <!-- (blur)="checkValidation()"-->
                        <div class="error"
                            *ngIf="searchFormCountry.get('entity_no')?.hasError('maxlength')">
                            {{ 'formError.fix15_charactersRequired' | translate }}
                        </div>
                    </div>
                    <div class="pt-11">
                        <label class="labels-left">{{ 'monitor.file' | translate }}</label><br />
                        <input formControlName="file" type="text" [placeholder]="'placeholder.file_ref' | translate"> <!-- (blur)="checkValidation()"-->
                        <div class="error"
                            *ngIf="searchFormCountry.get('file')?.hasError('maxlength')">
                            {{ 'formError.fix40_charactersRequired' | translate }}
                        </div>
                    </div>
                </div>

                <div class="zanna_div row browse">
                    <div class="pr-22 pl-22 pt-22 pb-22 p-l-r-0">
                        <button (click)="close()" class="button button__primary labels-left" >
                            {{ 'searchResults.btn.cancel' | translate }}
                        </button>
                        <button (click)="validateId()" [disabled] = "searchFormCountry.invalid"
                            class="button button__primary labels-left" 
                            [ngClass]="{'button__disabled': searchFormCountry.invalid}" style="margin-left: 20px;">
                            {{ 'monitor.addToMonitoring' | translate }}
                        </button>
                    </div>
                </div>
        </form>
    </div> 
</div>