import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import * as moment from 'moment';

@Component({
  selector: 'app-global-new-defendant-renderer',
  templateUrl: './global-cell-renderer.component.html',
  styleUrls: ['./global-cell-renderer.component.scss'],
})
export class GlobalNewDefendantDetailCellRenderer implements OnInit, ICellRendererAngularComp {
  @Input() cellData: any;
  subSectionData: any = [];
  isMobile: boolean = false;
  Data: any = [];
  @HostListener('window:resize', ['$event'])

  onResize(event: any) {
    if (event.target.innerWidth > 1000) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }
  constructor() {
    if (window.screen.width > 1000) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }

  ngOnInit(): void {
    this.Data = this.cellData;
  }

  checkData(data: any) {
    if (data && typeof data === 'string') {
      const slicedDate = data.slice(0, 10);
      return moment(slicedDate, 'YYYY-MM-DD', true).isValid()
        ? moment(slicedDate).format('DD MMM YYYY')
        : data;
    }
    return "-";
  }

  getArray(data: any): string {
    if (typeof data === 'string') {
      const isPhone = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g.test(data);
      if (isPhone) {
        const cleanedPhone = data.replace(/[^+\d]+/g, '');
        return cleanedPhone ? `${cleanedPhone.slice(0, 3)} ${cleanedPhone.slice(3)}` : data;
      } else {
        return data;
      }
    }
    return '';
  }

  agInit(params: any): void {
  }

  refresh(params: any): boolean {
    return false;
  }
}