<div class="dialog-content-wrapper">
    <div mat-dialog-content>
        
        <div class="cross-header">
            <span class="title-text">
                {{ 'searchResults' | translate }}
            </span>
            <mat-icon class="close-btn" (click)="matDialogRef.close()">close</mat-icon>
        </div>

        <div>
            <div class="contain-records">
                <span class="comp-count">{{ searchResultList.length }}</span>
                <span class="comp-format fs-2">
                    {{ 'monitor.companiesFound' | translate }}
                </span>
                <span class="unable-find" *ngIf="searchResultList.length > 0 && !isMobile">
                    {{ 'searchResults.unableFind' | translate }}
                </span>
                <span class="refine-search" (click)="matDialogRef.close('true')" *ngIf="!isMobile && searchResultList.length > 0">
                    {{ 'searchResults.addManual' | translate }}
                </span>
                <div class="unable-find fs-2" *ngIf="isMobile">
                    {{ 'searchResults.unableFind' | translate }}
                    <span class="refine-search fs-2" (click)="matDialogRef.close('true')">
                        {{ 'searchResults.addManual' | translate }}
                    </span>
                </div>
            </div>
            
            <div class="data-grid-strip">
                <span *ngIf="isRowSelected">1 {{ 'grid.rowsSelected' | translate }}</span>
            </div>
            
            <ag-grid-angular 
                class="ag-theme-alpine"
                id="searchResultGrid"
                [rowData]="searchResultList"
                [columnDefs]="searchColumnDefs"
                (gridReady)="onGridReady($event)"
                [gridOptions]="gridOptions"
                [icons]="icons"
                [domLayout]="domLayout"
                (filterChanged)="onFilterChanged($event)"
                (selectionChanged)="onSelectionChanged($event)"
                (rowSelected)="onRowSearchSelected($event)"
                [masterDetail]="true"
                [detailRowHeight]="detailRowHeight"
                [detailCellRenderer]="detailCellRenderer">
            </ag-grid-angular>
            <div class="note" *ngIf="searchResultList.length > 0">
                <div style="color: #929292;"> Note: F.K.A = Formerly known as</div>
                <!-- <div class="aligned" style="color: #929292;"> N.K.A = Now known as</div> -->
            </div>
            <span class="error" *ngIf="errorMsgFlag">{{ 'searchResults.errMsg' | translate }}</span>            
            <div class="col btn-grp" style="margin-top: 30px">
                <button (click)="matDialogRef.close()" class="button button__basic">
                    {{ 'searchResults.btn.cancel' | translate }}
                </button>
                <button [disabled]="disabledFlag" (click)="resultSelect()" class="button button__primary ml-22">
                    {{ 'bulk.upload.confirm' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>