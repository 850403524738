import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DocumentViewComponent } from '../document-view/document-view.component';
import { HomeService } from '../home/home.service';
import { LoaderService } from '../services/loader.service';
declare const $: any;

@Component({
	selector: 'app-view-comparison',
	templateUrl: './view-comparison.html',
	styleUrls: ['./view-comparison.scss'],
	encapsulation: ViewEncapsulation.None
})

export class ViewComparisonComponent implements OnInit {

	reportVariationList: any = [];
	currency: any = '';
	viewCompareReports: boolean;

	constructor(public matDialogRef: MatDialogRef<ViewComparisonComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any, private homeService: HomeService,
		private loaderService: LoaderService, private dialog: MatDialog) {
		this.viewCompareReports = false;
		if (data.value.length > 0 && data.categoryId != 1) {
			this.reportVariationList = data.value.filter((i: { recommend: boolean; }) => i.recommend === true);
		} else {
			this.reportVariationList = data.value;
		}
		this.viewCompareReports = [4, 5, 6, 7].includes(data.categoryId);
		this.reportVariationList.forEach((element: any) => {
			let comparisonData: any;
			if (localStorage.getItem('lang') == 'jp') {
				comparisonData = element.comparisonDataJpn;
			} else {
				comparisonData = element.comparisonData;
			}
			if (typeof comparisonData === 'string' && comparisonData !== null) {
				comparisonData = JSON.parse(comparisonData);
			}
			let obj = new Map();
			for (const [key, value] of Object.entries((comparisonData).listing)) {
				obj.set(key, value);
			}
			element['compareData'] = obj;

			let map = new Map();
			for (const [key, value] of Object.entries((comparisonData))) {
				map.set(key, value);
			}
			element['headerData'] = map;
		});
		this.currency = data.currency;
	}

	ngOnInit(): void {
		let self = this;
		setTimeout(() => {
			self.setHeight();
		}, 1000);

		$(window).resize(function () {
			self.setHeight();
		});

		setTimeout(() => {
			$('#comparison')[0].scrollIntoView();
		}, 300);
	}

	setHeight() {
		let maxHeight = 0;
		let subHeight = 0;
		for (let i = 0; i < this.reportVariationList.length; i++) {
			let ele = document.getElementById('div-' + i);
			if (ele) {
				if (ele.offsetHeight > maxHeight) {
					maxHeight = ele.offsetHeight;
				}
			}
			if (i == this.reportVariationList.length - 1) {
				for (let j = 0; j < this.reportVariationList.length; j++) {
					let elem = document.getElementById('div-' + j);
					if (elem) {
						elem.style.minHeight = maxHeight + 'px';
					}
				}
			}

			let subele = document.getElementById('subInfo-' + i);
			if (subele) {
				if (subele.offsetHeight > subHeight) {
					subHeight = subele.offsetHeight;
				}
			}
			if (i == this.reportVariationList.length - 1) {
				for (let j = 0; j < this.reportVariationList.length; j++) {
					let subelem = document.getElementById('subInfo-' + j);
					if (subelem) {
						subelem.style.minHeight = subHeight + 'px';
					}
				}
			}
		}
	}

	getKeys(map: any) {
		return Array.from(map.keys());
	}

	getValues(map: any, key: any) {
		return map.get(key);
	}

	convertCamalCaseToNormal(text: any) {
		return text;
		// return text.replace(/([A-Z])/g, ' $1').replace(/^./, function (str: any) {
		// 	return str.toUpperCase();
		// })
	}

	selectReportVariation(data: any) {
		this.matDialogRef.close(data);
	}

	sampleReport(offeringId: any) {
		this.loaderService.isLoading.next(true);
		this.homeService.sampleReport(offeringId).subscribe(res => {
			this.dialog.open(DocumentViewComponent, {
				width: '80vw',
				data: {
					response: res,
					format: 'PDF'
				}
			});
			this.loaderService.isLoading.next(false);
		}, err => {
			this.loaderService.isLoading.next(false);
		});
	}
}