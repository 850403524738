<div *ngIf="params.template=='approvePopup'">
	<div class="action-list">
		<ul>
			<li class="update-delete-popover" (click)="onDelete($event)">
				<a class="alignment">{{ 'gridActions.delete' | translate }}</a>
			</li>
			<li class="update-delete-popover" (click)="requestToJp()"
				*ngIf="checkValid(params.data) && (!params.data.parentCartSeqNo && !showTranslation)">
				<a class="alignment">{{ 'gridActions.requestJPTranslation' | translate }}</a>
			</li>
	  </ul>
  	</div>
</div>


<div *ngIf="params.template=='thankYouPopup'">
	<div class="action-list">
		<ul>
			<li class="popover-list" (click)="requestToJp()"
				*ngIf="checkValid(params.data) && (!params.data.parentCartSeqNo && !showTranslation)">
				<a>{{ 'gridActions.requestJPTranslation' | translate }}</a>
			</li>
		</ul>
	</div>	
</div>

<div *ngIf="params.template=='orderHistoryPopup'">
	<div class="action-list">
		<ul>
			<li class="popover-list" *ngIf="checkValid(params.data) && params.data.itemStatus != 'Cancelled'"
				(click)="requestToJp()">
				<a>{{ 'gridActions.requestJPTranslation' | translate }}</a>
			</li>
			<!-- <li [ngClass]="(params.data.itemStatus == 'Processing' || params.data.itemStatus == 'Archived') ? 'popover-list disable-option' : 'popover-list'" 
				(click)="downloadReport('html')">
				<a>{{ 'gridActions.collectHTML' | translate }}</a>
			</li> -->
			<span *ngIf="validJSONCopy(params.data)">
				<li [ngClass]="(params.data.itemStatus == 'Processing' || params.data.itemStatus == 'Cancelled' || params.data.archived ||  params.data.categoryId == 4) ? 'popover-list disable-option' : 'popover-list'"
					(click)="downloadReport('json')">
					<a>{{ 'gridActions.collectJSON' | translate }}</a>
				</li>
			</span>
			<li [ngClass]="(params.data.itemStatus == 'Processing' || params.data.itemStatus == 'Cancelled' || params.data.archived) ? 'popover-list disable-option' : 'popover-list'"
				(click)="downloadReportForMobile('downloadReportForMobile')">
				<a>{{ 'gridActions.collectPDF' | translate }}</a>
			</li>
			<li class="popover-list" (click)="downloadReport('billing')">
				<a>{{ 'gridActions.billingStatement' | translate }}</a>
			</li>
		</ul>
	</div>	
</div>

<div *ngIf="params.template=='deleteUpdatePopup'">
	<div class="action-list">
		<ul>
			<li class="update-delete-popover" (click)="onUpdate($event)">
				<a class="alignment">{{ 'gridActions.updateEntity' | translate }}</a>
			</li>
			<li class="update-delete-popover" (click)="onDelete($event)">
				<a class="alignment">{{ 'gridActions.deleteEntity' | translate }}</a>
			</li>
		</ul>
	</div>
</div>

<div class="close-icon">
	<i class="fa fa-times" (click)="close()"></i>
</div>


