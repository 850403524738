import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
import { MatDialog } from '@angular/material/dialog';
import { LoaderService } from '../services/loader.service';

@Component({
	selector: 'app-session-expired',
	templateUrl: './session-expired.component.html',
	styleUrls: ['./session-expired.component.scss']
})

export class SessionExpiredComponent {
	pageName: any;
	header: any = '';
	subHeader: any = '';
	questnetURL: any = '';
	ibr360URL: any = '';
	title: any = "Quick Basic International Reports";
	constructor(private router: Router, private appService: AppService, private dialog: MatDialog, private loaderService: LoaderService) {

		this.pageName = this.router.getCurrentNavigation()?.extras.state?.page;
		this.questnetURL = this.appService.questnetURL;
		this.ibr360URL = this.appService.ibr360URL;
		this.dialog.closeAll();
		localStorage.removeItem('upgrade_plan_show');
		if (this.pageName) {
			if (this.pageName == 'expired') {
				this.header = 'Your session has expired!';
				this.subHeader = 'Your session has expired due to inactivity. Simply login again to continue.';
			} else if (this.pageName == 'logout') {
				this.header = 'You have successfully logged out!';
				this.subHeader = 'Thank you for using International Business Report - Experian. If you would like to continue, please login through either of the below platforms.';
			}
		}
		this.loaderService.isLoading.next(false);
	}
}
