<div *ngIf="!isMobile">
    <div *ngFor="let item of notifications">   
        <div class="toast sticky" *ngIf="item.type== 3">
            <div class="warning-color">
                <mat-icon class="icon-warning">
                    warning
                </mat-icon>
            </div>
            <div class="text"> <strong class="icon-warning">{{ 'monitor.action_warning' | translate }}:</strong>{{ 'monitor.warning_message' | translate }}&nbsp;<a href="mailto:cs@experian.com" class="link">{{ 'monitor.upgrade_plan' | translate }}</a></div>
            <mat-icon (click)="removeNotification(item)" class="close">close</mat-icon>
        </div>
    </div>
</div>

<div *ngIf="isMobile"> 
    <div *ngFor="let item of notifications">
        <div class="mobile-toast sticky" *ngIf="item.type== 3">
            <div class="warning-color">
                <mat-icon class="icon-warning">
                    warning
                </mat-icon>
            </div>
            <div class="text"> <strong class="icon-warning">{{ 'monitor.action_warning' | translate }}:</strong>
                <div class="warning_msg">{{ 'monitor.warning_message' | translate }} &nbsp;<a href="mailto:cs@experian.com" class="link-mobile">{{ 'monitor.upgrade_plan' | translate }}</a></div>
            </div>
            <mat-icon (click)="removeNotification(item)" class="close-mobile">close</mat-icon>
        </div>
    </div>      
</div>