<div class="dialog-content-wrapper">
    <div mat-dialog-content *ngIf="report.status == 'complete'">
        <div class="close-dialog">
            <mat-icon class="close-btn" (click)="dialogRef.close(false)">close</mat-icon>
        </div>
        <div class="cross-header">
            <span class="title-text">
                {{ 'bulk.upload.complete' | translate }}
            </span>
        </div>  
        <div>
            <label>{{ 'bulk.upload.file_processed' | translate }}</label>
        </div>
        <div class="pt-7">
            <div style="overflow-wrap: break-word;">{{ report.fileName }}</div>
        </div>       
        <div>
            <div>{{ bulkUploadStatus.TotalCount }} records</div>
        </div>  

        <div class="zanna_div row pt-30">
            <div class="d_6_12 col">
                <button (click)="dialogRef.close(false)" class="button btn-size button__primary ml-10">
                    {{ 'bulk.upload.review_report' | translate }}
                </button>
            </div>
        </div>
    </div>
    <div mat-dialog-content *ngIf="report.status == 'monitor-complete'">
        <div class="close-dialog">
            <mat-icon class="close-btn" (click)="dialogRef.close(false)">close</mat-icon>
        </div>
        <div class="cross-header">
            <span class="title-text">
                {{ 'bulk.upload.monitor_complete' | translate }}
            </span>
        </div>  
        <div>
            <label>{{ 'bulk.upload.file_processed' | translate }}</label>
        </div>
        <div class="pt-7">
            <div style="overflow-wrap: break-word;">{{ report.fileName }}</div>
        </div>       
        <div>
            <div>{{ bulkUploadStatus.TotalCount }} records</div>
        </div>  

        <div class="zanna_div row pt-30">
            <div class="d_6_12 col">
                <button (click)="dialogRef.close(false)" class="button btn-size button__primary ml-10">
                    {{ 'bulk.upload.review_entities' | translate }}
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="report.status == 'cancel'">
        <div class="cross-header">
            <span class="title-text">
                {{ 'bulk.upload.cancel_warning' | translate }}
            </span>
        </div>  
        <div class="pt-7">
            <div class="grey-color">
                {{ 'bulk.upload.cancel_sub_text' | translate }} 
            </div>
            <div class="loading-screen pt-20">
                <div>
                    <span class="loading-screen-bar">
                        <span [ngStyle]="{'width': loader + '%'}"></span>
                    </span>
                </div>
            </div>
            <div class="pt-7">
                <div><label>{{ 'bulk.upload.finding' | translate }}  {{ bulkUploadStatus?.EntityName }} ({{ bulkUploadStatus?.CurrentCount }} of {{ bulkUploadStatus?.TotalCount }} )</label></div>
                <div>
                    <label>
                        {{ 'bulk.upload.time_left' | translate }}: {{ minutes}} 
                        <span *ngIf=" 1 >= minutes">{{ 'bulk.upload.minute' | translate }}</span>
                        <span *ngIf=" minutes > 1 ">{{ 'bulk.upload.minutes' | translate }}</span>
                    </label>
                </div>
            </div>
        </div>
        <div class="zanna_div row pt-30">
            <div class="d_12_12 col">
                <button (click)="cancelProcessing()" class="button button__basic ml-10">
                    {{ 'bulk.upload.cancel_processing' | translate }}
                </button> 
                <button (click)="runInBackground()" class="button button__primary ml-22 ml-22">
                    {{ 'bulk.upload.continue_processing' | translate }}
                </button> <!-- type="submit"-->
            </div>
        </div>
    </div>
    <div *ngIf="report.status == 'monitor-cancel'">
        <div class="cross-header">
            <span class="title-text">
                {{ 'bulk.upload.cancel_warning' | translate }}
            </span>
        </div>  
        <div class="pt-7">
            <div class="grey-color">
                {{ 'bulk.upload.cancel_sub_text' | translate }} 
            </div>
            <div class="loading-screen pt-20">
                <div>
                    <span class="loading-screen-bar">
                        <span [ngStyle]="{'width': loader + '%'}"></span>
                    </span>
                </div>
            </div>
            <div class="pt-7">
                <div><label>{{ 'bulk.upload.finding' | translate }}  {{ bulkUploadStatus?.EntityName }} ({{ bulkUploadStatus?.CurrentCount }} of {{ bulkUploadStatus?.TotalCount }} )</label></div>
                <div>
                    <label>
                        {{ 'bulk.upload.time_left' | translate }}: {{ minutes}} 
                        <span *ngIf=" 1 >= minutes">{{ 'bulk.upload.minute' | translate }}</span>
                        <span *ngIf=" minutes > 1 ">{{ 'bulk.upload.minutes' | translate }}</span>
                    </label>
                </div>
            </div>
        </div>
        <div class="zanna_div row pt-30">
            <div class="d_12_12 col">
                <button (click)="monitorcancelProcessing()" class="button button__basic ml-10">
                    {{ 'bulk.upload.cancel_processing' | translate }}
                </button> 
                <button (click)="runInBackground()" class="button button__primary ml-22 ml-22">
                    {{ 'bulk.upload.continue_processing' | translate }}
                </button> <!-- type="submit"-->
            </div>
        </div>
    </div>
</div>