import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AppService } from '../app.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BulkUploadGridService {
 

	headers = new HttpHeaders();
	sessionId: any = "";

	constructor(private http: HttpClient, private appService: AppService) {

		this.sessionId = localStorage.getItem('sessionID');
		this.headers = this.headers.set('Accept', 'application/json');
		this.headers = this.headers.set('Access-Control-Allow-Origin', '*');
		this.headers = this.headers.set('Access-Control-Allow-Credentials', 'true');
		this.headers = this.headers.set('sessionid', this.sessionId);
		this.headers = this.headers.set('X-FRAME-OPTIONS', 'deny');
		this.headers = this.headers.set('Content-Security-Policy', 'frame-ancestors "none";');

	}

	setSid(sessionId: any) {
		localStorage.setItem('sessionID', sessionId);
		this.sessionId = localStorage.getItem('sessionID');
		this.headers = this.headers.set('sessionid', sessionId);
	}
	
	getOrderHistory() {
		this.sessionId = localStorage.getItem('sessionID');
		this.headers = this.headers.set('sessionid', this.sessionId);
		//const params = new HttpParams().set('itemStatus', '')
		return this.http.get<any>(this.appService.baseURL + '/api/v1/Order/OrderHistory',
			{ headers: this.headers }).pipe(map(res => res));
	}

	upload(fileToUpload: File, categoryId: any) {
		const formData = new FormData();
    	formData.append('inputFile', fileToUpload);
		formData.append('vendorCategoryId', categoryId);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/BulkOrder/BulkFileUpload', formData, { headers: this.headers }).pipe(map(res => res));
	}
	
	confirmBulkOrder(bulkOrderNumber: any) {
		const formData = new FormData();
    	formData.append('bulkOrderNumber', bulkOrderNumber);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/BulkOrder/ProcessBulkOrder', formData, { headers: this.headers }).pipe(map(res => res));
	}

	getBulkOrderProductCategory() {
		var data: any;
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Product/GetBulkOrderProductCategory', data, { headers: this.headers }).pipe(map(res => res));
	}

	addToCart(data: any) {
		return this.http.post<any>(this.appService.baseURL + '/api/v1/BulkOrder/AddToCart', data, { headers: this.headers }).pipe(map(res => res));
	}

	addToCartNoMatch(data: any) {
		return this.http.post<any>(this.appService.baseURL + '/api/v1/BulkOrder/UpdateProductAvailabilityForOfflineInBulk', data, { headers: this.headers }).pipe(map(res => res));
	}

	bulkUploadMatchesList(bulkOrderNumber: any, searchId: any) {
		var formData: any = new FormData();
		formData.append("bulkOrderNumber", bulkOrderNumber);
		formData.append("searchId", searchId);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/BulkOrder/GetBulkUploadSearchDetails',  formData, { headers: this.headers }).pipe(map(res => res));
	}

	updateTranslation(bulkOrderItemId: any, status: any) {
		var formData: any = new FormData();
		formData.append("bulkOrderItemId", bulkOrderItemId);
		formData.append("status", status);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/BulkOrder/SetTranslationForBulkOrderItem',  formData, { headers: this.headers }).pipe(map(res => res));
	}

	getAvailabilityDetails(bulkOrderNumber: any, searchId: any, src: any) {
		var formData: any = new FormData();
		formData.append("bulkOrderNumber", bulkOrderNumber);
		formData.append("searchId", searchId);
		formData.append("scr", src);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/BulkOrder/GetAvailabilityDetails',  formData, { headers: this.headers }).pipe(map(res => res));
	}

	saveEntityDetails(details: any) {
		return this.http.post<any>(this.appService.baseURL + '/api/v1/BulkOrder/AddSearchEntityDetails',  details , { headers: this.headers }).pipe(map(res => res));
	}

	sampleReport(offeringId: any): Observable<Blob> {
		const params = new HttpParams().set('productOfferingId', offeringId)
		const httpOptions = {
			responseType: 'blob' as 'json',
			headers: this.headers,
			params
		};
		return this.http.get<any>(this.appService.baseURL + '/api/v1/Order/GetSampleReport',
			httpOptions).pipe(map(res => res));
	}

	bulkUploadList(count: any, bulkOrderNumber: any, filterStatus: any) {
		const formData = new FormData();
    	formData.append('bulkOrderNumber', bulkOrderNumber);
		formData.append('recordCount', count);
		formData.append("filterStatus", filterStatus);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/BulkOrder/BulkUploadedRecordList', formData, { headers: this.headers }).pipe(map(res => res));
	}

	UpdateAvailabilityDetails(details: any) {
		return this.http.post<any>(this.appService.baseURL + '/api/v1/BulkOrder/UpdateAvailabilityDetails', details, { headers: this.headers }).pipe(map(res => res));
	}

	deleteAndUndoDuplicate(bulkOrderNumber: any, entityName: any) {
		const formData = new FormData();
    	formData.append('bulkOrderNumber', bulkOrderNumber);
		formData.append('entityName', entityName);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/BulkOrder/DeleteAndUndoDuplicateRecord', formData, { headers: this.headers }).pipe(map(res => res));
	}

	removeRecords(bulkOrderItemId: any) {
		return this.http.post<any>(this.appService.baseURL + '/api/v1/BulkOrder/RemoveBulkReportOrderItem', bulkOrderItemId , { headers: this.headers }).pipe(map(res => res));
	}

	bulkUploadProcessingStatus(bulkOrderNumber: any) {
		const formData = new FormData();
    	formData.append('bulkOrderNumber', bulkOrderNumber);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/BulkOrder/GetBulkUploadCurrentStatus', formData, { headers: this.headers }).pipe(map(res => res));
	}

	cancelBulkUpload(bulkOrderNumber: any) {
		const formData = new FormData();
    	formData.append('bulkOrderNumber', bulkOrderNumber);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/BulkOrder/CancelBulkUpload', formData, { headers: this.headers }).pipe(map(res => res));
	}

	cancelProcessing(bulkOrderNumber: any) {
		const formData = new FormData();
    	formData.append('bulkOrderNumber', bulkOrderNumber);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/BulkOrder/CancelBulkOrder', formData, { headers: this.headers }).pipe(map(res => res));
	}

	addChildToCart(data: any, seqId : any) {
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Order/items/'+seqId+'/children', data,
			{ headers: this.headers }).pipe(map(res => res));
	}

	generateReport(formData: any): Observable<Blob> {
		const httpOptions = {
			responseType: 'blob' as 'json',
			headers: this.headers
		};
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Order/GenerateReport', formData,
			httpOptions).pipe(map(res => res));
	}

}