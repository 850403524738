<app-header (langChange)="changeLanguageGrid($event)" [title]="title"></app-header>
<app-toast class="position"></app-toast>
<main class="p1">
    <section>
        <article>
            <div class="zanna_div row mt-60">
                <h1 class="M(0) Lh(1) eds-heading heading-xl-caps">
                    <span class="icon-back" routerLink="/monitor" [queryParams]="{recordSlide: 'true'}"><i class="fa fa-angle-left"></i></span>
                    <span>{{ 'monitor.previouslyDeleted.title' | translate }}</span>
                </h1>
                <p class="p-subTitle">
                    {{ 'monitor.subtitle' | translate }}
                </p> 
            </div> 
            <div *ngIf="showTip && !isMobile">   
                <div class="toast sticky">
                    <div class="success-color">
                        <mat-icon class="icon-success">
                          info_outline
                        </mat-icon>
                    </div>
                    <div class="text"> <strong class="tip">{{ 'monitor.action_tip' | translate }}:</strong> <div style="margin: 0px 10px 0px 6px;">Use the search boxes from each column to search that specific field. To sort the list use the arrows found at the header of the table to sort the entire data table. You can permanently delete multiple entities by bulk selecting multiple entites.</div></div><mat-icon class="close" (click)="hideTip()">close</mat-icon>
                </div>
            </div> 
            <div class="zanna_div row">
                <div class="data-grid-strip">
                    <div *ngIf="!isMobile">
                        <div *ngIf="gridOptions?.api?.getSelectedNodes().length > 1" class="first">
                            {{ gridOptions?.api?.getSelectedNodes().length }} {{ 'grid.rowsSelected' | translate }}
                        </div>
                        <div *ngIf="gridOptions?.api?.getSelectedNodes().length > 1" class="middle" (click)="deselectAll()">
                            {{ 'monitor.deselectAll' | translate }}
                        </div>
                        <div *ngIf="gridOptions?.api?.getSelectedNodes().length > 1" class="middle-button" (click)="RemoveInBulk()">
                            {{ 'monitor.previouslyDeleted.remove_permanently' | translate }}
                        </div>
                    </div>
                </div>
                <div class="btn-data-grid-strip border-b-n" *ngIf="isMobile && records.length>0">
                    <div (click)=ExpandAll(allExpand) class="arrow-container">
                        <mat-icon class="down-arrow-icon icon-color" *ngIf="!allExpand">keyboard_arrow_down</mat-icon>
                        <mat-icon class="up-arrow-icon icon-color" *ngIf="allExpand">keyboard_arrow_up</mat-icon> <span>{{expandCollapseButtonValue}}</span>
                    </div>
                </div>
                <ag-grid-angular 
                    class="ag-theme-alpine"
                    [id]="gridId"
                    [rowData]="records"
                    [columnDefs]="columnDefs"
                    [animateRows]="true"
                    [masterDetail]="true"
                    [detailRowHeight]="detailRowHeight"
                    [loadingOverlayComponent]="loadingOverlayComponent"
                    [loadingOverlayComponentParams]="loadingOverlayComponentParams"
                    [detailCellRenderer]="detailCellRenderer"
                    (gridReady)="onGridReady($event)"
                    [gridOptions]="gridOptions"
                    [tooltipShowDelay]="tooltipShowDelay"
					(filterChanged)="onFilterChanged($event)"
                    [domLayout]="domLayout"
                    [icons]="icons">
                </ag-grid-angular>
                <app-monitoring-pagination *ngIf="records.length > 0" 
                    [pageSize]="PaginationPageSize" [gridOptions]="gridOptions" (loadNext)="loadNextRecords()" (loadPrev)="loadPreviousRecords()"
                    [noOfPages]="gridOptions.api?.paginationGetTotalPages()" [totalRows]="totalRecords" [showing]="showing" [avalilableResults]="avalilableResults">
                </app-monitoring-pagination>
            </div>
        </article>
    </section>
</main>
<app-footer></app-footer>