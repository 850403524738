import { Component } from '@angular/core';
import { ILoadingOverlayParams } from '@ag-grid-community/core';
import { ILoadingOverlayAngularComp } from '@ag-grid-community/angular';
declare const $: any;

@Component({
	selector: 'app-grid-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss']
})

export class GridLoaderComponent implements ILoadingOverlayAngularComp {

	params: any;

	agInit(params: ILoadingOverlayParams): void {
		this.params = params;
		var element = $('.search-result');
		element.addClass('loading');
		setTimeout(() => {  
			element.find('.section-loading-screen-bar span').css('width', '20%');
		}, 200);
		setTimeout(() => {  
			element.find('.section-loading-screen-bar span').css('width', '40%');
		}, 4000);
		setTimeout(() => {  
			element.find('.section-loading-screen-bar span').css('width', '60%');
		}, 8000);
		setTimeout(() => {  
			element.find('.section-loading-screen-bar span').css('width', '80%');
		}, 16000);
		
	}
}
