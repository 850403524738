import { HostListener, Injectable } from '@angular/core';  
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router, NavigationStart } from '@angular/router';  
import { Observable, Subject } from 'rxjs';  
import { ActionDialogComponent } from '../action-dialog/action-dialog.component';
  
  
import { Notification, NotificationType } from './toast.model';  
  
@Injectable({  
    providedIn: 'root'  
}) 
export class NotificationService {  
    isMobile: boolean = false;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if(event.target.innerWidth > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
	}
    public subject = new Subject<Notification>();  
    public keepAfterRouteChange = true;  
    actionDialogRef: MatDialogRef<ActionDialogComponent>;
    
    constructor(public router: Router, public dialog: MatDialog) {  
        if(window.screen.width > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true  
        router.events.subscribe(event => {  
            if (event instanceof NavigationStart) {  
                if (this.keepAfterRouteChange) {  
                    this.keepAfterRouteChange = false;  
                } else {  
                    // clear alert messages  
                    // this.clear();  
                }  
            }  
        });  
    }  
  
    // getAlert(): Observable<any> {  
    //     return this.subject.asObservable();  
    // }  
  
    success(message: string, keepAfterRouteChange = false) {  
        this.showNotification(NotificationType.Success, message, keepAfterRouteChange);  
    }  
  
    error(message: string, keepAfterRouteChange = false) {  
        this.showNotification(NotificationType.Error, message, keepAfterRouteChange);  
    }  
  
    info(message: string, keepAfterRouteChange = false) {  
        this.showNotification(NotificationType.Info, message, keepAfterRouteChange);  
    }  
  
    warn(message: string, keepAfterRouteChange = false) {  
        this.showNotification(NotificationType.Warning, message, keepAfterRouteChange);  
    } 
    
    restore(message: string, keepAfterRouteChange = false) {  
        this.showNotification(NotificationType.Restore, message, keepAfterRouteChange);  
    } 
  
    delete(message: string, keepAfterRouteChange = false) { 
        this.showNotification(NotificationType.Delete, message, keepAfterRouteChange);  
    }

    deleteMultiple(message: string, keepAfterRouteChange = false) { 
        this.showNotification(NotificationType.DeleteMultiple, message, keepAfterRouteChange);  
    }

    edit(message: string, keepAfterRouteChange = false) {  
        this.showNotification(NotificationType.Edit, message, keepAfterRouteChange);  
    }

    showNotification(type: NotificationType, message: string, keepAfterRouteChange = false) {  
        this.keepAfterRouteChange = keepAfterRouteChange; 
        if(type != 3) {
            this.subject.next(<Notification>{ type: type, message: message });  
            this.actionDialogRef = this.dialog.open(ActionDialogComponent, {
                width: this.isMobile? 'auto':'30vw',
                data: { type: type, message: message }
            });
            this.actionDialogRef.afterClosed().subscribe(res => {
            });
        } else {
            this.subject.next(<Notification>{ type: type, message: message }); 
        }
    }  
  
    // clear() {  
    //     this.subject.next();  
    // }  
}  