<div class="overflow-y">
   <div fxLayout="col" class="f-s-adjustment">
       <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
           <b>{{'monitor.entityNo' | translate}}</b>
       </div>
       <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
          {{cellData.EntityId}}
       </div>
   </div>    
   <div fxLayout="col" class="f-s-adjustment">
       <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
           <b>{{'monitor.clientFileRef' | translate}}</b>
       </div>
       <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;" >
          {{cellData.FileReference}}
       </div>
   </div>    
   <div fxLayout="col" class="f-s-adjustment">
       <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
           <b>{{'monitor.bwsAlert' | translate}}</b>
       </div>
       <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
          {{cellData.Change}}
       </div>
   </div>  
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
          <b>{{'monitor.dateOfAlert' | translate}}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
         {{cellData.AlertDate | date: 'dd MMM y'}}
      </div>
  </div>   
</div>