import { Injectable } from '@angular/core';
import { HttpResponse, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { LoaderService } from '../services/loader.service';
import { map } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

	requests: HttpRequest<any>[] = [];

	constructor(private loaderService: LoaderService, private router: Router) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			map((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
				}
				return event;
			}),
			catchError((error: HttpErrorResponse) : Observable<any>  => {
				if (error?.status == 401) {
					this.loaderService.isLoading.next(false);
					this.router.navigate(['/session-expired'],{ state: { page: 'expired'}});
					return of();
				}
				return throwError(error);
			})
		);
	}
}
