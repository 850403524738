import { Component } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'custom-tooltip',
    templateUrl: './custom-tooltip.component.html',
    styleUrls: ['./custom-tooltip.component.scss']
})

export class CustomToolTipComponent implements ITooltipAngularComp {
    params: any;
    data: any;

    agInit(params: any): void {
        this.params = params;
        this.data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
        this.data.type = this.params.type || null;
    }
}