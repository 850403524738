import { Component, OnInit, Input, HostListener } from '@angular/core';
import { GridOptions, GridApi } from 'ag-grid-community';
import { Router } from '@angular/router';
import { GridCellRendererComponent } from '../../grid-cell-renderer/grid-cell-renderer.component';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoaderService } from '../../services/loader.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { HomeService } from '../../home/home.service';
import { ErrorDialogComponent } from '../../error-dialog-box/error-dialog.component';
import { MonitoringService } from '../monitoring.service'
import * as moment from 'moment';
import { IndividualPickAlertComponent } from '../shared-module/individual-pick-alert/individual-pick-alert';
import { CompanyPickAlertComponent } from '../shared-module/company-pick-alert/company-pick-alert';
import { NotificationService } from '../shared-module/toast/toast.service';
import { ConfimDialogComponent } from 'src/app/confirm-dialog-box/confirm-dialog.component';
import { DetailCellRenderer } from '../shared-module/master-data-grids/cell-renderer.component';
import { ManuallPickAlertComponent } from '../shared-module/manuall-pick-alert/company-pick-alert';
import { GridLoaderComponent } from '../shared-module/loader/loader.component';
import { RestoreDataCellRenderer } from './restore-grid-data/restore-grid-data.component';
import { MyNoRowsComponent } from '../shared-module/search-result/no-overlay.component';
import { ConfimDeleteDialogComponent } from '../shared-module/confirm-dialog-box/confirm-dialog.component';
import { SelectAllHeader } from '../shared-module/header-component/select-all.component';
@Component({
	selector: 'app-restore',
	templateUrl: './restore.component.html',
	styleUrls: ['./restore.component.scss']
})
export class RestoreComponent implements OnInit {
	isMobile: boolean = false;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if(event.target.innerWidth > 1000) {
			this.isMobile = false;
			this.getColDefs();
		} else {
			this.isMobile = true;
			this.getColDefs();
		}
	}
	changeLanEvent: Subject<void> = new Subject<void>();

	title: any = "Monitoring";

	$langEventSubscription: Subscription;
	$getDataSubscription: Subscription;
	@Input() changeLangEvent: Observable<void>;
	columnDefs: any = [];
	records: any = [];
	gridOptions: any;
	gridColumnApi: any;
	api: any;
	entityRestored: boolean = false;
	loadNew: boolean = false;
	icons: any;
	selectedEntity: any;
	tooltipShowDelay: any;
	blob: any;
	editEntity: any;
	uncollectedCount = 0;
	mySubscription: Subscription
	private paginationPageSize = 5;
	private totalPages = 0;
	initialFlag: boolean = false;
	domLayout: any = "autoHeight";
	detailCellRenderer: any;
	countryList: any;
	pagesCount: number = 1;
	defaultRowsCount: number = 1000;
	pageNumber: number = 1;
	totalRecords: number;
	loadingOverlayComponent: any;
  	loadingOverlayComponentParams: any;
	loadMessage: string = "Loading Next 1000 Records..."
	showing: any;
	avalilableResults: number = 0;
	detailRowHeight: number;
	detailRowAutoHeight: boolean;
	allExpand: boolean = false;
	gridId: String;
	expandCollapseButtonValue: String = "Expand all rows"
	confirmDialogRef: MatDialogRef<ConfimDeleteDialogComponent>;
	showTip: boolean = true;
	get PaginationPageSize(): number {
		return this.paginationPageSize;
	}

	get gridAPI(): GridApi {
		return this.gridOptions.api;
	}

	get TotalPages(): number {
		return this.totalPages;
	}

	constructor(
		public monitoringService: MonitoringService,
		public dialog: MatDialog,
		public loaderService: LoaderService,
		public translateService: TranslateService,
		private homeService: HomeService,
		public router: Router,
		public nofifiacationService: NotificationService
	) {
		if(window.screen.width > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
		this.getColDefs();
		window.scroll(0, 0);
		this.gridOptions = <GridOptions>{
			onGridReady: () => {
				setTimeout(() => {
					this.gridOptions.api?.sizeColumnsToFit();
				}, 1000);
				this.totalPages = this.gridOptions.api?.paginationGetTotalPages();
			},
			onGridSizeChanged: () => {
				this.gridOptions.api.sizeColumnsToFit();
			},
			noRowsOverlayComponent: 'myNoRowsComponent',
			noRowsOverlayComponentParams: {
				"component": "previously-deleted"
			},
			rowSelection: 'multiple',
			pagination: true,
			paginationPageSize: 10,
			animateRows: true,
			suppressCellSelection: true,
			enableCellTextSelection: true,
			defaultColDef: {
				flex: 1,
				wrapText: true,
				resizable: true,
				autoHeight: true,
				unSortIcon: true,
				sortable: true,
				suppressMenu: true,
				lockPosition: true,
				tooltipComponent: 'customTooltip'
			}
		};

		this.detailCellRenderer = 'myDetailCellRenderer';
		this.detailRowHeight = 110;
		this.gridOptions.frameworkComponents = {
			"cellRenderer": GridCellRendererComponent,
			myDetailCellRenderer: RestoreDataCellRenderer,
			customLoadingOverlay: GridLoaderComponent,
			myNoRowsComponent: MyNoRowsComponent
		};

		this.loadingOverlayComponent = 'customLoadingOverlay';
		this.loadingOverlayComponentParams = {
			loadingMessage: this.loadMessage
		};

		this.tooltipShowDelay = 0;

		this.icons = {
			sortUnSort: '<img src="../../assets/img/sort.svg">',
			sortAscending: '<img src="../../assets/img/sort-asc.svg">',
			sortDescending: '<img src="../../assets/img/sort-desc.svg">'
		};
		this.monitoringService.setSid(localStorage.getItem('sessionID'))
	}

	onGridReady(params: any) {
		this.api = params.api;
		this.gridColumnApi = params.columnApi;
		this.loadRecords(this.defaultRowsCount, this.pageNumber);
	}

	ngOnInit(): void {
		this.monitoringService.selection.subscribe((select: string) => { 
			if(select == "select-all") {
				this.gridOptions.api.forEachNodeAfterFilter((node: any) => {
					node.setSelected(true);
				});
			} else if(select == "select-current-page") {
				this.gridOptions.api.forEachNodeAfterFilter((node: any) => {
					node.setSelected(false);
				});
				this.gridOptions.api.getRenderedNodes().map((node: any) => {
					node.setSelected(true);
				});
			}
		});
	}

	ngAfterViewInit(): void {
		this.gridAPI.resetRowHeights();
	}

	onFilterChanged(event: any) {
		if (this.gridOptions.api.getDisplayedRowCount() == 0) {
			this.gridAPI.showNoRowsOverlay();
		} else {
			this.gridAPI.hideOverlay();
		}
		// this.gridOptions.api.deselectAll();
	}

	dateGetter(params: any) {
		if(params.data.deletedDate) {
			return moment(params.data.deletedDate).format('DD MMM YYYY');
		} else {
			return " ";
		}
	}
	
	nameGetter(params: any) {
		if(params.data?.entityName) {
			return params.data.entityName + params.data.entityId;
		} else {
			return params.data.entityId;
		}
	}

	dateComparator(date11: any, date22: any) {
		var date1: any = moment(date11).format('DD/MM/YYYY');
		var date2: any = moment(date22).format('DD/MM/YYYY');
		var result1: any;
		var result2: any;
		if (date1 === undefined || date1 === null || date1.length !== 10) {
			result1 = null;
		} else{
			var yearNumber1: any = date1.substring(6, 10);
			var monthNumber1: any = date1.substring(3, 5);
			var dayNumber1: any = date1.substring(0, 2);
			result1 = yearNumber1 * 10000 + monthNumber1 * 100 + dayNumber1;
		}
		
		if (date2 === undefined || date2 === null || date2.length !== 10) {
			result2 = null;
		} else{
			var yearNumber2: any = date2.substring(6, 10);
			var monthNumber2: any = date2.substring(3, 5);
			var dayNumber2: any = date2.substring(0, 2);
			result2 = yearNumber2 * 10000 + monthNumber2 * 100 + dayNumber2;
		}
		if (result1 === null && result2 === null) {
			  return 0;
		}
		if (result1 === null) {
			return -1;
		}
		if (result2 === null) {
			return 1;
		}
		return result1 - result2;
	}
	
	getColDefs() {
		this.translateService.get(['monitor.select', 'monitor.entityIdentification', 'monitor.previouslyDeleted.dateOfDeletion', 'monitor.previouslyDeleted.deletedBy',
			'monitor.previouslyDeleted.userId', 'monitor.action']).subscribe(value => {
				this.columnDefs = [
					{
						field: 'select',
						headerName: value['monitor.select'],
						headerComponentFramework : SelectAllHeader,
						filter: false,
						width: 60,
						maxWidth: 100,
						hide:this.isMobile,
						sortable: false,
						checkboxSelection: true,
						headerCheckboxSelection: false,
						headerCheckboxSelectionFilteredOnly: true,
						cellStyle: { 'overflow': 'visible', 'justify-content': 'center'}
					},
					{
						field: 'entityName',
						headerName: value['monitor.entityIdentification'],
						floatingFilter: this.isMobile? false:true,
						filter: 'agTextColumnFilter',
						width: this.isMobile? 250:220,
						minWidth: this.isMobile? 250:220,
						filterParams: {
							trimInput: true
						},
						valueGetter: this.nameGetter,
						cellRenderer: function(param: any){
							if(param.data.entityType == "I") {
									var row = "NRIC / Passport No. "+param.data.entityId+ "</br>";
									if(param.data.entityName)
										row = row + param.data.entityName+ '<br/>';
									return row;
							} else {
								
									var row = "Entity Number. "+param.data.entityId+ "</br>";
									if(param.data.entityName)
										row = row + param.data.entityName+ '<br/>';
									return row;
							}
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '9px 18px','font-size': '14px' }
					},
					{
						field: 'deletedDate',
						valueGetter: this.dateGetter,
						comparator: this.dateComparator,
						headerName: value['monitor.previouslyDeleted.dateOfDeletion'],
						width: 150,
						maxWidth: 180,
						hide:this.isMobile,
						sortable: true,
						cellStyle: { 'overflow': 'visible', 'text-align': 'center', 'padding': '9px 18px' }
					},
					// {
					// 	field: 'deletedBy',
					// 	headerName: value['monitor.previouslyDeleted.deletedBy'],
					// 	width: 180,
					// 	minWidth: 180,
					// 	hide:this.isMobile,
					// 	floatingFilter: this.isMobile? false:true,
					// 	filter: 'agTextColumnFilter',
					// 	filterParams: {
					// 		trimInput: true
					// 	},
					// 	cellStyle: { 'overflow': 'visible', 'text-align': 'center', 'padding': '9px 18px' }
					// },
					{
						field: 'userId',
						headerName: value['monitor.previouslyDeleted.userId'],
						width: 180,
						minWidth: 180,
						hide:this.isMobile,
						floatingFilter: this.isMobile? false:true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'center', 'padding': '9px 18px' }
					},
					{
						width: this.isMobile?60:120,
						maxWidth: this.isMobile?60:130,
						sortable: false,
						headerName: this.isMobile?" " :value['monitor.action'],
						cellRenderer: "cellRenderer",
						cellRendererParams: {
							onClick: this.onActionClick.bind(this),
							label: 'previously-deleted'
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'center', 'padding': '6px 18px' }
					},
				]
			});
	}

	ngOnDestroy(): void {
		if (this.mySubscription) {
			this.mySubscription.unsubscribe();
		}
		if(this.$getDataSubscription) {
			this.$getDataSubscription.unsubscribe();
		}
	}

	changeLanguage() {
		this.getColDefs();
		this.gridOptions.api.refreshHeader({ forrce: true });
		setTimeout(() => {
			this.gridOptions.api?.sizeColumnsToFit();
		}, 100);
	}

	loadRecords(defaultRowsCount: number, pageNumber: number) {
		this.api.showLoadingOverlay();
		this.$getDataSubscription = this.monitoringService.getPreviouslyDeleted(defaultRowsCount, pageNumber).subscribe((data: any) => {
			this.loaderService.isLoading.next(false);
			var data = JSON.parse(data);
			if (data?.success) {
				var newData = data.data;
				if (newData.records.length > 0) {
					var oldPage = this.loadNew ? this.gridOptions.api?.paginationGetTotalPages() : null;
					var arr = this.records;
					if(this.loadNew) {
						newData.records.map((row: any, index: number) => {
							row.availableAlerts = JSON.parse(row.availableAlerts);
							arr.push(row);
							if(index == newData.records.length - 1){
								this.records = arr.map((records:any)=>({
									...records,
									expanded:false
								}));
								this.gridOptions.api.setRowData(this.records);
								this.loadNew = false;
								this.gridAPI.refreshCells(); 
							}
						});
					} else {
						// newData.records[0].deletedDate = "2022-11-03T00:00:00";
						newData?.records.map((alert: any) => {
							alert.availableAlerts = JSON.parse(alert.availableAlerts);
						});
						this.records = newData.records.map((records:any)=>({
							...records,
							expanded:false
						}));
						this.totalRecords = newData.recordCount;
					}
					this.gridId = "monitorListGrid";
					this.avalilableResults = this.records.length;
					this.pagesCount = Math.floor(this.totalRecords / this.defaultRowsCount);
					if((this.totalRecords / this.defaultRowsCount) % 1 > 0) {
						this.pagesCount = this.pagesCount + 1;
					}
					var from = (pageNumber-1)* defaultRowsCount  + 1;
					var to = pageNumber * defaultRowsCount;
					if(pageNumber == this.pagesCount) {
						to = this.totalRecords;
					}
					this.showing = {
						'from': from,
						'to': to,
						'loadPage': oldPage
					}
					this.records.map((data: any) => {
						data.isAllMonitored = true
					});
					this.api.hideOverlay();
				 } else {
					 this.records = [];
					 this.gridId = "monitorListGridMobile";
					this.api.hideOverlay();
				 }
			} else {
				this.openTryAgainPopup();
				this.api.hideOverlay();
			}
		}, (err: any) => {
			this.records = [];
			this.gridId = "monitorListGridMobile";
			this.api.hideOverlay();
			this.openTryAgainPopup();
		});
	}

	openTryAgainPopup() {
		this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
			let dialogRef = this.dialog.open(ErrorDialogComponent, {
				disableClose: true,
				width: this.isMobile? 'auto':'37vw',
				data: {
					errorMessage: value['errorMsg.pleaseTryAgain']
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					this.loadRecords(this.defaultRowsCount, this.pageNumber);
				}else {
					this.gridAPI.redrawRows();
				}
			});
		})
	}

	loadNextRecords() {
		this.loadNew = true;
		this.loadRecords(this.defaultRowsCount, ++this.pageNumber);
	}

	loadPreviousRecords() {
	}

	RemoveInBulk() {
		var array: any = [];
		this.api.getSelectedNodes().map((node: any) => {
			array.push(node.data.entityId);
		});
		this.confirmDialogRef = this.dialog.open(ConfimDeleteDialogComponent, {
			disableClose: true,
			width: this.isMobile? 'auto':'37vw',
		});
		this.translateService.get(['confirmAction.permanantMultipledelete']).subscribe(value => {
			this.confirmDialogRef.componentInstance.confirmMessage = value['confirmAction.permanantMultipledelete'] + this.api.getSelectedNodes().length + ")";
		});
		this.confirmDialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.loaderService.isLoading.next(true);
				this.monitoringService.deleteMonitoringRecordPermanantly(array).subscribe((data: any) => {
					this.loaderService.isLoading.next(false);
					if (typeof data == 'string' && JSON.parse(data)?.success) {
						var deletedRecords = JSON.parse(data);
						var deletedCount = 0;
						deletedRecords.data.map((rec: any) => {
							if(rec.status == "Success") {
								deletedCount++;
								this.records = this.records.filter((record: any) => record.entityId !== rec.id);
								this.avalilableResults = this.records.length;
								this.totalRecords = this.totalRecords - 1;
								this.gridAPI.redrawRows();
							}
						});
						this.translateService.get(['monitor.multiple_delete']).subscribe(value => {
							this.nofifiacationService.deleteMultiple(deletedCount+value['monitor.multiple_delete']);
						});
					}							
				}, (err: any) => {
					this.loaderService.isLoading.next(false);
					this.openTryAgainRecordDeletionPopup();
				});
			} 
		});
	}

	openTryAgainRecordDeletionPopup() {
		this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
			let dialogRef = this.dialog.open(ErrorDialogComponent, {
				disableClose: true,
				width: this.isMobile? 'auto':'37vw',
				data: {
					errorMessage: value['errorMsg.pleaseTryAgain']
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					var array: any = [];
					this.loaderService.isLoading.next(true);
					this.monitoringService.deleteMonitoringRecordPermanantly(array).subscribe((data: any) => {
						this.loaderService.isLoading.next(false);
						if (typeof data == 'string' && JSON.parse(data)?.success) {
							var deletedRecords = JSON.parse(data);
							var deletedCount = 0;
							deletedRecords.data.map((rec: any) => {
								if(rec.status == "Success") {
									deletedCount++;
									this.records = this.records.filter((record: any) => record.entityId !== rec.id);
									this.avalilableResults = this.records.length;
									this.totalRecords = this.totalRecords - 1;
									this.gridAPI.redrawRows();
								}
							});
							this.translateService.get(['monitor.multiple_delete']).subscribe(value => {
								this.nofifiacationService.deleteMultiple(deletedCount+value['monitor.multiple_delete']);
							});
						}							
					}, (err: any) => {
						this.loaderService.isLoading.next(false);
						this.openTryAgainRecordDeletionPopup();
					});
				} 
			});
				
		})
	}

	onActionClick(ev: any) {
		if (ev.label === 'restore') {
			if (ev.data.entityType == "I") {
				this.onSubmitIndividual(ev.data);
				this.gridAPI.refreshCells(ev);
			} else {
				this.onCompanyRestore(ev.data);
				this.gridAPI.refreshCells(ev);
			}
		}

		if (ev.label === 'expand') {
			this.detailRowHeight = 110;
			ev.data.expanded = !ev.node.expanded;
			ev.node.setExpanded(!ev.node.expanded);
			this.gridAPI.refreshCells(ev);
		}

		if (ev.label === 'delete') {
			this.gridAPI.refreshCells(ev);
			this.confirmDialogRef = this.dialog.open(ConfimDeleteDialogComponent, {
				disableClose: true,
				width: this.isMobile? 'auto':'37vw',
			});
			this.translateService.get(['confirmAction.permanantdeleteMessage']).subscribe(value => {
				this.confirmDialogRef.componentInstance.confirmMessage = value['confirmAction.permanantdeleteMessage'];
			});
			this.confirmDialogRef.afterClosed().subscribe(res => {
				if (res) {
					this.loaderService.isLoading.next(true);
					var array = [ev.data.entityId];
					this.monitoringService.deleteMonitoringRecordPermanantly(array).subscribe((data: any) => {
						this.loaderService.isLoading.next(false);
						if (typeof data == 'string' && JSON.parse(data)?.success) {
							var deletedRecords = JSON.parse(data);
							deletedRecords.data.map((rec: any) => {
								if(rec.status == "Success") {
									this.records = this.records.filter((record: any) => record.entityId !== rec.id);
									this.avalilableResults = this.records.length;
									this.totalRecords = this.totalRecords - 1;
									this.showing.to = this.totalRecords;
									this.gridAPI.redrawRows();
									this.translateService.get(['monitor.action_deleted']).subscribe(value => {
										this.nofifiacationService.delete(value['monitor.action_deleted']);
									});
								}
							});
						}							
					}, (err: any) => {
						this.loaderService.isLoading.next(false);
						this.openTryAgainPopup();
					});
				} else {
					this.gridAPI.refreshCells(ev);
				}
			});
		}
	}

	changeLanguageGrid(ev: any) {
		this.changeLanEvent.next();
	}

	onSubmitIndividual(data: any) {
		let dialogRef = this.dialog.open(IndividualPickAlertComponent, {
			disableClose: true,
			width: this.isMobile? 'auto':'70vw',
			panelClass: 'advanced-search-dialog',
			data: {
				isRestore: true,
				data: data
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.entityRestored = true;
				this.records = this.records.filter((record: any) => record.entityId !== res.entityId);
				this.avalilableResults = this.records.length;
				this.totalRecords = this.totalRecords - 1;
				this.showing.to = this.totalRecords;
				this.gridAPI.redrawRows();
				this.loaderService.isLoading.next(false);
				this.translateService.get(['monitor.action_restored']).subscribe(value => {
					this.nofifiacationService.restore(value['monitor.action_restored']);
				});
			} else {
				this.gridAPI.redrawRows();
			}
		});
	}

	openEditTryAgainPopup() {
		this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
			let dialogRef = this.dialog.open(ErrorDialogComponent, {
				disableClose: true,
				width: this.isMobile? 'auto':'37vw',
				data: {
					errorMessage: value['errorMsg.pleaseTryAgain']
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					this.onCompanyRestore(this.editEntity);
				}
			});
		})
	}

	onCompanyRestore(res: any) {
		let payload = {
			countryCode: res.countryCode,
			name: res.entityName,
			number: res.entityId
		}
		this.editEntity = res;
		this.loaderService.isLoading.next(true);
		this.monitoringService.searchCompanies(payload).subscribe((data: any) => {
			this.loaderService.isLoading.next(false);
			if (typeof data == 'string' && JSON.parse(data)?.success) {
				var searchResults = JSON.parse(data);
				console.log("search results:", searchResults);
				if(searchResults?.data?.length > 0) {
					var isExist = false;
					searchResults.data.map((result: any, index: number) => {
						console.log('reesult.entNo:', result.entNo, res.entityId);
						if(result.entNo == res.entityId ) {
							isExist = true;
							res['edit'] = true;
							res['restore'] = true;
							this.selectedEntity = res;
							let dialogRef = this.dialog.open(CompanyPickAlertComponent, {
								disableClose: true,
								width: this.isMobile? 'auto':'70vw',
								panelClass: 'advanced-search-dialog',
								data: {
									results: res
								}
							});
							dialogRef.afterClosed().subscribe(res => {
								if (res) {
									this.entityRestored = true;
									this.records = this.records.filter((record: any) => record.entityId !== res.entityId);
									this.avalilableResults = this.records.length;
									this.totalRecords = this.totalRecords - 1;
									this.showing.to = this.totalRecords;
									this.gridAPI.redrawRows();
									this.loaderService.isLoading.next(false);
									this.translateService.get(['monitor.action_restored']).subscribe(value => {
										this.nofifiacationService.restore(value['monitor.action_restored']);
									});
								} else {
									this.gridAPI.redrawRows();
								}
							});
						} else if(searchResults.data?.length-1 == index && isExist == false) {
							res['edit'] = true;
							res['restore'] = true;
							this.selectedEntity = res;
							let dialogRef = this.dialog.open(ManuallPickAlertComponent, {
								disableClose: true,
								width: this.isMobile? 'auto':'70vw',
								panelClass: 'advanced-search-dialog',
								data: {
									results: res
								}
							});
							dialogRef.afterClosed().subscribe(res => {
								if (res) {
									this.entityRestored = true;
									this.records = this.records.filter((record: any) => record.entityId !== res.entityId);
									this.avalilableResults = this.records.length;
									this.totalRecords = this.totalRecords - 1;
									this.showing.to = this.totalRecords;
									this.gridAPI.redrawRows();
									this.loaderService.isLoading.next(false);
									this.translateService.get(['monitor.action_restored']).subscribe(value => {
										this.nofifiacationService.restore(value['monitor.action_restored']);
									});
								} else {
									this.gridAPI.redrawRows();
								}
							});	
						}
					});
				} else {
					res['edit'] = true;
					res['restore'] = true;
					this.selectedEntity = res;
					let dialogRef = this.dialog.open(ManuallPickAlertComponent, {
						disableClose: true,
						width: this.isMobile? 'auto':'70vw',
						panelClass: 'advanced-search-dialog',
						data: {
							results: res
						}
					});
					dialogRef.afterClosed().subscribe(res => {
						if (res) {
							this.entityRestored = true;
							this.records = this.records.filter((record: any) => record.entityId !== res.entityId);
							this.avalilableResults = this.records.length;
							this.totalRecords = this.totalRecords - 1;
							this.showing.to = this.totalRecords;
							this.gridAPI.redrawRows();
							this.loaderService.isLoading.next(false);
							this.translateService.get(['monitor.action_restored']).subscribe(value => {
								this.nofifiacationService.restore(value['monitor.action_restored']);
							});
						} else {
							this.gridAPI.redrawRows();
						}
					});
				}
			} else {
				res['edit'] = true;
							res['restore'] = true;
							this.selectedEntity = res;
							let dialogRef = this.dialog.open(ManuallPickAlertComponent, {
								disableClose: true,
								width: this.isMobile? 'auto':'70vw',
								panelClass: 'advanced-search-dialog',
								data: {
									results: res
								}
							});
							dialogRef.afterClosed().subscribe(res => {
								if (res) {
									this.entityRestored = true;
									this.records = this.records.filter((record: any) => record.entityId !== res.entityId);
									this.avalilableResults = this.records.length;
									this.totalRecords = this.totalRecords - 1;
									this.showing.to = this.totalRecords;
									this.gridAPI.redrawRows();
									this.loaderService.isLoading.next(false);
									this.translateService.get(['monitor.action_restored']).subscribe(value => {
										this.nofifiacationService.restore(value['monitor.action_restored']);
									});
								} else {
									this.gridAPI.redrawRows();
								}
							});	
			}
		});
	}

	hideTip() {
		this.showTip = false;
	}
	
	ExpandAll(data:any){
		if(!data){
			this.gridOptions.api.forEachNode((node: any) => {
				node.data.expanded = true;
				node.expanded = true;
			});
			this.allExpand = true;
			this.expandCollapseButtonValue = "Collapse all rows"
		}else{
			this.gridOptions.api.forEachNode((node: any) => {
				node.data.expanded = false;
				node.expanded = false;
			});
			this.allExpand = false;
			this.expandCollapseButtonValue = "Expand all rows"
		}
		this.gridOptions.api.onGroupExpandedOrCollapsed();
	}

	deselectAll() {
		this.gridOptions.api.deselectAll();
	}
}
