<div class="overflow-y">
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
            <b>{{'selectionTable.address' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
           {{cellData.address}}
        </div>
    </div>    
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
            <b>{{'selectionTable.entityStatus' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;" >
           {{cellData.stt}}
        </div>
    </div>    
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
            <b>{{'selectionTable.expiryDate' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
           {{cellData.expDat}}
        </div>
    </div>    
</div>
