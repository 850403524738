import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ErrorDialogComponent } from 'src/app/error-dialog-box/error-dialog.component';
import { LoaderService } from 'src/app/services/loader.service';
import { MonitoringService } from '../../monitoring.service';

@Component({
    selector: 'common-dialog-box',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['confirm-dialog.component.scss']
})

export class CommonDialogComponent {
    public confirmMessage: any = '';
    paramData: any;
    constructor(public dialogRef: MatDialogRef<CommonDialogComponent>, 
        public loaderService: LoaderService,
        @Inject(MAT_DIALOG_DATA) private data: any, 
        private service: MonitoringService,
        public dialog: MatDialog,
        public translateService: TranslateService
    ) {
        this.paramData = data;
    }

    complete() {
        this.loaderService.isLoading.next(true);
        this.service.completeProcessing(this.paramData.data).subscribe(
            (res: any) => {
                this.loaderService.isLoading.next(false);
                if(res.success) {
                    var obj: any = { 
                        "bulkAlertUploadNumber": this.paramData.data,
                        "bulkOrderStatus": "Complete"
                      };
                    localStorage.setItem('MonitoringbulkProcessComplete', 'reviewed');
                    this.dialogRef.close(obj)
                } else {
                    this.openTryAgainPopup();
                } 
            },
            (err: any) => {
                this.openTryAgainPopup();
            }
        );
        
    }

    openTryAgainPopup() {
		this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
			let dialogRef = this.dialog.open(ErrorDialogComponent, {
				disableClose: true,
				width: '37vw',
				data: {
					errorMessage: value['errorMsg.pleaseTryAgain']
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					this.complete();
				}
			});
		})
	}
}