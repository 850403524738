import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'error-translation-result-dialog',
    templateUrl: './error-translation-result-dialog.component.html',
    styleUrls: ['error-translation-result-dialog.component.scss']
})

export class ErrorTranslationResultDialogComponent {
    public confirmMessage: any = '';
    constructor(public dialogRef: MatDialogRef<ErrorTranslationResultDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
         }
}