import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { NotificationService } from "../shared-module/toast/toast.service";
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MonitoringService } from '../monitoring.service';
import { LoaderService } from 'src/app/services/loader.service';
import { GridOptions, GridApi } from 'ag-grid-community';
import { ErrorDialogComponent } from 'src/app/error-dialog-box/error-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { BWSDetailCellRenderer } from './master-data-grid-bws-gaz/cell-renderer.component';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { ExpandGridCellRendererComponent } from '../shared-module/expand-grid-cell-renderer/expand-grid-cell-renderer.component';
import { BWSAlertDetailCellRenderer } from './master-data-grids/cell-renderer.component';
import { MyNoRowsComponent } from '../shared-module/search-result/no-overlay.component';

@Component({
	selector: 'app-business-alert',
	templateUrl: './business-monitoring-service-alert.component.html',
	styleUrls: ['./business-monitoring-service-alert.component.scss']
})
export class BusinessMonitoringServiceComponent implements OnInit {
	isMobile: boolean = false;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if(event.target.innerWidth > 1000) {
			this.isMobile = false;
			this.getColDefs();
			this.bwsGazzetedgetColDefs();
		} else {
			this.isMobile = true;
			this.getColDefs();
			this.bwsGazzetedgetColDefs();
		}
	}
	@Output() tabsEvent = new EventEmitter();
	changeLanEvent: Subject<void> = new Subject<void>();
	title: any = "Monitoring";
	columnDefs: any = [];
 	gridOptions: any;
	gridColumnApi: any;
	btnDisabled: boolean = false;
	bwsGazzetedGridOptions: any;
	bwsGazzetedGridColumnApi: any;
	bwsGazzetedApi: any;
	bwsGazzetedcolumnDefs: any = [];

	exportList: any;
	exportOtherInfoList: any;

 	api: any;
 	domLayout: any = "autoHeight";
	alartList: any;
	otherInfo: any;
	summary: any;
	detailCellRenderer: any;
	gridDetailCellRenderer: any;
	bwsGridDetailHeight: number;
	detailRowHeight: number;
	getRowStyle: any;
	expandCollapseButtonValue: String = "Collapse all rows";
	expandCollapseButtonOthersValue: String = "Collapse all rows";
	entityId: any;
	category: any = "GAZETTED";
	startDate: any;
	endDate: any;
	allExpand: boolean = true;
	allOtherExpand: boolean = true;
	count: any = 0;
	currentDate = moment(new Date()).format('DD/MM/YYYY');

	get gridAPI(): GridApi {
		return this.gridOptions.api;
	}

	constructor(
		public _notificationservice: NotificationService,
		public router: ActivatedRoute,
		public iconRegistry: MatIconRegistry,
		public sanitizer: DomSanitizer,
		private dialog: MatDialog,
		public monitoringService: MonitoringService,
		public translateService: TranslateService,
		public loaderService: LoaderService,
	) {
		if(window.screen.width > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
		this.router.queryParams.subscribe((route) => {
			if (route.recordSlide == 'true') {
				
			}
		});
		this.getColDefs();
		this.bwsGazzetedgetColDefs();
		this.entityId = localStorage.getItem('entityId');
		this.startDate = localStorage.getItem('dateFrom');
		this.endDate = localStorage.getItem('dateTo');
		this.loadRecords();
		window.scroll(0, 0);
		this.getGridDefination();
		this.getBwsGazzetedGridDefination();
		this.export();
	}

	onGridReady(params: any) {
		this.gridColumnApi = params.columnApi;
		this.api = params.api;
	}

	onGridReadyBwsGaz(params: any) {
		this.bwsGazzetedGridColumnApi = params.columnApi;
		this.bwsGazzetedApi = params.api;
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
		this.gridAPI.resetRowHeights();
	}

	getGridDefination() {
		this.gridOptions = <GridOptions>{
			onGridReady: () => {
				setTimeout(() => {
					this.gridOptions.api?.sizeColumnsToFit();
				}, 1000);
			 },
			onGridSizeChanged: () => {
				this.gridOptions.api.sizeColumnsToFit();
			},
			noRowsOverlayComponent: 'myNoRowsComponent',
			noRowsOverlayComponentParams: {
				"component": "detailed"
			},
			pagination: false,
			animateRows: true,
			suppressCellSelection: true,
			enableCellTextSelection: true,
			suppressContextMenu:true,
			defaultColDef: {
				flex: 1,
				wrapText: true,
				resizable: true,
				autoHeight: true,
				unSortIcon: true,
				sortable: true,
				suppressMenu: true,
				lockPosition: true,
				tooltipComponent: 'customTooltip'
			}
		};
		this.gridDetailCellRenderer = 'gridDetailCellRenderer';
		this.bwsGridDetailHeight = 160;
		this.gridOptions.frameworkComponents = {
			 myNoRowsComponent: MyNoRowsComponent,
			"cellRenderer": ExpandGridCellRendererComponent,
			"gridDetailCellRenderer":  BWSAlertDetailCellRenderer
		};
		this.monitoringService.setSid(localStorage.getItem('sessionID'));
		this.getRowStyle = (params: any) => {
			if (params.node.rowIndex % 2 !== 0) {
				return { background: '#D0D0D0' };
			}
		};
		
	}

	getBwsGazzetedGridDefination() {
		
		this.bwsGazzetedGridOptions = <GridOptions>{
			onGridReady: () => {
				setTimeout(() => {
					this.bwsGazzetedGridOptions.api?.sizeColumnsToFit();
				}, 1000);
			 },
			onGridSizeChanged: () => {
				this.bwsGazzetedGridOptions.api.sizeColumnsToFit();
			},
			overlayNoRowsTemplate: '<h4> No record found. </h4>',
			pagination: false,
			animateRows: true,
			suppressCellSelection: true,
			enableCellTextSelection: true,
			suppressContextMenu:true,
			defaultColDef: {
				flex: 1,
				wrapText: true,
				resizable: true,
				autoHeight: true,
				unSortIcon: true,
				sortable: true,
				suppressMenu: true,
				lockPosition: true,
				tooltipComponent: 'customTooltip'
			}
		};
		this.detailCellRenderer = 'myDetailCellRenderer';
		this.bwsGazzetedGridOptions.frameworkComponents = {
			"cellRenderer": ExpandGridCellRendererComponent,
			"myDetailCellRenderer": BWSDetailCellRenderer
		};
		this.detailRowHeight = this.isMobile?700:620;
		this.getRowStyle = (params: any) => {
			if (params.node.rowIndex % 2 !== 0) {
				return { background: '#D0D0D0' };
			}
		};
	}

	loadRecords() {
		this.loaderService.isLoading.next(true);
		this.monitoringService.getPaymentAlarts("BWS", this.entityId, this.endDate, this.startDate, "ALL").subscribe((data: any) => {
			if (data?.success && data.data != null) {
				data?.data?.list?.Alerts?.map((obj: any, index: number) => {
					obj['srNo'] = index+ 1;
					obj['expanded'] = true;
				});
				this.alartList = data?.data?.list?.Alerts;
				if(data?.data?.count)
				this.count = this.count + data?.data?.count;
				this.loaderService.isLoading.next(false);
			} else {
				this.loaderService.isLoading.next(false);
				this.openTryAgainPopup();
				this.alartList = [];
			}
		}, (err: any) => {
			this.loaderService.isLoading.next(false);
			this.alartList = [];
			this.openTryAgainPopup();
		});

		this.monitoringService.getPaymentAlarts("BWS", this.entityId, this.endDate, this.startDate, this.category).subscribe((data: any) => {
			this.loaderService.isLoading.next(false);
			if (data?.success) {
				data?.data?.list?.Alerts?.map((obj: any, index: number) => {
					obj['srNo'] = index+ 1;
				});
				this.otherInfo = data?.data?.list?.Alerts;
				if(data?.data?.count)
				this.count = this.count + data?.data?.count;
			} 
		});

		if(!this.entityId) {
			this.monitoringService.getAlertsSummary().subscribe((data: any) => {
				if(data.success) {
					data?.data?.summaryDetails?.map((alert: any) => {
						if(alert.monitoringType == "BWS") {
							this.summary = alert;
						}
					});
				} 
			});
		}
	}

	export() {
		this.monitoringService.exportToExcel("BWS", this.endDate, this.startDate, "ALL", this.entityId).subscribe((data: any) => {
			if (data?.success) {
				this.exportList = data?.data?.Alerts[0]?.BWS;
				this.exportOtherInfoList = data?.data?.Alerts[1]?.BWS_OTHER_INFORMATION;
			}
		});
		
	}

	generateExcel() {
		let element = document.getElementById('excel-table');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
		var wscols = [
			{wch:30},
			{wch:30},
			{wch:30},
			{wch:30},
			{wch:30},
			{wch:30}
		];	
		ws['!cols'] = wscols;
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'BWS');
		console.log("this.exportOtherInfoList:", this.exportOtherInfoList);
		if(this.exportOtherInfoList?.length > 0) {
			console.log("this.exportOtherInfoList:", this.exportOtherInfoList);
			let elementOtherInfo = document.getElementById('other-info-excel-table');
          const otherInfo: XLSX.WorkSheet = XLSX.utils.table_to_sheet(elementOtherInfo, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
			var wscols = [
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30}
			];	
			otherInfo['!cols'] = wscols;
			XLSX.utils.book_append_sheet(wb, otherInfo, 'BWS Other Information');
		}
		if(this.summary) {
			let element = document.getElementById('summary-excel-table');
          const wsSummary: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
			var wscolsExport = [
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30}
			];	
			wsSummary['!cols'] = wscolsExport;
			XLSX.utils.book_append_sheet(wb, wsSummary, 'Summary');
		}
		XLSX.writeFile(wb, 'BWS_ExcelSheet.xlsx');
	}

	openTryAgainExportPopup() {
		this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
			let dialogRef = this.dialog.open(ErrorDialogComponent, {
				disableClose: true,
				width: '37vw',
				data: {
					errorMessage: value['errorMsg.pleaseTryAgain']
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					this.exportAsExcelFile();
				}
			});
		})
	}

	exportAsExcelFile(): void {
		if(this.exportList || this.exportOtherInfoList) {
			this.generateExcel();
		} else {
			this.loaderService.isLoading.next(true);
			this.monitoringService.exportToExcel("BWS", this.endDate, this.startDate, "ALL", this.entityId).subscribe((data: any) => {
			if (data?.success) {
				this.exportList = data?.data?.Alerts[0]?.BWS;
                this.exportOtherInfoList = data?.data?.Alerts[1]?.BWS_OTHER_INFORMATION;
				this.loaderService.isLoading.next(false);
				setTimeout(()=> {
					this.generateExcel();
				}, 1000);
			} else {
				this.loaderService.isLoading.next(false);
				this.openTryAgainExportPopup();
			}
		}, (err: any) => {
			this.loaderService.isLoading.next(false);
			this.openTryAgainExportPopup();
		});
		}
    }
	
	openTryAgainPopup() {
		this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
			let dialogRef = this.dialog.open(ErrorDialogComponent, {
				disableClose: true,
				width: this.isMobile?'auto':'37vw',
				data: {
					errorMessage: value['errorMsg.pleaseTryAgain']
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					this.loaderService.isLoading.next(true);
					this.monitoringService.getPaymentAlarts("BWS", this.endDate, this.startDate, "ALL", this.entityId).subscribe((data: any) => {
						if (data?.success && data.data != null) {
							data?.data?.list?.Alerts?.map((obj: any, index: number) => {
								obj['srNo'] = index+ 1;
							});
							this.alartList = data?.data?.list?.Alerts;
							this.loaderService.isLoading.next(false);
						} else {
							this.loaderService.isLoading.next(false);
							this.openTryAgainPopup();
							this.alartList = [];
						}
					}, (err: any) => {
						this.loaderService.isLoading.next(false);
						this.alartList = [];
						this.openTryAgainPopup();
					});
				}
			});
		})
	}

	serialNumberGetter(params:any) {
		return 1;
	}

	dateGetter(params: any) {
		return moment(params.data.AlertDate).format('DD MMM YYYY');
	}

 	getColDefs() {
		this.translateService.get(['monitor.fms.no', 'monitor.entityNo', 'monitor.dateOfAlert',
			'monitor.entityName', 'monitor.clientFileRef', 'monitor.bwsAlert']).subscribe(value => {
				this.columnDefs = [
					{
						field: 'srNo',
						headerName: value['monitor.fms.no'],
						tooltipField: 'clientFileRef',
						tooltipComponentParams: { type: 'fileRef' },
						sortable: false,
						width: this.isMobile?60:110,
						maxWidth: this.isMobile?60:110,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': this.isMobile?'13px 0px 13px 16px':'13px 13px 13px 20px'}
					},
					{
						field: 'EntityId',
						headerName: value["monitor.entityNo"],
						tooltipComponentParams: { type: 'fileRef' },
						sortable: false,
						width: 110,
						minWidth: 110,
						hide:this.isMobile,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' }
					},
					{
						field: 'EntityName',
						headerName: value['monitor.entityName'],
						tooltipComponentParams: { type: 'fileRef' },
						sortable: false,
						width: this.isMobile?200:110,
						minWidth: this.isMobile?200:110,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': this.isMobile?'13px 0px 13px 13px':'13px','font-size': '14px' }
					},
					{
						field: 'FileReference',
						headerName: value['monitor.clientFileRef'],
						tooltipComponentParams: { type: 'fileRef' },
						sortable: false,
						width: 110,
						minWidth: 110,
						hide:this.isMobile,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' }
					},
					{
						field: 'Change',
						headerName: value['monitor.bwsAlert'],
						sortable: false,
						width: 110,
						minWidth: 110,
						hide:this.isMobile,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' },
					},
					{
						field: 'Change',
						headerName: value['monitor.dateOfAlert'],
						valueGetter: this.dateGetter,
						sortable: false,
						width: 110,
						minWidth: 110,
						hide:this.isMobile,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' },
					},
					{
						headerName: '',
						width: 50,
						minWidth: 50,
						sortable: false,
						hide:this.isMobile? false:true,
						cellRenderer: "cellRenderer",
						cellRendererParams: {
							onClick: this.bwsAlertAction.bind(this),
							label: 'bws-alert-action'
						},
						cellStyle: { 'text-align': 'right' },
					}
				]
			});
	} 

	bwsAlertAction(ev: any) {
		if (ev.label === 'expand') {
			ev.data.expanded = !ev.node.expanded;
			ev.node.setExpanded(!ev.node.expanded);
			this.gridAPI.refreshCells(ev);
		}
	}
 
	bwsGazzetedgetColDefs() {
		this.translateService.get(['monitor.fms.no', 'monitor.entityNo',
			'monitor.entityName', 'monitor.clientFileRef', 'monitor.bwsAlert']).subscribe(value => {
				this.bwsGazzetedcolumnDefs = [
					{
						field: 'srNo',
						headerName: value['monitor.fms.no'],
						tooltipField: 'clientFileRef',
						tooltipComponentParams: { type: 'fileRef' },
						sortable: false,
						width: this.isMobile?60:110,
						maxWidth: this.isMobile?60:110,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': this.isMobile?'13px 0px 13px 13px':'13px', 'padding-left': '20px'}
					},
					{
						field: 'EntityId',
						headerName: value["monitor.entityNo"],
						tooltipComponentParams: { type: 'fileRef' },
						sortable: false,
						width: 110,
						minWidth: 110,
						hide:this.isMobile,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' }
					},
					{
						field: 'EntityName',
						headerName: value['monitor.entityName'],
						tooltipComponentParams: { type: 'fileRef' },
						sortable: false,
						width: this.isMobile?200:110,
						minWidth: this.isMobile?200:110,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': this.isMobile?'13px 0px 13px 13px':'13px','font-size': '14px'}
					},
					{
						field: 'FileReference',
						headerName: value['monitor.clientFileRef'],
						tooltipComponentParams: { type: 'fileRef' },
						sortable: false,
						width: 110,
						minWidth: 110,
						hide:this.isMobile,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' }
					},
					// {
					// 	field: 'change',
					// 	headerName: "",
					// 	sortable: false,
					// 	width: 110,
					// 	minWidth: 110,
					// 	hide:this.isMobile,
					// 	cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' },
					// },
					{
						headerName: '',
						width: 50,
						minWidth: 50,
						sortable: false,
						hide:this.isMobile? false:true,
						cellRenderer: "cellRenderer",
						cellRendererParams: {
							onClick: this.bwsGazzAction.bind(this),
							label: 'bws-gaz-action'
						},
						cellStyle: { 'text-align': 'right' },
					}
				]
			});
	} 

	bwsGazzAction(ev: any){
		if (ev.label === 'expand') {
			ev.data.expanded = !ev.node.expanded;
			ev.node.setExpanded(!ev.node.expanded);
			this.gridAPIGazzeted.refreshCells(ev);
		}
	}
	
	get gridAPIGazzeted(): GridApi {
		return this.bwsGazzetedGridOptions.api;
	}
 
 	changeLanguageGrid(ev: any) {
		this.getColDefs();
		this.gridOptions.api.refreshHeader({ forrce: true });
		setTimeout(() => {
			this.gridOptions.api?.sizeColumnsToFit();
		}, 100);
	}

	onMobileDataRendered(params: any) {
		this.gridOptions.api.forEachNode((node:any) =>{
			node.expanded = true;
		});
	}

	onFirstDataRendered(params: any) {	
		this.bwsGazzetedGridOptions.api.forEachNode((node:any) =>{
			node.expanded = true;
		});	
	}

	ExpandAll(data:any){
		this.btnDisabled = true;
		if(data){			
			this.gridOptions.api.forEachNode((node: any) => {
				node.data.expanded = false;
				node.expanded = false;
			});
			if(this.otherInfo!= null){
				this.bwsGazzetedGridOptions.api.forEachNode((node: any) => {
					node.data.expanded = false;
					node.expanded = false;
				});	
			}					
			this.allExpand = false;
			this.expandCollapseButtonValue = "Expand all rows";
		}else{
			this.gridOptions.api.forEachNode((node: any) => {
				node.data.expanded = true;
				node.expanded = true;
			});
			if(this.otherInfo!= null){
				this.bwsGazzetedGridOptions.api.forEachNode((node: any) => {
					node.data.expanded = true;
					node.expanded = true;
				});
			}			
			this.allExpand = true;
			this.expandCollapseButtonValue = "Collapse all rows";
		}
		this.gridOptions.api.onGroupExpandedOrCollapsed();
		setTimeout(() => {
			if(this.otherInfo!= null){
				this.bwsGazzetedGridOptions.api.onGroupExpandedOrCollapsed();
			}
			this.btnDisabled = false;
		}, 100);		
	}

	onPrint(){
		window.print();
	}
}
