import { Component, OnInit, Output, ViewChild, EventEmitter, Input } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/authGuard.service';
import { HomeService } from 'src/app/home/home.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

	@ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;
	language: string = 'en';
	hideHeader: boolean = false;
	@Input() title: any;
	@Output() langChange = new EventEmitter();
	mobileMenu:boolean = false;
	showLanguage:boolean = false;
	hideMenu: boolean = false;
	serviceData: any;
	serviceObject: any;
	monitoringService: any;
	homeServiceAccess: any;
	globalMonitoringService:any;
	constructor(private translateService: TranslateService,private homeService: HomeService,
		private loaderService: LoaderService, private router: Router, public auth: AuthService) {
			router.events.forEach((event) => {
				if (event instanceof NavigationEnd) {
					if (event.url == '/session-expired') {
						this.hideMenu = true;
					} else {
						this.hideMenu = false;
					}
				}
			});	
			auth.iIRMonitoringAccess.subscribe((res: any) => {
				this.monitoringService = res;
			});
	
			auth.iIRROCReportAccess.subscribe((res: any) => {
				this.homeServiceAccess = res;
			});

			auth.iirGlobalMonitoringAccess.subscribe((res: any) => {
				this.globalMonitoringService = res;
			});
		}

	ngOnInit(): void {
		let lang: any = localStorage.getItem("lang");
		if (lang != undefined && lang != null) {
			this.language = lang;
		}
		if (this.router.url == '/session-expired') {
			this.hideHeader = true;
		} else {
			this.hideHeader = false;
		}
	}
	gotToHome() {
		this.router.navigate(['/home']);
	}

	tabChange(event: any) {
		document.getElementsByClassName("selected")[0].classList.remove("selected")
		event.target.classList.add('selected')
	}
	showMobileMenu(){
		this.mobileMenu = !this.mobileMenu
	}
	hideMobileMenu(){
		this.mobileMenu = false;
	}
	showLanguageMenu(){
		this.showLanguage = !this.showLanguage
	}

	changeLanguage(lang: any) {
		this.language = lang;
		this.translateService.use(lang);
		localStorage.setItem("lang", lang);
		this.langChange.emit(lang);
	}
	

	logout() {
		this.loaderService.isLoading.next(true);
		this.homeService.logout().subscribe((data: any) => {
			this.loaderService.isLoading.next(false);
			localStorage.removeItem('userID');
			localStorage.removeItem('sessionID');
			localStorage.removeItem('currency');
			localStorage.removeItem('upgrade_plan_show');
			localStorage.removeItem('record_added');
			this.router.navigate(['/session-expired'], { state: { page: 'logout' } });
		});
	}
}