<div class="overflow-y">
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-12 left-section" style="white-space: break-spaces;">
            <b>{{'selectionTable.address' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-12 right-section" style="white-space: break-spaces;">
           {{cellData.address2}}
        </div>
    </div>    
    <div fxLayout="col" class="f-s-adjustment" *ngIf="cellData.categoryId=='4'">
        <div fxFlex="50" class="left p-t-12 left-section" style="white-space: break-spaces;">
            <b>{{'selectionTable.status' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-12 right-section" style="white-space: break-spaces;" >
           {{cellData.status}}
        </div>
    </div>    
    <div fxLayout="col" class="f-s-adjustment" *ngIf="cellData.categoryId=='4'">
        <div fxFlex="50" class="left p-t-12 left-section" style="white-space: break-spaces;">
            <b>{{'selectionTable.regnr' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-12 right-section" style="white-space: break-spaces;">
           {{cellData.regnr}}
        </div>
    </div>    
</div>
