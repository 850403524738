import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'bulk-grid-cell-renderer',
	template: `<div class="text">
					<div *ngIf="params.data?.status != 'Tat_Not_Available'">
						{{params.data?.availabilitySpeedDescription}}
					</div>
					<div *ngIf="params.data?.status == 'Tat_Not_Available'">
					{{ 'bulk.upload.tat_not' | translate }}
					</div>
					<div *ngIf="params.data?.status == 'Tat_Not_Available'">
						<a class="link" (click)="viewOptions($event)"> {{ 'bulk.upload.view_option' | translate }} </a>
					</div>
				</div>`,
	styles: ['.link { color:#426DA9; text-decoration: underline; cursor: pointer;}', '.green { background-color: #5CD08E; color: white;}', '.yellow { background-color: #FEBA00; color: white;}', '.pink { background-color: #F66C86; color: white;}', '.text {font-size: 14px;}']
})

export class TurnoverCellRendererComponent implements ICellRendererAngularComp {

	public params: any;
	option: string | undefined;
	showTranslation: boolean = false;

	constructor() { }

	agInit(params: any): void {
		this.params = params;
		if (!this.params.data.parentCartSeqNo) {
			this.showTranslation = this.params.api.getModel().rowsToDisplay.some((obj: any) => (obj.data.parentCartSeqNo == this.params.data.cartSeqNo));
		}
	}

	refresh(): boolean {
		return false;
	}

	checkValid(data: any) {
		return data.translation;
	}

	
	onDelete(ev: any) {
		this.params['label'] = 'delete';
		this.params.onClick(this.params);
	}

	addToCart(ev: any) {
		this.params['label'] = 'addToCart';
		this.params.onClick(this.params);
	}
	
	viewOptions(ev: any) {
		this.params['label'] = 'viewOptions';
		this.params.onClick(this.params);
	}

}