import { Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from '@ag-grid-community/angular';
import { INoRowsOverlayParams } from '@ag-grid-community/core/dist/cjs/rendering/overlays/noRowsOverlayComponent';
import { IAfterGuiAttachedParams } from '@ag-grid-community/core/dist/cjs/interfaces/iAfterGuiAttachedParams';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Subject } from 'rxjs';
import { MonitoringService } from '../../monitoring.service';

@Component({
  selector: 'app-my-no-rows',
  template: `<mat-icon class="svg-icon" svgIcon="no-record"></mat-icon>
                <span *ngIf="params?.component == 'search-result'"><h4> No results found, would you like to <a><button class="manual" (click)="clickImport()">Add Manually</button> </a> </h4></span>
                <span *ngIf="params?.component == 'previously-deleted'"><h4> This section is currently empty</h4> </span>
                <span *ngIf="params?.component == 'detailed'"><h4> No results found.</h4> </span>`,
  styles: ['.manual { pointer-events: all !important; border: none; background: inherit; }', '.svg-icon { font-size: 20px; height: 36px; width: 32px; padding-top: 38px; }']
})
export class MyNoRowsComponent implements INoRowsOverlayAngularComp {

  params: any;

  agInit(params: INoRowsOverlayParams): void {
    this.params = params;
  }

  constructor(public iconRegistry: MatIconRegistry,
		public sanitizer: DomSanitizer, public service: MonitoringService) {
      iconRegistry
      .addSvgIcon('no-record', sanitizer.bypassSecurityTrustResourceUrl('assets/img/no-results.svg'))
  }

  clickImport() {
    this.service.addManually();
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void { }

}