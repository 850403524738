<div class="overflow-y">
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
            <b>{{'monitor.previouslyDeleted.dateOfDeletion' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
           {{cellData.deletedDate | date : 'dd MMM y'}}
        </div>
    </div>
    
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
            <b>{{'monitor.previouslyDeleted.deletedBy' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
           {{cellData.deletedBy}}
        </div>
    </div>
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
            <b>{{'monitor.previouslyDeleted.userId' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
           {{cellData.userId}}
        </div>
    </div>    
</div>
