import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class AppService {
	baseURL: any;
	searchURL: any;
	questnetURL: any;
	ibr360URL: any;
	
	constructor() {
		this.baseURL = environment.server_url;
		this.searchURL = environment.search_url;
		this.questnetURL = environment.questnet_url;
		this.ibr360URL = environment.ibr360_url;
	}
}
