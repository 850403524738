<app-header [title]="title"></app-header>
<main class="static">
    <section style="text-align: center;">
        <h1>{{header}}</h1>
        <p>{{subHeader}}
        <section class="cards">
            <div>
                <figure>
                    <img src="/assets/img/logo-questnet.png">
                </figure>
                <a href="{{questnetURL}}" class="pri_btn">Login via Questnet</a>
            </div>
            <div>
                <figure>
                    <img src="/assets/img/logo-ibr360.png">
                </figure>
                <a href="{{ibr360URL}}" class="pri_btn">Login via IBR360</a>
            </div>
        </section>
    </section>
</main>
<app-footer></app-footer>