import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'advanced-search',
	templateUrl: './advanced-search.component.html',
	styleUrls: ['./advanced-search.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class AdvancedSearchComponent implements OnInit {

    searchForm!: FormGroup;
    
    constructor(public matDialogRef: MatDialogRef<AdvancedSearchComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any, private formBuilder: FormBuilder) {
    }

    ngOnInit() {
        this.createAdvancedSearchForm();
    }

    createAdvancedSearchForm() {
        this.searchForm = this.formBuilder.group({
			clientRefNo: '',
			orderNo: '',
            itemNo: '',
            startDate: '',
            endDate: ''
		});
    }

    searchResults(value: any) {
    }
}