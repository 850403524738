import { Injectable } from "@angular/core";
import { Router } from '@angular/router'
import { LoaderService } from 'src/app/services/loader.service';
import { HomeService } from 'src/app/home/home.service';

const MINUTES_UNITL_AUTO_LOGOUT = 15// in mins
const CHECK_INTERVAL = 15000 // in ms
const STORE_KEY =  'lastAction';
@Injectable({ providedIn: 'root' })
export class AutoLogoutService {
 public getLastAction() {
   var key= localStorage.getItem(STORE_KEY);
    if(key != null)
        return parseInt(key);
    else
        return 0;
  }
 public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }

  constructor(private router: Router, private loaderService: LoaderService,private homeService: HomeService) {
      localStorage.setItem(STORE_KEY,Date.now().toString());
      this.initListener();
      this.initInterval();
    //  this.check();
  }

  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover',()=> this.reset());
    document.body.addEventListener('mouseout',() => this.reset());
    document.body.addEventListener('keydown',() => this.reset());
    document.body.addEventListener('keyup',() => this.reset());
    document.body.addEventListener('keypress',() => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (isTimeout)  {
    //   localStorage.clear();
    //   this.router.navigate(['./login']);
    this.loaderService.isLoading.next(true);
		this.homeService.logout().subscribe((data: any) => {
			this.loaderService.isLoading.next(false);
			localStorage.removeItem('userID');
			localStorage.removeItem('sessionID');
			localStorage.removeItem('currency');
			localStorage.removeItem('upgrade_plan_show');
			localStorage.removeItem('record_added');
			this.router.navigate(['/session-expired'], { state: { page: 'logout' } });
		});
    }
  }
}