<app-header (langChange)="changeLanguageGrid($event)" [title]="title"></app-header>
<app-toast class="position sticky" *ngIf="!isMobile"></app-toast>
<app-toast class="position-mobile sticky" *ngIf="isMobile"></app-toast>
<main class="p1">
    <section>
        <article>
            <div class="zanna_div row mt-60">
                <h1 class="M(0) Lh(1) eds-heading heading-xl-caps">
                    {{ 'global_monitor.title' | translate }}
                    <button type="button" (click)="userManualReport()" class="button button__primary userManual-btn"
                        *ngIf="!isMobile">
                        <mat-icon class="material-icon">help</mat-icon>
                        {{'home.userManual' | translate}}
                    </button>
                </h1>
            </div>
            <article>
                <div class="zanna_div row">
                    <div class="data-grid-strip">
                        <div class="end">
                            <div class="last" (click)="minimize = !minimize" *ngIf="!isMobile">
                                <i class="fa fa-sliders content-align font-14" aria-hidden="true"></i>
                                <span class="font-14">{{ 'monitor.filter' | translate }}</span>
                                <i class="fa fa-angle-down content-align font-18" aria-hidden="true"
                                    *ngIf="minimize"></i>
                                <i class="fa fa-angle-up content-align font-18" aria-hidden="true"
                                    *ngIf="!minimize"></i>
                            </div>
                        </div>
                    </div>
                    <mat-card [style.min-width.px]="'1000'" *ngIf="!minimize">
                        <div *ngIf="showTip">
                            <div class="toast sticky">
                                <div class="success-color">
                                    <mat-icon class="icon-success">
                                        info_outline
                                    </mat-icon>
                                </div>
                                <div class="text"> <strong class="tip">{{ 'monitor.action_tip' | translate }}:</strong>
                                    <div style="margin: 0px 10px 0px 6px;">You can search for entities through Entity
                                        Name. Filter by date
                                        selector to select a certain timeframe to view. To sort the list use the arrows
                                        found at the
                                        header of the table to sort the entire data table.</div>
                                </div><mat-icon class="close" (click)="hideTip()">close</mat-icon>
                            </div>
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="49" style="margin: 10px;">
                                <label>{{ 'global_monitor.searchByEntityName' | translate }}</label><br />
                                <input matInput type="text" [(ngModel)]="searchByEntityName">
                                <mat-icon matSuffix class="search-input-icon">search</mat-icon>
                            </div>
                            <div fxFlex="24" style="margin: 10px 6px;">
                                <label>{{'global_monitor.date' | translate}}</label><br />
                                <input type="text" ngxDaterangepickerMd [(ngModel)]="startDate"
                                    [showCustomRangeLabel]="true" [lockStartDate]="false" [alwaysShowCalendars]="true"
                                    [singleDatePicker]="true" [locale]="locale" [ranges]="ranges"
                                    [linkedCalendars]="false" [autoApply]="true" [showClearButton]="false"
                                    [maxDate]="maxDate" (datesUpdated)="startDateUpdated()"
                                    [placeholder]="'placeholder.from' | translate" readonly />
                                <mat-icon matSuffix class="search-input-icon ngx-daterangepicker-action"
                                    (click)="open($event)">date_range</mat-icon>
                            </div>
                            <div fxFlex="24" style="margin: 10px;">
                                <label style="padding-bottom: 14px;"></label><br />
                                <input type="text" #picker1 ngxDaterangepickerMd [(ngModel)]="endDate"
                                    [showCustomRangeLabel]="false" [alwaysShowCalendars]="true"
                                    [singleDatePicker]="true" [locale]="locale" [linkedCalendars]="false"
                                    [maxDate]="maxDate" [autoApply]="true" [showClearButton]="false"
                                    (datesUpdated)="endDateUpdated()" [placeholder]="'placeholder.to' | translate"
                                    readonly />
                                <mat-icon matSuffix
                                    class="search-input-icon open ngx-daterangepicker-action">date_range</mat-icon>
                            </div>
                        </div>
                        <div fxLayout="row">
                            <div style="margin: 10px;flex: auto;">
                                <button class="button button__primary float-right" (click)="applyFilter()"
                                    style="margin: 10px;">
                                    {{ 'bulk.upload.apply' | translate }}
                                </button>
                                <button (click)="removeFilter()" class="button btn button__basic float-right"
                                    style="margin: 10px;">
                                    {{ 'monitor.clear_filter' | translate }}
                                </button>
                            </div>
                        </div>

                    </mat-card>
                    <div class="btn-data-grid-strip border-b-n">
                        <div class="filter-container" (click)="openFilterPopup()" *ngIf="isMobile">
                            <i class="fa fa-sliders p-r-8 icon-color" aria-hidden="true"></i>
                            <span class="font-14">{{ 'monitor.filter' | translate }}</span>
                        </div>
                        <div (click)=ExpandAll(allExpand) class="arrow-container"
                            *ngIf="isMobile && alertList.length>0">
                            <mat-icon class="down-arrow-icon icon-color"
                                *ngIf="!allExpand">keyboard_arrow_down</mat-icon>
                            <mat-icon class="up-arrow-icon icon-color" *ngIf="allExpand">keyboard_arrow_up</mat-icon>
                            <span>{{expandCollapseButtonValue}}</span>
                        </div>
                    </div>
                    <ag-grid-angular class="ag-theme-alpine" [id]="gridId" [rowData]="alertList"
                        [columnDefs]="columnDefs" [masterDetail]="true" [detailRowHeight]="detailRowHeight"
                        [detailCellRenderer]="detailCellRenderer" [loadingOverlayComponent]="loadingOverlayComponent"
                        [loadingOverlayComponentParams]="loadingOverlayComponentParams"
                        (gridReady)="onGridReady($event)" [gridOptions]="gridOptions"
                        [tooltipShowDelay]="tooltipShowDelay" (filterChanged)="onFilterChanged($event)"
                        [domLayout]="domLayout" [icons]="icons">
                    </ag-grid-angular>
                    <app-pagination *ngIf="alertList.length > 0" [pageSize]="PaginationPageSize"
                        [gridOptions]="gridOptions" [noOfPages]="gridOptions.api?.paginationGetTotalPages()"
                        [totalRows]="totalRecords">
                    </app-pagination>
                </div>
            </article>
        </article>
    </section>
</main>
<app-footer></app-footer>