import { Component, HostListener, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoaderService } from '../../../services/loader.service';
import { MonitoringService } from '../../monitoring.service';
import { ErrorDialogComponent } from '../../../error-dialog-box/error-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';


@Component({
  selector: 'app-individual-pick-alert',
  templateUrl: './individual-pick-alert.component.html',
  styleUrls: ['./individual-pick-alert.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IndividualPickAlertComponent implements OnInit {
  isMobile: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth > 1000) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }
  isExpand: boolean = false;
  selectAll: any = false;
  editData: any = [];
  searchForm!: FormGroup;
  disabled: boolean = false;
  addData: any;
  masterAlerts: any;
  isRestore: any;
  //isBulk: boolean = false;
  alertsArray: any = [];
  summaryDetails: any;
  isAddChangesToEdit: boolean = false;
  isAddchangesToRestore: boolean = false;
  country: any;
  isLms: boolean = false;
  lmsAlreadyExist: boolean = false;
  isSubscriptionEqual: boolean = false;
  isLmsMid: boolean = false;
  showLmsDisclaimer: boolean = false;
  lmsCoast: any;
  infoDialogRef: MatDialogRef<InfoDialogComponent>;
  constructor(public matDialogRef: MatDialogRef<IndividualPickAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, public dialog: MatDialog,
    public monitoringService: MonitoringService, public translateService: TranslateService,
    public loaderService: LoaderService,) {
    if (data) {
      if (window.screen.width > 1000) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
      // this.editData = data.data;
      this.country = data?.country?.countryName;
      this.addData = data.isAdd;
      this.isRestore = data.isRestore;
      //this.isBulk = data.isBulk;
      // if(this.isBulk) {
      //   data.data.map((res: any) => {
      //     this.editData.push(res);
      //   });
      // } else {
      //   this.editData = data.data;
      // }
      this.editData = data.data;
      this.getAlertsData()
    };
    for (var index = 0; index < this.editData.length; index++) {
      this.editData[index].isExpand = false;
    }
  }

  ngOnInit() {
  }

  checkIsAlreadyExist() {
    this.loaderService.isLoading.next(true);
    this.monitoringService.GetEntitySubscription(this.editData?.nric_number).subscribe((data: any) => {
      var isAlreadyExist = data?.data?.response?.filter((alert: any) => alert.isSubscribed == true || alert.isSubscribed == '1');
      if (data?.data?.isDeleted) {
        this.isAddchangesToRestore = true;
        this.loaderService.isLoading.next(false);
        this.editData.availableAlerts.map((alert: any) => {
          this.masterAlerts.map((masterAlert: any) => {
            if (alert.monitoringType == masterAlert.monitoringType) {
              if (masterAlert.accessControlDetails?.restoreName == "N") {
                masterAlert.disabled = true;
              } else {
                masterAlert.disabled = false;
              }
            }
          });
        });
      } else if (isAlreadyExist.length > 0) {
        this.isAddChangesToEdit = true;
        var lmsAlreadyExist = isAlreadyExist.find((record: any) => record.monitoringType == 'LMS');
        if (lmsAlreadyExist) {
          this.loaderService.isLoading.next(false);
          this.lmsAlreadyExist = true;
          this.showLmsDisclaimer = true;
        } else {
          this.loaderService.isLoading.next(false);
          this.infoDialogRef = this.dialog.open(InfoDialogComponent, {
            disableClose: true,
            width: '30vw',
          });
          this.translateService.get(['confirmAction.alreadyExist', 'confirmAction.EntityAlreadyExist']).subscribe(value => {
            this.infoDialogRef.componentInstance.action = encodeURIComponent(value['confirmAction.alreadyExist']).replace(/%20/g, " ");
            this.infoDialogRef.componentInstance.confirmMessage = encodeURIComponent(value['confirmAction.EntityAlreadyExist']).replace(/%20/g, " ");
          });
          this.infoDialogRef.afterClosed().subscribe(res => {
            this.infoDialogRef.close(false);
          });
        }
        data?.data?.response?.map((alert: any) => {
          this.masterAlerts.map((masterAlert: any) => {
            if (alert.monitoringType == masterAlert.monitoringType) {
              if (alert.isSubscribed == '0' || alert.isSubscribed == null || alert.isSubscribed == false) {
                masterAlert.isSubscribed = false;
                if (masterAlert.monitoringType == "NBB") {
                  if (masterAlert.entitlement) {
                    masterAlert.link = "Non_Bank_Bereau_Records";
                  } else {
                    masterAlert.link = "Payment";
                  }
                }
                if (masterAlert.monitoringType == "DNSA") {
                  masterAlert.link = "DNSA";
                }
                if (masterAlert.accessControlDetails?.addName == "N") {
                  masterAlert.disabled = true;
                }
              } else if (alert.isSubscribed == '1' || alert.isSubscribed == true) {
                if (masterAlert.monitoringType == "LMS") {
                  masterAlert.link = "restricted";
                  masterAlert.isSubscribed = true;
                } else {
                  if (masterAlert.monitoringType == "NBB") {
                    if (masterAlert.entitlement) {
                      if (masterAlert.entitlement == "Basic") {
                        masterAlert.link = "Basic_Upgrade_To_Detailed"
                      }
                    } else {
                      masterAlert.link = "Payment";
                    }
                  }
                  masterAlert.isSubscribed = true;
                }
                if (masterAlert.monitoringType == "DNSA") {
                  masterAlert.link = "DNSA";
                }
                if (masterAlert.accessControlDetails?.removeName == "N") {
                  masterAlert.disabled = true;
                }
              }

            }
          })
        });
        this.checkEnabled();
      } else {
        this.masterAlerts.map((masterAlert: any) => {
          if (masterAlert.accessControlDetails?.addName == "N") {
            masterAlert.disabled = true;
          };
        })
        this.loaderService.isLoading.next(false);
      }
    }, (err: any) => {
      this.loaderService.isLoading.next(false);
    });
  }


  getAlertsData() {
    this.loaderService.isLoading.next(true);
    this.monitoringService.getAlertsSummary().subscribe((data: any) => {
      this.loaderService.isLoading.next(false);
      if (data.success) {
        this.masterAlerts = data?.data?.summaryDetails.map((record: any) => ({
          ...record,
          expanded: false,
          disabled: false
        }));
        if (this.addData) {
          this.masterAlerts = this.masterAlerts.filter((e: any) => e.monitoringType !== 'NBB' && e.monitoringType !== 'DNSA');
          this.checkIsAlreadyExist();
        } else if (this.isRestore) {
          this.masterAlerts = this.masterAlerts.filter((e: any) => e.monitoringType !== 'NBB' && e.monitoringType !== 'DNSA');
        }

        this.masterAlerts.map((res: any) => {
          if (res.totalNamesSubscribed < 1 && res.monitoringType != "NBB" && res.monitoringType != "LMS") {
            res.isSubscribed = false;
            res.link = "signUpForPlan";
          } else if (res.remainingBalance < 1 && res.monitoringType != "NBB" && res.monitoringType != "LMS") {
            res.isSubscribed = false;
            res.link = "upgradePlan";
          }
        });

        if (!this.addData) {
          this.editData.availableAlerts.map((alert: any) => {
            this.masterAlerts.map((masterAlert: any) => {
              if (alert.monitoringType == masterAlert.monitoringType) {
                if (alert.isSubscribed == '0' || alert.isSubscribed == null || alert.isSubscribed == false) {
                  masterAlert.isSubscribed = false;
                  if (masterAlert.monitoringType == "NBB") {
                    if (masterAlert.entitlement) {
                      masterAlert.link = "Non_Bank_Bereau_Records";
                    } else {
                      masterAlert.link = "Payment";
                    }
                  }
                  if (masterAlert.monitoringType == "DNSA") {
                    masterAlert.link = "DNSA";
                  }
                  if (this.isRestore) {
                    if (masterAlert.accessControlDetails?.restoreName == "N") {
                      masterAlert.disabled = true;
                    }
                  } else {
                    if (masterAlert.accessControlDetails?.addName == "N") {
                      masterAlert.disabled = true;
                    }
                  }

                } else if (alert.isSubscribed == '1' || alert.isSubscribed == true) {
                  if (masterAlert.monitoringType == "LMS") {
                    masterAlert.link = "restricted";
                    masterAlert.isSubscribed = true;
                  } else {
                    if (masterAlert.monitoringType == "NBB") {
                      if (masterAlert.entitlement) {
                        if (masterAlert.entitlement == "Basic") {
                          masterAlert.link = "Basic_Upgrade_To_Detailed"
                        }
                      } else {
                        masterAlert.link = "Payment";
                      }
                    }
                    masterAlert.isSubscribed = true;
                  }
                  if (masterAlert.monitoringType == "DNSA") {
                    masterAlert.link = "DNSA";
                  }
                  if (masterAlert.accessControlDetails?.removeName == "N") {
                    masterAlert.disabled = true;
                  }
                }

              }
            })
          })

        }

        this.checkEnabled();
      } else {
        this.loaderService.isLoading.next(false);
        this.openTryAgainAlertsPopup();
      }
    }, (err: any) => {
      this.loaderService.isLoading.next(false);

    });
  }

  onSelectAllCheck() {
    if (this.selectAll) {
      this.masterAlerts.map((alert: any) => {
        if (!alert.link && !alert.disabled && alert.monitoringType != "NBB" && alert.monitoringType != "DNSA") {
          alert.isSubscribed = true;
          if (alert.monitoringType == "LMS") {
            this.isLms = true;
          }
        }
      })
    }
    else {
      this.masterAlerts.map((alert: any) => {
        if (!alert.link && !alert.disabled && alert.monitoringType != "NBB" && alert.monitoringType != "DNSA") {
          alert.isSubscribed = false;
          if (alert.monitoringType == "LMS") {
            this.isLms = false;
          }
        }
      })
    }
    this.checkEnabled();
  }

  checkEnabled() {
    var selectDisabled = this.masterAlerts.find((obj: any) => (obj.isSubscribed === true));
    if (selectDisabled == undefined) {
      if (this.addData) {
        this.disabled = true;
      } else if (this.isRestore) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    } else {
      this.disabled = false;
    }
    this.checkIsSameSubscription();
  }

  onAlertCheck(alert: any) {
    if (!alert.isSubscribed) {
      this.selectAll = false;
      this.equalSubscription(false);
    } else {
      this.equalSubscription(true);
    }
    if (alert.isSubscribed && alert.monitoringType == "LMS") {
      this.isLms = true;
    } else if (!alert.isSubscribed && alert.monitoringType == "LMS") {
      this.isLms = false;
    }
    this.checkEnabled();
  }

  checkIsSameSubscription() {
    var filtered = this.masterAlerts.filter((e: any) => e.isSubscribed == true);
    if (filtered) {
      filtered.map((res: any) => {
        if (res.subscriptionId && !res.link)
          filtered.map((validate: any) => {
            if (res.monitoringType != validate.monitoringType) {
              if (res.subscriptionId == validate.subscriptionId)
                this.isSubscriptionEqual = true;
            }
          });
      });
    }
  }

  equalSubscription(isChecked: boolean) {
    this.masterAlerts.map((res: any) => {
      if (res.subscriptionId && !res.link)
        this.masterAlerts.map((validate: any) => {
          if (res.monitoringType != validate.monitoringType) {
            if (res.subscriptionId == validate.subscriptionId)
              res.isSubscribed = isChecked;
          }
        });
      if (this.masterAlerts.filter((e: any) => e.isSubscribed == true).length == this.masterAlerts.length) {
        this.selectAll = true;
      }
    });
  }

  onSaveClick() {
    if (this.addData) {
      if (this.isAddchangesToRestore) {
        this.restoreAlert();
        localStorage.setItem('ACTION', "RESTORE");
      } else {
        this.addAlert();
      }
    }
    else if (this.isRestore) {
      this.restoreAlert();
    }
    else {
      this.updateAlert();
    }
  }

  lmsDisclaimer() {
    if (!this.lmsCoast) {
      this.loaderService.isLoading.next(true);
      this.monitoringService.checkIsLmsMid(this.editData?.nric_number).subscribe((data: any) => {
        if (data?.data?.Message == "MID Not Found.") {
          this.isLmsMid = true;
          this.loaderService.isLoading.next(true);
          this.monitoringService.getLmsCoast().subscribe((data: any) => {
            this.loaderService.isLoading.next(false);
            this.showLmsDisclaimer = true;
            if (data.success) {
              this.lmsCoast = data.data.productPrice;
            }
          }, (err: any) => {
            this.showLmsDisclaimer = true;
            this.loaderService.isLoading.next(false);
          });
        } else {
          this.showLmsDisclaimer = true;
          this.loaderService.isLoading.next(false);
        }
      }, (err: any) => {
        this.showLmsDisclaimer = true;
        this.loaderService.isLoading.next(false);
      });
    } else {
      this.showLmsDisclaimer = true;
    }
  }

  hideDisclaimer() {
    this.showLmsDisclaimer = false;
    this.lmsAlreadyExist = false;
  }

  addAlert() {
    this.alertsArray = [];
    var data: any = {
      "EntityId": this.editData.nric_number,
      "EntityName": this.editData.name,
      "ClientFileReference": this.editData?.file ? this.editData.file : "",
      "EntityType": "I",
      "CountryCode": "SGP",
      "IsSubscriptionEqual": this.isSubscriptionEqual
    };

    this.masterAlerts.map((res: any) => {
      var add = {
        "MonitoringName": res.monitoringType,
        "MonitoringValue": res.isSubscribed
      };
      this.alertsArray.push(add);
    });
    data["MonitoringTypeList"] = this.alertsArray;
    this.loaderService.isLoading.next(true);
    if (!this.isAddChangesToEdit) {
      this.monitoringService.addMonitoredList(data).subscribe((data: any) => {
        if (typeof data == 'string' && JSON.parse(data)?.success) {
          data = JSON.parse(data);
          this.loaderService.isLoading.next(false);
          localStorage.setItem('ACTION', "ADDED");
          this.matDialogRef.close(true);
        } else if (JSON.parse(data)?.errors[0].errorMsg == "Entity number already exist") {
          this.loaderService.isLoading.next(false);
          if (this.isLms) {
            this.lmsAlreadyExist = true;
          } else {
            this.infoDialogRef = this.dialog.open(InfoDialogComponent, {
              disableClose: true,
              width: '30vw',
            });
            this.translateService.get(['confirmAction.alreadyExist', 'confirmAction.EntityAlreadyExist']).subscribe(value => {
              this.infoDialogRef.componentInstance.action = encodeURIComponent(value['confirmAction.alreadyExist']).replace(/%20/g, " ");
              this.infoDialogRef.componentInstance.confirmMessage = encodeURIComponent(value['confirmAction.EntityAlreadyExist']).replace(/%20/g, " ");
            });
            this.infoDialogRef.afterClosed().subscribe(res => {
              this.matDialogRef.close(false);
            });
          }
        }
      }, (err: any) => {
        this.loaderService.isLoading.next(false);
        this.openTryAgainPopup();
      });
    } else {
      this.monitoringService.updateMonitoredList(data).subscribe((data: any) => {
        if (typeof data == 'string' && JSON.parse(data)?.success) {
          data = JSON.parse(data);
          this.loaderService.isLoading.next(false);
          localStorage.setItem('ACTION', "EDITED");
          this.matDialogRef.close(true);
        } else {
          this.loaderService.isLoading.next(false);
          this.openTryAgainPopup();
        }
      }, (err: any) => {
        this.loaderService.isLoading.next(false);
        this.openTryAgainPopup();
      });
    }
  }

  updateAlert() {
    this.alertsArray = [];
    var data: any = {
      "EntityId": this.editData.entityId,
      "EntityName": this.editData.entityName,
      "ClientFileReference": this.editData?.clientFileReference ? this.editData.clientFileReference : "",
      "EntityType": "I",
      "CountryCode": "SGP",
      "IsSubscriptionEqual": this.isSubscriptionEqual
    };

    this.masterAlerts.map((res: any) => {
      var add = {
        "MonitoringName": res.monitoringType,
        "MonitoringValue": res.isSubscribed
      };
      this.alertsArray.push(add);
    });
    data["MonitoringTypeList"] = this.alertsArray;

    this.loaderService.isLoading.next(true);
    this.monitoringService.updateMonitoredList(data).subscribe((data: any) => {
      if (typeof data == 'string' && JSON.parse(data)?.success) {
        data = JSON.parse(data);
        this.loaderService.isLoading.next(false);
        this.matDialogRef.close(this.masterAlerts);
      } else {
        this.loaderService.isLoading.next(false);
        this.openTryAgainPopup();
      }
    }, (err: any) => {
      this.loaderService.isLoading.next(false);
      this.openTryAgainPopup();
    });
  }

  restoreAlert() {
    this.alertsArray = [];
    var data: any = {
      "EntityId": this.editData.entityId,
      "EntityName": this.editData.entityName,
      "ClientFileReference": this.editData?.clientFileReference ? this.editData.clientFileReference : "",
      "EntityType": "I",
      "CountryCode": "SGP",
      "IsSubscriptionEqual": this.isSubscriptionEqual
    };

    this.masterAlerts.map((res: any) => {
      var add = {
        "MonitoringName": res.monitoringType,
        "MonitoringValue": res.isSubscribed ? res.isSubscribed : false
      };
      this.alertsArray.push(add);
    });
    data["MonitoringTypeList"] = this.alertsArray;

    this.loaderService.isLoading.next(true);
    this.monitoringService.restoreEntity(data).subscribe((data: any) => {
      if (typeof data == 'string' && JSON.parse(data)?.success) {
        data = JSON.parse(data);
        this.loaderService.isLoading.next(false);
        this.matDialogRef.close(this.editData);
      } else {
        this.loaderService.isLoading.next(false);
        this.openTryAgainPopup();
      }
    }, (err: any) => {
      this.loaderService.isLoading.next(false);
      this.openTryAgainPopup();
    });
  }

  openTryAgainAlertsPopup() {
    this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
      let dialogRef = this.dialog.open(ErrorDialogComponent, {
        disableClose: true,
        width: this.isMobile ? 'auto' : '37vw',
        data: {
          errorMessage: value['errorMsg.pleaseTryAgain']
        }
      });
      dialogRef.afterClosed().subscribe((res: any) => {
        if (res) {
          this.getAlertsData();
        }
      });
    })
  }

  openTryAgainPopup() {
    this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
      let dialogRef = this.dialog.open(ErrorDialogComponent, {
        disableClose: true,
        width: this.isMobile ? 'auto' : '37vw',
        data: {
          errorMessage: value['errorMsg.pleaseTryAgain']
        }
      });
      dialogRef.afterClosed().subscribe((res: any) => {
        if (res) {
          this.onSaveClick();
        }
      });
    })
  }
  expand(index: any) {
    this.editData[index].isExpand = !this.editData[index].isExpand;
  }
  expandNotBulk() {
    this.isExpand = !this.isExpand;
  }
}
