import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { NotificationService } from "../../monitoring/shared-module/toast/toast.service";
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MonitoringService } from '../../monitoring/monitoring.service';
import { LoaderService } from 'src/app/services/loader.service';
import { GridApi } from 'ag-grid-community';
import 'ag-grid-enterprise';
import { ErrorDialogComponent } from 'src/app/error-dialog-box/error-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { GlobalMonitoringService } from '../global-monitoring.service';


@Component({
	selector: 'app-global-monitoring-alert',
	templateUrl: './global-monitoring-alert.component.html',
	styleUrls: ['./global-monitoring-alert.component.scss']
})
export class GlobalMonitoringAlertComponent implements OnInit {
	isMobile: boolean = false;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if (event.target.innerWidth > 1000) {
			this.isMobile = false;

		} else {
			this.isMobile = true;

		}
	}
	@Output() tabsEvent = new EventEmitter();
	changeLanEvent: Subject<void> = new Subject<void>();
	columnDefs: any = [];
	title: any = "Global Monitoring";
	btnDisabled: boolean = false;
	newDefendantGridOptions: any;
	newDefendantGridColumnApi: any;
	newDefendantApi: any;

	domLayout: any = "autoHeight";
	alartList: any;

	CMSNewDefendant: any;

	CMSNewDefendantHeight: any = 200;

	newDefendantList: any = [];

	itemId: any;
	NegativeBlackList: any = "New Records found in Negative / Blacklist";
	paymentAlerts: any;
	paymentAlertsCount: any = 0;
	isBasic: boolean = false;
	allExpand: boolean = true;
	allPlantiffExpand: boolean = true;
	allHearingDefendantExpand: boolean = true;
	allHearingPlantiffExpand: boolean = true;
	expandCollapseButtonValue: String = "Collapse all rows"
	expandCollapsePlantiffButtonValue: String = "Collapse all rows"
	expandCollapseHearingDefendantButtonValue: String = "Collapse all rows"
	expandCollapseHearingPlantiffButtonValue: String = "Collapse all rows"

	count: any = 0;
	currentDate: any;

	startDate: any;
	endDate: any;

	demoData: any = [];
	Data: any = [];

	public detailCellRenderer: any;

	constructor(
		public _notificationservice: NotificationService,
		public router: ActivatedRoute,
		public iconRegistry: MatIconRegistry,
		public sanitizer: DomSanitizer,
		private dialog: MatDialog,
		public monitoringService: MonitoringService,
		public translateService: TranslateService,
		public loaderService: LoaderService,
		private http: HttpClient,
		private globalService: GlobalMonitoringService,
	) {
		this.loaderService.isLoading.next(false);
		if (window.screen.width > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
		this.itemId = localStorage.getItem('itemId');
		this.startDate = localStorage.getItem('dateFrom');
		this.endDate = localStorage.getItem('dateTo');
		this.loadRecords();

		this.router.queryParams.subscribe((route) => {
			if (route.recordSlide == 'true') {

			}
		});


		window.scroll(0, 0);
		this.currentDate = moment(new Date()).format('DD/MM/YYYY');
		this.monitoringService.setSid(localStorage.getItem('sessionID'));
	}

	onPrint() {
		window.print();
	}
	changeLanguageGrid(ev: any) {
	}

	getSubSections(section: any) {
		return section.SubSection;
	}




	ngOnInit(): void { }

	ngAfterViewInit(): void {
		// this.gridAPI.resetRowHeights();
	}

	loadRecords() {
		this.loaderService.isLoading.next(true);
		this.globalService.getGlobalMonitoringAlerts(this.itemId).subscribe((data: any) => {
			if (data != null) {
				this.loaderService.isLoading.next(false);
				this.Data = data.Data.Sections;
				this.count = this.Data.length;
			} else {
				this.loaderService.isLoading.next(false);
				this.Data = [];
				this.openTryAgainPopup();
			}
		},
			(err: any) => {
				this.Data = [];
				this.openTryAgainPopup();
			}
		);

	}


	openTryAgainPopup() {
		this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
			let dialogRef = this.dialog.open(ErrorDialogComponent, {
				disableClose: true,
				width: this.isMobile ? 'auto' : '37vw',
				data: {
					errorMessage: value['errorMsg.pleaseTryAgain']
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					this.loadRecords();
				}
			});
		})
	}

	serialNumberGetter(params: any) {
		return 1;
	}


	dateComparator(date11: any, date22: any) {
		var date1: any = moment(date11).format('DD/MM/YYYY');
		var date2: any = moment(date22).format('DD/MM/YYYY');
		var result1: any;
		var result2: any;
		if (date1 === undefined || date1 === null || date1.length !== 10) {
			result1 = null;
		} else {
			var yearNumber1: any = date1.substring(6, 10);
			var monthNumber1: any = date1.substring(3, 5);
			var dayNumber1: any = date1.substring(0, 2);
			result1 = yearNumber1 * 10000 + monthNumber1 * 100 + dayNumber1;
		}

		if (date2 === undefined || date2 === null || date2.length !== 10) {
			result2 = null;
		} else {
			var yearNumber2: any = date2.substring(6, 10);
			var monthNumber2: any = date2.substring(3, 5);
			var dayNumber2: any = date2.substring(0, 2);
			result2 = yearNumber2 * 10000 + monthNumber2 * 100 + dayNumber2;
		}
		if (result1 === null && result2 === null) {
			return 0;
		}
		if (result1 === null) {
			return -1;
		}
		if (result2 === null) {
			return 1;
		}
		return result1 - result2;
	}


	get newDefendantGridAPI(): GridApi {
		return this.newDefendantGridOptions.api;
	}

	dateGetter(params: any) {
		return moment(params.data.AlertDate).format('DD MMM YYYY');
	}

	onFirstDataRendered1(params: any) {
		this.newDefendantGridOptions.api.forEachNode((node: any) => {
			node.expanded = true;
		});
	}

	ExpandAll(data: any) {
		// this.btnDisabled = true;
		if (data) {
			this.newDefendantGridOptions.api.forEachNode((node: any) => {
				node.data.expanded = false;
				node.expanded = false;
			});

			this.allExpand = false;
			this.expandCollapseButtonValue = "Expand all rows"
		} else {
			this.newDefendantGridOptions.api.forEachNode((node: any) => {
				node.data.expanded = true;
				node.expanded = true;
			});

			this.allExpand = true;
			this.expandCollapseButtonValue = "Collapse all rows"
		}
		this.newDefendantGridOptions.api.onGroupExpandedOrCollapsed();
	}
}
