import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { PagerService } from '../../../services/pager.service';

@Component({
	selector: 'app-monitoring-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.scss'],
	providers: [PagerService]
})

export class MonitoredNamesPaginationComponent implements OnChanges {
	isMobile: boolean = false;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if(event.target.innerWidth > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
	}
	@Input() pageSize = 0;
	@Input() gridOptions: any;
	@Input() noOfPages = 0;
	private paginationPages: any = {};
	@Input() totalRows: any;
	@Input() hideNextRec: boolean = false;
	@Input() showing: any;
	@Input() avalilableResults: any;
	@Output() loadNext = new EventEmitter<any>();
	@Output() loadPrev = new EventEmitter<any>();
	selected: number = 10;
	get PaginationPages() {
		return this.paginationPages;
	}

	get currentPage(): number {
		return this.gridOptions ? this.gridOptions.api?.paginationGetCurrentPage() : 0;
	}

	get totalPages(): number {
		return this.noOfPages;
	}

	constructor(private pagerService: PagerService) {
		if(window.screen.width > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
		this.gridOptions = <GridOptions>{
		};
	}

	ngOnChanges(changes: SimpleChanges) {
		for (const propName in changes) {
			if (propName === 'noOfPages') {
				this.paginationPages = this.noOfPages ? this.pagerService.getPager(this.noOfPages, 1) : {};
			} else if(propName === 'showing'){
				this.goToPage(this.showing.loadPage);
			}
		}
		this.selected = this.gridOptions.api?.paginationGetPageSize();
	}

	changeRowPage(ev: any) {
		//this.selected = ev.target.value;
		this.gridOptions.api?.paginationSetPageSize(Number(ev.target.value));
	}

	goToPage(index: number) {
		this.gridOptions.api?.paginationGoToPage(index);
		this.paginationPages = this.pagerService.getPager(this.noOfPages, index + 1);
	}

	loadNextRecords() {
		if(this.showing.to != this.totalRows) {
			this.loadNext.emit();
		}
	}

	loadPreviousRecords() {
		if(this.showing.from != 1) {
			this.loadPrev.emit();
		}
	}

	goToNext() {
		this.gridOptions.api?.paginationGoToNextPage();
		this.paginationPages = this.pagerService.getPager(this.noOfPages, this.currentPage);
	}

	goToPrevious() {
		this.gridOptions.api.paginationGoToPreviousPage();
		this.paginationPages = this.pagerService.getPager(this.noOfPages, this.currentPage);
	}
	scrollUp() {
		window.scroll(0,0);
	}
}
