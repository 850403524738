<span id="print-section" >
<app-header (langChange)="changeLanguageGrid($event)" [title]="title"></app-header>
<app-toast class="position"></app-toast>
<main class="p1">
    <section>
        <article>
            <div class="zanna_div row mt-60">
                <h1 class="M(0) Lh(1) eds-heading heading-xl-caps print">
                    {{ 'monitor.bws.title' | translate }}
                    <button type="button" class="button button__primary userManual-btn" [useExistingCss]="true" printSectionId="print-section" ngxPrint *ngIf="!isMobile">
                        <mat-icon class="material-icon icon-color">printer</mat-icon>
                        &nbsp;{{ 'monitor.bws.print_page' | translate }}
                    </button>
                    <button type="button" class="button button__primary" [ngClass]="((alartList==null || alartList.length == 0) && (otherInfo == null || otherInfo.length == 0) ) ? 'disabled' : 'userManual-btn'"  (click)="exportAsExcelFile()" *ngIf="!isMobile">
                        <mat-icon class="material-icon icon-color">get_app</mat-icon>
                        &nbsp;{{ 'monitor.bws.export' | translate }}
                    </button>
                </h1>
                <p class="p-subTitle">
                    {{ 'monitor.cms.subtitle' | translate }} {{ count }} {{ 'monitor.bws.heading' | translate }} {{ currentDate }}.
                </p>  
            </div>             
            <div class="expand-btn-area" *ngIf="isMobile">
                <button type="button" class="expand-btn btn btn-primary" (click)="ExpandAll(allExpand)" [disabled]="btnDisabled">
                    <mat-icon *ngIf="!allExpand">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="allExpand">keyboard_arrow_up</mat-icon>
                    {{expandCollapseButtonValue}}
                </button>
            </div> 
            <div class="zanna_div row" >
                <div class="data-grid-strip">
                    <span style="color: #fff !important;">
                        {{ 'monitor.bws.bws_alerts' | translate }}
                    </span>        
                </div>
                <ag-grid-angular *ngIf="!isMobile" class="ag-theme-alpine" id="alartsGrid" [rowData]="alartList" [columnDefs]="columnDefs" [animateRows]="true"
                    (gridReady)="onGridReady($event)" [gridOptions]="gridOptions" 
                    [domLayout]="domLayout">
                </ag-grid-angular>
                <ag-grid-angular *ngIf="isMobile" class="ag-theme-alpine" id="alartsGrid" [rowData]="alartList" [columnDefs]="columnDefs" [animateRows]="true"
                    (gridReady)="onGridReady($event)" [masterDetail]="true" [detailRowHeight]="bwsGridDetailHeight" [detailCellRenderer]="gridDetailCellRenderer" [gridOptions]="gridOptions" 
                    [domLayout]="domLayout" (firstDataRendered)="onMobileDataRendered($event)">
                </ag-grid-angular>
            </div>
            <div class="zanna_div row" *ngIf="otherInfo && otherInfo.length > 0">
                <div class="data-grid-strip">
                    <span style="color: #fff !important;">
                        {{ 'monitor.bws.other_info' | translate }}
                    </span>        
                </div>
                <ag-grid-angular class="ag-theme-alpine" id="alartsGrid" [rowData]="otherInfo" [columnDefs]="bwsGazzetedcolumnDefs" [animateRows]="true"
                    (gridReady)="onGridReady($event)" [masterDetail]="true" [gridOptions]="bwsGazzetedGridOptions" [detailRowHeight]="detailRowHeight" [detailCellRenderer]="detailCellRenderer"
                    (gridReady)="onGridReadyBwsGaz($event)" (firstDataRendered)="onFirstDataRendered($event)" [domLayout]="domLayout">
                </ag-grid-angular>
            </div>
            <div class="zanna_div row" *ngIf="!entityId && summary && alartList?.length > 0">
                <div class="data-grid-strip">
                    <span style="color: #fff !important;">
                        {{'cart.summary' | translate}}
                    </span>        
                </div>
                
                <mat-card style="padding: 20px;background: white; border-bottom: 0.5px solid #b7b1b1;" *ngIf="!isMobile">
                    <div fxLayout="col">
                        <div fxFlex="30">
                            <div style="padding: 10px;"> {{ 'monitor.bws.total_names_subscribed' | translate }} </div>
                        </div>
                        <div fxFlex="4"></div>
                        <div fxFlex="60">
                            <div style="padding: 10px;"> {{ summary?.totalNamesSubscribed}}</div>
                        </div>
                    </div>

                    <div fxLayout="col">
                        <div fxFlex="30">
                            <div style="padding: 10px;"> {{ 'monitor.bws.number_of_names_subscribed' | translate }} </div>
                        </div>
                        <div fxFlex="4"></div>
                        <div fxFlex="60">
                            <div style="padding: 10px;"> {{ summary?.monitoredNames}}</div>
                        </div>
                    </div>

                    <div fxLayout="col">
                        <div fxFlex="30">
                            <div style="padding: 10px;"> {{ 'monitor.bws.remaining_Balance' | translate }}</div>
                        </div>
                        <div fxFlex="4"></div>
                        <div fxFlex="60">
                            <div style="padding: 10px;"> {{ summary?.remainingBalance}} </div>
                        </div>
                    </div>

                    <div fxLayout="col">
                        <div fxFlex="30">
                            <div style="padding: 10px;">{{ 'monitor.bws.revision_name' | translate }}</div>
                        </div>
                        <div fxFlex="4"></div>
                        <div fxFlex="60">
                            <div style="padding: 10px;"> {{ summary?.lastRevisionOfNames | date: 'dd/MM/yyyy' }} </div>
                        </div>
                    </div>

                    <div fxLayout="col">
                        <div fxFlex="30">
                            <div style="padding: 10px;"> {{ 'monitor.bws.sub_info' | translate }}  </div>
                        </div>
                        <div fxFlex="4"></div>
                        <div fxFlex="60">
                            <div style="padding: 10px;"> </div>
                        </div>
                    </div>
                </mat-card>
                <mat-card class="fs-2" style="padding: 20px;background: white;border-bottom: 0.5px solid #b7b1b1;" *ngIf="isMobile">
                    <div fxLayout="col">
                        <div fxFlex="80">
                            <div style="padding: 10px;"> {{ 'monitor.bws.total_names_subscribed' | translate }} </div>
                        </div>                        
                        <div fxFlex="20">
                            <div style="padding: 10px;text-align: right;"> {{ summary?.totalNamesSubscribed}}</div>
                        </div>
                    </div>

                    <div fxLayout="col">
                        <div fxFlex="80">
                            <div style="padding: 10px;"> {{ 'monitor.bws.number_of_names_subscribed' | translate }} </div>
                        </div>                        
                        <div fxFlex="20">
                            <div style="padding: 10px;text-align: right;"> {{ summary?.monitoredNames}}</div>
                        </div>
                    </div>

                    <div fxLayout="col">
                        <div fxFlex="80">
                            <div style="padding: 10px;"> {{ 'monitor.bws.remaining_Balance' | translate }}</div>
                        </div>                        
                        <div fxFlex="20">
                            <div style="padding: 10px;text-align: right;"> {{ summary?.remainingBalance}} </div>
                        </div>
                    </div>

                    <div fxLayout="col">
                        <div fxFlex="80">
                            <div style="padding: 10px;">{{ 'monitor.bws.revision_name' | translate }}</div>
                        </div>                        
                        <div fxFlex="20">
                            <div style="padding: 10px;text-align: right;"> {{ summary?.lastRevisionOfNames | date: 'dd/MM/yyyy' }} </div>
                        </div>
                    </div>

                    <div fxLayout="col">
                        <div fxFlex="80">
                            <div style="padding: 10px;"> {{ 'monitor.bws.sub_info' | translate }}  </div>
                        </div>                        
                        <div fxFlex="20">
                            <div style="padding: 10px;text-align: right;"> </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </article>
    </section>
</main>
</span>

<!-- Basic Export template -->
<div style="display: none;">   
    <table id="excel-table">
        <tr>
            <th> Entity Number </th>
            <th> Entity Name </th>
            <th> Client File Ref. </th>
            <th> Entity Type </th>
            <th> BWS Alert Triggers </th>
            <th> Date of Alert </th>
        </tr>
        <tr *ngFor="let item of exportList">
            <td> {{ item.EntityNumber }} </td>
            <td> {{ item.EntityName }} </td>
            <td> {{ item.ClientFileReference }} </td>
            <td> <span *ngIf="item.EntityType == 'C'"> Company</span>  <span *ngIf="item.EntityType == 'I'"> Individual</span> </td>
            <td> {{ item.BwsAlertTrigger }} </td>
            <td> {{ item.DateOfAlert | date: 'dd/MM/yyyy'}} </td>
        </tr>
    </table>
  </div>

  <!-- Export Other Information Template -->

  <div style="display: none;">   
    <table id="other-info-excel-table">
        <tr>
            <th> Entity Number </th>
            <th> Entity Name </th>
            <th> Client File Ref. </th>
            <th> Entity Type </th>
            <th> Subject </th>
            <th> Date (last day of receiving proofs) </th>
            <th> Location of Meeting </th>
            <th> Time of Meeting </th>
            <th> Amount Per Centum </th>
            <th> First and Final or Otherwise </th>
            <th> When Payable </th>
            <th> Where Payable </th>
            <th> Occurence Date </th>
            <th> Status </th>
            <th> Suit No </th>
            <th> Name of Judicial Manager / Liquidators </th>
            <th> Address of Judicial Manager / Liquidators </th>
            <th> Date of Notice </th>
            <th> Change Date </th>
        </tr>
        <tr *ngFor="let item of exportOtherInfoList">
            <td> {{ item.EntityNumber }} </td>
            <td> {{ item.EntityName }} </td>
            <td> {{ item.ClientFileReference }} </td>
            <td> <span *ngIf="item.EntityType == 'C'"> Company</span>  <span *ngIf="item.EntityType == 'I'"> Individual</span> </td>
            <td> {{ item.Subject }} </td>
            <td> {{ item.ProofDate }} </td>
            <td> {{ item.MeetingLocation }} </td>
            <td> {{ item.MeetingTime }} </td>
            <td> {{ item.AmountPerCentum }} </td>
            <td> {{ item.FirstFinal }} </td>
            <td> {{ item.PayableDate }} </td>
            <td> {{ item.PayableLocation }} </td>
            <td> {{ item.OccurrenceDate }} </td>
            <td> {{ item.Status }} </td>
            <td> {{ item.SuitNo }} </td>
            <td> {{ item.LiquidatorName }} </td>
            <td> {{ item.LiquidatorAddress }} </td>
            <td> {{ item.NoticeDate }} </td>
            <td> {{ item.DateOfAlert | date: 'dd/MM/yyyy'}} </td>
        </tr>
    </table>
  </div>

  <!-- SUMMARY EXPORT TEMPLATE -->

  <div style="display: none;" *ngIf="summary">   
    <table id="summary-excel-table">
        <tr>
            <th>Total No of Names Subscribed</th>
            <th>No of Names Monitored</th>
            <th>Remaining Balance</th>
            <th>Last Revision of Names</th>
            <th>Info Date</th>
        </tr>
        <tr>
            <td> {{ summary?.totalNamesSubscribed}} </td>
            <td> {{ summary?.monitoredNames}} </td>
            <td> {{ summary?.remainingBalance}} </td>
            <td> {{ summary?.lastRevisionOfNames | date: 'dd/MM/yyyy' }} </td>
            <td> {{ summary?.infoDate | date: 'dd/MM/yyyy' }} </td> 
        </tr>
    </table>
  </div>
<app-footer></app-footer>