import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'app-restore-grid-data-renderer',
  templateUrl: './restore-grid-data.component.html',
  styleUrls: ['./restore-grid-data.component.scss']
})
export class RestoreDataCellRenderer implements ICellRendererAngularComp {
  public cellData: any;
  agInit(params: any): void {
    this.cellData = params.data;
    console.log("celldata",this.cellData)
  }

  refresh(params: any): boolean {
    return false;
  }
}