import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-document-view',
	templateUrl: './document-view.component.html',
	styleUrls: ['./document-view.component.scss']
})

export class DocumentViewComponent implements OnInit {

	constructor(public dialogRef: MatDialogRef<DocumentViewComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) { }

	ngOnInit(): void {
		this.loadDocument();
	}

	async loadDocument() {
		if (this.data?.format == 'HTML') {
			var target: any = document.getElementById('blob-src-test');
			target.src = (window.URL || window.webkitURL).createObjectURL(new Blob([this.data.response], { type: 'text/html' }));
		}
		if (this.data?.format == 'PDF') {
			var target: any = document.getElementById('blob-src-test');
			target.src = (window.URL || window.webkitURL).createObjectURL(new Blob([this.data.response], { type: 'application/pdf' }))
		}
	}

	async downloadReport() {
		const blob = new Blob([this.data.response], { type: "text/html" });
		let url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.setAttribute('download', 'Report.html');
		a.setAttribute('href', url);
		a.click();
	}
}
