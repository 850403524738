<!-- <p class="rows-display" *ngIf="!isMobile">Results -->
	<!-- <b>{{avalilableResults}}</b>  /-->
	<!-- <b>{{totalRows}}</b>  {{ 'pagination.rows' | translate }}
</p> -->
<div class="rows-display" *ngIf="!isMobile"> 
	<div>
		Results: {{avalilableResults}}<span *ngIf="!hideNextRec">/{{totalRows}}</span> rows loaded
	</div>
	<div *ngIf="!hideNextRec" (click)="loadNextRecords()" [ngClass]="showing.to === totalRows ? 'load-next-record-disableArrow' : 'load-next-record'">
		<mat-icon> arrow_forward</mat-icon>
		<span>Load next 1000 rows</span>
	</div>	
</div>
<!-- <div class="rows-display"><input type="text" width="50px"></div> -->
<div class="page-container table-pagination eds-pagination" id="pagination" *ngIf="PaginationPages">
	<!-- <div class="eds-tab tab-compact" (click)="loadPreviousRecords()">
		<a href="javascript:void(0)" data-first=""
			[ngClass]="showing.from === 1 ? 'tab-content eds-link disableArrow' : 'tab-content eds-link'">
			<i class="tab-icon material-icons">first_page</i>
		</a>
	</div> -->
	<div class="rows-display-mobile" *ngIf="isMobile"> 
		<div class="result-section">
			<div>Results: {{avalilableResults}} <span *ngIf="!hideNextRec">/{{totalRows}}</span> rows loaded</div>
		</div>
		<div *ngIf="!hideNextRec" (click)="loadNextRecords()" [ngClass]="showing.to === totalRows ? 'load-next-record-disableArrow' : 'load-next-record-mobile'">
			<mat-icon> refresh</mat-icon>
			<span>Load next 1000 rows</span>
		</div>	
	</div>
	<div class="eds-tab tab-compact" *ngIf="!isMobile">
		<a (click)="goToPage(0)" data-previous=""
			[ngClass]="currentPage === 0 ? 'tab-content eds-link disableArrow' : 'tab-content eds-link'">	
			<i class="tab-icon material-icons">first_page</i>	
			<span style="margin-left: 4px;">Go to Page 1</span>		
		</a>
	</div>
	<div class="eds-tab tab-compact" *ngIf="isMobile">
		<a (click)="goToPage(0)" data-previous=""
			[ngClass]="currentPage === 0 ? 'tab-content eds-link disableArrow' : 'tab-content eds-link'">
			<i class="tab-icon material-icons">first_page</i>
		</a>
	</div>
	<div class="eds-tab tab-compact" *ngIf="isMobile">
		<a (click)="goToPrevious()" data-previous="" [ngClass]="currentPage === 0 ? 'tab-content eds-link disableArrow' : 'tab-content eds-link'">	
			<i class="tab-icon material-icons">chevron_left</i>
		</a>
	</div>
	<div class="mobile-page-section" *ngIf="isMobile">
		<div class="starting-page">
			<a (click)="goToPage(0)" data-previous="" [ngClass]="currentPage === 0 ? 'tab-content eds-link disableArrow' : 'tab-content eds-link'">
				<span>Go to Page 1</span>
			</a>
		</div>
		<div class="last-page">
			<a (click)="goToPage(PaginationPages?.totalPages-1)" data-next="" [ngClass]="currentPage === PaginationPages?.totalPages-1 ? 'tab-content eds-link disableArrow' : 'tab-content eds-link'">
				<span>Go to Page  {{PaginationPages?.totalPages}}</span>
			</a>
		</div>
	</div>
	<div *ngIf="!isMobile">
		<span *ngFor="let page of PaginationPages?.pages">
			<div [ngClass]="currentPage === page-1 ? 'eds-tab tab-compact tab-selected' : 'eds-tab tab-compact'">
				<a data-page="0" (click)="goToPage(page-1)" class="tab-content eds-link  link-selected">
					{{page}}
				</a>
			</div>
		</span>
	</div>
	<div *ngIf="isMobile">
		<span>
			<div class="eds-tab tab-compact tab-selected">
				<a data-page="0" (click)="goToNext()" class="tab-content eds-link  link-selected">
					{{currentPage+1}}
				</a>
			</div>
		</span>
	</div>

	<div class="eds-tab tab-compact" *ngIf="!isMobile && (PaginationPages?.totalPages - PaginationPages?.startPage) > 5">
		<a data-page="0" class="tab-content eds-link dot-section" (click)="goToPage(PaginationPages?.startPage + 5)">
			...
		</a>
	</div>

	<div class="eds-tab tab-compact" *ngIf="!isMobile && (PaginationPages?.totalPages - PaginationPages?.startPage) > 5">
		<a data-page="0" class="tab-content eds-link" (click)="goToPage(PaginationPages?.totalPages-1)">
			{{PaginationPages?.totalPages}}
		</a>
	</div>
	<div class="eds-tab tab-compact" id="afterPages" *ngIf="isMobile">
		<a (click)="goToNext()" data-next=""
			[ngClass]="currentPage === PaginationPages?.totalPages-1 ? 'tab-content eds-link disableArrow' : 'tab-content eds-link'">
			<i class="tab-icon material-icons">chevron_right</i>
		</a>
	</div>
	<div class="eds-tab tab-compact" id="afterPages" *ngIf="!isMobile">
		<a (click)="goToPage(PaginationPages?.totalPages-1)" data-next=""
			[ngClass]="currentPage === PaginationPages?.totalPages-1 ? 'tab-content eds-link disableArrow' : 'tab-content eds-link'">
			<span style="margin-right: 4px;">Go to Page  {{PaginationPages?.totalPages}}</span>
			<i class="tab-icon material-icons">last_page</i>
		</a>
	</div>
	<div class="eds-tab tab-compact" id="afterPages" *ngIf="isMobile">
		<a (click)="goToPage(PaginationPages?.totalPages-1)" data-next=""
			[ngClass]="currentPage === PaginationPages?.totalPages-1 ? 'tab-content eds-link disableArrow' : 'tab-content eds-link'">
			<i class="tab-icon material-icons">last_page</i>
		</a>
	</div>

	<!-- <div class="eds-tab tab-compact" *ngIf="showing.to != totalRows" title="Load Next Records">
		<a (click)="loadNextRecords()" data-last=""
			[ngClass]="showing.to === totalRows ? 'tab-content eds-link disableArrow' : 'tab-content eds-link'">
			<i class="tab-icon material-icons">last_page</i>
		</a>
	</div> -->

	<!-- &nbsp;&nbsp;<span> Jump to <input type="text" class="input-layout" (keyup)="gotoPage($event)" [(ngModel)]="goto"> </span>&nbsp;&nbsp; -->
	<div class="pagesize-label" *ngIf="!isMobile">{{ 'pagination.rowsPerPage' | translate }}</div>
	
	<div class="pagesize-select eds-input">
		<select class="input-element input-dropdown" id="pageSizeSelect" [(ngModel)]="selected" (change)="changeRowPage($event)">
			<option value="10">10</option>
			<option value="25">25</option>
			<option value="50">50</option>
			<option value="100">100</option>
		</select>
	</div>
	<div class="range" id="range"></div>
</div>
<div class="scroll-up" *ngIf="!isMobile">
	<button class="scroll-up__button" (click)="scrollUp()"><mat-icon class="scroll-up__button__icon">arrow_drop_up</mat-icon></button>
</div>

