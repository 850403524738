import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { interval, Subscription } from 'rxjs';
import { ErrorDialogComponent } from 'src/app/error-dialog-box/error-dialog.component';
import { LoaderService } from 'src/app/services/loader.service';
import { FileHandle } from './dragDrop.directive';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MonitoringService } from '../monitoring.service';

declare const $: any;

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MonitoringBulkUploadComponent implements OnInit {
  fileToUpload: File;
  fileUploaded: string = 'step1';
  time: number = 1;
  fileName: string = "";
  showTip: boolean = true;
  bulkAlertUploadNumber: any;
  minutes: number = 0;
  loader: any;
  bulkUploadStatus: any;
  cancelUploading: boolean = false;
  loaded: number = 10;
  mySubscription: Subscription;
  alertList: any;
  alertType: any;
  lmsCoast: any = 0;
  validateSubscription$: Subscription;
  files: FileHandle[] = [];
  validatedBulkUploadeDeta:any={};
  constructor(public matDialogRef: MatDialogRef<MonitoringBulkUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, public dialog: MatDialog,
    public uploadService: MonitoringService, public translateService: TranslateService,
    public loaderService: LoaderService, public iconRegistry: MatIconRegistry,
		public sanitizer: DomSanitizer) {
      iconRegistry
		  .addSvgIcon('reupload', sanitizer.bypassSecurityTrustResourceUrl('assets/img/reupload.svg'))
      .addSvgIcon('bulk-upload', sanitizer.bypassSecurityTrustResourceUrl('assets/img/bulk-upload.svg'));
      this.alertList = data?.filter((alert: any) => alert?.accessControlDetails?.batchUpload != "N");
      if(data) {
        this.alertType = data[0]?.monitoringType;
      } 
  }

  ngOnInit() {
   
  }

  filesDropped(files: FileHandle[]): void {
    this.files = files;
    this.fileUploaded = 'step2';
    this.matDialogRef.updateSize('600px', '300px'); 
		this.time =1;
    this.loaded = 20;
    this.fileName = files[0].file.name;
    this.fileToUpload = files[0].file;
    this.uploadFiles();
    setTimeout(() => { 
      this.loaded = 50;
    }, 4000);
  }

  csvInputChange(fileInputEvent: any) {
    this.fileUploaded = 'step2';
    this.matDialogRef.updateSize('600px', 'auto');
		setTimeout(() => {  
		  this.time =1;
      this.loaded = 40;
      this.fileToUpload = fileInputEvent.target.files[0];
      this.fileName = fileInputEvent.target.files[0].name;
      this.uploadFiles();
		}, 4000);    
  }

  cancelFileValidation() {
    this.cancelUploading = true;
    this.matDialogRef.close(false)
  }

  uploadFiles() {  
    if(this.alertType !="LMS") {
      this.lmsCoast = null;
    }
    this.validateSubscription$ = this.uploadService.upload(this.fileToUpload, this.alertType).subscribe(
      (res: any) => {
        if(res.success && res.data.isValidFile){
          if(this.cancelUploading) {
            this.bulkAlertUploadNumber =  res.data?.bulkAlertUploadNumber;
            this.cancelBulkOrder();
          } else if(res?.data?.summary?.bwsCapacityExceed || res?.data?.summary?.cmsCapacityExceed) {
            this.matDialogRef.updateSize('600px', 'auto');
            this.fileUploaded = "Capacity_Exceed";
            this.validatedBulkUploadeDeta=res.data?.summary;
            this.bulkAlertUploadNumber =  res.data?.bulkAlertUploadNumber;
          } else if(res?.data?.isLimitExceed) {
            this.fileUploaded = 'Exceed';
          } else {
            this.validatedBulkUploadeDeta=res.data?.summary;
            this.bulkAlertUploadNumber =  res.data?.bulkAlertUploadNumber;
            this.fileUploaded = 'step3'; 
            if(this.alertType =="LMS") {
              this.matDialogRef.updateSize('600px', 'auto');
              this.loaderService.isLoading.next(true);
              this.uploadService.getLmsCoast().subscribe((data: any) => {
                this.loaderService.isLoading.next(false);
                if(data.success) {
                  this.lmsCoast = data.data.productPrice;
                }
              }, (err: any) => {
                this.loaderService.isLoading.next(false);
              });
            } if(this.lmsCoast) {
              this.matDialogRef.updateSize('600px', 'auto');
            }
          }
        } else{
          if(this.cancelUploading) {
            this.matDialogRef.close(false);
          } else if(res?.data?.isLimitExceed) {
            this.fileUploaded = 'Exceed';
          } else {
            this.fileUploaded = 'invalid';
          }
          
        }
      },
      (err: any) => {
        this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
          let dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '37vw',
            data: {
              errorMessage: value['errorMsg.pleaseTryAgain']
            }
          });
          dialogRef.afterClosed().subscribe(res => {
            if (res) {
              this.uploadFiles();
            }
          });
        });	
      }
    );    
  }

  cancelBulkOrder() {
    this.loaderService.isLoading.next(true);
    this.uploadService.cancelBulkUpload(this.bulkAlertUploadNumber).subscribe(
      (res: any) => {
        this.loaderService.isLoading.next(false);
        if(res.success == true){
          this.matDialogRef.updateSize('680px', 'auto');
          this.fileUploaded = 'step1';
          this.loaded = 10;
          this.time = 1;
          this.fileName = "";
        }
        else{ 
          this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
            let dialogRef = this.dialog.open(ErrorDialogComponent, {
              width: '37vw',
              data: {
                errorMessage: value['errorMsg.pleaseTryAgain']
              }
            });
            dialogRef.afterClosed().subscribe(res => {
              if (res) {
                this.cancelBulkOrder();
              }
            });
          });
        }
      },
      (err: any) => {
        this.loaderService.isLoading.next(false);
        this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
          let dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '37vw',
            data: {
              errorMessage: value['errorMsg.pleaseTryAgain']
            }
          });
          dialogRef.afterClosed().subscribe(res => {
            if (res) {
              this.cancelBulkOrder();
            }
          });
        });
      }
    ); 
  }

  reUploadFiles() {
    this.matDialogRef.updateSize('680px', 'auto');
    this.fileUploaded = 'step1';
    this.loaded = 10;
    this.time = 1;
    this.fileName = "";
  }

  uploadselectedFiles() {
    this.fileUploaded = 'step4'
  }

  runInBackground() {
    var obj: any = { 
      "bulkAlertUploadNumber": this.bulkAlertUploadNumber,
      "bulkOrderStatus": "Uploaded"
    };
    if(this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
    this.matDialogRef.close(obj);
  }

  cancelProcess() {
   
    this.loaderService.isLoading.next(true);
    if(this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
    this.uploadService.cancelProcessing(this.bulkAlertUploadNumber).subscribe(
      (res: any) => {
        this.loaderService.isLoading.next(false);
        if(res.success == true) {
          var obj: any = { 
            "bulkAlertUploadNumber": this.bulkAlertUploadNumber,
            "bulkOrderStatus": "Cancelled"
          };
          if(this.mySubscription) {
            this.mySubscription.unsubscribe();
          }
          this.matDialogRef.close(obj);
        } else {
          this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
            let dialogRef = this.dialog.open(ErrorDialogComponent, {
              width: '37vw',
              data: {
                errorMessage: value['errorMsg.pleaseTryAgain']
              }
            });
            dialogRef.afterClosed().subscribe(res => {
              if (res) {
                this.cancelProcess();
              }
            });
          });
        }
      },
      (err: any) => {
        this.loaderService.isLoading.next(false);
        this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
          let dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '37vw',
            data: {
              errorMessage: value['errorMsg.pleaseTryAgain']
            }
          });
          dialogRef.afterClosed().subscribe(res => {
            if (res) {
              this.cancelProcess();
            }
          });
        });
      }
    ); 
  }

  confirmBulkOrder() {
    this.loaderService.isLoading.next(true);
    this.uploadService.confirmBulkOrder(this.bulkAlertUploadNumber).subscribe(
      (res: any) => {
        this.loaderService.isLoading.next(false);
        if(res.success == true){
          localStorage.removeItem('MonitoringbulkProcessComplete');
          this.matDialogRef.updateSize('600px', 'auto');
          this.fileUploaded = 'step4'
          this.bulkUpladProcessingStatus();
        } else {
          this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
            let dialogRef = this.dialog.open(ErrorDialogComponent, {
              width: '37vw',
              data: {
                errorMessage: value['errorMsg.pleaseTryAgain']
              }
            });
            dialogRef.afterClosed().subscribe(res => {
              if (res) {
                this.confirmBulkOrder();
              }
            });
          });
        }
      },
      (err: any) => {
        this.loaderService.isLoading.next(false);
        this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
          let dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '37vw',
            data: {
              errorMessage: value['errorMsg.pleaseTryAgain']
            }
          });
          dialogRef.afterClosed().subscribe(res => {
            if (res) {
              this.confirmBulkOrder();
            }
          });
        });
      }
    );
  }

  getStatus() {
    this.mySubscription = interval(10000).subscribe((x => {
      this.uploadService.bulkUploadProcessingStatus(this.bulkAlertUploadNumber).subscribe( 
        (res: any) => {
          if(res.success == true && res.data != null){
            this.loaderService.isLoading.next(false);
            this.bulkUploadStatus = JSON.parse(res.data.statusJson);
            this.loader = ( this.bulkUploadStatus.CurrentCount / this.bulkUploadStatus.TotalCount ) * 100;
            var val = this.bulkUploadStatus?.EstimatedTime / 60;
				    this.minutes = Math.round(val);
            if(this.bulkUploadStatus.CurrentCount == this.bulkUploadStatus.TotalCount) {
              this.mySubscription.unsubscribe();
              this.runInBackground();
            }
          }
        });
    }));
  }

  bulkUpladProcessingStatus() {
    this.loaderService.isLoading.next(true);
		this.uploadService.bulkUploadProcessingStatus(this.bulkAlertUploadNumber).subscribe( //this.validatedData
		(res: any) => {
			if(res.success == true && res.data != null){
        this.loaderService.isLoading.next(false);
				this.bulkUploadStatus = JSON.parse(res.data.statusJson);
				this.loader = ( this.bulkUploadStatus?.CurrentCount / this.bulkUploadStatus?.TotalCount ) * 100;
        var val = this.bulkUploadStatus?.EstimatedTime / 60;
				this.minutes = Math.round(val);
        this.getStatus();
			} else {
        this.getStatus();
      }
		});
	}

  hideTip() {
    this.showTip = false;
  }
}
