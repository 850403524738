import { Component } from '@angular/core';
import { LoaderService } from '../services/loader.service';
declare const $: any;

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss']
})

export class LoaderComponent {

	loading: boolean | undefined;

	constructor(private loaderService: LoaderService) {
		this.loaderService.isLoading.subscribe((v: any) => {
			if (v) {
				this.loading = v;
				var element = $('.search-result');
				element.addClass('loading');
				setTimeout(function () {
					if (element)
						element.find('.section-loading-screen-bar span').css('width', '40%');
				}, 200)

				setTimeout(function () {
					if (element)
						element.find('.section-loading-screen-bar span').css('width', '70%');
				}, 800)
			} else {
				if (element)
					element.find('.section-loading-screen-bar span').css('width', '100%');
				this.loading = v;
			}
		});
	}
}
