<div class="dialog-content-wrapper">
    <div mat-dialog-content>
        <div class="close-dialog">
            <mat-icon class="close-btn" (click)="matDialogRef.close(false)">close</mat-icon>
        </div>
        <div class="cross-header">
            <span class="title-text">
                {{ 'bulk.upload.downtime_note' | translate }}
            </span>
        </div>  
        <div>
            <span class="grey-color">
                {{ 'bulk.upload.downtime_sub_note' | translate }}
            </span>
        </div>
        <div class="pt-30">
            <label> {{ 'bulk.upload.countries' | translate }}</label>&nbsp;&nbsp; 
        </div>
        <div class="pt-7">
            <div *ngFor="let country of mySet"> <label> {{ country }} </label></div>
        </div>

        <div class="zanna_div row pt-30">
            <div class="d_6_12">
                <button (click)="matDialogRef.close(true)" class="button button__primary">
                    {{ 'bulk.upload.continue' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
