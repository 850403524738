<div class="master-deatils" *ngIf="!isMobile">
   <div class="alignment row">
      <label style="margin-top: 25px;"><b>{{ 'monitor.cms.case_details' | translate }}</b></label>
   </div>
   <div class="alignment row" style="white-space: initial;">
       <!-- <label>{{ cellData.summary }}</label> -->
       <div fxLayout="col">
        <div fxFlex="30">
           <div class="mb-10"> {{ 'monitor.cms.court_type' | translate }}: {{ cellData.CourtType }}</div>
           <div class="mb-10"> {{ 'monitor.cms.case_no' | translate }}.: {{ cellData.CaseNo }}</div>
           <div class="mb-10"> {{ 'monitor.cms.defendant' | translate }}: {{ cellData?.PartyName }}</div>
           <div class="mb-10"> {{ 'monitor.cms.cause' | translate }}: {{ cellData.Cause }}</div>
           
        </div>
        <div fxFlex="4"></div>
        <div fxFlex="60">
           <div class="mb-10"> {{ 'monitor.cms.year' | translate }}: {{ cellData?.CaseYear }}</div>
           <div class="mb-10"> {{ 'monitor.cms.transfer' | translate }}: <span *ngIf="cellData.Transfer">{{ cellData.Transfer }}</span><span *ngIf="!cellData.Transfer">{{ 'monitor.cms.nil' | translate }}</span></div>
           <div class="mb-10"> {{ 'monitor.cms.amount' | translate }}: 
               <span *ngFor="let amt of cellData.CaseAmounts; let i = index" [ngClass]="i != 0 ? 'ml-57' : ''" >{{ amt?.Currency}} {{ amt?.Amount }}<br></span>
           </div> 
        </div>
    </div>
    </div>
</div>

<div class="overflow-y" *ngIf="isMobile">
   <div fxLayout="col" class="f-s-adjustment">
       <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
           <b>{{'monitor.entityNo' | translate}}</b>
       </div>
       <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
          {{cellData.EntityId}}
       </div>
   </div>    
   <div fxLayout="col" class="f-s-adjustment">
       <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
           <b>{{'monitor.clientFileRef' | translate}}</b>
       </div>
       <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;" >
          {{cellData.FileReference}}
       </div>
   </div>    
   <div fxLayout="col" class="f-s-adjustment">
       <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
           <b>{{'monitor.dateOfAlert' | translate}}</b>
       </div>
       <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
          {{cellData.AlertDate}}
       </div>
   </div>  
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
          <b>{{ 'monitor.cms.court_type' | translate }}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
         {{cellData.CourtType}}
      </div>
   </div>      
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
         <b>{{ 'monitor.cms.case_no' | translate  }}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
         {{cellData.CaseNo}}
      </div>
   </div>
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
         <b>{{ 'monitor.cms.defendant' | translate }}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
         {{cellData.PartyName}}
      </div>
   </div>
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
         <b>{{ 'monitor.cms.cause' | translate}}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
         {{cellData.Cause}}
      </div>
   </div>
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
         <b>{{ 'monitor.cms.year' | translate  }}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
         {{cellData?.CaseYear}}
      </div>
   </div>
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
         <b>{{ 'monitor.cms.transfer' | translate  }}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
         <span *ngIf="cellData.Transfer">{{ cellData.Transfer }}</span>
         <span *ngIf="!cellData.Transfer">{{ 'monitor.cms.nil' | translate }}</span>
      </div>
   </div> 
   <div fxLayout="col" class="f-s-adjustment" *ngIf="cellData.CaseAmounts && cellData.CaseAmounts != null">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
         <b>{{ 'monitor.cms.amount' | translate }}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
         <span *ngFor="let amt of cellData.CaseAmounts; let i = index" [ngClass]="i != 0 ? 'ml-57' : ''" >{{ amt?.Currency}} {{ amt?.Amount }}<br></span>
      </div>
   </div>
</div>