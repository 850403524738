import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'billing-statement',
    templateUrl: './billing-statement.component.html',
    styleUrls: ['./billing-statement.component.scss']
})

export class BillingStatementComponent implements OnInit {
    isMobile:boolean = true
    isExpand:boolean = false;
    datePrinted: any = new Date();
    billingData: any;
    currency: any;

    title: any = "Order History";

    constructor(private router: Router) {
        this.billingData = this.router.getCurrentNavigation()?.extras.state?.dataList;

        if (this.billingData == undefined) {
            this.router.navigate(['order-history']);
        } else {
            this.billingData.sellingPrice = (this.billingData.sellingPrice).toFixed(2);
        }

        this.currency = localStorage.getItem('currency');
    }

    ngOnInit(): void {
        this.currency = localStorage.getItem('currency');
        if (this.currency == 'null') {
            this.currency = '';
        }
    }
    expandBill(){
        this.isExpand = !this.isExpand;
    }

}