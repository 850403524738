import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'confirm-dialog-box',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['confirm-dialog.component.scss']
})

export class ManuallyAddConfimDialogComponent {
    public confirmMessage: any = '';
    paramData: any;
    constructor(public dialogRef: MatDialogRef<ManuallyAddConfimDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {
        this.paramData = data;
    }
}