<div class="dialog-content-wrapper" *ngIf="data.isMatch">
    <div mat-dialog-content>
        
        <div class="cross-header">
            <span class="title-text">
                {{ 'bulk.upload.match_result' | translate }}
            </span>
            <mat-icon class="close-btn" (click)="matDialogRef.close(false)">close</mat-icon>
        </div>

        <div>
            <div class="contain-records">
                <span class="comp-count">{{ searchResultList.length }}</span>
                <span class="comp-format">
                    {{ 'bulk.upload.match_found' | translate }} {{data?.results?.userSearchTerm}}
                </span>
                <!-- <span class="unable-find">
                    {{ 'bulk.upload.unable' | translate }}
                </span>
                <span class="refine-search" (click)="matDialogRef.close('true')">
                    {{ 'bulk.upload.request_report' | translate }}
                </span> -->
            </div>
            
            <div class="data-grid-strip">
                
            </div>
            
            <ag-grid-angular 
                class="ag-theme-alpine"
                id="searchResultGrid"
                [rowData]="searchResultList"
                [columnDefs]="searchColumnDefs"
                (gridReady)="onGridReady($event)"
                [gridOptions]="gridOptions"
                [icons]="icons"
                [domLayout]="domLayout"
                (filterChanged)="onFilterChanged($event)"
                (selectionChanged)="onSelectionChanged($event)"
                (rowSelected)="onRowSearchSelected($event)">
            </ag-grid-angular>
            <app-pagination *ngIf="searchResultList.length > 0" 
                [pageSize]="PaginationPageSize" [gridOptions]="gridOptions" 
                [noOfPages]="gridOptions.api?.paginationGetTotalPages()" [totalRows]="gridOptions?.api?.getDisplayedRowCount()">
            </app-pagination>
            <span class="error" *ngIf="errorMsgFlag">{{ 'searchResults.errMsg' | translate }}</span>            
            <div class="col btn-grp">
                <button (click)="matDialogRef.close()" class="button button__basic">
                    {{ 'searchResults.btn.cancel' | translate }}
                </button>
                <button [disabled]="disabledFlag" (click)="resultSelect()" class="button button__primary ml-22">
                    {{ 'searchResults.btn.selectAlert' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<div class="dialog-content-wrapper" *ngIf="!data.isMatch">
    <div mat-dialog-content>
        
        <div class="cross-header">
            <span class="title-text">
                {{ 'bulk.upload.ReportType' | translate }}
            </span>
            <mat-icon class="close-btn" (click)="matDialogRef.close()">close</mat-icon>
        </div>

        <div>
            <div class="contain-records">
                <span class="comp-format">
                    {{ 'variationTable.disclaimer' | translate }}
                </span>
                <!-- <span class="unable-find">
                    {{ 'bulk.upload.unable' | translate }}
                </span>
                <span class="refine-search" (click)="matDialogRef.close('true')">
                    {{ 'bulk.upload.request_report' | translate }}
                </span> -->
            </div>
            
            <div class="data-grid-strip">
                <span *ngIf="isRowSelected">1 {{ 'grid.rowsSelected' | translate }}</span>
            </div>
            
            <ag-grid-angular 
                class="ag-theme-alpine"
                id="searchResultGrid"
                [rowData]="availability"
                [columnDefs]="searchColumnDefs"
                (gridReady)="onGridReady($event)"
                [gridOptions]="gridOptions"
                [icons]="icons"
                [domLayout]="domLayout"
                (filterChanged)="onFilterChanged($event)"
                (selectionChanged)="onSelectionChanged($event)"
                (rowSelected)="onRowSearchSelected($event)">
            </ag-grid-angular>
            <span class="error" *ngIf="errorMsgFlag">{{ 'searchResults.errMsg' | translate }}</span>            
            <div class="col btn-grp">
                <button (click)="matDialogRef.close()" class="button button__basic">
                    {{ 'searchResults.btn.cancel' | translate }}
                </button>
                <button [disabled]="disabledFlag" (click)="UpdateAvailabilityDetails()" class="button button__primary ml-22">
                    {{ 'searchResults.btn.selectAlert' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
