<div class="dialog-content-wrapper">
    <div>
        
        <div class="cross-header">
            
            <mat-icon class="close-btn" (click)="dialogRef.close(false)" style="float: right; cursor: pointer;">close</mat-icon>
            <mat-icon class="download-icon"
			*ngIf="data?.format == 'HTML'"
			(click)="downloadReport()">get_app
		    </mat-icon>
        </div>
        
        <div class="contain-records">
            <iframe id="blob-src-test" border="0" width="100%" height="600"></iframe>
        </div>
    </div>
</div>
