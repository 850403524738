import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { HomeService } from './home/home.service';
import { LoaderService } from './services/loader.service';
import { AuthService } from './authGuard.service';

@Injectable({ providedIn: 'root' })
export class HomeAuthService implements CanActivate {

	constructor(private _router: Router,public homeService: HomeService, private loaderService: LoaderService, public auth: AuthService ) {
        
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if(this.auth.isHome()) {
            return true;
        } else {
            if(this.auth.validated) {
            this._router.navigate(['/monitor']);
            }
            return false;
        }
    }
	

    
}
