import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AppService } from '../app.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HomeService {

	headers = new HttpHeaders();
	userId: any = '';
	sid: any = "";
	sessionData: any = {};
	constructor(private http: HttpClient, private appService: AppService) {
		this.headers = this.headers.set('Accept', 'application/json');
		this.headers = this.headers.set('Access-Control-Allow-Origin', '*');
		this.headers = this.headers.set('Access-Control-Allow-Credentials', 'true');
		this.headers = this.headers.set('sessionid', '');
		this.sid = localStorage.getItem('sessionID');
		console.log("sid",this.sid);
		if(this.sid)
		this.headers = this.headers.set('sessionid', this.sid);
		this.headers = this.headers.set('X-FRAME-OPTIONS', 'deny');
		this.headers = this.headers.set('Content-Security-Policy', 'frame-ancestors "none";');
	}

	setSid(params: any) {
		this.sid = params.sessionID;
		if(this.sid)
		this.headers = this.headers.set('sessionid', this.sid);
		this.sessionData = (params);
		this.userId = this.sessionData?.userID;
	}

	getSessionData() {
		return this.sessionData;
	}

	validateSession(sessionId : any) {
		if(sessionId)
		this.headers = this.headers.set('sessionid',sessionId);
		return this.http.get<any>(this.appService.baseURL + '/api/v1/Session/Validate',
			{ headers: this.headers }).pipe(map(res => res));
	}

	getCountries(sessionId : any) {
		if(sessionId)
		this.headers = this.headers.set('sessionid',sessionId);
		return this.http.get<any>(this.appService.baseURL + '/api/v1/Country/GetCountries',
			{ headers: this.headers }).pipe(map(res => res));
	}

	getProductsByCountry(country: any) {
		var formData: any = new FormData();
		formData.append("countryCode", country);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Product/GetProductCategory', formData,
			{ headers: this.headers }).pipe(map(res => res));
	}

	getAvailibities(formData: any, reportType: any) {
		if (reportType == 'offline') {
			return this.http.post<any>(this.appService.baseURL + '/api/v1/Product/GetProductAvailabilityOffline', formData,
				{ headers: this.headers }).pipe(map(res => res));
		} else {
			return this.http.post<any>(this.appService.baseURL + '/api/v1/Product/GetProductAvailability', formData,
				{ headers: this.headers }).pipe(map(res => res));
		}
	}

	getTranslations(code: any,categoryId:any) {
		const params = new HttpParams()
			.set('countryCode', code)
			.set('vendorCategory',categoryId)
		return this.http.get<any>(this.appService.baseURL + '/api/v1/Product/Translation',
			{ params, headers: this.headers }).pipe(map(res => res));
	}

	searchCompanies(data: any, categoryId: any) {    //this.appService.searchURL + '/api/v1/Search'
		const params = new HttpParams()
			.set('searchType', "0")
			.set('vendorCategory', categoryId)
			.set('requestJson', JSON.stringify(data));
		return this.http.post<any>(this.appService.baseURL + '/api/v1/search/Entity', params,
			{ headers: this.headers }).pipe(map(res => res));
	}

	addToCart(data: any) {
		return this.http.post<any>(this.appService.baseURL + '/api/v1/ShoppingCart', data,
			{ headers: this.headers }).pipe(map(res => res));
	}

	addChildToCart(data: any, seqId : any) {
		return this.http.post<any>(this.appService.baseURL + '/api/v1/ShoppingCart/'+seqId+'/children', data,
			{ headers: this.headers }).pipe(map(res => res));
	}

	submitOrder(data: any) {
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Order/PlaceOrder', data,
			{ headers: this.headers }).pipe(map(res => res));
	}

	getCartList() {
		//const params = new HttpParams().set('userId', this.userId)
		return this.http.get<any>(this.appService.baseURL + '/api/v1/ShoppingCart',
			{ headers: this.headers }).pipe(map(res => res));
	}

	deleteCartItem(cartId: any) {
		const params = new HttpParams().set('userId', this.userId)
		return this.http.delete<any>(this.appService.baseURL + '/api/v1/ShoppingCart/' + cartId,
			{ params, headers: this.headers }).pipe(map(res => res));
	}

	logout() {
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Session/Logout', null,
			{ headers: this.headers }).pipe(map(res => res));
	}

	sampleReport(offeringId: any): Observable<Blob> {
		const params = new HttpParams().set('productOfferingId', offeringId)
		const httpOptions = {
			responseType: 'blob' as 'json',
			headers: this.headers,
			params
		};
		return this.http.get<any>(this.appService.baseURL + '/api/v1/Order/GetSampleReport',
			httpOptions).pipe(map(res => res));
	}
	userManualReport(orderSource:any,lang:any){
		var url;
		if(orderSource == 'IBR360' && lang != 'en'){
			url= 'assets/reports/IBR_JAPAN.pdf';
		}else if(orderSource == 'IBR360' && lang == 'en'){
			url= 'assets/reports/IBR_ENGLISH.pdf';
		}else if(orderSource == 'QN'){
			url= 'assets/reports/QUESTNET.pdf';
		}else{
			url= 'assets/reports/QUESTNET.pdf';
		}
		const httpOptions = {
			responseType: 'blob' as 'json',
			headers: this.headers
		};
		return this.http.get(url,httpOptions).pipe(map(res => res));
	}

	getBulkUploadDetails() {
		return this.http.get<any>(this.appService.baseURL + '/api/v1/BulkOrder/GetBulkUploadDetails',
			{ headers: this.headers }).pipe(map(res => res));
	}

}
