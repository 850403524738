import { Component, Inject, ViewEncapsulation, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { GridApi, GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BulkUploadGridService } from '../bulk-upload-grid.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ErrorDialogComponent } from 'src/app/error-dialog-box/error-dialog.component';
declare const $: any;

@Component({
	selector: 'app-offline-search',
	templateUrl: './offline-search.html',
	styleUrls: ['./offline-search.scss'],
	encapsulation: ViewEncapsulation.None
})

export class OfflineSearchComponent implements OnInit, AfterViewInit, OnDestroy {
	reportType: any;
	language: any;
	productListGdn: any = [
		{"reportType":"Premium Report","speedCode":"TAT3-4","reportTypeAndTAT":"Premium Report [3-4 working days]", "SpeedDescription": "3-4 working days"},
		{"reportType":"Premium Report","speedCode":"TAT7-8","reportTypeAndTAT":"Premium Report [7-8 working days]", "SpeedDescription": "7-8 working days"},
		{"reportType":"Standard Report","speedCode":"TAT3-4","reportTypeAndTAT":"Standard Report [3-4 working days]", "SpeedDescription": "3-4 working days"},
		{"reportType":"Standard Report","speedCode":"TAT1-2","reportTypeAndTAT":"Standard Report [1-2 working days]", "SpeedDescription": "1-2 working days"}
	];
	productListRoc: any = [
		{"reportType":"Standard Company Profile","speedCode":"TAT1","reportTypeAndTAT":"Standard Company Profile [1 working days]", "SpeedDescription": "1 working days"},
		{"reportType":"Standard Company Profile","speedCode":"TAT2","reportTypeAndTAT":"Standard Company Profile [2 working days]", "SpeedDescription": "2 working days"},
		{"reportType":"Standard Company Profile","speedCode":"TAT3","reportTypeAndTAT":"Standard Company Profile [3 working days]", "SpeedDescription": "2 working days"},
		{"reportType":"Standard Company Profile","speedCode":"TAT10","reportTypeAndTAT":"Standard Company Profile [10 working days]", "SpeedDescription": "10 working days"}
	];
	productList: any;
	selectLanguage: any = [
		{ "languageName": "None", value: 'N'},
		{ "languageName": "Japanese", value: 'Y'}
	];
	reportTypeSetting: boolean = true;

	columnDefs: any = [];
	gridOptions: any;
	gridColumnApi: any;
	icons: any;
	api: any;
	paginationPageSize = 5;
	totalPages = 0;
	addToCart: any = {
		reportType: "",
		speedCode: "",
		bulkOrderItems: []
	};
	selectedOptions: any = {
		"reportType": "",
		"SpeedDescription": "",
		"translation": "N"
	}
	distributionCode: any;
	records: any; 

	constructor(public matDialogRef: MatDialogRef<OfflineSearchComponent>, public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) private data: any, public loaderService: LoaderService, public uploadService: BulkUploadGridService,
		public translateService: TranslateService) {
			console.log("data", data);
		if(data.data[0].supplierCode == "WB") {
			this.productList = this.productListRoc;
		} else {
			this.productList = this.productListGdn;	
		}
		this.distributionCode = localStorage.getItem('distributionCode');
		this.reportType = this.productList[0].reportTypeAndTAT;
		this.language = this.selectLanguage[0].languageName;
		this.addToCart.reportType = this.productList[0].reportType;
		this.addToCart.speedCode = this.productList[0].speedCode;
		this.selectedOptions.reportType = this.productList[0].reportType;
		this.selectedOptions.SpeedDescription = this.productList[0].SpeedDescription;
		data.data = data.data.filter((res: any) => res.status != "Added_To_Cart_Offline");
		data.data.map((record: any) => {
			var obj: any = {
				"BulkReportOrderItemId": record.id,
				"Translation": false
			};
			this.addToCart.bulkOrderItems.push(obj);
		});
		
		this.getColDefs();
		this.gridOptions = <GridOptions>{
			onGridReady: () => {
				this.gridAPI.setDomLayout('normal');
				let element: any = document.getElementById('NoMatchReportTypeGrid');
				if (element) {
					element.style.height = '51vh';
				}
				setTimeout(() => {
					this.gridOptions.api?.sizeColumnsToFit();
				}, 1000);
				this.totalPages = this.gridOptions.api?.paginationGetTotalPages();
			},
			onGridSizeChanged: () => {
				this.gridOptions.api.sizeColumnsToFit();
			},
			pagination: true,
			paginationPageSize: 10,
			animateRows: true,
			suppressCellSelection: true,
			enableCellTextSelection: true,
			defaultColDef: {
				flex: 1,
				wrapText: true,
				resizable: true,
				autoHeight: true,
				unSortIcon: true,
				sortable: true,
				suppressMenu: true,
				lockPosition: true
			}
		};

		this.icons = {
			sortUnSort: '<img src="../../assets/img/sort.svg">',
			sortAscending: '<img src="../../assets/img/sort-asc.svg">',
			sortDescending: '<img src="../../assets/img/sort-desc.svg">'
		};
	}

	get gridAPI(): GridApi {
		return this.gridOptions.api;
	}

	onGridReady(params: any) {
		this.gridColumnApi = params.columnApi;
		this.api = params.api;
	}

	ngOnInit() {

	}

	
	ngAfterViewInit(): void {
		
	}

	changeReportType(event: any) {
		this.addToCart.reportType = event.reportType;
		this.addToCart.speedCode = event.speedCode;
		this.selectedOptions.reportType = event.reportType;
		this.selectedOptions.SpeedDescription = event.SpeedDescription;
	}

	changeTranslation(event: any) {
		if(event.languageName == 'Japanese') {
			this.addToCart.bulkOrderItems.map((item: any) => {
				item.Translation = true;
				this.selectedOptions.translation = "Y";
			});
		} else {
			this.addToCart.bulkOrderItems.map((item: any) => {
				item.Translation = false;
				this.selectedOptions.translation = "N";
			});
		}
	}

	apply() {
		this.loaderService.isLoading.next(true);
		this.uploadService.addToCartNoMatch(this.addToCart).subscribe((res: any) => {
			this.loaderService.isLoading.next(false);
			if(res.success && res.data.length > 0) {
				this.reportTypeSetting = false;
				this.records = res.data;
			} else {
				this.matDialogRef.close(this.selectedOptions);
			}
		},
		(err: any) => {
			this.loaderService.isLoading.next(false);
			this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
				let dialogRef = this.dialog.open(ErrorDialogComponent, {
					width: '37vw',
					data: {
						errorMessage: value['errorMsg.pleaseTryAgain']
					}
				});
				dialogRef.afterClosed().subscribe(res => {
					if (res) {
						this.apply();
					}
				});
			});
		});
	}

	close() {
		this.matDialogRef.close(this.selectedOptions);
	}

	exportToExcel() {
		this.api.exportDataAsExcel(this.getParams());
	}

	getParams() {
		return {
		  allColumns: true,
		};
	}
	getColDefs() {
		this.translateService.get(['bulk.upload.identification','bulk.upload.country_address', 'bulk.upload.city', 'bulk.upload.address']).subscribe(value => {
				this.columnDefs = [
					{
						field: 'userSearchTerm',
						headerName: value['bulk.upload.identification'],
						width: 220,
						minWidth: 220,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'userCountry',
						headerName: value['bulk.upload.country_address'],
						tooltipField: 'address',
						width: 110,
						minWidth: 110,
						cellRenderer: function (param: any) {
							var row = "";
							if(param.data?.userCountry)
								row = param.data?.userCountry + "</br>";
							if(param.data?.userCity)
								row = row + param.data?.userCity+"</br>";
							if(param.data?.userAddress) 
								row = row + param.data?.userAddress;
							return row;
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'userCity',
						headerName: value['bulk.upload.city'],
						hide: true,
						width: 220,
						minWidth: 220,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'userAddress',
						headerName: value['bulk.upload.address'],
						hide: true,
						width: 220,
						minWidth: 220,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
				]
			});
	}

	ngOnDestroy() {
	
	}
}