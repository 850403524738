<div class="master-deatils" *ngIf="!isMobile">
   <div class="alignment row">
      <div fxLayout="col" style="margin-bottom: 10px;margin-top: 10px;">
         <div fxFlex="30" class="vertically-center">
            <div class="text-clip" > {{ 'monitor.bws.subject' | translate }}</div>
         </div>
         <div fxFlex="4"></div>
         <div fxFlex="60">
            <div class="text-clip"> <span *ngIf="cellData.Subject">{{ cellData.Subject }}</span><span *ngIf="!cellData.Subject"  > - </span></div>
         </div>
     </div>
     <div  fxLayout="col" style="margin-bottom: 10px;margin-top: 10px;">
         <div fxFlex="30" class="vertically-center">
            <div class="text-clip"> {{ 'monitor.bws.last_day_proofs' | translate }}</div>
         </div>
         <div fxFlex="4"></div>
         <div fxFlex="60">
            <div class="text-clip"> <span *ngIf="cellData.ProofDate">{{ cellData.ProofDate }}</span> <span *ngIf="!cellData.ProofDate" > - </span></div>
         </div>
      </div>
      <div  fxLayout="col" style="margin-bottom: 10px;margin-top: 10px;">
         <div fxFlex="30" class="vertically-center">
            <div class="text-clip"> {{ 'monitor.bws.location_meeting' | translate }}</div>
         </div>
         <div fxFlex="4"></div>
         <div fxFlex="60">
            <div class="text-clip"> <span *ngIf="cellData.MeetingLocation">{{ cellData.MeetingLocation }}</span> <span *ngIf="!cellData.MeetingLocation"  > - </span></div>
         </div>
      </div>
      <div  fxLayout="col" style="margin-bottom: 10px;margin-top: 10px;">
         <div fxFlex="30" class="vertically-center">
            <div class="text-clip"> {{ 'monitor.bws.time_meeting' | translate }}</div>   
         </div>
         <div fxFlex="4"></div>
         <div fxFlex="60">
            <div class="text-clip"> <span *ngIf="cellData.MeetingTime">{{ cellData.MeetingTime }}</span> <span *ngIf="!cellData.MeetingTime" > - </span></div>
         </div>
      </div>
      <div  fxLayout="col" style="margin-bottom: 10px;margin-top: 10px;">
         <div fxFlex="30" class="vertically-center">
            <div class="text-clip"> {{ 'monitor.bws.amount_per_centum' | translate }}</div> 
         </div>
         <div fxFlex="4"></div>
         <div fxFlex="60">
            <div class="text-clip"> <span *ngIf="cellData.AmountPerCentum">{{ cellData.AmountPerCentum }}</span> <span *ngIf="!cellData.AmountPerCentum" > - </span></div>
         </div>
      </div>
      <div  fxLayout="col" style="margin-bottom: 10px;margin-top: 10px;">
         <div fxFlex="30" class="vertically-center">
            <div class="text-clip"> {{ 'monitor.bws.first_and_final' | translate }}</div> 
         </div>
         <div fxFlex="4"></div>
         <div fxFlex="60">
            <div class="text-clip"> <span *ngIf="cellData.FirstFinal"> {{ cellData.FirstFinal }}</span> <span *ngIf="!cellData.FirstFinal"  > - </span></div>                 
         </div>
      </div>
      <div  fxLayout="col" style="margin-bottom: 10px;margin-top: 10px;">
         <div fxFlex="30" class="vertically-center">
            <div class="text-clip"> {{ 'monitor.bws.wehn_payable' | translate }} </div>  
         </div>
         <div fxFlex="4"></div>
         <div fxFlex="60">
            <div class="text-clip"> <span *ngIf="cellData.PayableDate">{{ cellData.PayableDate }}</span> <span *ngIf="!cellData.PayableDate"  > - </span></div>  
         </div>
      </div>
      <div  fxLayout="col" style="margin-bottom: 10px;margin-top: 10px;">
         <div fxFlex="30" class="vertically-center">
            <div class="text-clip"> {{ 'monitor.bws.where_payable' | translate }}</div> 
         </div>
         <div fxFlex="4"></div>
         <div fxFlex="60">
            <div class="text-clip"> <span *ngIf="cellData.PayableLocation">{{ cellData.PayableLocation }}</span> <span *ngIf="!cellData.PayableLocation"  > - </span></div> 
         </div>
      </div>
      <div  fxLayout="col" style="margin-bottom: 10px;margin-top: 10px;">
         <div fxFlex="30" class="vertically-center">
            <div class="text-clip"> {{ 'monitor.bws.occurence_date' | translate }} </div>  
         </div>
         <div fxFlex="4"></div>
         <div fxFlex="60">
            <div class="text-clip"> <span *ngIf="cellData.OccurrenceDate">{{ cellData.OccurrenceDate }}</span> <span *ngIf="!cellData.OccurrenceDate"  > - </span></div>  
         </div>
      </div>
      <div  fxLayout="col" style="margin-bottom: 10px;margin-top: 10px;">
         <div fxFlex="30" class="vertically-center">
            <div class="text-clip"> {{ 'monitor.bws.status' | translate }} </div>  
         </div>
         <div fxFlex="4"></div>
         <div fxFlex="60">
            <div class="text-clip"> <span *ngIf="cellData.Status">{{ cellData.Status }}</span> <span *ngIf="!cellData.Status"  > - </span></div>  
         </div>
      </div>
      <div  fxLayout="col" style="margin-bottom: 10px;margin-top: 10px;">
         <div fxFlex="30" class="vertically-center">
            <div class="text-clip"> {{ 'monitor.bws.suit_no' | translate }}</div> 
         </div>
         <div fxFlex="4"></div>
         <div fxFlex="60">
            <div class="text-clip"> <span *ngIf="cellData.SuitNo">{{ cellData.SuitNo }} </span><span *ngIf="!cellData.SuitNo"  > - </span></div>  
         </div>
      </div>
      <div  fxLayout="col" style="margin-bottom: 10px;margin-top: 10px;">
         <div fxFlex="30" class="vertically-center">
            <div class="text-clip"> {{ 'monitor.bws.name_of_liuidators' | translate }} </div>  
         </div>
         <div fxFlex="4"></div>
         <div fxFlex="60">
            <div class="text-clip"> <span *ngIf="cellData.LiquidatorName">{{ cellData.LiquidatorName }}</span> <span *ngIf="!cellData.LiquidatorName"  > - </span></div>         
         </div>
      </div>
      <div  fxLayout="col" style="margin-bottom: 10px;margin-top: 10px;">
         <div fxFlex="30" class="vertically-center">
            <div class="text-clip"> {{ 'monitor.bws.address_of_liuidators' | translate }} </div>  
         </div>
         <div fxFlex="4"></div>
         <div fxFlex="60">
            <div class="text-clip"> <span *ngIf="cellData.LiquidatorAddress">{{ cellData.LiquidatorAddress }}</span><span *ngIf="!cellData.LiquidatorAddress"  > - </span> </div>  
         </div>
      </div>
      <div  fxLayout="col" style="margin-bottom: 10px;margin-top: 10px;">
         <div fxFlex="30" class="vertically-center">
            <div class="text-clip"> {{ 'monitor.bws.hearing_dates' | translate }} </div> 
         </div>
         <div fxFlex="4"></div>
         <div fxFlex="60">
            <div class="text-clip"> <span *ngIf="cellData.HearingDate">{{ cellData.HearingDate }}</span><span *ngIf="!cellData.HearingDate"  > - </span> </div> 
         </div>
      </div>
      <div  fxLayout="col" style="margin-bottom: 10px;margin-top: 10px;">
         <div fxFlex="30" class="vertically-center">
            <div class="text-clip"> {{ 'monitor.bws.date_of_notice' | translate }} </div>  
         </div>
         <div fxFlex="4"></div>
         <div fxFlex="60">
            <div class="text-clip"> <span *ngIf="cellData.NoticeDate">{{ cellData.NoticeDate }}</span> <span *ngIf="!cellData.NoticeDate"  > - </span></div>    
         </div>
      </div>
      <!-- <div  fxLayout="col" style="margin-bottom: 10px;margin-top: 10px;">
         <div fxFlex="30" class="vertically-center">
            <div class="text-clip"> {{ 'monitor.bws.wound_up_date' | translate }} </div>
         </div>
         <div fxFlex="4"></div>
         <div fxFlex="60">
            <div class="text-clip"> <span *ngIf="cellData.WoundUpDate">{{ cellData.WoundUpDate }}</span> <span *ngIf="!cellData.WoundUpDate"  > - </span></div>
         </div>
      </div> -->
   </div>
</div>
   <!-- <table class="master-table" style="width: 100%;">
      <tr style="word-break: break-all;">
          <td >Subject</td>
          <td style="word-break: break-all;"> {{ cellData.Subject }} </td>
      </tr>
      <tr>
         <td> Date (Last Day for receiving Proofs)</td>
         <td style="word-break: break-all;"> {{ cellData.HearingDate }} </td>
      </tr>
      <tr>
         <td>Location of Meeting</td>
         <td style="word-break: break-all;">{{ cellData.MeetingLocation }}</td>
      </tr>
      <tr>
         <td> Time of Meeting</td>
         <td style="word-break: break-all;"> {{ cellData.MeetingTime }}</td>
      </tr>
      <tr>
         <td>Amount Per Centum</td>
         <td style="word-break: break-all;">{{ cellData.AmountPerCentum }}</td>
      </tr>
      <tr>
         <td>First and Final or Otherwise</td>
         <td style="word-break: break-all;">{{ cellData.FirstFinal }}</td>
      </tr>
      <tr>
         <td>When Payable</td>
         <td style="word-break: break-all;">{{ cellData.PayableDate }}</td>
      </tr>
      <tr>
         <td>Where Payable</td>
         <td style="word-break: break-all;">{{ cellData.PayableLocation }}</td>
      </tr>
      <tr>
         <td>Occurence Date</td>
         <td style="word-break: break-all;">{{ cellData.OccurrenceDate }}</td>
      </tr>
      <tr>
         <td>Status</td>
         <td style="word-break: break-all;">{{ cellData.Status }}</td>
      </tr>
      <tr>
         <td>Suit No</td>
         <td style="word-break: break-all;">{{ cellData.SuitNo }}</td>
      </tr>
      <tr>
         <td>Name of Liquidators</td>
         <td style="word-break: break-all;">{{ cellData.LiquidatorName }}</td>
      </tr>
      <tr>
         <td>Address of Liquidators</td>
         <td style="word-break: break-all;">{{ cellData.LiquidatorAddress }}</td>
      </tr>
      <tr>
         <td>Hearing Dates:</td>
         <td style="word-break: break-all;">{{ cellData.HearingDate }}</td>
      </tr>
      <tr>
         <td>Date of Notice</td>
         <td style="word-break: break-all;">{{ cellData.NoticeDate }}</td>
      </tr>
      <tr>
         <td>Wound Up Date</td>
         <td style="word-break: break-all;">{{ cellData.WoundUpDate }}</td>
      </tr>
      </table> -->
<div class="overflow-y" *ngIf="isMobile">
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
         <b>{{'monitor.entityNo' | translate}}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
         {{cellData.EntityId}}
      </div>
   </div>    
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
         <b>{{'monitor.clientFileRef' | translate}}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
         {{cellData.FileReference}}
      </div>
   </div>    
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
         <b>{{'monitor.bws.subject' | translate}}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
         <span *ngIf="cellData.Subject">{{ cellData.Subject }}</span>
         <span *ngIf="!cellData.Subject"  > - </span>
      </div>
   </div>    
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
         <b>{{ 'monitor.bws.last_day_proofs' | translate }}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;" >
         <span *ngIf="cellData.HearingDate">{{ cellData.HearingDate }}</span>
         <span *ngIf="!cellData.HearingDate" > - </span>
      </div>
   </div>    
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
         <b>{{'monitor.bws.location_meeting' | translate}}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
         <span *ngIf="cellData.MeetingLocation">{{ cellData.MeetingLocation }}</span>
         <span *ngIf="!cellData.MeetingLocation"  > - </span>
      </div>
   </div>  
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
         <b>{{'monitor.bws.time_meeting' | translate}}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
         <span *ngIf="cellData.MeetingTime">{{ cellData.MeetingTime }}</span>
         <span *ngIf="!cellData.MeetingTime" > - </span>
      </div>
   </div>   
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
         <b>{{'monitor.bws.amount_per_centum' | translate}}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
         <span *ngIf="cellData.AmountPerCentum">{{ cellData.AmountPerCentum }}</span>
         <span *ngIf="!cellData.AmountPerCentum" > - </span>
      </div>
   </div>
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
         <b>{{'monitor.bws.first_and_final' | translate}}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
         <span *ngIf="cellData.FirstFinal"> {{ cellData.FirstFinal }}</span>
         <span *ngIf="!cellData.FirstFinal"  > - </span>
      </div>
   </div>  
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
         <b>{{'monitor.bws.wehn_payable' | translate}}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
         <span *ngIf="cellData.PayableDate">{{ cellData.PayableDate }}</span>
         <span *ngIf="!cellData.PayableDate"  > - </span>
      </div>
   </div>  
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
         <b>{{'monitor.bws.where_payable' | translate}}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
         <span *ngIf="cellData.PayableLocation">{{ cellData.PayableLocation }}</span>
         <span *ngIf="!cellData.PayableLocation"  > - </span>
      </div>
   </div>  
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
         <b>{{'monitor.bws.occurence_date' | translate}}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
         <span *ngIf="cellData.OccurrenceDate">{{ cellData.OccurrenceDate }}</span>
         <span *ngIf="!cellData.OccurrenceDate"  > - </span>
      </div>
   </div>  
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
         <b>{{'monitor.bws.status' | translate}}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
         <span *ngIf="cellData.Status">{{ cellData.Status }}</span>
         <span *ngIf="!cellData.Status"  > - </span>
      </div>
   </div>  
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
         <b>{{'monitor.bws.suit_no' | translate}}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
         <span *ngIf="cellData.SuitNo">{{ cellData.SuitNo }} </span>
         <span *ngIf="!cellData.SuitNo"  > - </span>
      </div>
   </div>  
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
         <b>{{'monitor.bws.name_of_liuidators' | translate}}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
         <span *ngIf="cellData.LiquidatorName">{{ cellData.LiquidatorName }}</span>
         <span *ngIf="!cellData.LiquidatorName"  > - </span>         
      </div>
   </div>  
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
         <b>{{'monitor.bws.address_of_liuidators' | translate}}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
         <span *ngIf="cellData.LiquidatorAddress">{{ cellData.LiquidatorAddress }}</span>
         <span *ngIf="!cellData.LiquidatorAddress"  > - </span> 
      </div>
   </div>  
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
         <b>{{'monitor.bws.hearing_dates' | translate}}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
         <span *ngIf="cellData.HearingDate">{{ cellData.HearingDate }}</span>
         <span *ngIf="!cellData.HearingDate"  > - </span> 
      </div>
   </div>
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
         <b>{{'monitor.bws.date_of_notice' | translate}}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
            <span *ngIf="cellData.NoticeDate">{{ cellData.NoticeDate }}</span>
            <span *ngIf="!cellData.NoticeDate"  > - </span>   
      </div>
   </div>
   <div fxLayout="col" class="f-s-adjustment">
      <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
         <b>{{'monitor.bws.wound_up_date' | translate}}</b>
      </div>
      <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
         <span *ngIf="cellData.WoundUpDate">{{ cellData.WoundUpDate }}</span>
         <span *ngIf="!cellData.WoundUpDate"  > - </span>
      </div>
   </div>
</div>
      