import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'app-order-history-data',
  templateUrl: './order-history-data.component.html',
  styleUrls: ['./order-history-data.component.scss']
})
export class OrderHistoryDataCellRenderer implements ICellRendererAngularComp {
  public cellData: any;
  agInit(params: any): void {
    this.cellData = params.data;
    console.log("orderHistoryData",this.cellData)
  }

  refresh(params: any): boolean {
    return false;
  }
}