import { Component, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { PagerService } from '../services/pager.service';

@Component({
	selector: 'app-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.scss'],
	providers: [PagerService]
})

export class PaginationComponent implements OnChanges {
	isMobile: boolean = false;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if(event.target.innerWidth > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
	}
	@Input() pageSize = 0;
	@Input() gridOptions: any;
	@Input() noOfPages = 0;
	private paginationPages: any = {};
	@Input() totalRows: 0;
	selected: number = 10;
	get PaginationPages() {
		return this.paginationPages;
	}

	get currentPage(): number {
		return this.gridOptions ? this.gridOptions.api?.paginationGetCurrentPage() : 0;
	}

	get totalPages(): number {
		return this.noOfPages;
	}

	constructor(private pagerService: PagerService) {
		if(window.screen.width > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
		this.gridOptions = <GridOptions>{
		};
	}

	ngOnChanges(changes: SimpleChanges) {
		for (const propName in changes) {
			if (propName === 'noOfPages') {
				this.paginationPages = this.noOfPages ? this.pagerService.getPager(this.noOfPages, 1) : {};
			}
		}
		this.selected = this.gridOptions.api?.paginationGetPageSize();
	}

	changeRowPage(ev: any) {
		this.gridOptions.api?.paginationSetPageSize(Number(ev.target.value));
	}

	goToPage(index: number) {
		this.gridOptions.api?.paginationGoToPage(index);
		this.paginationPages = this.pagerService.getPager(this.noOfPages, index + 1);
	}

	goToNext() {
		this.gridOptions.api?.paginationGoToNextPage();
		this.paginationPages = this.pagerService.getPager(this.noOfPages, this.currentPage);
	}

	goToPrevious() {
		this.gridOptions.api.paginationGoToPreviousPage();
		this.paginationPages = this.pagerService.getPager(this.noOfPages, this.currentPage);
	}
}
