<article>
    <div class="zanna_div board-margin">
        <div class="data-grid-strip"> 
            <span style="color: #fff !important;">
                {{ 'monitor.dashboard' | translate }}
            </span>
            <span class="end">
                <i class="fa fa-window-minimize" aria-hidden="true" *ngIf = "!minimize" (click)="minimize = !minimize"></i>
                <i class="fa fa-plus" aria-hidden="true" *ngIf = "minimize" (click)="minimize = !minimize"></i>
            </span>
        </div>
        <mat-card fxLayout="col" class="mobile-alignment" fxLayoutAlign="space-around start" [style.overflow]="'auto'" [style.min-width.px]="'1051'" *ngIf="!minimize">
                <span fxFlex="40" class="spacing-1">
                    <div>
                        <mat-card class="card-1">
                            <div class="browse">
                                <label class="pt-22 pb-22 pl-22 card-title">{{ 'cart.summary' | translate }}</label>
                                 <div class="float-right pb-22" *ngIf="summaryDetails?.capacityExceed">
                                    <a href="mailto:cs@experian.com" class="pt-22 pr-22 pb-22 browse pos">
                                        <mat-icon class="icon-warning">
                                            warning
                                        </mat-icon>
                                        <div class="pt-4 float-right upload-link" style="text-decoration: underline;">
                                            <a href="mailto:cs@experian.com">{{ 'monitor.upgrade_plan' | translate }}</a>
                                        </div>
                                    </a>
                                </div> 
                            </div>
                            <div class="fs-2" *ngIf=!isMobile>
                                <div class="pl-22 pr-22 ">{{ 'monitor.plan_alert' | translate }}</div>
                                <div class="pb-22 pl-22 pr-22 ">{{ 'monitor.plan_alert1' | translate }}</div>
                            </div>
                            <div class="fs-2 mb-18" *ngIf=isMobile>
                                <div class="pl-22 pr-22 ">{{ 'monitor.plan_alert' | translate }} {{ 'monitor.plan_alert1' | translate }}</div>
                            </div>
                            <div *ngFor="let item of summary; let i = index">
                                <div fxLayout="col" class="pl-22 pr-22 p-b-3" *ngIf="i % 2 == 0" [ngClass]="i+2 >= summary.length ? 'pb-22': ''">
                                    <div fxFlex="50" class="right">
                                        <div class="ticket">
                                            <div fxLayout="col">
                                                <span class="pt-8 pb-4 pl-11 label" fxFlex="20">
                                                    <!-- {{ 'monitor.businessWatch' | translate }} -->
                                                    {{ item.monitoringType}}
                                                </span>
                                                <span fxFlex="80" style="padding: 10px 4px 0px 0px;">
                                                    <span class="cat-prop dark-text">
                                                        {{ item.monitoredNames}} <span [ngClass]="item.monitoringType == 'LMS'? 'hidden': ''" >/ {{ item.totalNamesSubscribed}}</span>
                                                    </span><br>
                                                    <span class="cat-prop light-text" [ngClass]="item.monitoringType == 'LMS'? 'hide': ''" >
                                                        {{ item.remainingBalance}} {{ 'monitor.remaining' | translate }}
                                                    </span>
                                                </span>
                                            </div>
                                            <mat-progress-bar mode="determinate" value="{{ item.monitoredNames*100 / item.totalNamesSubscribed}}" class="progress-{{i}}"></mat-progress-bar>
                                        </div>
                                    </div>
                                    <div fxFlex="50" class="left" *ngIf="i+1 < summary.length">
                                        <div class="ticket">
                                            <div fxLayout="col">
                                                <span class="pt-8 pb-4 pl-11 label" fxFlex="20">
                                                    {{ summary[i+1].monitoringType }}
                                                </span>
                                                <span fxFlex="80" style="padding: 10px 4px 0px 0px;">
                                                    <span class="cat-prop dark-text">
                                                        {{ summary[i+1].monitoredNames }} <span [ngClass]="summary[i+1].monitoringType == 'LMS'? 'hidden': ''" >/ {{ summary[i+1].totalNamesSubscribed }}</span>
                                                    </span><br>
                                                    <span class="cat-prop light-text" [ngClass]="summary[i+1].monitoringType == 'LMS'? 'hide': ''" >
                                                        {{ summary[i+1].remainingBalance }} {{ 'monitor.remaining' | translate }}
                                                    </span>
                                                </span>
                                            </div>
                                            <mat-progress-bar mode="determinate" value="{{ summary[i+1].monitoredNames*100 / summary[i+1].totalNamesSubscribed }}" class="progress-{{i+1}}"></mat-progress-bar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                    <div>
                        <!-- <mat-card class="card-2">
                            <div>
                                <label class="pt-22 pl-22"><span class="invalid-count">{{ invalidEntitiesCount }} </span>{{ 'monitor.invalidEntities' | translate }}</label>
                            </div>
                            <div>
                                <div class="pl-22 pr-22 ">{{ 'monitor.invalidEntitiesMessage' | translate }}</div>
                            </div>
                            <div class="pb-22">
                                <a href="mailto:cs@experian.com" class="pl-22 pr-22 browse">
                                    <mat-icon class="icon-warning">
                                        warning
                                    </mat-icon>
                                    <div class="pt-4 float-right link">
                                        {{ 'monitor.viewInvalidEntities' | translate }}
                                    </div>
                                </a>
                            </div>
                        </mat-card> -->
                    </div>
                </span>
                <mat-card fxFlex="59" class="spacing-2 m-w-100 p-t-2">
                    <div class="pl-22 pr-22 pt-22 desktop-view">   
                        <label class="pb-22 card-title">{{ 'monitor.addToMonitoringList' | translate }}</label>
                        <div class="col pb-22 browse" style="padding: 0px; display: inline-flex;">
                            <div class="order-status-bar">
                                <a 
                                    [ngClass]="country? 'selected' : ''" 
                                    style="cursor: pointer;" (click)="country = !country; individual = !individual; changeTab()">
                                    <span>{{ 'monitor.company' | translate }}</span>
                                </a>
                                <a  data-filter="" style="cursor: pointer;"
                                    [ngClass]="individual? 'selected' : ''" 
                                    (click)="country = !country; individual = !individual; changeTab()">
                                    <span>{{ 'monitor.individual' | translate }}</span>
                                </a>
                            </div>
                            <div class="float-right pb-22" *ngIf="(bulkuploadLinkStatus?.bulkAlertStatus == 'Completed' || bulkuploadLinkStatus == null) && bulkUploadAccess"> 
                                <a class="pb-22 browse pos" (click)="bulkUpload()"> 
                                    <mat-icon class="download-icon">upload</mat-icon>
                                    <div class="float-right upload-link fs-2">
                                        {{ 'monitor.bulkImport' | translate }}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="pl-22 pr-22 pt-22 mobile-view">   
                        <label class="pb-22 card-title">{{ 'monitor.addToMonitoringList' | translate }}</label>
                        <div class="col pb-22" style="padding: 0px">
                            <div class="order-status-bar">
                                <a 
                                    [ngClass]="country? 'selected' : ''" 
                                    style="cursor: pointer;" (click)="country = !country; individual = !individual; changeTab()">
                                    <span>{{ 'monitor.company' | translate }}</span>
                                </a>
                                <a  data-filter="" style="cursor: pointer;"
                                    [ngClass]="individual? 'selected' : ''" 
                                    (click)="country = !country; individual = !individual; changeTab()">
                                    <span>{{ 'monitor.individual' | translate }}</span>
                                </a>
                            </div>
                            <div class="pb-22 p-t-2 desktop-view" *ngIf="(bulkuploadLinkStatus?.bulkOrderStatus != 'Processing' && bulkuploadLinkStatus?.bulkOrderStatus != 'Uploaded') || bulkuploadLinkStatus == null">
                                <a class="pr-22 pb-22 browse" (click)="bulkUpload()"> 
                                    <mat-icon class="download-icon">get_app</mat-icon>
                                    <div class="pt-4 link fs-2">
                                        {{ 'monitor.bulkImport' | translate }}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>  -->
                    <div *ngIf="individual" class="desktop-view">
                        <form [formGroup]="searchFormIndividual" (ngSubmit)="onSubmitIndividual()">
                                <div class="row pl-22 pr-22 pt-11">
                                    <div class="d_12_12">
                                        <label>{{ 'monitor.country' | translate }}*</label><br />
                                        <!-- <ng-select name="country" formControlName="countryCode" [items]="countryList" 
                                                bindLabel="countryName" 
                                                bindValue="countryCode"
                                                [searchFn]="customSearchFn">
                                        </ng-select> -->
                                        <input type="text" value="Singapore" disabled>
                                    </div>
                                </div>
                                
                                <div class="row pl-22 pr-22 pt-11">
                                    <div class="d_12_12">
                                        <label>{{ 'monitor.name' | translate }}*</label><br />
                                        <input formControlName="name" type="text" [placeholder]="'placeholder.enter_name' | translate">
                                        <div class="error"
                                            *ngIf="searchFormIndividual.get('name')?.hasError('maxlength')">
                                            {{ 'formError.fix100_charactersRequired' | translate }}
                                        </div>
                                    </div>
                                </div>

                                <div fxLayout="col" class="pl-22 pr-22 pt-11" *ngIf="!isMobile">
                                    <div fxFlex="48">
                                        <label>{{ 'monitor.passportNumber' | translate }}*</label><br />
                                        <input formControlName="nric_number" type="text" [placeholder]="'placeholder.nric_passport' | translate"> <!--  (blur)="checkValidation()"-->
                                        <div class="error"
                                            *ngIf="searchFormIndividual.get('nric_number')?.hasError('maxlength')">
                                            {{ 'formError.fix15_charactersRequired' | translate }}
                                        </div>
                                    </div>
                                    <div fxFlex="4"></div>
                                    <div fxFlex="48">
                                        <label>{{ 'monitor.file' | translate }}</label><br />
                                        <input formControlName="file" type="text" [placeholder]="'placeholder.file_ref' | translate"> <!-- (blur)="checkValidation()"-->
                                        <div class="error"
                                            *ngIf="searchFormIndividual.get('file')?.hasError('maxlength')">
                                            {{ 'formError.fix40_charactersRequired' | translate }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row pl-22 pr-22 pt-11" *ngIf="isMobile">
                                    <div class="d_12_12">
                                        <label>{{ 'monitor.passportNumber' | translate }}*</label><br />
                                        <input formControlName="nric_number" type="text" [placeholder]="'placeholder.nric_passport' | translate"> <!-- (blur)="checkValidation()"-->
                                        <div class="error"
                                            *ngIf="searchFormIndividual.get('nric_number')?.hasError('maxlength')">
                                            {{ 'formError.fix15_charactersRequired' | translate }}
                                        </div>
                                    </div>
                                    <div class="d_12_12">
                                        <label class="pt-11">{{ 'monitor.file' | translate }}</label><br />
                                        <input formControlName="file" type="text" [placeholder]="'placeholder.file_ref' | translate"> <!-- (blur)="checkValidation()"-->
                                        <div class="error"
                                            *ngIf="searchFormIndividual.get('file')?.hasError('maxlength')">
                                            {{ 'formError.fix40_charactersRequired' | translate }}
                                        </div>
                                    </div>
                                </div>
                                <div class="zanna_div row browse" *ngIf="!isMobile">
                                    <div class="pr-22 pt-22 pb-22">
                                        <button type="submit" [disabled]="searchFormIndividual.invalid"
                                            class="button button__primary float-right" 
                                            [ngClass]="{'button__disabled': searchFormIndividual.invalid}">
                                            {{ 'monitor.addToMonitoring' | translate }}
                                        </button>
                                    </div>
                                </div>
                                <div class="zanna_div row browse" *ngIf="isMobile">
                                    <div class="pl-22 pt-22 pb-22">
                                        <button type="submit" [disabled]="searchFormIndividual.invalid"
                                            class="button button__primary" 
                                            [ngClass]="{'button__disabled': searchFormIndividual.invalid}">
                                            {{ 'monitor.addToMonitoring' | translate }}
                                        </button>
                                    </div>
                                </div>
                        </form>
                    </div>  
                    
                    <div *ngIf="country" class="desktop-view">
                        <form [formGroup]="searchFormCountry" (ngSubmit)="onSubmitCompany()">
                                <div class="row pl-22 pr-22 pt-11">
                                    <div class="d_12_12">
                                        <label>{{ 'monitor.country' | translate }}*</label><br />
                                        <!-- <ng-select name="country" formControlName="countryCode" [items]="countryList" 
                                                (change)="changeCountry()"
                                                bindLabel="countryName" 
                                                bindValue="countryCode"
                                                [searchFn]="customSearchFn">
                                        </ng-select> -->
                                        <input type="text" value="Singapore" disabled>
                                    </div>
                                </div>
                                
                                <div class="row pl-22 pr-22 pt-11">
                                    <div class="d_12_12">
                                        <label>{{ 'monitor.entity_name' | translate }}</label><br />
                                        <input formControlName="entity_name" type="text" [placeholder]="'placeholder.enter_entity_name' | translate">
                                    </div>
                                    <div class="error"
                                        *ngIf="searchFormCountry.get('entity_name')?.hasError('maxlength')">
                                        {{ 'formError.fix100_charactersRequired' | translate }}
                                    </div>
                                    <div class="error"
                                        *ngIf="searchFormCountry.get('entity_name')?.hasError('minlength')">
                                        {{ 'formError.charactersRequired' | translate }}
                                    </div>
                                </div>

                                <div fxLayout="col" class="pl-22 pr-22 pt-11" *ngIf="!isMobile">
                                    <div fxFlex="48">
                                        <label>{{ 'monitor.entityNo' | translate }}</label><br />
                                        <input formControlName="entity_no" type="text" [placeholder]="'placeholder.entity_number' | translate"> <!-- (blur)="checkValidation()"-->
                                        <div class="error"
                                            *ngIf="searchFormCountry.get('entity_no')?.hasError('maxlength')">
                                            {{ 'formError.fix15_charactersRequired' | translate }}
                                        </div>
                                    </div>
                                    <div fxFlex="4"></div>
                                    <div fxFlex="48">
                                        <label>{{ 'monitor.file' | translate }}</label><br />
                                        <input formControlName="file" type="text" [placeholder]="'placeholder.file_ref' | translate"> <!-- (blur)="checkValidation()"-->
                                        <div class="error"
                                            *ngIf="searchFormCountry.get('file')?.hasError('maxlength')">
                                            {{ 'formError.fix40_charactersRequired' | translate }}
                                        </div>
                                    </div>
                                </div>
                                <div class="pl-22 pr-22 pt-11" *ngIf="isMobile">
                                    <div>
                                        <label>{{ 'monitor.entityNo' | translate }}</label><br />
                                        <input formControlName="entity_no" type="text" [placeholder]="'placeholder.entity_number' | translate"> <!-- (blur)="checkValidation()"-->
                                        <div class="error"
                                            *ngIf="searchFormCountry.get('entity_no')?.hasError('maxlength')">
                                            {{ 'formError.fix15_charactersRequired' | translate }}
                                        </div>
                                    </div>
                                    <div class="pt-11">
                                        <label>{{ 'monitor.file' | translate }}</label><br />
                                        <input formControlName="file" type="text" [placeholder]="'placeholder.file_ref' | translate"> <!-- (blur)="checkValidation()"-->
                                        <div class="error"
                                            *ngIf="searchFormCountry.get('file')?.hasError('maxlength')">
                                            {{ 'formError.fix40_charactersRequired' | translate }}
                                        </div>
                                    </div>
                                </div>
                                <div class="zanna_div row browse">
                                    <div class="pl-22 pr-22 pt-22 pb-22">
                                        <label> {{ 'monitor.searchByEntityNameOrNumber' | translate }} </label> <!-- searchFormCountry.invalid-->
                                        <button type="submit" [disabled]="( searchFormCountry.get('entity_name')?.value.length < 1 && searchFormCountry.get('entity_no')?.value.length < 1 ) || searchFormCountry.invalid"
                                            class="button button__primary float-right desktop-view" 
                                            [ngClass]="{'button__disabled': ( searchFormCountry.get('entity_name')?.value.length < 1 && searchFormCountry.get('entity_no')?.value.length < 1 ) || searchFormCountry.invalid }">
                                            {{ 'monitor.searchForEntity' | translate }}
                                        </button>
                                    </div>
                                    <div class="search-btn mobile-view">
                                        <button type="submit" [disabled]="( searchFormCountry.get('entity_name')?.value.length < 1 && searchFormCountry.get('entity_no')?.value.length < 1 ) || searchFormCountry.invalid"
                                        class="button button__primary" 
                                        [ngClass]="{'button__disabled': ( searchFormCountry.get('entity_name')?.value.length < 1 && searchFormCountry.get('entity_no')?.value.length < 1 ) || searchFormCountry.invalid }">
                                        {{ 'monitor.searchForEntity' | translate }}
                                    </button>
                                    </div>
                                </div>
                        </form>
                    </div>  
                </mat-card>
        </mat-card>
        <mat-card fxLayout="col" class="mobile-alignment" fxLayoutAlign="space-between start" [style.overflow]="'auto'" [style.min-width.px]="'1000'" *ngIf="minimize">
           <span *ngIf="!isMobile" class="pt-11 pb-22 pl-11"> {{ 'monitor.dashboardExpandMsg' | translate }} </span>
           <div *ngIf="isMobile" class="pt-11 pb-22 pl-11"> {{ 'monitor.dashboardExpandMsg' | translate }} </div>
        </mat-card>
    </div>
    
</article>
