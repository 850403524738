<header class="header desktop-header">
    <div class="header-format">
        <span class="title">{{ title }}</span>
        <div class="language-format" *ngIf="!hideHeader">
            <div [matMenuTriggerFor]="menu" class="menu-item">
                <span style="margin-right: 7px;">{{ language }}</span>
                <img src="../../../assets/img/Polygon.svg">
            </div>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="changeLanguage('en')">English</button>
                <button mat-menu-item (click)="changeLanguage('jp')">Japanese</button>
            </mat-menu>
        </div>
        <div class="logout-btn-format" (click)="logout()" *ngIf="!hideHeader">
            <span class="logout-btn">Logout</span>
        </div>
    </div>
</header>
<div class="mobile-header">
    <div class="logo">
        <img src="../../../assets/img/logo-experian.svg">
    </div>
    <div class="title">{{ title }}</div>
    <div class="menu-icon" id="toggleSlideMenu" *ngIf="!hideMenu">
        <a (click)="showMobileMenu()" *ngIf='!mobileMenu'><i class="fa fa-bars" aria-hidden="true"></i></a>
        <a (click)="hideMobileMenu()" *ngIf='mobileMenu'><i class="fa fa-times" aria-hidden="true"></i></a>
    </div>

    <div class="mobile-menu" id="check" *ngIf='mobileMenu'>
        <section class="pl-7">
            <nav (click)="tabChange($event)">
                <a (click)="gotToHome()" class="selected menu-section" *ngIf="homeServiceAccess">
                    <div class="menu-container pl-20">
                        <div class="menu-type-icon">
                            <img src="../../../assets/img/icon-home.svg" alt="">
                        </div>
                        <div class="menu-title pr-2 order-align">
                            {{ 'app.sidebar.report' | translate }}
                        </div>
                    </div>
                </a>
                <!-- Staging Monitoring tab -->
                <a routerLink="/global-monitor" class="menu-section" *ngIf="globalMonitoringService">
                    <div class="menu-container">
                        <div class="menu-type-icon">
                            <img src="../../../assets/img/global-monitor.svg" alt="">
                        </div>
                        <div class="menu-title pr-2">
                            {{ 'app.sidebar.globalMonitoring' | translate }}
                        </div>
                    </div>
                </a>
                <a routerLink="/monitor" class="menu-section" *ngIf="monitoringService">
                    <div class="menu-container">
                        <div class="menu-type-icon">
                            <i class="fa fa-compass"></i>
                        </div>
                        <div class="menu-title pr-2">
                            {{ 'app.sidebar.monitoring' | translate }}
                        </div>
                    </div>
                </a>
                <a routerLink="/order-history" class="menu-section" *ngIf="homeServiceAccess">
                    <div class="menu-container pl-20">
                        <div class="menu-type-icon">
                            <img src="../../../assets/img/icon-order-history.svg" alt="">
                        </div>
                        <div class="menu-title pr-2 order-align">
                            {{ 'app.sidebar.orderHistory' | translate }}
                        </div>
                    </div>
                </a>
                <a routerLink="/page-not-found-component" class="menu-section">
                    <div class="menu-container">
                        <div class="menu-type-icon">
                            <i class="fa fa-cog"></i>
                        </div>
                        <div class="menu-title pr-2">
                            {{ 'app.sidebar.setting' | translate }}
                        </div>
                    </div>
                </a>
                <a (click)="showLanguageMenu()" class="menu-section">
                    <div class="menu-container">
                        <div class="menu-type-icon">
                            <i class="fa fa-language"></i>
                        </div>
                        <div class="menu-title pr-2">
                            {{ language }}
                        </div>
                        <div class="arrow-icon">
                            <i class="fa fa-chevron-down" *ngIf='!showLanguage'></i>
                            <i class="fa fa-chevron-up" *ngIf='showLanguage'></i>
                        </div>
                    </div>
                </a>
                <a *ngIf='showLanguage' class="language">
                    <span (click)="changeLanguage('en')">English</span>
                </a>
                <a *ngIf='showLanguage' class="language">
                    <span (click)="changeLanguage('jp')">Japanese</span>
                </a>
                <a (click)="logout()" class="menu-section">
                    <div class="menu-container">
                        <div class="menu-type-icon">
                            <i class="fa fa-sign-out"></i>
                        </div>
                        <div class="menu-title pr-2">
                            Logout
                        </div>
                    </div>
                </a>
            </nav>
        </section>
    </div>
</div>