<div id="confirm">
    <!-- <div class="close-dialog">
        <mat-icon class="close-btn" (click)="dialogRef.close(false)">close</mat-icon>
    </div> -->
    <h1 matDialogTitle class="confirm-title">
        <mat-icon class="icon-warning">
            info
        </mat-icon>
        {{ action }}
    </h1>
    <div matDialogContent class="content-msg">{{ confirmMessage }}</div>
    <div mat-dialog-actions class="pt-24" style="justify-content: center;">
        <button (click)="dialogRef.close(false)" style="font-weight: 500; width: 100px;" class="button button__basic">
            {{ 'confirmAction.ok' | translate }}
        </button>
    </div>
</div>