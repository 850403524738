import { Component, OnInit, AfterViewInit, OnDestroy, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { GridOptions, GridApi } from 'ag-grid-community';
import { Router } from '@angular/router';
import { GridCellRendererComponent } from '../grid-cell-renderer/grid-cell-renderer.component';
import { BulkUploadGridService } from './bulk-upload-grid.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoaderService } from '../services/loader.service';
import { CustomToolTipComponent } from '../custom-tooltip/custom-tooltip.component';
import { interval, Subscription } from 'rxjs';
import { HomeService } from '../home/home.service';
import * as moment from 'moment';
import { ErrorTranslationResultDialogComponent } from '../error-translation-result-dialog-box/error-translation-result-dialog.component';
import { MatchResultComponent } from './match-result/match-result';
import { BulkCellRendererComponent } from './grid-cell-renderer.component';
import { BulkGridCellRendererComponent } from './bulk-grid-cell-renderer/bulk-grid-cell-renderer.component';
import { OfflineSearchComponent } from './offline-search/offline-search';
import { TurnoverCellRendererComponent } from './turnover-cell-renderer.component';
import { UploadCompleteDialogComponent } from './upload-complete-dialog-box/upload-complete-dialog.component';
import { ErrorDialogComponent } from '../error-dialog-box/error-dialog.component';
import { NoMatchPopupCellRendererComponent } from './no-match-popup-cell-renderer/no-match-popup-cell-renderer.component';
import { DownTimeComponent } from './downtime/downtime.component';
import { MatchCellRendererComponent } from './match-cell-renderer.component';

@Component({
	selector: 'app-bulk-upload-grid',
	templateUrl: './bulk-upload-grid.component.html',
	styleUrls: ['./bulk-upload-grid.component.scss']
})

export class BulkUploadGridComponent implements OnInit, AfterViewInit, OnDestroy {
	columnDefs: any = [];
	foundRecords: any = [];
	noMatchRecords: any = [];
	duplicateRecords: any = [];
	foundRecordsCount: number = 0;
	noMatchRecordsCount: number = 0;
	duplicateRecordsCount: number = 0;
	foundRecordsLoader: boolean = true;
	NoMatchLoader: boolean = true;
	gridOptions: any;
	gridColumnApi: any;
	isCompletedBulkUpload: string = 'Processing';
	sharedData: any;
	api: any;
	selectedStatusBar: any = 'Match_Found';
	bulkData: any = [];
	@Input() validatedData: any;
	@Output() completeStatus = new EventEmitter<any>();
	@Output() getCartDetails = new EventEmitter<any>();
	icons: any;
	tooltipShowDelay: any;
	blob: any;
	loader: any = 0;
	uncollectedCount = 0;
	isGdn: boolean = true;
	isGdnSet: boolean = false;
	minutes: number = 0;
	mySubscription: Subscription;
	loadDataSubscription: Subscription;
	bulkOrderNumber: any = 70;
	private paginationPageSize = 5;
	private totalPages = 0;
	initialFlag: boolean = false;
	domLayout: any = "autoHeight";
	loadFirstTime:boolean=true;
	bulkUploadStatus: any;
	bulkUploadSettingButton: boolean = false;
	addToCartButton: boolean = false;
	selectedRecords: any;
	get PaginationPageSize(): number {
		return this.paginationPageSize;
	}

	get gridAPI(): GridApi {
		return this.gridOptions.api;
	}

	get TotalPages(): number {
		return this.totalPages;
	}

	constructor(public uploadService: BulkUploadGridService, public dialog: MatDialog, public homeService: HomeService,
		public loaderService: LoaderService, public translateService: TranslateService, public router: Router) {
		this.loaderService.isLoading.next(true);
		this.getColDefs();
		this.gridOptions = <GridOptions>{
			onGridReady: () => {
				setTimeout(() => {
					this.gridOptions.api?.sizeColumnsToFit();
				}, 1000);
				this.totalPages = this.gridOptions.api?.paginationGetTotalPages();
			},
			onGridSizeChanged: () => {
				this.gridOptions.api.sizeColumnsToFit();
			},
			overlayNoRowsTemplate: '<h4> No record found. </h4>',
			rowSelection: 'multiple',
			pagination: true,
			paginationPageSize: 10,
			animateRows: true,
			suppressCellSelection: true,
			enableCellTextSelection: true,
			defaultColDef: {
				flex: 1,
				wrapText: true,
				resizable: true,
				autoHeight: true,
				unSortIcon: true,
				sortable: true,
				suppressMenu: true,
				lockPosition: true,
				tooltipComponent: 'customTooltip'
			}
		};

		this.gridOptions.frameworkComponents = {
			"cellRenderer": GridCellRendererComponent,
			"bulkCellRenderer": BulkGridCellRendererComponent,
			"scoreRenderer": BulkCellRendererComponent,
			"matchesRenderer": MatchCellRendererComponent,
			"customTooltip": CustomToolTipComponent,
			"turnoverCellRendererComponent": TurnoverCellRendererComponent,
			"noMatchPopupCellRenderer": NoMatchPopupCellRendererComponent
		};

		this.tooltipShowDelay = 0;

		this.icons = {
			sortUnSort: '<img src="../../assets/img/sort.svg">',
			sortAscending: '<img src="../../assets/img/sort-asc.svg">',
			sortDescending: '<img src="../../assets/img/sort-desc.svg">'
		};
		this.uploadService.setSid(localStorage.getItem('sessionID')); 
	}

	
	onGridReady(params: any) {
		this.gridColumnApi = params.columnApi;
		this.api = params.api;
	}

	ngOnInit(): void {
	}

	getIsBulkUpload() {
			this.mySubscription = interval(30000).subscribe((x => {
				this.bulkUpladProcessingStatus();
				if(this.selectedStatusBar == 'Match_Found') {
					this.loadData();
				} else if(this.selectedStatusBar == 'Match_Not_Found') {
					this.loadNoMatchData();
				}
			}));
	}

	ngOnChanges(changes: SimpleChanges) {
		if(changes?.validatedData?.currentValue) {
			this.selectedStatusBar = 'Match_Found';
			this.isCompletedBulkUpload = this.validatedData.bulkOrderStatus; 
			this.bulkOrderNumber = this.validatedData?.bulkOrderNumber; 
			this.NoMatchLoader = true;
			this.foundRecordsLoader = true;
			this.foundRecords = [];
			this.noMatchRecords = [];
			this.duplicateRecords = [];
			this.duplicateRecordsCount = 0;
			this.noMatchRecordsCount = 0;
			this.foundRecordsCount = 0;
			if(this.validatedData.bulkOrderStatus == 'Processing' || this.validatedData.bulkOrderStatus == 'Uploaded') {
				this.bulkUpladProcessingStatus();
				this.getColDefs();
				this.loadData();
				this.getIsBulkUpload();
			} else {
				this.bulkUpladProcessingStatus();
				this.getColDefs();
				this.loadData();
			}
			
		}
	}

	ngAfterViewInit(): void {
		this.gridAPI.resetRowHeights();
	}

	onFilterChanged(event: any) {
		if (this.gridOptions.api.getDisplayedRowCount() == 0) {
			this.gridAPI.showNoRowsOverlay();
		} else {
			this.gridAPI.hideOverlay();
		}
		this.gridOptions.api.deselectAll();
	}

	checkProcessingRecords() {
		let present = this.bulkData.some((item: any) => ((item.itemStatus).toLowerCase() == "processing"));
		if (present) {
		}
	}

	getColDefs() {
		if(this.isGdn) {
			this.translateService.get(['bulk.upload.identification','bulk.upload.product', 'bulk.upload.country_address', 'bulk.upload.turnAroundTime', 'bulk.upload.score',
				'bulk.upload.action']).subscribe(value => {
					this.columnDefs = [
						{
							field: 'RowSelect',
							headerName: ' ',
							checkboxSelection: true,
							headerCheckboxSelection: true,
							headerCheckboxSelectionFilteredOnly: true,
							sortable: false,
							width: 60,
							maxWidth: 60,
							cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding-top': '18px'}
						},
						{
							field: 'entityName',
							headerName: value['bulk.upload.identification'],
							tooltipField: 'entityName',
							tooltipComponentParams: { type: 'fileRef' },
							width: 220,
							minWidth: 220,
							floatingFilter: true,
							filter: 'agTextColumnFilter',
							filterParams: {
								trimInput: true
							},
							cellRenderer: function(param: any){
								return "<b>Requested for:</b> "+param.data?.userSearchTerm + "</br>" + "<b>Match found:</b> " + param.data?.searchEntityName+ '<br/>';
							},
							cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
						},
						{
							field: 'availabilityReportType',
							headerName: value['bulk.upload.product'],
							width: 140,
							minWidth: 140,
							floatingFilter: true,
							filter: 'agTextColumnFilter',
							filterParams: {
								trimInput: true
							},
							cellRenderer: function(param: any){
								if(param.data.availabilityReportType)
									return param.data.availabilityReportType;
								else 
									return param.data.userReportType;
							},
							cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
						},
						{
							field: 'countryName',
							headerName: value['bulk.upload.country_address'],
							tooltipField: 'address',
							width: 150,
							minWidth: 150,
							floatingFilter: true,
							filter: 'agTextColumnFilter',
							filterParams: {
								trimInput: true
							},
							//cellRenderer: "scoreRenderer",
							cellRenderer: function(param: any){
								//return "<b>Requested for:</b> "+param.data?.userAddress + "</br>" + "<b>Match found:</b> " + param.data?.searchCity+ '<br/>';
								var row = "";
								if(param.data?.countryName)
									row = param.data?.countryName + " / ";
								if(param.data?.userCity)
									row = row + param.data?.userCity+" / ";
								if(param.data?.userAddress) 
									row = row + param.data?.userAddress;
								//return row;
								var rowMatch = "";
								if(param.data?.countryName)
								rowMatch = param.data?.countryName + "/ ";								
								if(param.data?.searchStreet) 
								rowMatch = rowMatch + param.data?.searchStreet +" ";
								if(param.data?.searchCity)
								rowMatch = rowMatch + param.data?.searchCity;
								return "<b>Requested for:</b> "+ row + "</br>" + "<b>Match found:</b> " + rowMatch + '<br/>';

							} ,
							cellRendererParams: {
								onClick: this.onActionClick.bind(this),
								label: 'address'
							},
							cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
						},
						{
							field: 'availabilitySpeedDescription',
							headerName: value['bulk.upload.turnAroundTime'],
							width: 150,
							minWidth: 150,
							floatingFilter: true,
							filter: 'agTextColumnFilter',
							filterParams: {
								trimInput: true
							},
							cellRenderer: "turnoverCellRendererComponent",
							cellRendererParams: {
								onClick: this.onActionClick.bind(this),
								label: 'bulk-upload-matches'
							},
							cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
						},
						{
							field: 'availableTill',
							headerName: value['bulk.upload.score'],
							width: 150,
							minWidth: 150,
							sortable: false,
							cellRenderer: "matchesRenderer",
							cellRendererParams: {
								onClick: this.onActionClick.bind(this),
								label: 'bulk-upload-matches'
							},
							cellStyle: { 'overflow': 'visible', 'text-align': 'center', 'padding': '13px 18px', 'justify-content': 'center' }
						},
						{
							width: 110,
							maxWidth: 110,
							sortable: false,
							headerName: value['bulk.upload.action'],
							cellRenderer: "cellRenderer",
							cellRendererParams: {
								onClick: this.onActionClick.bind(this),
								label: 'bulk-upload'
							},
							cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '6px 18px' }
						},
					]
				});
			} else {
				this.translateService.get(['bulk.upload.identification','bulk.upload.product', 'bulk.upload.country_address', 'bulk.upload.turnAroundTime', 'bulk.upload.score',
					'bulk.upload.action']).subscribe(value => {
						this.columnDefs = [
							{
								field: 'RowSelect',
								headerName: ' ',
								checkboxSelection: true,
								headerCheckboxSelection: true,
								headerCheckboxSelectionFilteredOnly: true,
								sortable: false,
								width: 60,
								maxWidth: 60,
								cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding-top': '18px'}
							},
							{
								field: 'entityName',
								headerName: value['bulk.upload.identification'],
								tooltipField: 'entityName',
								tooltipComponentParams: { type: 'fileRef' },
								width: 220,
								minWidth: 220,
								floatingFilter: true,
								filter: 'agTextColumnFilter',
								filterParams: {
									trimInput: true
								},
								cellRenderer: function(param: any){
									return "<b>Requested for:</b> "+param.data?.userSearchTerm + "</br>" + "<b>Match found:</b> " + param.data?.searchEntityName+ '<br/>';
								},
								cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
							},
							{
								field: 'availabilityReportType',
								headerName: value['bulk.upload.product'],
								width: 140,
								minWidth: 140,
								floatingFilter: true,
								filter: 'agTextColumnFilter',
								filterParams: {
									trimInput: true
								},
								cellRenderer: function(param: any){
									if(param.data.availabilityReportType)
										return param.data.availabilityReportType;
									else 
										return param.data.userReportType;
								},
								cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
							},
							{
								field: 'countryName',
								headerName: value['bulk.upload.country_address'],
								tooltipField: 'address',
								width: 150,
								minWidth: 150,
								floatingFilter: true,
								filter: 'agTextColumnFilter',
								filterParams: {
									trimInput: true
								},
								// cellRenderer: function (param: any) {
								// 	var row = param.data?.countryName + "</br>";
								// 	if(param.data?.searchStreet)
								// 		row = row + param.data?.searchStreet+"</br>";
								// 	if(param.data?.searchCity)
								// 		row = row + param.data?.searchCity + " ";
								// 	if(param.data?.searchPostCode) 
								// 		row = row + param.data?.searchPostCode;
								// 	return row;
								// },
								//cellRenderer: "scoreRenderer",
								cellRenderer: function(param: any){
									//return "<b>Requested for:</b> "+param.data?.userAddress + "</br>" + "<b>Match found:</b> " + param.data?.searchCity+ '<br/>';
									var row = "";
									if(param.data?.countryName)
										row = param.data?.countryName + " / ";
									if(param.data?.userCity)
										row = row + param.data?.userCity+" / ";
									if(param.data?.userAddress) 
										row = row + param.data?.userAddress;
									//return row;
									var rowMatch = "";
									if(param.data?.countryName)
									rowMatch = param.data?.countryName + "/ ";								
									if(param.data?.searchStreet) 
									rowMatch = rowMatch + param.data?.searchStreet +" ";
									if(param.data?.searchCity)
									rowMatch = rowMatch + param.data?.searchCity +" ";
									if(param.data?.searchPostCode)
									rowMatch = rowMatch + param.data?.searchPostCode;
									return "<b>Requested for:</b> "+ row + "</br>" + "<b>Match found:</b> " + rowMatch + '<br/>';
								} ,
								cellRendererParams: {
									onClick: this.onActionClick.bind(this),
									label: 'address'
								},
								cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
							},
							{
								field: 'availabilitySpeedDescription',
								headerName: value['bulk.upload.turnAroundTime'],
								width: 150,
								minWidth: 150,
								floatingFilter: true,
								filter: 'agTextColumnFilter',
								filterParams: {
									trimInput: true
								},
								cellRenderer: "turnoverCellRendererComponent",
								cellRendererParams: {
									onClick: this.onActionClick.bind(this),
									label: 'bulk-upload-matches'
								},
								cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
							},
							{
								width: 110,
								maxWidth: 110,
								sortable: false,
								headerName: value['bulk.upload.action'],
								cellRenderer: "cellRenderer",
								cellRendererParams: {
									onClick: this.onActionClick.bind(this),
									label: 'bulk-upload'
								},
								cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '6px 18px' }
								
							},
						]
					});
				}
	}


	getColDefsNoMatch() {
		let distributionCode = localStorage.getItem('distributionCode');
		if(distributionCode == 'JPN'){
			this.translateService.get(['bulk.upload.identification','bulk.upload.product', 'bulk.upload.country_address', 'bulk.upload.issues', 'bulk.upload.manual_report_type', 'bulk.upload.translation_language', 'bulk.upload.turnAroundTime', 'bulk.upload.score',
			'bulk.upload.action']).subscribe(value => {
				this.columnDefs = [
					{
						field: 'RowSelect',
						headerName: ' ',
						checkboxSelection: true,
						headerCheckboxSelection: true,
						headerCheckboxSelectionFilteredOnly: true,
						sortable: false,
						width: 60,
						maxWidth: 60,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding-top': '5px'}
					},
					{
						field: 'userSearchTerm',
						headerName: value['bulk.upload.identification'],
						tooltipField: 'entityName',
						tooltipComponentParams: { type: 'fileRef' },
						width: 220,
						minWidth: 220,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'userReportType',
						headerName: value['bulk.upload.product'],
						width: 140,
						minWidth: 140,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'countryName',
						headerName: value['bulk.upload.country_address'],
						tooltipField: 'address',
						width: 150,
						minWidth: 150,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellRenderer: function (param: any) {
							var row = "";
							if(param.data?.countryName)
								row = param.data?.countryName + "</br>";
							if(param.data?.userCity)
								row = row + param.data?.userCity+"</br>";
							if(param.data?.userAddress) 
								row = row + param.data?.userAddress;
							return row;
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						width: 110,
						minWidth: 110,
						sortable: false,
						headerName: value['bulk.upload.manual_report_type'],
						cellRenderer: "noMatchPopupCellRenderer",
						cellRendererParams: {
							onClick: this.onActionClick.bind(this),
							label: 'reportType'
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '6px 0px', 'width': '100%' }
					},
					{
						width: 110,
						minWidth: 110,
						sortable: false,
						headerName: value['bulk.upload.translation_language'],
						cellRenderer: "noMatchPopupCellRenderer",
						cellRendererParams: {
							onClick: this.onActionClick.bind(this),
							label: 'language'
						},
						cellStyle: { 'padding': '6px 0px' }
					},
					{
						width: 110,
						maxWidth: 110,
						sortable: false,
						headerName: value['bulk.upload.action'],
						cellRenderer: "bulkCellRenderer",
						cellRendererParams: {
							onClick: this.onActionClick.bind(this),
							label: 'bulk-no-match'
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '6px 18px' }
					},
				]
			});
		} else {
			this.translateService.get(['bulk.upload.identification','bulk.upload.product', 'bulk.upload.country_address', 'bulk.upload.issues', 'bulk.upload.manual_report_type', 'bulk.upload.translation_language', 'bulk.upload.turnAroundTime', 'bulk.upload.score',
			'bulk.upload.action']).subscribe(value => {
				this.columnDefs = [
					{
						field: 'RowSelect',
						headerName: ' ',
						checkboxSelection: true,
						headerCheckboxSelection: true,
						headerCheckboxSelectionFilteredOnly: true,
						sortable: false,
						width: 60,
						maxWidth: 60,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding-top': '5px'}
					},
					{
						field: 'userSearchTerm',
						headerName: value['bulk.upload.identification'],
						tooltipField: 'entityName',
						tooltipComponentParams: { type: 'fileRef' },
						width: 220,
						minWidth: 220,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'userReportType',
						headerName: value['bulk.upload.product'],
						width: 140,
						minWidth: 140,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'countryName',
						headerName: value['bulk.upload.country_address'],
						tooltipField: 'address',
						width: 110,
						minWidth: 110,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellRenderer: function (param: any) {
							var row = "";
							if(param.data?.countryName)
								row = param.data?.countryName + "</br>";
							if(param.data?.userCity)
								row = row + param.data?.userCity+"</br>";
							if(param.data?.userAddress) 
								row = row + param.data?.userAddress;
							return row;
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						width: 110,
						minWidth: 110,
						sortable: false,
						headerName: value['bulk.upload.manual_report_type'],
						cellRenderer: "noMatchPopupCellRenderer",
						cellRendererParams: {
							onClick: this.onActionClick.bind(this),
							label: 'reportType'
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '6px 0px' }
					},
					{
						width: 110,
						maxWidth: 110,
						sortable: false,
						headerName: value['bulk.upload.action'],
						cellRenderer: "bulkCellRenderer",
						cellRendererParams: {
							onClick: this.onActionClick.bind(this),
							label: 'bulk-no-match'
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '6px 18px' }
					},
				]
			});
		}
	}

	getColDefsDuplicate() {
		this.translateService.get(['bulk.upload.identification','bulk.upload.product', 'bulk.upload.country_address', 'bulk.upload.turnAroundTime', 'bulk.upload.score',
			'bulk.upload.action']).subscribe(value => {
				this.columnDefs = [
					{
						field: 'entityName',
						headerName: value['bulk.upload.identification'],
						tooltipField: 'entityName',
						tooltipComponentParams: { type: 'fileRef' },
						width: 220,
						minWidth: 220,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellRenderer: function(param: any){
							return "<b>Result for:</b> "+param.data.entityName + "</br>" + param.data.entityName+ '<br/>';
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'reportType',
						headerName: value['bulk.upload.product'],
						width: 140,
						minWidth: 140,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'country',
						headerName: value['bulk.upload.country_address'],
						tooltipField: 'address',
						width: 150,
						minWidth: 150,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'issue',
						headerName: value['bulk.upload.issues'],
						width: 140,
						minWidth: 140,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellRenderer: function(param: any){
							if(param.data.duplicateCount == 0) {
								return 1 + " duplicate record(s) found on the same subject";
							} else {
								return param.data.duplicateCount + " duplicate record(s) found on the same subject";
							}
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					}
				]
			});
	}

	ngOnDestroy(): void {
		if(this.mySubscription)
		this.mySubscription.unsubscribe();
	}

	changeLanguage(ev: any) {
		this.getColDefs();
		this.gridOptions.api.refreshHeader({ forrce: true });
		setTimeout(() => {
			this.gridOptions.api?.sizeColumnsToFit();
		}, 100);
	}

	loadData() {
		if(this.foundRecordsLoader)
		this.loaderService.isLoading.next(true);
			this.foundRecordsLoader = false;
			var fetchFrom = this.foundRecords.length;
			if(this.loadDataSubscription)
			this.loadDataSubscription.unsubscribe();
			this.loadDataSubscription = this.uploadService.bulkUploadList(this.foundRecords.length, this.bulkOrderNumber, this.selectedStatusBar).subscribe( 
			(res: any) => {
				if(this.foundRecords.length == 0)
				this.loaderService.isLoading.next(false);
				if(res.success == true){
					if(!this.isGdnSet &&res.data && res.data.result && res.data.result.length > 0 && res.data?.result[0].supplierCode == "WB") {
						this.isGdn = false;
						this.isGdnSet = true;
						this.getColDefs();
					}
					if(fetchFrom != 0) {
						this.gridAPI.applyTransaction({
							add: res.data?.result
						});
						res.data?.result?.map((record: any) => {
							this.foundRecords.push(record);
						});
					} else {
						this.foundRecords = res.data?.result;
					}
					if(this.foundRecords)
					this.foundRecordsCount = this.foundRecords.length;
					this.duplicateRecordsCount = res.data?.duplicateCount;
					var countCal:number = 0;
					res.data?.countDetails.map((count: any, index: number) => {
						if(count.status == 'Match_Not_Found') {
							countCal = countCal + count.count;
							this.noMatchRecordsCount = countCal;
						}
						if(count.status == 'Added_To_Cart_Offline') {
							countCal = countCal + count.count;
							this.noMatchRecordsCount = countCal;
						}
						if(count.status == 'Tat_Not_Available_Offline') {
							countCal = countCal + count.count;
							this.noMatchRecordsCount = countCal;
						}
					});
				}
			},
			(err: any) => {
				this.loaderService.isLoading.next(false);
			}
		); 
	}

	loadNoMatchData() {
		if(this.NoMatchLoader)
			this.loaderService.isLoading.next(true);
			this.NoMatchLoader = false;
			var fetchFrom = this.noMatchRecords.length;
			this.uploadService.bulkUploadList(this.noMatchRecords.length,  this.bulkOrderNumber, this.selectedStatusBar).subscribe(
			(res: any) => {
				if(this.noMatchRecords.length == 0)
				this.loaderService.isLoading.next(false);
				if(res.success == true){
					if(fetchFrom != 0) {
						this.gridAPI.applyTransaction({
							add: res.data?.result
						});
						res.data?.result?.map((record: any) => {
							this.noMatchRecords.push(record);
								//this.gridOptions.api.setRowData(this.uploadData);
						});
					} else {
						this.noMatchRecords = res.data?.result;
					}
					if(this.noMatchRecords)
					this.noMatchRecordsCount = this.noMatchRecords.length;
				} else{

				}
			},
			(err: any) => {
				this.loaderService.isLoading.next(false);
			}
		); 
	}

	loadDuplicateData() {
		if(this.duplicateRecords.length == 0) {
			this.loaderService.isLoading.next(true);
				var fetchFrom = this.duplicateRecords.length;
				this.uploadService.bulkUploadList(this.duplicateRecords.length, this.bulkOrderNumber , this.selectedStatusBar).subscribe(
				(res: any) => {
					this.loaderService.isLoading.next(false);
					if(res.success == true){
						this.duplicateRecords = res.data?.duplicateList;
						if(this.duplicateRecords)
						this.duplicateRecordsCount = res.data?.duplicateCount;
					}
				},
				(err: any) => {
					this.loaderService.isLoading.next(false);
				}
			); 
		}
	}

	bulkUpladProcessingStatus() {
		this.uploadService.bulkUploadProcessingStatus(this.bulkOrderNumber).subscribe(
		(res: any) => {
			if(res.success && res.data != null){
				if(res.data && res.data.statusJson)
				this.bulkUploadStatus = JSON.parse(res.data.statusJson);
				this.loader = ( this.bulkUploadStatus?.CurrentCount / this.bulkUploadStatus?.TotalCount ) * 100;
				var val = this.bulkUploadStatus?.EstimatedTime / 60;
				this.minutes = Math.round(val);
				this.sharedData = {
					"fileName": res.data.bulkReportFileName,
					"bulkOrderNumber": this.bulkOrderNumber,
					"status": "complete",
					"data": this.bulkUploadStatus
				};
				if(this.bulkUploadStatus.CurrentCount == this.bulkUploadStatus.TotalCount) {
					this.isCompletedBulkUpload = "Processed";
					if(this.mySubscription)
					this.mySubscription.unsubscribe();
					var obj: any = { 
						"bulkOrderNumber": this.bulkOrderNumber,
						"bulkOrderStatus": "Processed"
					};
					if(this.mySubscription)
					this.mySubscription.unsubscribe();
					let reviewed  = localStorage.getItem('bulkProcessComplete');
					if(reviewed != 'reviewed' ) {
						let dialogRef = this.dialog.open(UploadCompleteDialogComponent, {
							disableClose: true,
							width: "600px",
							// height: "260px",
							panelClass: 'advanced-search-dialog',
							data: this.sharedData
						});
						dialogRef.afterClosed().subscribe(res => {
							localStorage.setItem('bulkProcessComplete', 'reviewed');
							this.completeStatus.emit(obj);
						});	
					}
				}
			} else {
				// if(this.mySubscription)
				// this.mySubscription.unsubscribe();
			}
		});
	}

	cancelProcessing() {
		this.sharedData.status = 'cancel';
		var obj: any = { 
			"bulkOrderNumber": this.bulkOrderNumber,
			"bulkOrderStatus": "Cancelled"
		};
		let dialogRef = this.dialog.open(UploadCompleteDialogComponent, {
			disableClose: true,
			width: "600px",
			// height: "320px",
			panelClass: 'advanced-search-dialog',
			data: this.sharedData
		});
		dialogRef.afterClosed().subscribe(res => {
			if(res) {
				if(this.mySubscription)
					this.mySubscription.unsubscribe();
				this.isCompletedBulkUpload = res.bulkOrderStatus;
				this.bulkOrderNumber = res.bulkOrderNumber; 
				localStorage.setItem('bulkProcessComplete', 'reviewed');
				this.completeStatus.emit(obj);
				this.bulkUpladProcessingStatus();
				this.loadData();
			}
		});
	}

	changeStatusBar(status: any) {
		this.selectedStatusBar = status;
		if(this.selectedStatusBar == 'Match_Found') {
			this.bulkUploadSettingButton = false;
			this.addToCartButton = false;
			this.getColDefs();
			this.loadData();
		} else if(this.selectedStatusBar == 'Match_Not_Found') {
			this.bulkUploadSettingButton = false;
			this.addToCartButton = false;
			this.getColDefsNoMatch()
			this.loadNoMatchData();
			if(!this.NoMatchLoader) {
				this.loaderService.isLoading.next(true);
				setTimeout(() => {
					this.loaderService.isLoading.next(false);
				}, 5000);
			}
		} else if(this.selectedStatusBar == 'Duplicate') {
			this.bulkUploadSettingButton = false;
			this.addToCartButton = false;
			this.getColDefsDuplicate()
			this.loadDuplicateData();
		}
	}

	offline() {
			let selectedNodes = this.api.getSelectedNodes();
    		var obj = selectedNodes.map((node: any) => node.data);
			let dialogRef = this.dialog.open(OfflineSearchComponent, {
				disableClose: true,
				width: "50vw",
				panelClass: 'search-result-dialog',
				data: {
					"data": obj
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if(res) {
					this.api.getSelectedNodes().map((record: any) => {
						if(record.data.productAvailabilityJson != null) {
							var options: any = JSON.parse(record.data.productAvailabilityJson);
							options.map((option: any) => {
								if(option.SpeedDescription == res.SpeedDescription && option.reportType == res.reportType) {
									record.data.availabilityReportType = res.reportType;
									record.data.availabilitySpeedDescription = res.SpeedDescription;
									record.data.translation = res.translation;
									this.gridAPI.refreshCells();
								}
							})
							
						}
					})
				}
			});
	}

	onSelectionChanged($event: any) {
		this.checkButtonsStatus();
	}

	checkButtonsStatus() {
		var notAdded: any = [];
		notAdded = this.api.getSelectedNodes().filter((data: any) => data.data.status != "Added_To_Cart_Offline");
		if(notAdded.length > 0 && this.api.getSelectedNodes().length > 1) {
			this.bulkUploadSettingButton = true;
			this.addToCartButton = true;
		} else {
			this.bulkUploadSettingButton = false;
			this.addToCartButton = false;
		}
	}

	onOnlineSelectionChanged($event: any) {
		this.checkOnlineButtonsStatus();
	}

	checkOnlineButtonsStatus() {
		var notAdded: any = [];
		notAdded = this.api.getSelectedNodes().filter((data: any) => data.data.status != "Added_To_Cart" && data.data.availabilityReportType != null);
		if(notAdded.length > 0 && this.api.getSelectedNodes().length > 1) {
			this.bulkUploadSettingButton = true;
			this.addToCartButton = true;
		} else {
			this.bulkUploadSettingButton = false;
			this.addToCartButton = false;
		}
	}
	
	bulkAddToCart(status: any) {
		var gdnDown: any = this.api.getSelectedNodes().filter((data: any) => data.data.downtime == true);
		if(gdnDown.length > 0) {
			let dialogRef = this.dialog.open(DownTimeComponent, {
				disableClose: true,
				width: "600px",
				height: "360px",
				panelClass: 'advanced-search-dialog',
				data: gdnDown
			});
			dialogRef.afterClosed().subscribe(res => {
				if(res) {
					this.process(status);
				}
			});	
		} else {
			this.process(status);
		}	
	}

	process(status: any) {
		this.loaderService.isLoading.next(true);
		var payload: any = [];
		var notAdded: any = this.api.getSelectedNodes().filter((data: any) => data.data.status != status && data.data.availabilityReportType != null);
		notAdded.map((record: any) => {
			var obj: any = {
				"BulkReportOrderItemId": record.data.id,
				"Translation": record.data.translation == "N" ? false : true
			};
			payload.push(obj);
		});
		this.uploadService.addToCart(payload).subscribe((data: any) => {
			this.loaderService.isLoading.next(false);
			if(data.success) {
				this.api.getSelectedNodes().map((record: any) => {
					if(record.data.availabilityReportType != null) {
						record.data.status = status;	
						this.gridAPI.refreshCells();
						this.checkButtonsStatus();
						this.checkOnlineButtonsStatus();
					}				
				});
				this.getCartDetails.emit();
			} else  {
				this.translateService.get(['errorMsg.addToCartFailed']).subscribe(value => {
					let dialogRef = this.dialog.open(ErrorDialogComponent, {
						width: '37vw',
						data: {
							errorMessage: value['errorMsg.addToCartFailed']
						}
					});
					dialogRef.afterClosed().subscribe(res => {
						if (res) {
							this.bulkAddToCart(status);
						}
					});
				});
			}
		},
		(err: any) => {
			this.loaderService.isLoading.next(false);
			this.translateService.get(['errorMsg.addToCartFailed']).subscribe(value => {
				let dialogRef = this.dialog.open(ErrorDialogComponent, {
					width: '37vw',
					data: {
						errorMessage: value['errorMsg.addToCartFailed']
					}
				});
				dialogRef.afterClosed().subscribe(res => {
					if (res) {
						this.bulkAddToCart(status);
					}
				});
			});
		});
	}

	addToCart(ev: any, status: string) {
		this.loaderService.isLoading.next(true);
		var payload: any = [];
		var obj: any = {
			"BulkReportOrderItemId": ev.data.id,
			"Translation": ev.data.translation == "N" ? false : true
		};
		payload.push(obj);
		this.uploadService.addToCart(payload).subscribe((data: any) => {
			this.gridAPI.refreshCells(ev);
			this.loaderService.isLoading.next(false);
			if(data.success) {
				ev.data.status = status;
				this.gridAPI.refreshCells(ev);
				this.getCartDetails.emit();
			} else  {
				this.gridAPI.refreshCells(ev);
				this.translateService.get(['errorMsg.addToCartFailed']).subscribe(value => {
					let dialogRef = this.dialog.open(ErrorDialogComponent, {
						width: '37vw',
						data: {
							errorMessage: value['errorMsg.addToCartFailed']
						}
					});
					dialogRef.afterClosed().subscribe(res => {
						if (res) {
							this.addToCart(ev, status);
						}
					});
				});
			}
		},
		(err: any) => {
			this.loaderService.isLoading.next(false);
			this.gridAPI.refreshCells(ev);
			this.translateService.get(['errorMsg.addToCartFailed']).subscribe(value => {
				let dialogRef = this.dialog.open(ErrorDialogComponent, {
					width: '37vw',
					data: {
						errorMessage: value['errorMsg.addToCartFailed']
					}
				});
				dialogRef.afterClosed().subscribe(res => {
					if (res) {
						this.addToCart(ev, status);
					}
				});
			});
		});
	}
	

	removeMultipleEntities() {
		this.loaderService.isLoading.next(true);
		var ids: any = [];
		this.api.getSelectedNodes().map((record: any) => {
			ids.push(record.data.id);
		});
		this.uploadService.removeRecords(ids).subscribe(
			(res: any) => {
				this.loaderService.isLoading.next(false);
				if(res.success == true) {
					this.noMatchRecords = []; 
					this.selectedStatusBar = 'Match_Not_Found';
					this.NoMatchLoader = true;
					this.addToCartButton = false;
					this.bulkUploadSettingButton = false;
					this.changeStatusBar(this.selectedStatusBar);
				} else {
					this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
						let dialogRef = this.dialog.open(ErrorDialogComponent, {
							width: '37vw',
							data: {
								errorMessage: value['errorMsg.pleaseTryAgain']
							}
						});
						dialogRef.afterClosed().subscribe(res => {
							if (res) {
								this.removeMultipleEntities();
							}
						});
					});	
				}
			},
			(err: any) => {
				this.loaderService.isLoading.next(false);
				this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
					let dialogRef = this.dialog.open(ErrorDialogComponent, {
						width: '37vw',
						data: {
							errorMessage: value['errorMsg.pleaseTryAgain']
						}
					});
					dialogRef.afterClosed().subscribe(res => {
						if (res) {
							this.removeMultipleEntities();
						}
					});
				});	
			}
		); 
	}

	removeMultipleOnlineEntities() {
		this.loaderService.isLoading.next(true);
		var ids: any = [];
		this.api.getSelectedNodes().map((record: any) => {
			ids.push(record.data.id);
		});
		this.uploadService.removeRecords(ids).subscribe(
			(res: any) => {
				this.loaderService.isLoading.next(false);
				if(res.success == true) {
					this.foundRecords = []; 
					this.selectedStatusBar = 'Match_Found';
					this.foundRecordsLoader = true;
					this.addToCartButton = false;
					this.bulkUploadSettingButton = false;
					this.changeStatusBar(this.selectedStatusBar);
				} else {
					this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
						let dialogRef = this.dialog.open(ErrorDialogComponent, {
							width: '37vw',
							data: {
								errorMessage: value['errorMsg.pleaseTryAgain']
							}
						});
						dialogRef.afterClosed().subscribe(res => {
							if (res) {
								this.removeMultipleOnlineEntities();
							}
						});
					});	
				}
			},
			(err: any) => {
				this.loaderService.isLoading.next(false);
				this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
					let dialogRef = this.dialog.open(ErrorDialogComponent, {
						width: '37vw',
						data: {
							errorMessage: value['errorMsg.pleaseTryAgain']
						}
					});
					dialogRef.afterClosed().subscribe(res => {
						if (res) {
							this.removeMultipleOnlineEntities();
						}
					});
				});	
			}
		); 
	}

	removeEntity(ev: any) {
		this.loaderService.isLoading.next(true);
		var id: any = [ev.data.id];
		this.uploadService.removeRecords(id).subscribe(
			(res: any) => {
				this.loaderService.isLoading.next(false);
				this.gridAPI.refreshCells(ev);
				if(res.success == true) {
					this.foundRecords = this.foundRecords.filter((record: any) => record.id !== ev.data.id);
					this.foundRecordsCount = this.foundRecords.length;
					var removeAll: any = [];
					removeAll.push(ev.data);
					this.gridAPI.applyTransaction({
						remove: removeAll
					});
				} else {
					this.removeEntityTryAgain(ev);
				}
			},
			(err: any) => {
				this.loaderService.isLoading.next(false);
				this.gridAPI.refreshCells(ev);
				this.removeEntityTryAgain(ev);
			}
		); 
	}

	removeNoMatchEntity(ev: any) {
		this.loaderService.isLoading.next(true);
		var id: any = [ev.data.id];
		this.uploadService.removeRecords(id).subscribe(
			(res: any) => {
				this.loaderService.isLoading.next(false);
				this.gridAPI.refreshCells(ev);
				if(res.success == true) {
					this.noMatchRecords = this.noMatchRecords.filter((record: any) => record.id !== ev.data.id);
					this.noMatchRecordsCount = this.noMatchRecords.length;
					var removeAll: any = [];
					removeAll.push(ev.data);
					this.gridAPI.applyTransaction({
						remove: removeAll
					});
				} else {
					this.gridAPI.refreshCells(ev);
				}
			},
			(err: any) => {
				this.loaderService.isLoading.next(false);
				this.gridAPI.refreshCells(ev);
			}
		); 
	}

	removeEntityTryAgain(ev: any) {
		this.loaderService.isLoading.next(false);
		this.gridAPI.refreshCells(ev);
		this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
			let dialogRef = this.dialog.open(ErrorDialogComponent, {
				width: '37vw',
				data: {
					errorMessage: value['errorMsg.pleaseTryAgain']
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					this.removeEntity(ev);
				}
			});
		});		
	}

	onActionClick(ev: any) {
		if (ev.label === 'delete') {
			this.loaderService.isLoading.next(true);
				this.uploadService.deleteAndUndoDuplicate(this.bulkOrderNumber, ev.data.entityName).subscribe(
				(res: any) => {
					this.loaderService.isLoading.next(false);
					if(res.success == true){
						ev.data.isDeleted = true;
						this.gridAPI.refreshCells(ev);
					}
				},
				(err: any) => {
					this.loaderService.isLoading.next(false);
				}
			); 
			
		}
		
		if (ev.label === 'remove') {
			this.removeEntity(ev);
		}

		if (ev.label === 'removeNoMatch') {
			this.removeNoMatchEntity(ev);
		}

		if (ev.label === 'undo') {
			this.loaderService.isLoading.next(true);
				this.uploadService.deleteAndUndoDuplicate(this.bulkOrderNumber, ev.data.entityName).subscribe(
				(res: any) => {
					this.loaderService.isLoading.next(false);
					if(res.success == true){
						ev.data.isDeleted = false;
						this.gridAPI.refreshCells(ev);
					}
				},
				(err: any) => {
					this.loaderService.isLoading.next(false);
				}
			); 
		}

		if (ev.label === 'addToCart') {
			this.addToCart(ev,"Added_To_Cart");
		}

		if (ev.label === 'addNoMatchToCart') {
			if(ev.data.availabilityReportType != null || ev.data.availabilitySpeedDescription != null) {
				this.addToCart(ev, "Added_To_Cart_Offline");
			} else {
				this.gridAPI.refreshCells(ev);
				this.dialog.open(ErrorDialogComponent, {
					width: '37vw',
					data: {
						errorMessage: "Please select report type!",
						isOk: true,
						tryAgain: false
					}
				});
			}
		}

		if (ev.label === 'search') {
			let dialogRef = this.dialog.open(OfflineSearchComponent, {
				disableClose: true,
				width: "81vw",
				panelClass: 'search-result-dialog',
				data: {
					"data": ev.data,
					"status": 'single'
				}
			});
			dialogRef.afterClosed().subscribe(res => {
			});
		}

		if (ev.label === 'viewMatches') {
			let dialogRef = this.dialog.open(MatchResultComponent, {
				disableClose: true,
				width: "81vw",
				panelClass: 'search-result-dialog',
				data: {
					 results: ev.data,
					 isMatch: true
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				this.gridOptions.api.refreshCells();
				this.gridOptions.api.refreshCells(ev);
				console.log("cell refreshed")
				if(res) {
					this.loadData();
				}
				ev.node.setSelected(false);
				this.gridAPI.refreshCells(ev);
			});
		}

		if (ev.label === 'viewOptions') {
			let dialogRef = this.dialog.open(MatchResultComponent, {
				disableClose: true,
				width: "81vw",
				panelClass: 'search-result-dialog',
				data: {
					 results: ev.data,
					 isMatch: false
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if(res) {
					ev.data.availabilityProductPrice = res?.AvailabilityProductPrice;
					ev.data.availabilityReportType = res?.AvailabilityReportType;
					ev.data.availabilitySpeedCode = res?.AvailabilitySpeedCode;
					ev.data.availabilitySpeedDescription = res?.AvailabilitySpeedDescription;
					ev.data.scr = res?.scr;
					ev.data.availabilityTatSupplier = res?.AvailabilityTatSupplier;
					ev.data.status = "Match_Found";
				}
				ev.node.setSelected(false);
				this.gridAPI.refreshCells(ev);
			});
		}
		
		
	}

}
