<app-header (langChange)="changeLanguageGrid($event)" [title]="title"></app-header>
<app-toast class="position sticky" *ngIf="!isMobile"></app-toast>
<app-toast class="position-mobile sticky" *ngIf="isMobile"></app-toast>
<main class="p1">
    <section>
        <article>
            <div class="zanna_div row mt-60">
                <h1 class="M(0) Lh(1) eds-heading heading-xl-caps">
                    {{ 'monitor.title' | translate }}
                    <button type="button" (click)="userManualReport()"class="button button__primary userManual-btn" *ngIf="!isMobile">
                        <mat-icon class="material-icon">help</mat-icon>
                       {{'home.userManual' | translate}} 
                    </button>
                    <!-- <button type="button" (click)="description()" class="button button__primary userManual-btn" *ngIf="!isMobile">
                        <mat-icon class="material-icon">description</mat-icon>
                       {{'home.auditTrial' | translate}} 
                    </button> -->
                    <button type="button" (click)="exportAllAlerts()" class="button button__primary userManual-btn" *ngIf="!isMobile">
                        <mat-icon class="material-icon">get_app</mat-icon>
                        {{ 'monitor.export_view' | translate }}
                    </button>
                </h1>
                <p class="p-subTitle">
                    {{ 'monitor.subtitle' | translate }}
                </p> 
            </div> 
            <div class="tabs zanna_div d-flex">
                <div class="cat-prop" routerLink="/monitor" (click)="alertSlide=true; prioritySlide=false; recordsSlide=false;getAlertsData()">
                    <span class="category" [ngClass]="alertSlide == true ? 'selected-tab-text' : 'not-selected-tab-text'">
                        {{ 'monitor.alertSlide' | translate }}
                        <hr [ngClass]="alertSlide ? 'selected-tab' : 'not-selected-tab'" class="alert-width">
                    </span>
                </div>
                <!-- <div class="cat-prop" (click)="alertSlide=false; prioritySlide=true; recordsSlide=false">
                    <span class="category" [ngClass]="prioritySlide == true ? 'selected-tab-text' : 'not-selected-tab-text'">{{ 'monitor.prioritySlide' | translate }}
                        <hr [ngClass]="prioritySlide ? 'selected-tab' : 'not-selected-tab'" class="high-priority-width">
                    </span>
                </div> -->
                <div class="cat-prop" (click)="changeSlide($event)">
                    <span class="category" [ngClass]="recordsSlide == true ? 'selected-tab-text' : 'not-selected-tab-text'">{{ 'monitor.monitoredSlide' | translate }}
                        <hr [ngClass]="recordsSlide ? 'selected-tab' : 'not-selected-tab'" class="records-width">
                    </span>
                </div>
            </div>
            <div *ngIf="alertSlide">
                <article class="mb-0 desktop-view">
                <div class="zanna_div row mb-80">
                    <ng-template #tipContent>
                        <div class="custom-multi-tooltip">
                            <div>
                                <div><label>{{ tooltipdata?.monitoringName }}</label></div>
                                <!-- <div>(<span *ngIf="tooltipdata.totalNamesSubscribed && tooltipdata.totalNamesSubscribed != 0">{{ 'monitor.subscribed' | translate }} </span><span *ngIf="!tooltipdata.totalNamesSubscribed || tooltipdata.totalNamesSubscribed == 0">{{ 'monitor.unsubscribed' | translate }} </span>)</div><br> -->
                                <div><label>{{ 'monitor.about_service' | translate }}:</label></div>
                                <div>{{ tooltipdata?.description }}</div>
                            </div>
                            <mat-icon class="tooltip-arrow-custom">arrow_drop_down</mat-icon>
                        </div>
                    </ng-template>
                    <div class="col" style="padding: 0px; display: inline-flex;">
                        <div class="order-status-bar">
                            <a data-filter="ALL" 
                                [ngClass]="selectedFilter == 'ALL' ? 'selected' : ''" 
                                style="cursor: pointer;" (click)="changeSelectedFilter('ALL')"
                                container="body" placement="top">
                                <span>
                                   All 
                                    <span *ngIf="uncollectedCount > 0">{{ uncollectedCount }}</span>
                                </span>
                            </a>
                            <a  *ngFor="let alert of alerts; let i = index" data-filter="alert.monitoringType" 
                                [ngClass]="selectedFilter == alert.monitoringType ? 'selected' : ''" 
                                style="cursor: pointer;" (click)="changeSelectedFilter(alert.monitoringType)" (mouseenter)="hovered(alert)"
                                [ngbTooltip]="tipContent" container="body" placement="top">
                                <mat-icon class="set-size">{{ alert.icon }}</mat-icon>
                                <span class="payment-space" *ngIf="alert.monitoringType =='NBB' && alert.entitlement != null">{{ alert.monitoringType }} ({{ alert.entitlement | uppercase}})
                                    <span *ngIf="alert.unReadCount > 0" class="count" [ngStyle]="{'width': alert.unReadCount.toString().length * 10 + 8 + 'px'}">{{ alert.unReadCount }}</span>
                                </span>
                                <span class="payment-space" *ngIf="alert.monitoringType =='NBB' && alert.entitlement == null">{{ alert.monitoringType }}
                                    <span *ngIf="alert.unReadCount > 0" class="count" [ngStyle]="{'width': alert.unReadCount.toString().length * 10 + 8 + 'px'}">{{ alert.unReadCount }}</span>
                                </span>
                                <span class="icon-space" *ngIf="alert.monitoringType !='NBB'">{{ alert.monitoringType }} 
                                    <span *ngIf="alert.unReadCount > 0" class="count" [ngStyle]="{'width': alert.unReadCount.toString().length * 10 + 8 + 'px'}">{{ alert.unReadCount }}</span>
                                </span>
                            </a>
                        </div>
                        <!-- <a class="advanced-search" >
                            <mat-icon style="font-size: 21px; margin-right: 3px;">upload</mat-icon>
                            <span>
                                 Export all alerts
                            </span>
                        </a> -->
                    </div>
                </div>
                </article>
                <app-dashboard (tabsEvent)="changeSlide($event)" (uploadStatus)="getCommpleteStatus($event)" [summuryData]="summuryData"></app-dashboard>

                <div class="mobile-view">
                    <select name="" id="tab-section" [(ngModel)]="option" (change)="changeSelectedFilter(option)">
                        <option value="ALL">
                            All      
                        </option>
                        <option *ngFor="let alert of alerts; let i = index" [value]="alert.monitoringType"  >                            
                            <span class="icon-space" *ngIf="alert.monitoringType =='NBB' && alert.entitlement != null">{{ alert.monitoringType }} ({{ alert.entitlement | uppercase}})
                                <span *ngIf="alert.unReadCount > 0">({{ alert.unReadCount }})</span>
                            </span>
                            <span class="icon-space" *ngIf="alert.monitoringType =='NBB' && alert.entitlement == null">{{ alert.monitoringType }}
                                <span *ngIf="alert.unReadCount > 0">({{ alert.unReadCount }})</span>
                            </span>
                            <span class="icon-space" *ngIf="alert.monitoringType !='NBB'">{{ alert.monitoringType }} 
                                <span *ngIf="alert.unReadCount > 0">({{ alert.unReadCount }})</span>
                            </span>    
                        </option>
                    </select>                
                </div>

                <app-reports  (filterEvent)="setDefault()" [changeLangEvent] = "changeLanEvent.asObservable()" [alertType]="alertDetails" [monitoringTypeClassification]="monitoringTypeClassification" [availableAlerts]="alerts" [buttonValue]="expandButtonValue" [buttonExpand]="buttonExpandValue"></app-reports> 
            </div>
            <article *ngIf="prioritySlide">
                <div class="zanna_div">
                    {{ highPriority }}
                </div>
            </article>
            <article *ngIf="recordsSlide">
                <div class="zanna_div">
                    <app-monitor-bulk-upload-grid *ngIf="!isMobile && bulkUploadDetails != null && bulkUploadDetails?.bulkAlertUploadNumber != null && bulkUploadDetails?.bulkAlertUploadNumber != '' && bulkUploadDetails?.bulkAlertStatus != 'Completed'" [validatedData]="bulkUploadDetails" (completeStatus)="getCommpleteStatus($event)" (addedToNamelist)="addedToNameList($event)"></app-monitor-bulk-upload-grid>
                    <app-monitor-records [changeLangEvent] = "changeLanEvent.asObservable()" [addedRecord]="addedRecord"></app-monitor-records>
                </div>
            </article>
        </article>
    </section>
</main>
<app-footer></app-footer>