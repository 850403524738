import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { NotificationService } from "../shared-module/toast/toast.service";
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MonitoringService } from '../monitoring.service';
import { LoaderService } from 'src/app/services/loader.service';
import { GridOptions, GridApi } from 'ag-grid-community';
import 'ag-grid-enterprise';
import { ErrorDialogComponent } from 'src/app/error-dialog-box/error-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { CMSHearingDefendantDetailCellRenderer } from './master-data-grid-hearing-defendant/cell-renderer.component';
import { CMSHearingPlandiffDetailCellRenderer } from './master-data-grid-hearing-plandiff/cell-renderer.component';
import { CMSNewDefendantDetailCellRenderer } from './master-data-grid-new-defendant/cell-renderer.component';
import { CMSNewPlandiffDetailCellRenderer } from './master-data-grid-new-plandiff/cell-renderer.component';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { ExpandGridCellRendererComponent } from '../shared-module/expand-grid-cell-renderer/expand-grid-cell-renderer.component';
import { MyNoRowsComponent } from '../shared-module/search-result/no-overlay.component';

@Component({
	selector: 'app-credit-alert',
	templateUrl: './credit-monitoring-service-alert.component.html',
	styleUrls: ['./credit-monitoring-service-alert.component.scss']
})
export class CreditMonitoringServiceComponent implements OnInit {
	isMobile: boolean = false;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if(event.target.innerWidth > 1000) {
			this.isMobile = false;
			this.getColDefs();
		} else {
			this.isMobile = true;
			this.getColDefs();
		}
	}
	@Output() tabsEvent = new EventEmitter();
	changeLanEvent: Subject<void> = new Subject<void>();
 	columnDefs: any = [];
	title: any = "Monitoring";
	btnDisabled: boolean = false;
 	newDefendantGridOptions: any;
	newDefendantGridColumnApi: any;
	newDefendantApi: any;

	newPlantiffGridOptions: any;
	newPlantiffGridColumnApi: any;
	newPlantiffApi: any;

	hearingDefendantGridOptions: any;
	hearingDefendantGridColumnApi: any;
	hearingDefendantApi: any;

	hearingPlantiffGridOptions: any;
	hearingPlantiffGridColumnApi: any;
	hearingPlantiffApi: any;

	exportCmsPayment: any;
	exportCmsLitigationBasic: any;
	exportCmsLitigationEnhanced: any;

 	domLayout: any = "autoHeight";
	alartList: any;
	CMSHearingDefendant: any;
	CMSHearingPlandiff: any;
	CMSNewDefendant: any;
	CMSNewPlandiff: any;
	CMSHearingDefendantHeight: any = 400;
	CMSHearingPlandiffHeight: any = 230;
	CMSNewDefendantHeight: any = 200;
	CMSNewPlandiffHeight: any = 200;
	newDefendantList: any = [];
	newPlantiffList: any = [];
	hearingDefendantList: any = [];
	hearingPlantiffList: any = [];
	entityId: any;
	NegativeBlackList: any = "New Records found in Negative / Blacklist";
	paymentAlerts: any;
	paymentAlertsCount: any = 0;
	isBasic: boolean = false;
	allExpand: boolean = true;
	allPlantiffExpand: boolean = true;
	allHearingDefendantExpand: boolean = true;
	allHearingPlantiffExpand: boolean = true;
	expandCollapseButtonValue: String = "Collapse all rows"
	expandCollapsePlantiffButtonValue: String = "Collapse all rows"
	expandCollapseHearingDefendantButtonValue: String = "Collapse all rows"
	expandCollapseHearingPlantiffButtonValue: String = "Collapse all rows"

	isLMS: false;
	count: any = 0;
	currentDate: any;
	summary: any;
	category: any = "LITIGATION";
	startDate: any;
	endDate: any;

	paymentCat1: any;
	paymentCat2: any;
	paymentCat3: any;
	paymentCat4: any;
	paymentCat5: any;
	
	constructor(
		public _notificationservice: NotificationService,
		public router: ActivatedRoute,
		public iconRegistry: MatIconRegistry,
		public sanitizer: DomSanitizer,
		private dialog: MatDialog,
		public monitoringService: MonitoringService,
		public translateService: TranslateService,
		public loaderService: LoaderService,
	) {
		if(window.screen.width > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
		this.entityId = localStorage.getItem('entityId');
		this.startDate = localStorage.getItem('dateFrom');
		this.endDate = localStorage.getItem('dateTo');
		this.loadRecords();
		this.router.queryParams.subscribe((route) => {
			if (route.recordSlide == 'true') {
				
			}
		});
		this.getColDefs();
		window.scroll(0, 0);
		this.currentDate = moment(new Date()).format('DD/MM/YYYY');
		this.newDefendantGridLoad();
		this.newPlantiffGridLoad();
		this.hearingDefendantGridLoad();
		this.hearingPlantiffGridLoad();
		this.monitoringService.setSid(localStorage.getItem('sessionID'));
	}

	onPrint(){
		window.print();
	}
	changeLanguageGrid(ev: any) {
		this.getColDefs();
	}
	newDefendantGridLoad() {
		this.newDefendantGridOptions = <GridOptions>{
			onGridReady: () => {
				setTimeout(() => {
					this.newDefendantGridOptions.api?.sizeColumnsToFit();
				}, 1000);
			 },
			onGridSizeChanged: () => {
				this.newDefendantGridOptions.api.sizeColumnsToFit();
			},
			noRowsOverlayComponent: 'myNoRowsComponent',
			noRowsOverlayComponentParams: {
				"component": "detailed"
			},
			pagination: false,
			animateRows: true,
			suppressCellSelection: true,
			enableCellTextSelection: true,
			suppressContextMenu:true,
			defaultColDef: {
				flex: 1,
				wrapText: true,
				resizable: true,
				autoHeight: true,
				unSortIcon: true,
				sortable: true,
				suppressMenu: true,
				lockPosition: true,
				tooltipComponent: 'customTooltip'
			}
		};
		this.CMSNewDefendant = 'CMSNewDefendant';
		this.newDefendantGridOptions.frameworkComponents = {
			"cellRenderer": ExpandGridCellRendererComponent,
			"CMSNewDefendant": CMSNewDefendantDetailCellRenderer,
			myNoRowsComponent: MyNoRowsComponent
		};
	}

	newPlantiffGridLoad() {
		this.newPlantiffGridOptions = <GridOptions>{
			onGridReady: () => {
				setTimeout(() => {
					this.newPlantiffGridOptions.api?.sizeColumnsToFit();
				}, 1000);
			 },
			onGridSizeChanged: () => {
				this.newPlantiffGridOptions.api.sizeColumnsToFit();
			},
			overlayNoRowsTemplate: '<h4> No record found. </h4>',
			pagination: false,
			animateRows: true,
			suppressCellSelection: true,
			enableCellTextSelection: true,
			suppressContextMenu:true,
			defaultColDef: {
				flex: 1,
				wrapText: true,
				resizable: true,
				autoHeight: true,
				unSortIcon: true,
				sortable: true,
				suppressMenu: true,
				lockPosition: true,
				tooltipComponent: 'customTooltip'
			}
		};
		this.CMSNewPlandiff = 'CMSNewPlandiff';
		this.newPlantiffGridOptions.frameworkComponents = {
			"cellRenderer": ExpandGridCellRendererComponent,
			"CMSNewPlandiff": CMSNewPlandiffDetailCellRenderer
		};
	}

	hearingDefendantGridLoad() {
		this.hearingDefendantGridOptions = <GridOptions>{
			onGridReady: () => {
				setTimeout(() => {
					this.hearingDefendantGridOptions.api?.sizeColumnsToFit();
				}, 1000);
			 },
			onGridSizeChanged: () => {
				this.hearingDefendantGridOptions.api.sizeColumnsToFit();
			},
			overlayNoRowsTemplate: '<h4> No record found. </h4>',
			pagination: false,
			animateRows: true,
			suppressCellSelection: true,
			enableCellTextSelection: true,
			suppressContextMenu:true,
			defaultColDef: {
				flex: 1,
				wrapText: true,
				resizable: true,
				autoHeight: true,
				unSortIcon: true,
				sortable: true,
				suppressMenu: true,
				lockPosition: true,
				tooltipComponent: 'customTooltip'
			}
		};
		this.CMSHearingDefendant = 'CMSHearingDefendant';
		this.hearingDefendantGridOptions.frameworkComponents = {
			"cellRenderer": ExpandGridCellRendererComponent,
			"CMSHearingDefendant": CMSHearingDefendantDetailCellRenderer
		};
	}

	hearingPlantiffGridLoad() {
		this.hearingPlantiffGridOptions = <GridOptions>{
			onGridReady: () => {
				setTimeout(() => {
					this.hearingPlantiffGridOptions.api?.sizeColumnsToFit();
				}, 1000);
			 },
			onGridSizeChanged: () => {
				this.hearingPlantiffGridOptions.api.sizeColumnsToFit();
			},
			overlayNoRowsTemplate: '<h4> No record found. </h4>',
			pagination: false,
			animateRows: true,
			suppressCellSelection: true,
			enableCellTextSelection: true,
			suppressContextMenu:true,
			defaultColDef: {
				flex: 1,
				wrapText: true,
				resizable: true,
				autoHeight: true,
				unSortIcon: true,
				sortable: true,
				suppressMenu: true,
				lockPosition: true,
				tooltipComponent: 'customTooltip'
			}
		};
		this.CMSHearingPlandiff = 'CMSHearingPlandiff';
		this.hearingPlantiffGridOptions.frameworkComponents = {
			"cellRenderer": ExpandGridCellRendererComponent,
			"CMSHearingPlandiff" : CMSHearingPlandiffDetailCellRenderer,
		};
	}

	onNewDefendantGridReady(params: any) {
		this.newDefendantGridColumnApi = params.columnApi;
		this.newDefendantApi = params.api;
	}

	onNewPlantiffGridReady(params: any) {
		this.newPlantiffGridColumnApi = params.columnApi;
		this.newPlantiffApi = params.api;
	}

	onHearingDefendantGridReady(params: any) {
		this.hearingDefendantGridColumnApi = params.columnApi;
		this.hearingDefendantApi = params.api;
	}

	onHearingPlantiffGridReady(params: any) {
		this.hearingPlantiffGridColumnApi = params.columnApi;
		this.hearingPlantiffApi = params.api;
	}

	onNewDefendantFilterChanged(event: any) {
		if (this.newDefendantGridOptions.api.getDisplayedRowCount() == 0) {
			this.newDefendantGridOptions.api.showNoRowsOverlay();
		} else {
			this.newDefendantGridOptions.api.hideOverlay();
		}
		this.newDefendantGridOptions.apiapi.deselectAll();
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
		// this.gridAPI.resetRowHeights();
	}

	loadRecords() {
		this.loaderService.isLoading.next(true);
		this.monitoringService.getPaymentAlarts("CMS", this.entityId, this.endDate, this.startDate, this.category).subscribe((data: any) => {
			this.loaderService.isLoading.next(false);
			if (data?.success  && data.data != null) {
				if(data.data?.list?.litigationAlerts?.entitlement == 'Basic') {
					this.isBasic = true;
					this.CMSHearingDefendantHeight = this.isMobile?350:190;
					this.CMSHearingPlandiffHeight = this.isMobile?350:190;
					this.CMSNewDefendantHeight = this.isMobile?350:175;
					this.CMSNewPlandiffHeight = this.isMobile?350:175;
				} else {
					this.CMSHearingDefendantHeight = this.isMobile?470:240;
					this.CMSHearingPlandiffHeight = this.isMobile?470:240;
					this.CMSNewDefendantHeight = this.isMobile?470:225;
					this.CMSNewPlandiffHeight = this.isMobile?470:225;
				}
				this.getExportData();
				this.count = data.data?.list?.litigationAlerts.count;
				data.data.list?.litigationAlerts?.list?.New_Alerts?.Defendant?.map((rec: any, index: number) => {
					rec['srNo'] = index + 1;
					rec['expanded'] = true;
					var year: any = rec.FillingDate?.split('/');
					if(year && year[2]) {
						rec['Year'] = year[2];
					} else {
						rec['Year'] = "";
					}
				});
				data.data.list?.litigationAlerts?.list?.New_Alerts?.Plaintiff?.map((rec: any, index: number) => {
					rec['srNo'] = index + 1;
					rec['expanded'] = true;
					var year: any = rec.FillingDate?.split('/');
					if(year && year.length > 2) {
						rec['Year'] = year[2];
					} else {
						rec['Year'] = "";
					}
				});
				data.data.list?.litigationAlerts?.list?.Change_in_Occurrence___Hearing?.Defendant?.map((rec: any, index: number) => {
					rec['srNo'] = index + 1;
					rec['expanded'] = true;
					var year: any = rec.FillingDate?.split('/');
					if(year && year[2]) {
						rec['Year'] = year[2];
					} else {
						rec['Year'] = "";
					}
				});
				data.data.list?.litigationAlerts?.list?.Change_in_Occurrence___Hearing?.Plaintiff?.map((rec: any, index: number) => {
					rec['srNo'] = index + 1;
					rec['expanded'] = true;
					var year: any = rec.FillingDate?.split('/');
					if(year && year[2]) {
						rec['Year'] = year[2];
					} else {
						rec['Year'] = "";
					}
				});
				this.newDefendantList = data.data.list?.litigationAlerts?.list?.New_Alerts?.Defendant;
				this.newPlantiffList = data.data.list?.litigationAlerts?.list?.New_Alerts?.Plaintiff;
				this.hearingDefendantList = data.data.list?.litigationAlerts?.list?.Change_in_Occurrence___Hearing?.Defendant;
				this.hearingPlantiffList = data.data.list?.litigationAlerts?.list?.Change_in_Occurrence___Hearing?.Plaintiff;
				this.paymentAlerts = data.data?.list?.paymentAlerts?.list?.CMSPAYMENT;
				this.paymentCat1 = data.data?.list?.paymentAlerts?.list?.CMSPAYMENT['New_Records_Found_in_Negative___Blacklist'];
				this.paymentCat2 = data.data?.list?.paymentAlerts?.list?.CMSPAYMENT['>_90_days_with_total_outstanding_amount_>_S_1_000'];
				this.paymentCat3 = data.data?.list?.paymentAlerts?.list?.CMSPAYMENT['>_90_days_with_total_outstanding_amount_≤_S_1_000'];
				this.paymentCat4 = data.data?.list?.paymentAlerts?.list?.CMSPAYMENT['≤_90_days_with_total_outstanding_amount_>_S_1_000'];
				this.paymentCat5 = data.data?.list?.paymentAlerts?.list?.CMSPAYMENT['≤_90_days_with_total_outstanding_amount_≤_S_1_000'];
				this.paymentAlertsCount = data.data?.list?.paymentAlerts?.count;
			} else {
				this.openTryAgainPopup();
			}
		}, (err: any) => {
			this.loaderService.isLoading.next(false);
			this.openTryAgainPopup();
		});
		if(!this.entityId) {
			this.monitoringService.getAlertsSummary().subscribe((data: any) => {
				if(data.success) {
					data.data.summaryDetails.map((alert: any) => {
						if(alert.monitoringType == "CMS") {
							this.summary = alert;
						}
					});
				} 
			});
		}
	}
	
	openTryAgainPopup() {
		this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
			let dialogRef = this.dialog.open(ErrorDialogComponent, {
				disableClose: true,
				width: this.isMobile?'auto':'37vw',
				data: {
					errorMessage: value['errorMsg.pleaseTryAgain']
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					this.loadRecords();
				}
			});
		})
	}

	serialNumberGetter(params:any) {
		return 1;
	}

 	getColDefs() {
		this.translateService.get(['monitor.fms.no', 'monitor.entityNo', 'monitor.clientFileRef',
			'monitor.entityName', 'monitor.clientFileRef', 'monitor.dateOfAlert',
			'monitor.action']).subscribe(value => {
				this.columnDefs = [
					{
						field: 'srNo',
						headerName: value['monitor.fms.no'],
						tooltipField: 'clientFileRef',
						tooltipComponentParams: { type: 'fileRef' },
						sortable: false,
						width: this.isMobile?60:110,
						maxWidth: this.isMobile?60:110,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': this.isMobile?'13px 0 13px 13px':'13px 13px 13px 20px', 'border-bottom': this.isMobile?'none':'1px solid white'}
					},
					{
						field: 'EntityId',
						headerName: value['monitor.entityNo'],
						tooltipField: 'clientFileRef',
						tooltipComponentParams: { type: 'fileRef' },
						sortable: false,
						width: 110,
						minWidth: 110,
						hide:this.isMobile,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' }
					},
					{
						field: 'EntityName',
						headerName: value['monitor.entityName'],
						tooltipField: 'clientFileRef',
						tooltipComponentParams: { type: 'fileRef' },
						sortable: false,
						width: this.isMobile?220:110,
						minWidth: this.isMobile?200:110,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': this.isMobile?'13px 0px 13px 13px':'13px', 'font-size':'14px' }
					},
					{
						field: 'FileReference',
						headerName: value['monitor.clientFileRef'],
						tooltipField: 'clientFileRef',
						tooltipComponentParams: { type: 'fileRef' },
						sortable: false,
						width: 110,
						minWidth: 110,
						hide:this.isMobile,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' }
					},
					{
						field: 'AlertDate',
						valueGetter: this.dateGetter,
						headerName: value['monitor.dateOfAlert'],
						sortable: false,
						width: 110,
						minWidth: 110,
						hide:this.isMobile,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' },
						suppressMenu: false,
					},
					{
						headerName: '',
						width: 50,
						minWidth: 50,
						sortable: false,
						hide:this.isMobile? false:true,
						cellRenderer: "cellRenderer",
						cellRendererParams: {
							onClick: this.cmsAlertAction.bind(this),
							label: 'cms-alert-action'
						},
						cellStyle: { 'text-align': 'right' },
					}
				]
			});
	} 

	cmsAlertAction(ev: any) {
		if (ev.label === 'expand') {
			ev.data.expanded = !ev.node.expanded;
			ev.node.setExpanded(!ev.node.expanded);
			if(this.newDefendantGridAPI)
			this.newDefendantGridAPI.refreshCells(ev);
			if(this.newPlantiffGridAPI)
			this.newPlantiffGridAPI.refreshCells(ev);
			if(this.hearingDefendantGridAPI)
			this.hearingDefendantGridAPI.refreshCells(ev);
			if(this.hearingPlantiffGridAPI)
			this.hearingPlantiffGridAPI.refreshCells(ev);
		}
	}

	
	get newDefendantGridAPI(): GridApi {
		return this.newDefendantGridOptions.api;
	}
	get newPlantiffGridAPI(): GridApi {
		return this.newPlantiffGridOptions.api;
	}
	get hearingDefendantGridAPI(): GridApi {
		return this.hearingDefendantGridOptions.api;
	}
	get hearingPlantiffGridAPI(): GridApi {
		return this.hearingPlantiffGridOptions.api;
	}
	
	dateGetter(params: any) {
		return moment(params.data.AlertDate).format('DD MMM YYYY');
	}
	
	onFirstDataRendered1(params: any) {
		this.newDefendantGridOptions.api.forEachNode((node:any) =>{
			node.expanded = true;
		});
	}

	onFirstDataRendered2(params: any) {
		this.newPlantiffGridOptions.api.forEachNode((node:any) =>{
			node.expanded = true;
		});
	}

	onFirstDataRendered3(params: any) {
		this.hearingDefendantGridOptions.api.forEachNode((node:any) =>{
			node.expanded = true;
		});
	}

	onFirstDataRendered4(params: any) {
		this.hearingPlantiffGridOptions.api.forEachNode((node:any) =>{
			node.expanded = true;
		});
	}

	getExportData() {
		if(this.isBasic) {
			this.loaderService.isLoading.next(true);
			this.monitoringService.exportToExcel("CMS", this.endDate, this.startDate, "LITIGATION", this.entityId).subscribe((data: any) => {
				this.loaderService.isLoading.next(false);
				if (data?.success) {
					this.exportCmsLitigationBasic = data?.data?.CMS_LIT_BASIC;
				}
			},
			(err: any) => {
				this.loaderService.isLoading.next(false);
			});
			this.monitoringService.exportToExcel("CMS", this.endDate, this.startDate, "PAYMENT", this.entityId).subscribe((data: any) => {
				if (data?.success) {
					this.exportCmsPayment = data?.data?.CMS_PAYMENT;
				}
			},
			(err: any) => {
				this.loaderService.isLoading.next(false);
			});
		} else {
			this.loaderService.isLoading.next(true);
			this.monitoringService.exportToExcel("CMS", this.endDate, this.startDate, "LITIGATION", this.entityId).subscribe((data: any) => {
				this.loaderService.isLoading.next(false);
				if (data?.success) {
					 this.exportCmsLitigationEnhanced = data?.data?.CMS_LIT_ENHANCED;
				}
			},
			(err: any) => {
				this.loaderService.isLoading.next(false);
			});
			this.monitoringService.exportToExcel("CMS", this.endDate, this.startDate, "PAYMENT", this.entityId).subscribe((data: any) => {
				if (data?.success) {
					 this.exportCmsPayment = data?.data?.CMS_PAYMENT;
				}
			},
			(err: any) => {
				this.loaderService.isLoading.next(false);
			});
		}		
	}

	ExpandAll(data:any){
		this.btnDisabled = true;
		if(data){
			this.newDefendantGridOptions.api.forEachNode((node: any) => {
				node.data.expanded = false;
				node.expanded = false;
			});
			this.newPlantiffGridOptions.api.forEachNode((node: any) => {
				node.data.expanded = false;
				node.expanded = false;
			});
			this.hearingDefendantGridOptions.api.forEachNode((node: any) => {
				node.data.expanded = false;
				node.expanded = false;
			});
			this.hearingPlantiffGridOptions.api.forEachNode((node: any) => {
				node.data.expanded = false;
				node.expanded = false;
			});
			this.allExpand = false;
			this.expandCollapseButtonValue = "Expand all rows"
		}else{
			this.newDefendantGridOptions.api.forEachNode((node: any) => {
				node.data.expanded = true;
				node.expanded = true;
			});
			this.newPlantiffGridOptions.api.forEachNode((node: any) => {
				node.data.expanded = true;
				node.expanded = true;
			});
			this.hearingDefendantGridOptions.api.forEachNode((node: any) => {
				node.data.expanded = true;
				node.expanded = true;
			});
			this.hearingPlantiffGridOptions.api.forEachNode((node: any) => {
				node.data.expanded = true;
				node.expanded = true;
			});
			this.allExpand = true;
			this.expandCollapseButtonValue = "Collapse all rows"
		}
		this.newDefendantGridOptions.api.onGroupExpandedOrCollapsed();
		setTimeout(() => {
			this.newPlantiffGridOptions.api.onGroupExpandedOrCollapsed();
			this.hearingDefendantGridOptions.api.onGroupExpandedOrCollapsed();
			this.hearingPlantiffGridOptions.api.onGroupExpandedOrCollapsed();	
			this.btnDisabled = false;		
		}, 100);
	}

	export() {
		if(this.isBasic) {
			let element = document.getElementById('excel-table-cms-basic');
          const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
			var wscols = [
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30}
			];	
			ws['!cols'] = wscols;
			const wb: XLSX.WorkBook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, 'CMS Litigation');
			let element1 = document.getElementById('excel-table-cms-payment');
          const ws1: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element1, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
			var wscols1 = [
				{wch:30},
				{wch:30},
				{wch:30}				
			];	
			ws1['!cols'] = wscols1;
			XLSX.utils.book_append_sheet(wb, ws1, 'CMS Payment');
			if(this.summary) {
				let element = document.getElementById('summary-excel-table');
              const wsSummary: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
				var wscolsExport = [
					{wch:30},
					{wch:30},
					{wch:30},
					{wch:30},
					{wch:30}
				];	
				wsSummary['!cols'] = wscolsExport;
				XLSX.utils.book_append_sheet(wb, wsSummary, 'CMS Summary');
			}
			XLSX.writeFile(wb, 'CMS_ExcelSheet.xlsx');
		} else {
			let element = document.getElementById('excel-table-cms-enhanced');
          const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
			var wscols = [
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30}
			];	
			ws['!cols'] = wscols;
			const wb: XLSX.WorkBook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, 'CMS Litigation');
			let element1 = document.getElementById('excel-table-cms-payment');
          const ws1: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element1, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
			var wscols1 = [
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30}				
			];	
			ws1['!cols'] = wscols1;
			XLSX.utils.book_append_sheet(wb, ws1, 'CMS Payment');
			if(this.summary) {
				let element = document.getElementById('summary-excel-table');
              const wsSummary: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
				var wscolsExport = [
					{wch:30},
					{wch:30},
					{wch:30},
					{wch:30},
					{wch:30}
				];	
				ws['!cols'] = wscolsExport;
				XLSX.utils.book_append_sheet(wb, wsSummary, 'CMS Summary');
			}
			XLSX.writeFile(wb, 'CMS_ExcelSheet.xlsx');
		}
	}
}
