<div id="confirm">
    <h1 matDialogTitle class="confirm-title">
        <mat-icon class="icon-warning">
            warning
        </mat-icon>
        {{ 'confirmAction.confirm' | translate }}
    </h1>
    <div matDialogContent class="content-msg" >
        <div>
            <span>
                {{ 'monitor.invalidNricNumber' | translate }} 
            </span>
            <strong>'{{ paramData.nric_id }}'! </strong> {{ 'monitor.doYouWantToProceed' | translate }}</div> 
        <div>{{ 'monitor.tip' | translate }} </div>
    </div>
    <div mat-dialog-actions class="pt-24" style="justify-content: center;">
        <button (click)="dialogRef.close(false)" style="font-weight: 500;" class="button button__basic">
            {{ 'confirmAction.no' | translate }}
        </button>
        <button (click)="dialogRef.close(true)" class="button button__primary ml-22" style="margin-left: 20px;">
            {{ 'confirmAction.yes' | translate }}
        </button>
    </div>
</div>