import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from 'src/app/home/home.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ErrorDialogComponent } from '../../error-dialog-box/error-dialog.component';
import { NoResultDialogComponent } from '../../no-result-dialog-box/no-result-dialog.component';
import { AddManuallyDialogComponent } from '../shared-module/add-manually/add-manually.component';
import { MonitoringSearchComponent } from '../shared-module/search-result/monitor-search';
import { MonitorConfimDialogComponent } from '../shared-module/confirm-dialog/confirm-dialog.component';
import { IndividualPickAlertComponent } from '../shared-module/individual-pick-alert/individual-pick-alert';
import { CompanyPickAlertComponent } from '../shared-module/company-pick-alert/company-pick-alert';
import { MonitoringService } from '../monitoring.service';
import { NotificationService } from '../shared-module/toast/toast.service';
import { ConfimDialogComponent } from 'src/app/confirm-dialog-box/confirm-dialog.component';
import { ManuallyAddConfimDialogComponent } from'../shared-module/manually-confirm-dialog/confirm-dialog.component';
import { ManuallPickAlertComponent } from'../shared-module/manuall-pick-alert/company-pick-alert';
import { BulkUploadComponent } from 'src/app/bulk-upload/bulk-upload.component';
import { MonitoringBulkUploadComponent } from '../bulk-upload/bulk-upload.component';
@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnChanges {
	isMobile: boolean = false;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if(event.target.innerWidth > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
	}
	@Output() tabsEvent = new EventEmitter();
	@Output() uploadStatus = new EventEmitter();
	@Input() summuryData: any;
	minimize: boolean = false;
	summary: any = [];
	summaryDetails: any = [];
	invalidEntitiesCount: number = 3;
	country: boolean = true;
	individual: boolean = false;
	bulkUploadDetails:any = null;
	bulkuploadLinkStatus: any;
	searchFormIndividual!: FormGroup;
	searchFormCountry!: FormGroup;
	countryList: any = [];
	selectedCountry: any = {};
	bulkUploadAccess: boolean = false;
	confirmDialogRef: MatDialogRef<ConfimDialogComponent>

	constructor(
		private formBuilder: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private loaderService: LoaderService,
		private homeService: HomeService,
		public translateService: TranslateService,
		private dialog: MatDialog,
		private service: MonitoringService,
		public nofifiacationService: NotificationService
	) {
		if(window.screen.width > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
		this.loadData();
		this.getBulkUploadDetails();
	}

	ngOnInit(): void {
		this.searchFormIndividual = this.formBuilder.group({
			countryCode: ['SGP', Validators.required],
			nric_number: ['', [Validators.required, Validators.maxLength(15)]],
			name: ['', [Validators.required, Validators.maxLength(100)]],
			file: ['', Validators.maxLength(40)]
		});

		this.searchFormCountry = this.formBuilder.group({
			countryCode: ['SGP', Validators.required],
			entity_name: ['',  [Validators.minLength(6), Validators.maxLength(100)]],
			entity_no: ['', Validators.maxLength(15)],
			file: ['', Validators.maxLength(40)]
		});
		this.getCountries();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['summuryData']) {
			this.summary = this.summuryData?.data?.summaryDetails?.filter((e: any) => e.monitoringType !=='NBB' && e.monitoringType !=='DNSA');
			var bulkUploadCount = this.summary?.filter((alert: any) => alert.accessControlDetails.batchUpload == 'Y' || alert.accessControlDetails.batchUpload == null);
			if(bulkUploadCount?.length > 0) {
				this.bulkUploadAccess = true;
			}
			console.log("bulkUpload count", bulkUploadCount);
			this.summaryDetails = this.summuryData?.data;
			if (this.summaryDetails?.capacityExceed && localStorage.getItem('upgrade_plan_show') == 'true') {
				this.nofifiacationService.warn("warning");
			}
		}
	}
	
	changeTab() {
		if (this.individual) {
			this.selectedCountry = this.countryList.find((obj: any) => (obj.countryCode === this.searchFormIndividual.value.countryCode));
		} else {
			this.selectedCountry = this.countryList.find((obj: any) => (obj.countryCode === this.searchFormCountry.value.countryCode));

		}
	}

	loadData() {
	}

	changeCountry() {
		this.selectedCountry = this.countryList.find((obj: any) => (obj.countryCode === this.searchFormCountry.value.countryCode));
	}


	additionalSearch() {
		var element = document.getElementsByClassName("additional-search-criterias")[0];
		var res = element.classList.contains("hidden");
		if (res) {
			element.classList.remove("hidden");
		} else {
			element.classList.add("hidden");
		}
	}

	getCountries() {
		this.loaderService.isLoading.next(true);
		this.countryList = [
			{
				alpha2Code: "SG",
				countryCode: "SGP",
				countryIncludes: null,
				countryName: "Singapore",
				countryNameJpn: "シンガポール"
			}
		];
		this.searchFormIndividual.controls['countryCode'].setValue('SGP');
		this.searchFormCountry.controls['countryCode'].setValue('SGP');
		this.selectedCountry = this.countryList.find((obj: any) => (obj.countryCode === this.searchFormIndividual.value.countryCode));
		this.changeTab();
	}

	IsValidNRIC(inputstr: any) {
		var NRIC = '';
		var strWeight = '2765432';
		var strReference = 'ABCDEFGHIZJ';
		var intTotal: any = 0;

		if (inputstr == null || inputstr == '')
			return false;
		NRIC = inputstr.toUpperCase();
		switch (NRIC.length) {
			case 8:
				return false;
			case 9:
				if (NRIC.substr(0, 1) == 'T')
					intTotal = 4;
				if (NRIC.substr(0, 1) == 'S' || NRIC.substr(0, 1) == 'T')
					NRIC = NRIC.substr(1, 8);
				else
					return false;
				break;
			default:
				return false;
		}
		if (isNaN(parseInt(NRIC.substr(0, 7)))) return false;

		for (var i = 0; i < 7; i++)
			intTotal = intTotal + parseInt(NRIC.substr(i, 1)) * parseInt(strWeight.substr(i, 1));

		if (NRIC.substr(7, 1) != strReference.substr(11 - (intTotal % 11) - 1, 1))
			return false;
		else
			return true;
	}

	onSubmitIndividual() {
		if (this.searchFormIndividual.value.countryCode == 'SGP') {
			if(!this.IsValidNRIC(this.searchFormIndividual.value.nric_number)) {
				let dialogRef = this.dialog.open(MonitorConfimDialogComponent, {
					disableClose: true,
					width: this.isMobile? 'auto':'37vw',
					data: {
						nric_id: this.searchFormIndividual.value.nric_number
					}
				});
				dialogRef.afterClosed().subscribe(res => {
					if (res != undefined && res != null && res) {
						let dialogRef = this.dialog.open(IndividualPickAlertComponent, {
							disableClose: true,
							width: this.isMobile? 'auto':'70vw',
							panelClass: 'advanced-search-dialog',
							data: {
								data: this.searchFormIndividual.value,
								isAdd: true,
								country: this.selectedCountry
							}
						});
						dialogRef.afterClosed().subscribe(res => {
							if (res)
								this.tabsEvent.emit('recordsSlide');
						});
					}
				});
			} else {
				let dialogRef = this.dialog.open(IndividualPickAlertComponent, {
					disableClose: true,
					width: this.isMobile? 'auto':'70vw',
					panelClass: 'advanced-search-dialog',
					data: {
						data: this.searchFormIndividual.value,
						isAdd: true,
						country: this.selectedCountry
					}
				});
				dialogRef.afterClosed().subscribe(res => {
					if (res)
						this.tabsEvent.emit('recordsSlide');
				});
			}
		} else {
			let dialogRef = this.dialog.open(IndividualPickAlertComponent, {
				disableClose: true,
				width: this.isMobile? 'auto':'70vw',
				panelClass: 'advanced-search-dialog',
				data: {
					data: this.searchFormIndividual.value,
					isAdd: true,
					country: this.selectedCountry
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res)
				this.tabsEvent.emit('recordsSlide');
			});
		}

	}

	customSearchFn(term: string, item: any) {
		term = term.toLowerCase();
		let splitTerm = term.split(' ').filter(t => t);
		let isWordThere: boolean[] = [];
		splitTerm.forEach(arr_term => {
			let byName = item['countryName'].toLowerCase();
			let includes = item['countryIncludes']
			if (includes != null) {
				let byIncludes = includes.toLowerCase();
				isWordThere.push(byIncludes.indexOf(arr_term) != -1 || byName.indexOf(arr_term) != -1);
			} else {
				isWordThere.push(byName.indexOf(arr_term) != -1);
			}
		});
		const all_words = (this_word: any) => this_word;
		return isWordThere.every(all_words);
	}

	IsValidRegno(sRegno: any) {
		var sAtoZ="ABCDEFGHIJKLMNOPQRSTUVWXYZ";                         
		if(sRegno==null || sRegno=="") {return false;}
		sRegno=sRegno.replace("\\040"," ");   
		sRegno=sRegno.toUpperCase();           
		
		switch (sRegno.length){
			case 9 :
				if (isNaN(sRegno.substr(0,8))) {
					return false;
				}
				if (sAtoZ.indexOf(sRegno.charAt(8))==-1){
					return false;
				}
				break;
			case 10 :
				if ((sRegno.charAt(0) == "F") || (sRegno.substr(3,2)=="FC")) {
					return true; 
				}
				
				if (sRegno.substr(3,2)=="LL") {
					return true; 
				}
				
				if (sRegno.substr(3,2)=="LP") {return true; }
		
				if (sRegno.substr(3,2)=="PF") {return true; }
		
				if (sRegno.substr(0,2) == "LL") {
		
				if ( !isNaN(sRegno.substr(2,7)) && sAtoZ.indexOf(sRegno.charAt(9))!=-1) {
					return true;
				} else {
					return false;
				}
		
			}
		
			if (isNaN(sRegno.substr(0,9)) ){return false; }
		
			if (sAtoZ.indexOf(sRegno.charAt(9))==-1) {return false;}
		
			break;
	
			default :
				return false;
			}
		return true;
	}

	onSubmitCompany() {
		let payload = {
			countryCode: this.searchFormCountry.value.countryCode,
			name: this.searchFormCountry.value.entity_name,
			number: this.searchFormCountry.value.entity_no
		}
		this.loaderService.isLoading.next(true);
		this.service.searchCompanies(payload).subscribe((data: any) => {
			this.loaderService.isLoading.next(false);
			if (typeof data == 'string' && JSON.parse(data)?.success) {
				data = JSON.parse(data);
				var searchResults = data.data? data.data: [];
				if (data.errors?.length > 0) {
					if (data.errors[0]?.errorCode == 'S004') {
						let dialogRef = this.dialog.open(NoResultDialogComponent, {
							disableClose: true,
							width: this.isMobile? 'auto':'37vw',
							data: {}
						});
					} else {
						this.translateService.get(['errorMsg.gdnDown']).subscribe(value => {
							let dialogRef = this.dialog.open(NoResultDialogComponent, {
								width: this.isMobile? 'auto':'37vw',
								disableClose: true,
								data: {
									infoMessage: value['errorMsg.gdnDown'],
									manualSearch: true,
									gdnFailed: true
								}
							});
						});
					}
				} else {
						let dialogRef = this.dialog.open(MonitoringSearchComponent, {
							disableClose: true,
							width: this.isMobile? 'auto':'81vw',
							panelClass: 'search-result-dialog',
							data: {
								results: searchResults
							}
						});
						dialogRef.afterClosed().subscribe(res => {
							if(res == 'true') {
								let dialogRef = this.dialog.open(AddManuallyDialogComponent, {
									disableClose: true,
									width: this.isMobile?"auto":"50vw",
									panelClass: 'advanced-search-dialog',
									data: {
										results: payload
									}
								});
								dialogRef.afterClosed().subscribe(manually => {
									if(manually) {
										if(this.IsValidRegno(manually.entity_no)) {	
											manually['edit'] = false;
											manually['manuall'] = true;
											manually['add'] = true;
											let dialogRef = this.dialog.open(ManuallPickAlertComponent, {
												disableClose: true,
												width: this.isMobile? 'auto':'70vw',
												panelClass: 'advanced-search-dialog',
												data: {
													results: manually
												}
											});
											dialogRef.afterClosed().subscribe(res => {
												if (res)
													this.tabsEvent.emit('recordsSlide');
											});
										} else {
											let dialogRef = this.dialog.open(ManuallyAddConfimDialogComponent, {
												disableClose: true,
												width: this.isMobile? 'auto':'33vw',
												data: {
													entity_no: manually.entity_no
												}
											});
											dialogRef.afterClosed().subscribe(flag => {
												if (flag) {
													manually['edit'] = false;
													manually['add'] = true;
													manually['manuall'] = true;
													let dialogRef = this.dialog.open(ManuallPickAlertComponent, {
														disableClose: true,
														width: this.isMobile? 'auto':'70vw',
														panelClass: 'advanced-search-dialog',
														data: {
															results: manually
														}
													});
													dialogRef.afterClosed().subscribe(res => {
														if (res)
															this.tabsEvent.emit('recordsSlide');
													});
												}
											});
										}
									}
								});
							} else {
								res['edit'] = false;
								res['add'] = true;
								res['country'] = this.selectedCountry;
								res['clientFileReference'] = this.searchFormCountry.value.file;
								if (res != undefined && res != null) {
									let dialogRef = this.dialog.open(CompanyPickAlertComponent, {
										disableClose: true,
										width: this.isMobile? 'auto':"70vw",
										panelClass: 'advanced-search-dialog',
										data: {
											results: res
										}
									});
									dialogRef.afterClosed().subscribe(res => {
										if (res)
											this.tabsEvent.emit('recordsSlide');
									});
								}
							}
							
						});
				}
			} else  {
				this.loaderService.isLoading.next(false);
				this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
					let dialogRef = this.dialog.open(ErrorDialogComponent, {
						disableClose: true,
						width: this.isMobile? 'auto':'37vw',
						data: {
							errorMessage: value['errorMsg.pleaseTryAgain']
						}
					});
					dialogRef.afterClosed().subscribe(res => {
						if (res) {
							this.onSubmitCompany();
						}
					});
				})
			}
		}, err => {
			this.loaderService.isLoading.next(false);
			this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
				let dialogRef = this.dialog.open(ErrorDialogComponent, {
					width: this.isMobile? 'auto':'37vw',
					disableClose: true,
					data: {
						errorMessage: value['errorMsg.pleaseTryAgain']
					}
				});
				dialogRef.afterClosed().subscribe((res: any) => {
					if (res) {
						this.onSubmitCompany();
					}
				});
			});
		});
	}


	bulkUpload() {
		let dialogRef = this.dialog.open(MonitoringBulkUploadComponent, {
			disableClose: true,
			data: this.summary,
			width: "680px",
			panelClass: 'advanced-search-dialog',
		});
		dialogRef.afterClosed().subscribe(res => {
			if(res){
				this.bulkUploadDetails = null;
				this.bulkUploadDetails = res;
				this.bulkuploadLinkStatus = res;
				this.bulkUploadDetails['redirect'] = true;
				this.uploadStatus.emit(this.bulkUploadDetails);
			}
		});
	}

	getBulkUploadDetails() {
		this.service.getBulkUploadDetails().subscribe((resp: any) => {
			if (resp.success) {
				if(resp?.data?.bulkAlertStatus == 'Uploaded') {
					this.service.cancelBulkUpload(resp.data.bulkAlertUploadNumber).subscribe(
						(res: any) => {
							this.bulkUploadDetails = res.data;
							this.bulkuploadLinkStatus = res.data;
						}
					);
				} else {
					this.bulkUploadDetails = resp;
					// if(this.bulkUploadDetails)
					this.bulkUploadDetails['redirect'] = false;
					this.bulkuploadLinkStatus = resp.data;
					this.uploadStatus.emit(this.bulkUploadDetails);
				}
			}
		}, err => {
		});
	}
}
