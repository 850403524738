import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AppService } from '../app.service';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class MonitoringService {

	headers = new HttpHeaders();
	sessionId: any = "";
	public manualSubscriber = new Subject<any>();
	public selection = new Subject<string>();
	constructor(private http: HttpClient, private appService: AppService) {

		this.sessionId = localStorage.getItem('sessionID');
		this.headers = this.headers.set('Accept', 'application/json');
		this.headers = this.headers.set('Access-Control-Allow-Origin', '*');
		this.headers = this.headers.set('Access-Control-Allow-Credentials', 'true');
		this.headers = this.headers.set('sessionid', this.sessionId);
	}

	setSid(sessionId: any) {
		localStorage.setItem('sessionID', sessionId);
		this.sessionId = localStorage.getItem('sessionID');
		this.headers = this.headers.set('sessionid', sessionId);
	}

	getOrderHistory() {
		this.sessionId = localStorage.getItem('sessionID');
		this.headers = this.headers.set('sessionid', this.sessionId);
		//const params = new HttpParams().set('itemStatus', '')
		return this.http.get<any>(this.appService.baseURL + '/api/v1/Order/OrderHistory',
			{ headers: this.headers }).pipe(map(res => res));
	}

	getCountries() {
		return this.http.get<any>(this.appService.baseURL + '/api/v1/Country/GetCountries',
			{ headers: this.headers }).pipe(map(res => res));
	}

	addChildToCart(data: any, seqId: any) {
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Order/items/' + seqId + '/children', data,
			{ headers: this.headers }).pipe(map(res => res));
	}

	generateReport(itemId: any, format: any): Observable<Blob> {
		const httpOptions = {
			responseType: 'blob' as 'json',
			headers: this.headers
		};
		var formData: any = new FormData();
		formData.append("itemId", itemId);
		formData.append("reportFormat", format);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Order/GenerateReport', formData,
			httpOptions).pipe(map(res => res));
	}

	getPreviouslyDeleted(recordsPerPage: number, pageNumber: number) {
		var formData: any = new FormData();
		formData.append("RecordsPerPage", recordsPerPage);
		formData.append("PageNumber", pageNumber);
		formData.append("FilterCriteria", '{}');
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Monitoring/GetDeletedNameListPaginated', formData, { headers: this.headers }).pipe(map(res => res));
	}

	getAllMonitoringRecords(recordsPerPage: number, pageNumber: number, filterValue: any): any {
		var formData: any = new FormData();
		formData.append("RecordsPerPage", recordsPerPage);
		formData.append("PageNumber", pageNumber);
		formData.append("FilterValue", filterValue);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Monitoring/GetNameListPaginated', formData, { headers: this.headers }).pipe(map(res => res));
	}

	exportRecords(recordsPerPage: number): any {
		var formData: any = new FormData();
		var pageNumber: any = 1;
		formData.append("RecordsPerPage", recordsPerPage);
		formData.append("PageNumber", pageNumber);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Monitoring/GetNameListPaginated', formData, { headers: this.headers }).pipe(map(res => res));
	}

	getSummury(): any {
		return this.http.get<any>(this.appService.baseURL + '/api/v1/monitoring/GetSummaryDetails', { headers: this.headers }).pipe(map(res => res));
	}

	updateMonitoredList(data: any): any {
		return this.http.put<any>(this.appService.baseURL + '/api/v1/monitoring/EditEntity', data,
			{ headers: this.headers }).pipe(map(res => res));
	}

	addMonitoredList(data: any): any {
		return this.http.post<any>(this.appService.baseURL + '/api/v1/monitoring/AddEntity', data,
			{ headers: this.headers }).pipe(map(res => res));
	}

	loadRecords(): any {
		var data = {
			data: "load"
		}
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Monitoring/ImportQnNamelist', data,
			{ headers: this.headers }).pipe(map(res => res));
	}

	addCorporateEntity(data: any): any {
		return this.http.post<any>(this.appService.baseURL + '/api/v1/monitoring/AddEntity', data,
			{ headers: this.headers }).pipe(map(res => res));
	}

	restoreEntity(data: any): any {
		return this.http.put<any>(this.appService.baseURL + '/api/v1/monitoring/RestoreEntity', data,
			{ headers: this.headers }).pipe(map(res => res));
	}

	deleteMonitoringRecord(data: any) {
		var formData: any = new FormData();
		formData.append("entityId", data);
		const options = {
			headers: this.headers,
			body: formData
		};
		return this.http.delete<any>(this.appService.baseURL + '/api/v1/monitoring/DeleteEntity', options).pipe(map(res => res));
	}

	deleteMonitoringRecordPermanantly(data: any) {
		return this.http.post<any>(this.appService.baseURL + '/api/v1/monitoring/RemoveDeletedEntity', data, { headers: this.headers }).pipe(map(res => res));
	}

	getAlertsSummary(): any {
		return this.http.get<any>(this.appService.baseURL + '/api/v1/Monitoring/GetCustomerAlertSummary', { headers: this.headers }).pipe(map(res => res));
	}

	searchCompanies(data: any) {
		var search = {
			"Name": data?.name,
			"EntityNo": data?.number,
			"Start": "",
			"Limit": ""
		}
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Monitoring/SearchCompany', search,
			{ headers: this.headers }).pipe(map(res => res));
	}

	getAllAlarts(recordsPerPage: any, pageNumber: any, filterValue: any): any {
		var formData: any = new FormData();
		if (filterValue) {
			if (filterValue.entityCommonFilter)
				formData.append("entityCommonFilter", filterValue.entityCommonFilter);
			if (filterValue.monitoringTypeFilter && filterValue.monitoringTypeFilter != "ALL")
				formData.append("monitoringTypeFilter", filterValue?.monitoringTypeFilter);
			if (filterValue.entityAlertClassificationFilter)
				formData.append("entityAlertClassificationFilter", filterValue?.entityAlertClassificationFilter);
			if (filterValue.alertTriggerFilter)
				formData.append("alertTriggerFilter", filterValue?.alertTriggerFilter);
			if (filterValue.alertDateFromFilter)
				formData.append("alertDateFromFilter", filterValue?.alertDateFromFilter);
			if (filterValue.alertDateToFilter)
				formData.append("alertDateToFilter", filterValue?.alertDateToFilter);
		}

		formData.append("RecordsPerPage", recordsPerPage);
		formData.append("PageNumber", pageNumber);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Monitoring/GetAlertDetails', formData, { headers: this.headers }).pipe(map(res => res));
	}

	getPaymentAlarts(alertType: any, entityId: any, dateTo: any, dateFrom: any, category: any): any {
		var formData: any = new FormData();
		formData.append("alertType", alertType);
		if (entityId)
			formData.append("entityId", entityId);
		if (dateTo)
			formData.append("dateTo", dateTo);
		if (dateFrom)
			formData.append("dateFrom", dateFrom);
		formData.append("alertCategory", category);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Monitoring/GetAlertTypeDetails', formData, { headers: this.headers }).pipe(map(res => res));
	}

	exportToExcel(alertType: any, dateTo: any, dateFrom: any, category: any, entityId?: any): any {
		var formData: any = new FormData();
		formData.append("alertType", alertType);
		if (entityId)
			formData.append("entityId", entityId);
		if (dateTo)
			formData.append("dateTo", dateTo);
		if (dateFrom)
			formData.append("dateFrom", dateFrom);
		formData.append("alertCategory", category);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Monitoring/GetAlertTypeExportDetails', formData, { headers: this.headers }).pipe(map(res => res));
	}

	getPaymentAlartsUnsub(alertType: any): any {
		var formData: any = new FormData();
		formData.append("alertType", alertType);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Monitoring/GetAlertTypeDetails', formData, { headers: this.headers }).pipe(map(res => res));
	}

	getAlertTypesAndAlertTriggers(alertType: any): any {
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Monitoring/GetAlertTypesAndTriggers', alertType, { headers: this.headers }).pipe(map(res => res));
	}

	// Monitoring Service

	bulkUploadProcessingStatus(bulkAlertUploadNumber: any) {
		const formData = new FormData();
		formData.append('bulkAlertUploadNumber', bulkAlertUploadNumber);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Monitoring/GetBulkUploadCurrentStatus', formData, { headers: this.headers }).pipe(map(res => res));
	}

	addToMonitoring(id?: any) {
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Monitoring/AddToNamelistManual', id, { headers: this.headers }).pipe(map(res => res));
	}

	bulkUploadList(count: any, bulkAlertUploadNumber: any, filterStatus: any) {
		const formData = new FormData();
		formData.append('bulkAlertUploadNumber', bulkAlertUploadNumber);
		formData.append('recordCount', count);
		formData.append("filterStatus", filterStatus);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Monitoring/BulkUploadedRecordList', formData, { headers: this.headers }).pipe(map(res => res));
	}

	removeRecords(bulkOrderItemId: any) {
		return this.http.post<any>(this.appService.baseURL + '/api/v1/BulkOrder/RemoveBulkReportOrderItem', bulkOrderItemId, { headers: this.headers }).pipe(map(res => res));
	}

	upload(fileToUpload: File, alertType: any) {
		const formData = new FormData();
		formData.append('inputFile', fileToUpload);
		formData.append('alertType', alertType);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Monitoring/BulkMonFileUpload', formData, { headers: this.headers }).pipe(map(res => res));
	}

	completeProcessing(bulkAlertUploadNumber: any) {
		const formData = new FormData();
		formData.append('bulkAlertUploadNumber', bulkAlertUploadNumber);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Monitoring/CompleteBulkUpload', formData,
			{ headers: this.headers }).pipe(map(res => res));
	}

	confirmBulkOrder(bulkAlertUploadNumber: any) {
		const formData = new FormData();
		formData.append('bulkAlertUploadNumber', bulkAlertUploadNumber);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Monitoring/ProcessBulkUpload', formData, { headers: this.headers }).pipe(map(res => res));
	}

	cancelBulkUpload(bulkAlertUploadNumber: any) {
		const formData = new FormData();
		formData.append('bulkAlertUploadNumber', bulkAlertUploadNumber);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Monitoring/CancelBulkUpload', formData, { headers: this.headers }).pipe(map(res => res));
	}

	cancelProcessing(bulkAlertUploadNumber: any) {
		const formData = new FormData();
		formData.append('bulkAlertUploadNumber', bulkAlertUploadNumber);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Monitoring/CancelProcessingBulkUpload', formData, { headers: this.headers }).pipe(map(res => res));
	}

	getBulkUploadDetails() {
		return this.http.get<any>(this.appService.baseURL + '/api/v1/Monitoring/GetBulkUploadDetails',
			{ headers: this.headers }).pipe(map(res => res));
	}

	getLmsCoast() {
		return this.http.get<any>(this.appService.baseURL + '/api/v1/Monitoring/GetLmsCost',
			{ headers: this.headers }).pipe(map(res => res));
	}

	getLmsSubscriptionSummary(bulkAlertUploadNumber: any) {
		const formData = new FormData();
		formData.append('bulkAlertUploadNumber', bulkAlertUploadNumber);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Monitoring/GetLmsSubscriptionSummary', formData, { headers: this.headers }).pipe(map(res => res));
	}

	checkIsLmsMid(entityId: any) {
		const formData = new FormData();
		formData.append('entityId', entityId);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Monitoring/GetLmsExistence', formData,
			{ headers: this.headers }).pipe(map(res => res));
	}

	GetEntitySubscription(entityId: any) {
		const formData = new FormData();
		formData.append('entityId', entityId);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Monitoring/GetEntitySubscription', formData,
			{ headers: this.headers }).pipe(map(res => res));
	}

	addManually() {
		this.manualSubscriber.next(true);
	}

	saveUserExportPreferenceDetail(alertType: any, alertDuration: any): any {
		var formData: any = new FormData();
		formData.append("alertType", alertType);
		formData.append("alertDuration", alertDuration);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/Monitoring/SaveUserExportPreferenceDetail', formData, { headers: this.headers }).pipe(map(res => res));
	}

	getUserExportPreferenceDetail(): any {
		return this.http.get<any>(this.appService.baseURL + '/api/v1/Monitoring/GetUserExportPreferenceDetail', { headers: this.headers }).pipe(map(res => res));
	}
	userManualReport() {
		var url = 'assets/reports/MonitoringServicesUserManual.pdf';

		const httpOptions = {
			responseType: 'blob' as 'json',
			headers: this.headers
		};
		return this.http.get(url, httpOptions).pipe(map(res => res));
	}

	getAllGlobalMonitringAlerts(recordsPerPage: any, pageNumber: any, filterValue: any): any {
		var formData: any = new FormData();
		if (filterValue) {
			if (filterValue.entityCommonFilter)
				formData.append("entityCommonFilter", filterValue.entityCommonFilter);
			if (filterValue.monitoringTypeFilter && filterValue.monitoringTypeFilter != "ALL")
				formData.append("monitoringTypeFilter", filterValue?.monitoringTypeFilter);
			if (filterValue.entityAlertClassificationFilter)
				formData.append("entityAlertClassificationFilter", filterValue?.entityAlertClassificationFilter);
			if (filterValue.alertTriggerFilter)
				formData.append("alertTriggerFilter", filterValue?.alertTriggerFilter);
			if (filterValue.alertDateFromFilter)
				formData.append("alertDateFromFilter", filterValue?.alertDateFromFilter);
			if (filterValue.alertDateToFilter)
				formData.append("alertDateToFilter", filterValue?.alertDateToFilter);
		}

		formData.append("RecordsPerPage", recordsPerPage);
		formData.append("PageNumber", pageNumber);
		return this.http.post<any>(this.appService.baseURL + '/api/v1/GlobalMonitoring/GetAlertDetails', formData, { headers: this.headers }).pipe(map(res => res));
	}
}