<mat-card class="p-s-r p-0 b-shadow-n">
    <div *ngIf="data.label == 'monitor-alert-filter-popup'" class="filter-popup">
        <div class="filter-title">
            <span>Filter By</span>
        </div>
        <div class="mt-36">
            <label>{{ 'monitor.searchByEntityName' | translate }}</label><br />
            <input matInput type="text" [(ngModel)]="searchByEntityName">
            <mat-icon matSuffix class="search-input-icon">search</mat-icon>
        </div>

        <div class="mt-27">

        </div>

        <div class="d-flex">

        </div>
        <div fxLayout="row">
            <div fxFlex="50" style="margin: 0 10px 10px 0;">
                <label>{{ 'global_monitor.date' | translate }}</label><br />
                <input type="text" ngxDaterangepickerMd [(ngModel)]="startDate" [showCustomRangeLabel]="true"
                    [lockStartDate]="false" [alwaysShowCalendars]="true" [singleDatePicker]="true" [locale]="locale"
                    [ranges]="ranges" [linkedCalendars]="false" [autoApply]="true" [showClearButton]="false"
                    [maxDate]="maxDate" (datesUpdated)="startDateUpdated()"
                    [placeholder]="'placeholder.from' | translate" readonly />
                <mat-icon matSuffix class="search-input-icon ngx-daterangepicker-action"
                    (click)="open($event)">date_range</mat-icon>
            </div>
            <div fxFlex="50" style="margin: 0 0 10px 10px;">
                <label style="padding-bottom: 14px;"></label><br />
                <input type="text" #picker1 ngxDaterangepickerMd [(ngModel)]="endDate" [showCustomRangeLabel]="false"
                    [alwaysShowCalendars]="true" [singleDatePicker]="true" [locale]="locale" [linkedCalendars]="false"
                    [autoApply]="true" [showClearButton]="false" opens="left" (datesUpdated)="endDateUpdated()"
                    [maxDate]="maxDate" [placeholder]="'placeholder.to' | translate" readonly />
                <mat-icon matSuffix class="search-input-icon open ngx-daterangepicker-action">date_range</mat-icon>
            </div>
        </div>

        <div fxLayout="row">
            <div style="margin: 10px 0;" class="mt-10">
                <button class="button button__primary float-right" (click)="applyFilter(allAlerts)"
                    style="margin: 10px;">
                    Apply
                </button>
                <button (click)="removeFilter()" class="button btn button__basic float-right mt-10"
                    style="margin: 10px;">
                    {{ 'monitor.clear_filter' | translate }}
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="data.label == 'monitor-all-record-filter-popup'">
        <div style="padding: 50px 16px 36px 16px;">
            <label>{{ 'monitor.searchByEntityName' | translate }}</label><br />
            <input matInput type="text" [(ngModel)]="filterValue">
            <mat-icon matSuffix class="search-input-icon">search</mat-icon>
            <div style="margin: 35px 0 0 0;flex: auto;">
                <button class="button button__primary m-r-10" (click)="ApplyFilter()">
                    Apply
                </button>
                <button (click)="RemoveFilter()" class="button btn button__basic">
                    {{ 'monitor.clear_filter' | translate }}
                </button>
            </div>
        </div>
    </div>
    <div class="close-icon" [ngStyle]="data.label == 'monitor-all-record-filter-popup'?{'top':'20px'}:{'top':'35px'}">
        <i class="fa fa-times" (click)="close()"></i>
    </div>
</mat-card>