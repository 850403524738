<div class="overflow-y">
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-12 left-section" style="white-space: break-spaces;">
            <b>{{'thankYou.orderItemId' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-12 right-section" style="white-space: break-spaces;">
           {{cellData.cartSeqNo}}
        </div>
    </div>
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-12 left-section" style="white-space: break-spaces;">
            <b>{{'thankYou.country' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-12 right-section" style="white-space: break-spaces;">
           {{cellData.countryName}}
        </div>
    </div>
    
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-12 left-section" style="white-space: break-spaces;">
            <b>{{'thankYou.language' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-12 right-section" style="white-space: break-spaces;">
           {{cellData.productLanguage}}
        </div>
    </div>
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-12 left-section" style="white-space: break-spaces;">
            <b>{{'thankYou.product' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-12 right-section" style="white-space: break-spaces;">
           {{cellData.productDescription}}
        </div>
    </div>
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-12 left-section" style="white-space: break-spaces;">
            <b>{{'thankYou.variationSpeed' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-12 right-section" style="white-space: break-spaces;">
           {{cellData.speedDescription}}
        </div>
    </div>
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-12 left-section" style="white-space: break-spaces;">
            <b>{{'thankYou.price' | translate}} ({{currency}})</b>
        </div>
        <div fxFlex="50" class="right p-t-12 right-section" style="white-space: break-spaces;">
            {{cellData.sellingPrice}}
        </div>
    </div>
</div>
