import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { NotificationService } from "../shared-module/toast/toast.service";
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MonitoringService } from '../monitoring.service';
import { LoaderService } from 'src/app/services/loader.service';
import { GridOptions, GridApi } from 'ag-grid-community';
import { ErrorDialogComponent } from 'src/app/error-dialog-box/error-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { DNSADetailCellRenderer } from './master-data-grid-dnsa/cell-renderer.component';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { ExpandGridCellRendererComponent } from '../shared-module/expand-grid-cell-renderer/expand-grid-cell-renderer.component';
import { MyNoRowsComponent } from '../shared-module/search-result/no-overlay.component';

@Component({
	selector: 'app-dnsa-alert',
	templateUrl: './daily-new-suits-alert.component.html',
	styleUrls: ['./daily-new-suits-alert.component.scss']
})
export class DailySuitServiceComponent implements OnInit {
	isMobile: boolean = false;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if(event.target.innerWidth > 1000) {
			this.isMobile = false;
			this.getColDefs();
		} else {
			this.isMobile = true;
			this.getColDefs();
		}
	}
	@Output() tabsEvent = new EventEmitter();
	changeLanEvent: Subject<void> = new Subject<void>();
 	
	title: any = "Monitoring";

	columnDefs: any = [];
 	gridOptions: any;
	gridColumnApi: any;
 	api: any;
 	domLayout: any = "autoHeight";
	gridHeight: any = 100;
	alartList: any;
	otherInfo: any;
	summary: any;
	detailCellRenderer: any;
	detailRowHeight: number;
	getRowStyle: any;
	
	count: any = 0;
	empty: any = [];
	currentDate = moment(new Date()).format('DD/MM/YYYY');

	entityId: any;
	startDate: any;
	endDate: any;
	exportList: any;
	allExpand: boolean = true;
	expandCollapseButtonValue: String = "Collapse all rows"
	get gridAPI(): GridApi {
		return this.gridOptions.api;
	}

	constructor(
		public _notificationservice: NotificationService,
		public router: ActivatedRoute,
		public iconRegistry: MatIconRegistry,
		public sanitizer: DomSanitizer,
		private dialog: MatDialog,
		public monitoringService: MonitoringService,
		public translateService: TranslateService,
		public loaderService: LoaderService,
	) {
		if(window.screen.width > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
		this.router.queryParams.subscribe((route) => {
			if (route.recordSlide == 'true') {
				
			}
		});
		this.getColDefs();
		this.entityId = localStorage.getItem('entityId');
		this.startDate = localStorage.getItem('dateFrom');
		this.endDate = localStorage.getItem('dateTo');
		this.loadRecords();
		this.export();
		window.scroll(0, 0);
		this.getGridDefination();
	}

	onGridReady(params: any) {
		this.gridColumnApi = params.columnApi;
		this.api = params.api;
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
		this.gridAPI.resetRowHeights();
	}

	getGridDefination() {
		this.gridOptions = <GridOptions>{
			onGridReady: () => {
				setTimeout(() => {
					this.gridOptions.api?.sizeColumnsToFit();
				}, 1000);
			 },
			onGridSizeChanged: () => {
				this.gridOptions.api.sizeColumnsToFit();
			},
			noRowsOverlayComponent: 'myNoRowsComponent',
			noRowsOverlayComponentParams: {
				"component": "detailed"
			},
			pagination: false,
			animateRows: true,
			suppressCellSelection: true,
			enableCellTextSelection: true,
			suppressContextMenu:true,
			defaultColDef: {
				flex: 1,
				wrapText: true,
				resizable: true,
				autoHeight: true,
				unSortIcon: true,
				sortable: true,
				suppressMenu: true,
				lockPosition: true,
				tooltipComponent: 'customTooltip'
			}
		};
		this.detailCellRenderer = 'detailCellRenderer';
		this.gridHeight = this.isMobile? 200:100;		
		this.gridOptions.frameworkComponents = {
			"cellRenderer": ExpandGridCellRendererComponent,
			"detailCellRenderer": DNSADetailCellRenderer,
			myNoRowsComponent: MyNoRowsComponent
		};
		this.monitoringService.setSid(localStorage.getItem('sessionID'));
		this.getRowStyle = (params: any) => {
			if (params.node.rowIndex % 2 !== 0) {
				return { background: '#D0D0D0' };
			}
		};
	}

	loadRecords() {
		this.loaderService.isLoading.next(true);
		this.monitoringService.getPaymentAlarts("DNSA", this.entityId, this.endDate, this.startDate, "ALL").subscribe((data: any) => {
			if (data?.success) {
				data.data.list?.Alerts?.map((obj: any, index: number) => {
					obj.Records.map((rec: any, id: any) => {
						rec['srNo'] = id + 1;
						rec['expanded'] = true;
					});
				});		
				this.alartList = data.data?.list?.Alerts;
				if(data.data?.count) 
				this.count = data.data?.count;
				this.loaderService.isLoading.next(false);
			} else {
				this.loaderService.isLoading.next(false);
				this.openTryAgainPopup();
				this.alartList = [];
			}
		}, (err: any) => {
			this.loaderService.isLoading.next(false);
			this.alartList = [];
			this.openTryAgainPopup();
		});
	}

	openTryAgainPopup() {
		this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
			let dialogRef = this.dialog.open(ErrorDialogComponent, {
				disableClose: true,
				width: this.isMobile?'auto':'37vw',
				data: {
					errorMessage: value['errorMsg.pleaseTryAgain']
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					this.loaderService.isLoading.next(true);
					this.monitoringService.getPaymentAlarts("DNSA", this.entityId, this.endDate, this.startDate, "ALL").subscribe((data: any) => {
						if (data?.success) {
							data?.data?.list?.Alerts.map((obj: any, index: number) => {
								obj['srNo'] = index+ 1;
							});
							this.alartList = data.data?.list;
							this.loaderService.isLoading.next(false);
						} else {
							this.loaderService.isLoading.next(false);
							this.openTryAgainPopup();
							this.alartList = [];
						}
					}, (err: any) => {
						this.loaderService.isLoading.next(false);
						this.alartList = [];
						this.openTryAgainPopup();
					});
				}
			});
		})
	}

	serialNumberGetter(params:any) {
		return 1;
	}

	dateGetter(params: any) {
		return moment(params.data.AlertDate).format('DD MMM YYYY');
	}

 	getColDefs() {
		this.translateService.get(['monitor.fms.no', 'monitor.dnsa.search_date', 'monitor.dnsa.search_type',
		'monitor.dnsa.search_name', 'monitor.dnsa.search_id']).subscribe(value => {
				this.columnDefs = [
					{
						field: 'srNo',
						headerName: value['monitor.fms.no'],
						tooltipField: 'clientFileRef',
						tooltipComponentParams: { type: 'fileRef' },
						sortable: false,
						width: this.isMobile?60:110,
						maxWidth: this.isMobile?60:110,
						cellStyle: this.isMobile?{'padding':'13px 0px 13px 16px'}:{ 'overflow': 'visible', 'text-align': 'left', 'padding': '13px', 'padding-left': '20px'}
					},
					{
						field: 'SearchDate',
						headerName: value["monitor.dnsa.search_date"],
						tooltipComponentParams: { type: 'fileRef' },
						sortable: false,
						width: 110,
						minWidth: 110,
						hide:this.isMobile,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' }
					},
					{
						field: 'SearchType',
						headerName: value['monitor.dnsa.search_type'],
						tooltipComponentParams: { type: 'fileRef' },
						sortable: false,
						// width: this.isMobile?200:110,
						minWidth: this.isMobile?200:110,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': this.isMobile?'13px 0px 13px 13px':'13px', }
					},
					{
						field: 'SearchName',
						headerName: value['monitor.dnsa.search_name'],
						tooltipComponentParams: { type: 'fileRef' },
						sortable: false,
						width: 110,
						minWidth: 110,
						hide:this.isMobile,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' }
					},
					{
						field: 'SearchId',
						headerName: value['monitor.dnsa.search_id'],
						// valueGetter: this.dateGetter,
						sortable: false,
						width: 110,
						minWidth: 110,
						hide:this.isMobile,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' },
					},
					{
						headerName: '',
						width: 50,
						maxWidth: 50,
						sortable: false,
						hide:this.isMobile? false:true,
						cellRenderer: "cellRenderer",
						cellRendererParams: {
							onClick: this.dailyNewSuitsAlertAction.bind(this),
							label: 'daily-new-suits-alert-action'
						},
						cellStyle: { 'text-align': 'right' },
					}
				]
			});
	} 

	dailyNewSuitsAlertAction(ev: any) {
		if (ev.label === 'expand') {
			ev.data.expanded = !ev.node.expanded;
			ev.node.setExpanded(!ev.node.expanded);
			this.gridOptions.api.refreshCells(ev);
			ev.refreshCell();
		}
	}

	onFirstDataRendered(params: any) {
		setTimeout(() => {
			params.api.forEachNode((node:any) =>{
				node.expanded = this.allExpand;
			});
		}, 10);
	}

	ExpandAll(data:any){
		if(data){
			var results = this.alartList;
			this.alartList = [];
			setTimeout(() => {
				this.alartList = results;	
				this.alartList.map((obj: any, index: number) => {
					obj.Records.map((rec: any, id: any) => {
						rec['expanded'] = false;
					});
				});		
			}, 500);
			this.allExpand = false;		
			this.expandCollapseButtonValue = "Expand all rows"
		}else{
			var results = this.alartList;
			this.alartList = [];
			setTimeout(() => {
				this.alartList = results;	
				this.alartList.map((obj: any, index: number) => {
					obj.Records.map((rec: any, id: any) => {
						rec['expanded'] = true;
					});
				});	
			}, 500);
			this.allExpand = true;
			this.expandCollapseButtonValue = "Collapse all rows"
		}		
		this.gridOptions.api.onGroupExpandedOrCollapsed();
	}
 
	 
 	changeLanguageGrid(ev: any) {
		this.getColDefs();
		this.gridOptions.api.refreshHeader({ forrce: true });
		setTimeout(() => {
			this.gridOptions.api?.sizeColumnsToFit();
		}, 100);
	}

	onPrint(){
		window.print();
	}

	

	openTryAgainExportPopup() {
		this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
			let dialogRef = this.dialog.open(ErrorDialogComponent, {
				disableClose: true,
				width: '37vw',
				data: {
					errorMessage: value['errorMsg.pleaseTryAgain']
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					this.exportAsExcelFile();
				}
			});
		})
	}
	

	export() {
		this.monitoringService.exportToExcel("DNSA", this.endDate, this.startDate, "ALL", this.entityId).subscribe((data: any) => {
			if (data?.success) {
				this.exportList = data?.data?.DNSA;
			}
		});
		
	}

	generateExcel() {
		let element = document.getElementById('excel-table');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
			var wscols = [
				{wch:30},
                {wch:30},
                {wch:30},
                {wch:30},
                {wch:30},
                {wch:30},
				{wch:30}
			];	
			ws['!cols'] = wscols;
			const wb: XLSX.WorkBook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, 'DNSA');
			XLSX.writeFile(wb, 'DNSA_ExcelSheet.xlsx');
	}

	exportAsExcelFile(): void {
		if(this.exportList) {
			this.generateExcel();
		} else {
			this.loaderService.isLoading.next(true);
			this.monitoringService.exportToExcel("DNSA", this.endDate, this.startDate, "ALL", this.entityId).subscribe((data: any) => {
			if (data?.success) {
				this.exportList = data?.data?.DNSA;
				this.loaderService.isLoading.next(false);
				setTimeout(()=> {
					this.generateExcel();
				}, 1000);
			} else {
				this.loaderService.isLoading.next(false);
				this.openTryAgainExportPopup();
			}
		}, (err: any) => {
			this.loaderService.isLoading.next(false);
			this.openTryAgainExportPopup();
		});
		}
    }
}
