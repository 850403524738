import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'app-detail-cell-renderer',
  templateUrl: './cell-renderer.component.html',
  styleUrls: ['./cell-renderer.component.scss']
})
export class BWSAlertDetailCellRenderer implements ICellRendererAngularComp {
  public cellData: any;
  agInit(params: any): void {
    this.cellData = params.data;
  }

  refresh(params: any): boolean {
    return false;
  }
}