<div class="dialog-content-wrapper">
    <div mat-dialog-content>

        <div class="cross-header" id="comparison">
            <span *ngIf="!viewCompareReports" class="title-text">{{ 'compareReports.title1' | translate }}</span>
            <span *ngIf="viewCompareReports" class="title-text">{{ 'compareReports.title2' | translate }}</span>
            <mat-icon class="close-btn" (click)="matDialogRef.close()">close</mat-icon>
        </div>

        <div class="price-table">
            <div class="feature-div" *ngFor="let report of reportVariationList; let i = index">
                <div *ngIf="!report.recommend" class="standard-div">
                    <span class="report-title">
                        {{ getValues(report.headerData , 'name') }}
                    </span>
                    <p class="p-subInfo" id="{{ 'subInfo-' + i}}">
                        {{ getValues(report.headerData , 'desc') }}
                    </p>
                    <div class="features" id="{{ 'div-' + i }}">
                        <span *ngFor="let key of getKeys(report.compareData)">
                            <span *ngIf="getValues(report.compareData , key)" class="img-text">
                                <img src="../../assets/img/check_purple.svg">
                                {{ convertCamalCaseToNormal(key) }}
                            </span>
                            <span *ngIf="!getValues(report.compareData , key)" class="img-text" style="opacity: 50%;">
                                <img src="../../assets/img/check_purple.svg">
                                {{ convertCamalCaseToNormal(key) }}
                            </span>
                        </span>
                    </div>
                    <div style="margin-top: 8%;">
                        <div class="product-detail">
                            <span class="anchor-btn">
                                <strong>Select an option</strong>
                            </span>
                            <span class="anchor-btn">
                                <a style="cursor: pointer;" (click)="sampleReport(report.productOfferingId)">{{
                                    'resultForm.link.viewSample' | translate }}</a>
                            </span>
                        </div>
                        <div>
                            <hr>
                        </div>
                        <div *ngFor="let productDetail1 of report.productPriceDetails">
                            <div class="product-detail">
                                <span style="line-height: 1.7;">
                                    <span><strong>{{ 'variationTable.turnaroundTime' | translate }}</strong></span><br>
                                    <span class="tat-detail">{{productDetail1.reportType}}</span><br>
                                    <span class="tat-detail">{{productDetail1.speedDescription}}</span>
                                    <span *ngIf="productDetail1.expectedDeliveryDate" class="tat-detail">
                                        <br>Expected delivery date:<br>
                                        {{productDetail1.expectedDeliveryDate | date: 'dd MMM y' }}
                                    </span>
                                </span>

                                <span>
                                    <button class="btn-primary" (click)="selectReportVariation(productDetail1)">
                                        <span>{{ 'compareReports.chooseThis' | translate }}
                                        </span><br><span>({{currency}} {{ productDetail1.productPrice | currency:'':''
                                            }})</span>
                                    </button>
                                </span>
                            </div><br>
                        </div>
                    </div>
                </div>

                <div *ngIf="report.recommend" class="permium-div">
                    <span class="report-title">
                        {{ getValues(report.headerData , 'name') }}
                    </span>
                    <p class="p-subInfo" id="{{ 'subInfo-' + i}}">
                        {{ getValues(report.headerData , 'desc') }}
                    </p>
                    <div class="features" id="{{ 'div-' + i }}">
                        <span *ngFor="let key of getKeys(report.compareData)">
                            <span *ngIf="getValues(report.compareData , key)" class="img-text">
                                <img src="../../assets/img/check_white.svg">
                                {{ convertCamalCaseToNormal(key) }}
                            </span>
                            <span *ngIf="!getValues(report.compareData , key)" class="img-text" style="opacity: 50%;">
                                <img src="../../assets/img/check_purple.svg">
                                {{ convertCamalCaseToNormal(key) }}
                            </span>
                        </span>
                    </div>
                    <p style="margin-top: 22px;">
                        Note: All the information are subjected to availability and filing requirements of country.
                    </p>
                    <div style="margin-top: 8%;">
                        <div class="product-detail">
                            <span class="anchor-premium">
                                <strong>Select an option</strong>
                            </span>
                            <span class="anchor-premium">
                                <a style="cursor: pointer;" (click)="sampleReport(report.productOfferingId)">{{
                                    'resultForm.link.viewSample' | translate }}</a>
                            </span>
                        </div>
                        <div>
                            <hr>
                        </div>
                        <div *ngFor="let productDetail2 of report.productPriceDetails">
                            <div class="product-detail">
                                <span style="line-height: 1.7;">
                                    <span><strong>
                                            {{ 'variationTable.turnaroundTime' | translate }}
                                        </strong></span><br>
                                        <span class="tat-detail">{{productDetail2.reportType}}</span><br>
                                    <span class="tat-detail">{{productDetail2.speedDescription}}</span>
                                    <span *ngIf="productDetail2.expectedDeliveryDate" class="tat-detail">
                                        <br>Expected delivery date:<br>
                                        {{productDetail2.expectedDeliveryDate | date: 'dd MMM y'}}
                                    </span>
                                </span>

                                <span>
                                    <button class="btn-premium" (click)="selectReportVariation(productDetail2)">
                                        <span>{{ 'compareReports.chooseThis' | translate }}</span>
                                        <br><span>({{currency}} {{ productDetail2.productPrice | currency:'':''
                                            }})</span>
                                    </button>
                                </span>
                            </div><br>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-text">
            {{ 'reportVariation.disclaimer' | translate }}
        </div>

    </div>
</div>