import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'bulk-grid-cell-renderer',
	template: `
				<div class="text" >
					<div>
						<button *ngIf="params.data.searchMatchReliabilityCode" [ngClass]="params.data.searchMatchReliabilityCode >= 81 ? 'green': params.data.searchMatchReliabilityCode >= 61 && params.data.searchMatchReliabilityCode <= 80 ? 'yellow': 'red'" class="button">
							{{params.data?.searchMatchReliabilityCode}}
						</button>
					</div>
					<div *ngIf="params.data.searchMatchCount > 1">
						{{ params.data.searchMatchCount}} <a class="link" (click)="viewMatches($event)">{{ 'bulk.upload.view_matches' | translate }} </a>
					</div>
				</div>
				`,
	styles: ['.link { color:#426DA9; text-decoration: underline; cursor: pointer;}', '.icon { font-size: 21px; color: #426DA9 }', '.custom-multi-tooltip { padding: 15px !important; cursor: pointer; color: white; background-color: black; z-index: 999999; opacity: 1; border-radius: 6px; width: 20vw;}', '.tooltip-arrow-custom { position: absolute; bottom: -13px; right: 47%; color: black; }', '.green { background-color: #5CD08E; color: white;}', '.yellow { background-color: #FEBA00; color: white;}', '.red { background-color: #F66C86; color: white;}', '.text {font-size: 14px;}']
})

export class MatchCellRendererComponent implements ICellRendererAngularComp {

	public params: any;
	option: string | undefined;
	showTranslation: boolean = false;

	constructor() { }

	agInit(params: any): void {
		this.params = params;
		console.log("refressing.")
	}

	refresh(): boolean {
		return false;
	}

	checkValid(data: any) {
		return data.translation;
	}

	
	onDelete(ev: any) {
		this.params['label'] = 'delete';
		this.params.onClick(this.params);
	}

	addToCart(ev: any) {
		this.params['label'] = 'addToCart';
		this.params.onClick(this.params);
	}
	
	viewMatches(ev: any) {
		this.params['label'] = 'viewMatches';
		this.params.onClick(this.params);
	}

}
