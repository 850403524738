import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PAYMENT_METHODS } from 'src/app/app.constant';
import { ErrorDialogComponent } from 'src/app/error-dialog-box/error-dialog.component';
import { LoaderService } from 'src/app/services/loader.service';
import { MonitoringService } from '../../monitoring.service';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
@Component({
  selector: 'app-lms-bulk-alert',
  templateUrl: './lms-subscription-bulk.component.html',
  styleUrls: ['./lms-subscription-bulk.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class LmsSubscriptionComponent implements OnInit {
  entity: any = [];
  exceedLimit: any = [];
  alertsArray: any = [];
  addalertsArray: any = [];
  disabled: boolean = false;
  coastDetails: any;
  paymentMethod: any = 'Postpaid';
  totalCartValue: any;
  lmsCost: any = 0;
  chargeableCount: any = 0.00;
  totalCount: any = 0;
  totalPrice: any = 0.00;
  infoDialogRef: MatDialogRef<InfoDialogComponent>;
  constructor(
    public service: MonitoringService,
    public matDialogRef: MatDialogRef<LmsSubscriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public loaderService: LoaderService,
    public dialog: MatDialog,
    public translateService: TranslateService,
    private formBuilder: FormBuilder) {
      console.log("data:", data);
  }

  ngOnInit() {
      this.lmsDisclaimer();
      var validatedObj = localStorage.getItem("validation");
				if (validatedObj) {
					let sessionData = JSON.parse(validatedObj);
          let paymentMethod = sessionData?.paymentScheme != 'null' ? sessionData?.paymentScheme : '';
          switch (paymentMethod) {
            case 'TRM':
              localStorage.setItem('paymentMethod', PAYMENT_METHODS.TRM);
              break;
            case 'PTS':
              localStorage.setItem('paymentMethod', PAYMENT_METHODS.PTS);
              break;
            case 'PPS':
              localStorage.setItem('paymentMethod', PAYMENT_METHODS.PPS);
              break;
          }
          this.paymentMethod = localStorage.getItem('paymentMethod');
        }
  }

  lmsDisclaimer() {
    this.loaderService.isLoading.next(true);
    this.service.getLmsSubscriptionSummary(this.data.data).subscribe((data: any) => {
      this.loaderService.isLoading.next(false);
      if(data.success) {
        this.coastDetails = data.data;
        this.chargeableCount = this.coastDetails?.chargeableCount ? this.coastDetails?.chargeableCount : 0;
        this.totalPrice = this.coastDetails?.totalPrice ? this.coastDetails?.totalPrice : 0;
        this.lmsCost = this.coastDetails?.lmsCost ? this.coastDetails?.lmsCost : 0;
        this.totalCount = this.coastDetails?.totalCount ? this.coastDetails?.totalCount : 0;
        console.log("coast:", this.coastDetails, data);
      } else 
        this.openTryAgainAlertsPopup();
    }, (err: any) => {
      this.loaderService.isLoading.next(false);
      this.openTryAgainAlertsPopup();
    });
  }

  complete() {
    this.loaderService.isLoading.next(true);
    this.service.completeProcessing(this.data.data).subscribe(
        (res: any) => {
            this.loaderService.isLoading.next(false);
            if(res.success) {
                var obj: any = { 
                    "bulkAlertUploadNumber": this.data.data,
                    "bulkOrderStatus": "Complete"
                  };
                localStorage.setItem('MonitoringbulkProcessComplete', 'reviewed');
                this.matDialogRef.close(obj)
            } else {
                this.openTryAgainCompletePopup();
            } 
        },
        (err: any) => {
            this.openTryAgainCompletePopup();
        }
    );
    
}

  hideDisclaimer() {
    this.matDialogRef.close(false);
  }

  openTryAgainCompletePopup() {
    this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
      let dialogRef = this.dialog.open(ErrorDialogComponent, {
        disableClose: true,
        width: '37vw',
        data: {
          errorMessage: value['errorMsg.pleaseTryAgain']
        }
      });
      dialogRef.afterClosed().subscribe((res: any) => {
        if (res) {
          this.complete();
        }
      });
    })
  }

  openTryAgainAlertsPopup() {
    this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
      let dialogRef = this.dialog.open(ErrorDialogComponent, {
        disableClose: true,
        width: '37vw',
        data: {
          errorMessage: value['errorMsg.pleaseTryAgain']
        }
      });
      dialogRef.afterClosed().subscribe((res: any) => {
        if (res) {
          this.lmsDisclaimer();
        }
      });
    })
  }
  
}
