import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { PageNotFoundComponent } from './layout/page-not-found/page-not-found.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { SessionExpiredComponent } from './session-expired/session-expired.component';
import { BillingStatementComponent } from './billing-statement/billing-statement.component';
import { MonitorComponent } from './monitoring/monitor/monitor.component'
import { RestoreComponent } from './monitoring/restore/restore.component';
import { BusinessMonitoringServiceComponent } from './monitoring/business-monitoring-service-alert/business-monitoring-service-alert.component';
import { PaymentMonitoringServiceComponent } from './monitoring/payment-monitoring-service-alert/payment-monitoring-service-alert.component';
import { CreditMonitoringServiceComponent } from './monitoring/credit-monitoring-service-alert/credit-monitoring-service-alert.component';
import { LitigationMonitoringServiceComponent } from './monitoring/litigation-monitoring-service-alert/litigation-monitoring-service-alert.component';
import { DailySuitServiceComponent } from './monitoring/daily-new-suits-alert/daily-new-suits-alert.component';
import { HomeAuthService } from './homeAuthGuard.service';
import { MonitorAuthService } from './monitorAuthGuard.service';
import { AuthService } from './authGuard.service';
import { GlobalMonitoringComponent } from './global-monitoring/global-monitoring.component';

import { GlobalMonitoringAuthService } from './globalMonitorAuthGuard.service';
import { GlobalMonitoringAlertComponent } from './global-monitoring/global-monitoring-alert/global-monitoring-alert.component';
const routes: Routes = [
	{ path: 'page-not-found-component', component: PageNotFoundComponent },
	{ path: 'session-expired', component: SessionExpiredComponent },
	{
		path: '', canActivate: [AuthService], children: [
			{ path: '', redirectTo: 'home', pathMatch: 'full' },
			{ path: 'home', component: HomeComponent, canActivate: [HomeAuthService] },
			{ path: 'order-history', component: OrderHistoryComponent, canActivate: [HomeAuthService] },
			{ path: 'thank-you', component: ThankYouComponent, canActivate: [HomeAuthService] },
			{ path: 'billing-statement', component: BillingStatementComponent, canActivate: [HomeAuthService] },
			{ path: 'monitor', component: MonitorComponent, canActivate: [MonitorAuthService] },
			{ path: 'global-monitor', component: GlobalMonitoringComponent, canActivate: [MonitorAuthService, GlobalMonitoringAuthService] },
			{ path: 'global-monitor/alert', component: GlobalMonitoringAlertComponent, canActivate: [MonitorAuthService, GlobalMonitoringAuthService] },
			{ path: 'monitor/deleted', component: RestoreComponent, canActivate: [MonitorAuthService] },
			{ path: 'monitor/BWS', component: BusinessMonitoringServiceComponent, canActivate: [MonitorAuthService] },
			{ path: 'monitor/NBB', component: PaymentMonitoringServiceComponent, canActivate: [MonitorAuthService] },
			{ path: 'monitor/CMS', component: CreditMonitoringServiceComponent, canActivate: [MonitorAuthService] },
			{ path: 'monitor/LMS', component: LitigationMonitoringServiceComponent, canActivate: [MonitorAuthService] },
			{ path: 'monitor/DNSA', component: DailySuitServiceComponent, canActivate: [MonitorAuthService] },
			{ path: '**', redirectTo: 'home' }
		]
	}

];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})

export class AppRoutingModule { }
