<section class="search-result">
    <article class="loader">
        <div class="section-loading-screen">
            <div>
                {{ 'monitor.loading_message' | translate }}
                <span class="section-loading-screen-bar">
                    <span></span>
                </span>
            </div>
        </div>
    </article>
</section>