<div class="dialog-content-wrapper">
    <div mat-dialog-content *ngIf="fileUploaded == 'step1'">
        <div class="close-dialog">
            <mat-icon class="close-btn" (click)="matDialogRef.close(false)">close</mat-icon>
        </div>
        <div class="cross-header">
            <span class="title-text">
                {{ 'bulk.upload' | translate }}
            </span>
        </div>  
        <div class="pt-7">
                <div style="width: 49%; display: inline-block;">
                    <ng-select name="product" [items]="productList" [(ngModel)]="categoryId" [placeholder]="'placeholder.select_product' | translate"
                        bindLabel="categoryName" 
                        bindValue="categoryId">
                    </ng-select>
                </div>
                <div style="float: right;">
                    <a *ngIf="categoryId == 1" class="link" href="assets/reports/GDNBulkUploadTemplate.xlsx" download> <mat-icon class="rotate">exit_to_app</mat-icon> <span style="margin-left: 10px;">{{ 'bulk.upload.gdnTemplate' | translate }}</span></a>
                    <a *ngIf="categoryId == 4" class="link" href="assets/reports/ROCBulkUploadTemplate.xlsx" download> <mat-icon class="rotate">exit_to_app</mat-icon> <span style="margin-left: 10px;">{{ 'bulk.upload.rocTemplate' | translate }}</span></a>
                </div>
        </div>
        <div class="p-20" appDrag (files)="filesDropped($event)">
            <div>
                <div class="upload upload-icon"><mat-icon class = "svg-icon" svgIcon="bulk-upload"></mat-icon></div>
                <div class="upload"><label> {{ 'bulk.upload.dragAndDrop' | translate }}</label></div>
                <div class="upload"><span> {{ 'bulk.upload.or' | translate }}</span></div>
                <div class="upload">
                    <input #csvInput hidden="true" type="file" multiple onclick="this.value=null" (change)="csvInputChange($event)" accept=".csv, .xlsx, .txt"/>
                    <button (click)="csvInput.click()" class="button button__primary"> {{ 'bulk.upload.browseFile' | translate }}</button>
                </div>
                <div class="upload-text pt-7">
                    <div> {{ 'bulk.upload.acceptedFiles' | translate }} </div>
                </div>       
                <div class="upload-text">
                    <div> {{ 'bulk.upload.maxFileSize' | translate }}: 5MB</div>
                </div>          
            </div>
        </div>
    </div>

    <div *ngIf="fileUploaded == 'step2'">
        <div class="cross-header">
            <span class="title-text">
                {{ 'bulk.upload.validateFile' | translate }}....
            </span>
        </div>  
        <div class="pt-7">
            <div class="grey-color">
                {{ 'bulk.upload.validate_title' | translate }}  
            </div>
            <!-- <div class="grey-color">
                {{ 'bulk.upload.validate_subtitle' | translate }} 
            </div> -->
            <div class="loading-screen pt-7">
                <div>
                    <span class="loading-screen-bar">
                        <span [ngStyle]="{'width': loaded + '%'}"></span>
                    </span>
                </div>
            </div>
            <div class="pt-7">
                <label>{{ 'bulk.upload.time_left' | translate }}: {{ time }} 
                    <span *ngIf=" 1 >= time">{{ 'bulk.upload.minute' | translate }}</span>
                    <span *ngIf=" time > 1 ">{{ 'bulk.upload.minutes' | translate }}</span>
                </label>
            </div>
        </div>
        <button (click)="cancelFileValidation()" class="button btn button__basic cancel-button">
                {{ 'searchResults.btn.cancel' | translate }}
        </button> 
    </div>
    <div mat-dialog-content *ngIf="fileUploaded == 'step3'">
        <!-- <div class="close-dialog">
            <mat-icon class="close-btn" (click)="matDialogRef.close(false)">close</mat-icon>
        </div> -->
        <div class="cross-header">
            <span class="title-text">
                {{ 'bulk.upload' | translate }}
            </span>
        </div>  
        <div>
            <span class="grey-color">
                {{ 'bulk.upload.confirm_file_correct' | translate }}
            </span>
        </div>
        <div class="pt-30">
            <label> {{ 'bulk.upload.file_selected' | translate }}</label>&nbsp;&nbsp; <a class="link" (click)="reUploadFiles();">{{ 'bulk.upload.reupload_files' | translate }}</a>
        </div>
        <div class="pt-7">
            <div style="overflow-wrap: break-word;">{{ fileName }}</div>
        </div>       
        <div>
            <div>{{validatedBulkUploadeDeta?.totalRecordCount}} {{ 'bulk.upload.rows' | translate }} ({{validatedBulkUploadeDeta?.acceptedRecordsCount}} {{ 'bulk.upload.valid' | translate }}, {{ validatedBulkUploadeDeta?.inValidRecordsCount }} {{ 'bulk.upload.invalid' | translate }}, {{validatedBulkUploadeDeta?.inCompleteRecordsCount}} {{ 'bulk.upload.incompleted' | translate }}, {{validatedBulkUploadeDeta.duplicateRecordCount}} <span *ngIf="validatedBulkUploadeDeta.duplicateRecordCount > 1">{{ 'bulk.upload.duplicates' | translate }} </span> <span *ngIf="validatedBulkUploadeDeta.duplicateRecordCount < 2">{{ 'bulk.upload.duplicat' | translate }} </span>)</div>
        </div>  

        <div class="zanna_div row pt-30">
            <div class="d_6_12 col">
                <button (click)="cancelBulkOrder()" [ngClass]="validatedBulkUploadeDeta.acceptedRecordsCount == 0 ? 'button__primary': 'button__basic'" class="button btn ml-10">
                    {{ 'searchResults.btn.cancel' | translate }}
                </button> 
                <button (click)="confirmBulkOrder()" [ngClass]="validatedBulkUploadeDeta.acceptedRecordsCount == 0 ? 'button__basic': 'button__primary'" class="button btn ml-22 ml-22" [disabled]="validatedBulkUploadeDeta.acceptedRecordsCount == 0">
                    {{ 'bulk.upload.confirm' | translate }}
                </button>
            </div>
        </div>
    </div>

    <div mat-dialog-content *ngIf="fileUploaded == 'invalid'">
        <div class="close-dialog">
            <mat-icon class="close-btn" (click)="matDialogRef.close(false)">close</mat-icon>
        </div>
        <div class="cross-header">
            <span class="title-text">
                {{ 'bulk.upload.invalid_file' | translate }}
            </span>
        </div>  
        <div>
            <span class="grey-color">
                {{ 'bulk.upload.file_incorrect' | translate }}  
                <a *ngIf="categoryId == 1" class="link" href="assets/reports/GDNBulkUploadTemplate.xlsx" download>{{ 'bulk.upload.gdnTemplate' | translate }}</a>
                <a *ngIf="categoryId == 4" class="link" href="assets/reports/ROCBulkUploadTemplate.xlsx" download>{{ 'bulk.upload.rocTemplate' | translate }}</a>
            </span>
        </div>
        <div class="pt-30">
            <label> {{ 'bulk.upload.file_selected' | translate }}</label> &nbsp;&nbsp; <a class="link" (click)="reUploadFiles();">{{ 'bulk.upload.reupload_files' | translate }}</a>
        </div>
        <div class="pt-7">
            <div style="overflow-wrap: break-word;">{{ fileName }}</div>
        </div>       

        <div class="zanna_div row pt-30">
            <div class="d_6_12 col">
                <button (click)="matDialogRef.close(false)" class="button btn button__basic ml-10">
                    {{ 'searchResults.btn.cancel' | translate }}
                </button> 
                <button (click)="reUploadFiles()" class="button btn button__primary ml-22 ml-22">
                    {{ 'bulk.upload.reupload' | translate }}
                </button> <!-- type="submit"-->
            </div>
        </div>
    </div>

    <div mat-dialog-content *ngIf="fileUploaded == 'Exceed'">
        <div class="close-dialog">
            <mat-icon class="close-btn" (click)="matDialogRef.close(false)">close</mat-icon>
        </div>
        <div class="cross-header">
            <span class="title-text">
                {{ 'bulk.upload.limit_exceed' | translate }}
            </span>
        </div>  
        <div>
            <span class="grey-color">
                {{ 'bulk.upload.limit_exceed_100' | translate }}  
            </span>
        </div>
        <div class="zanna_div row pt-30">
            <div class="d_6_12 col">
                <button (click)="matDialogRef.close(false)" class="button btn button__basic ml-10">
                    {{ 'searchResults.btn.cancel' | translate }}
                </button> 
                <button (click)="reUploadFiles()" class="button btn button__primary ml-22 ml-22">
                    {{ 'bulk.upload.reupload' | translate }}
                </button> 
            </div>
        </div>
        <div class="pt-30">
            <label> {{ 'bulk.upload.file_selected' | translate }}</label> &nbsp;&nbsp; 
        </div>
        <div class="pt-7">
            <div style="overflow-wrap: break-word;">{{ fileName }}</div>
        </div>       
        <!-- <div>
            <div>{{validatedBulkUploadeDeta.totalRecordCount}} {{ 'bulk.upload.rows' | translate }} ({{validatedBulkUploadeDeta?.acceptedRecordsCount}} {{ 'bulk.upload.valid' | translate }}, {{ validatedBulkUploadeDeta?.inValidRecordsCount }} {{ 'bulk.upload.invalid' | translate }}, {{validatedBulkUploadeDeta?.inCompleteRecordsCount}} {{ 'bulk.upload.incompleted' | translate }}, {{validatedBulkUploadeDeta.duplicateRecordCount}} <span *ngIf="validatedBulkUploadeDeta.duplicateRecordCount > 1">{{ 'bulk.upload.duplicates' | translate }} </span> <span *ngIf="validatedBulkUploadeDeta.duplicateRecordCount < 2">{{ 'bulk.upload.duplicat' | translate }} </span>)</div>
        </div>  
        <div class="zanna_div pt-30">
            {{ 'bulk.upload.alternative' | translate }},&nbsp;&nbsp;  <a style="color: #426DA9;text-decoration: underline;">{{ 'monitor.countryPicAlert.smeNetworkScoreWatchMessage' | translate }}</a> 
        </div>    -->
    </div>

    <div *ngIf="fileUploaded == 'step4'">
        <div class="cross-header">
            <span class="title-text">
                {{ 'bulk.upload.finding_reports' | translate }}...
            </span>
        </div>  
        <div class="pt-7">
            <div class="grey-color">
                {{ 'bulk.upload.summury' | translate }} 
            </div>
            <div class="grey-color">
                {{ 'bulk.upload.running_background' | translate }}
            </div>
            <div class="loading-screen pt-20">
                <div>
                    <span class="loading-screen-bar">
                        <span [ngStyle]="{'width': loader + '%'}"></span>
                    </span>
                </div>
            </div>
            <div class="pt-7">
                <div><label>{{ 'bulk.upload.finding' | translate }} {{ bulkUploadStatus?.EntityName }} ({{ bulkUploadStatus?.CurrentCount }} of {{ bulkUploadStatus?.TotalCount }} )</label></div>
                <div>
                    <label>
                        {{ 'bulk.upload.time_left' | translate }}: {{ minutes}} 
                        <span *ngIf=" 1 >= minutes">{{ 'bulk.upload.minute' | translate }}</span>
                        <span *ngIf=" minutes > 1 ">{{ 'bulk.upload.minutes' | translate }}</span>
                    </label>
                </div>
            </div>
        </div>
        <div class="zanna_div row pt-30">
            <div class="d_12_12 col">
                <button (click)="cancelProcess()" class="button button__basic ml-10">
                    {{ 'bulk.upload.cancel_processing' | translate }}
                </button> 
                <button (click)="runInBackground()" class="button button__primary ml-22 ml-22">
                    {{ 'bulk.upload.run_background' | translate }}
                </button> <!-- type="submit"-->
            </div>
        </div>
    </div>
</div>