<p class="rows-display desktop-view">{{totalRows}} {{ 'pagination.rows' | translate }}</p>
<div class="page-container table-pagination eds-pagination" id="pagination" *ngIf="PaginationPages">
	<div class="eds-tab tab-compact" (click)="goToPage(0)">
		<a href="javascript:void(0)" data-first=""
			[ngClass]="currentPage === 0 ? 'tab-content eds-link disableArrow' : 'tab-content eds-link'">
			<i class="tab-icon material-icons">first_page</i>
		</a>
	</div>

	<div class="eds-tab tab-compact">
		<a (click)="goToPrevious()" data-previous=""
			[ngClass]="currentPage === 0 ? 'tab-content eds-link disableArrow' : 'tab-content eds-link'">
			<i class="tab-icon material-icons">chevron_left</i>
		</a>
	</div>
	<div *ngIf="!isMobile">
		<span *ngFor="let page of PaginationPages?.pages;let index=index;">
			<div [ngClass]="currentPage === page-1 ? 'eds-tab tab-compact tab-selected' : 'eds-tab tab-compact'">
				<a data-page="0" (click)="goToPage(page-1)" class="tab-content eds-link  link-selected">
					{{page}}
				</a>
			</div>
		</span>
	</div>
	<div *ngIf="isMobile">
		<span>
			<div class="eds-tab tab-compact tab-selected">
				<a data-page="0" (click)="goToNext()" class="tab-content eds-link  link-selected">
					{{currentPage+1}}
				</a>
			</div>
		</span>
	</div>

	<div class="eds-tab tab-compact" *ngIf="!isMobile && (PaginationPages?.totalPages - PaginationPages?.startPage) > 5">
		<a data-page="0" class="tab-content eds-link" (click)="goToPage(PaginationPages?.startPage + 5)">
			...
		</a>
	</div>

	<div class="eds-tab tab-compact" *ngIf="!isMobile && (PaginationPages?.totalPages - PaginationPages?.startPage) > 5">
		<a data-page="0" class="tab-content eds-link" (click)="goToPage(PaginationPages?.totalPages-1)">
			{{PaginationPages?.totalPages}}
		</a>
	</div>

	<div class="eds-tab tab-compact" id="afterPages">
		<a (click)="goToNext()" data-next=""
			[ngClass]="currentPage === PaginationPages?.totalPages-1 ? 'tab-content eds-link disableArrow' : 'tab-content eds-link'">
			<i class="tab-icon material-icons">chevron_right</i>
		</a>
	</div>

	<div class="eds-tab tab-compact">
		<a (click)="goToPage(PaginationPages?.totalPages-1)" data-last=""
			[ngClass]="currentPage === PaginationPages?.totalPages-1 ? 'tab-content eds-link disableArrow' : 'tab-content eds-link'">
			<i class="tab-icon material-icons">last_page</i>
		</a>
	</div>

	<div class="pagesize-label desktop-view">{{ 'pagination.rowsPerPage' | translate }}</div>
	
	<div class="pagesize-select eds-input">
		<select class="input-element input-dropdown" id="pageSizeSelect" [(ngModel)]="selected" (change)="changeRowPage($event)">
			<option value="10" selected>10</option>
			<option value="25">25</option>
			<option value="50">50</option>
			<option value="100">100</option>
		</select>
	</div>

	<div class="range" id="range"></div>
</div>
