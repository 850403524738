<article>
    <div class="zanna_div row ymal mt-60">
        <h1 class="M(0) Lh(1) eds-heading heading-xl-caps">
            {{ 'home.keyProductHighlights' | translate }}
        </h1>
        <p class="p-subTitle">{{ 'keyProductHighlights.subInfo' | translate }}
            <button type="button" (click)="userManualReport()" class="button button__primary userManual-btn">
                <mat-icon class="material-icon">help</mat-icon>
                {{'home.userManual' | translate}}
            </button>
        </p>
    </div>
    <ng-template #tipContent class="display-hide">
        <div class="custom-multi-tooltip">
            <div>
                <div><label>
                        <p class="mt-22">{{'home.companyProfile.note' | translate }}</p>
                    </label></div>
            </div>
            <mat-icon class="tooltip-arrow-custom">arrow_drop_down</mat-icon>
        </div>
    </ng-template>
    <div class="zanna_div row display-hide">
        <div class="d_12_12 col" style="padding-left: 0px;">
            <div class="zanna_div table-head gradient"></div>
            <div class="zanna_div ymal-table row">
                <div class="d_3_12 col">
                    <strong>{{ 'home.companyProfile' | translate }} <mat-icon [ngbTooltip]="tipContent"
                            class="info-icon">info</mat-icon></strong>
                    <p class="mt-22">{{ 'home.companyProfile.desc' | translate }}
                    </p>
                    <a class="get-info" (click)="sampleReport('AFGROC')">
                        {{ 'home.getInfo' | translate }}
                        <!-- <div class="info-message">
                            {{ 'home.companyProfile.desc' | translate }}
                        </div> -->
                    </a>
                </div>
                <div class="d_3_12 col">
                    <strong>{{ 'home.riskCheck' | translate }} <mat-icon [ngbTooltip]="tipContent"
                            class="info-icon">info</mat-icon></strong>
                    <p class="mt-22">{{ 'home.riskCheck.desc' | translate }}</p>
                    <a class="get-info" (click)="sampleReport('SGPRSK')">
                        {{ 'home.getInfo' | translate }}
                        <!-- <div class="info-message">
                            {{ 'home.riskCheck.desc' | translate }}
                        </div> -->
                    </a>
                </div>
                <div class="d_3_12 col">
                    <strong>{{ 'home.standardReport' | translate }} <mat-icon [ngbTooltip]="tipContent"
                            class="info-icon">info</mat-icon></strong>
                    <p class="mt-22">{{ 'home.standardReport.desc' | translate }}</p>
                    <a class="get-info" (click)="sampleReport('SGPSML')">
                        {{ 'home.getInfo' | translate }}
                        <!-- <div class="info-message">
                            {{ 'home.standardReport.desc' | translate }}
                        </div> -->
                    </a>
                </div>
                <div class="d_3_12 col">
                    <strong>{{ 'home.premiumReport' | translate }} <mat-icon [ngbTooltip]="tipContent"
                            class="info-icon">info</mat-icon></strong>
                    <p class="mt-22">{{ 'home.premiumReport.desc' | translate }}</p>
                    <a class="get-info" (click)="sampleReport('SGPEXT')">
                        {{ 'home.getInfo' | translate }}
                        <!-- <div class="info-message">
                            {{ 'home.premiumReport.desc' | translate }}
                        </div> -->
                    </a>
                </div>
            </div>
            <div class="zanna_div table-head gradient"></div>
            <div class="zanna_div ymal-table row">
                <div class="d_3_12 col">
                    <strong>{{ 'home.ubo' | translate }} <mat-icon [ngbTooltip]="tipContent"
                            class="info-icon">info</mat-icon></strong>
                    <p class="mt-22">{{ 'home.ubo.desc' | translate }}
                    </p>
                    <a class="get-info" (click)="sampleReport('GUBO10')">
                        {{ 'home.getInfo' | translate }}
                        <!-- <div class="info-message">
                            {{ 'home.companyProfile.desc' | translate }}
                        </div> -->
                    </a>
                </div>
                <div class="d_3_12 col">
                    <strong>{{ 'home.globalMonitoring' | translate }} <mat-icon [ngbTooltip]="tipContent"
                            class="info-icon">info</mat-icon></strong>
                    <p class="mt-22">{{ 'home.globalMonitoring.desc' | translate }}</p>
                    <a class="get-info" (click)="sampleReport('GMORPT')">
                        {{ 'home.getInfo' | translate }}
                        <!-- <div class="info-message">
                            {{ 'home.riskCheck.desc' | translate }}
                        </div> -->
                    </a>
                </div>
                <div class="d_3_12 col">
                    <strong>{{ 'home.globalCreditReport' | translate }} <mat-icon [ngbTooltip]="tipContent"
                            class="info-icon">info</mat-icon></strong>
                    <p class="mt-22">{{ 'home.globalCreditReport.desc' | translate }}</p>
                    <a class="get-info" (click)="sampleReport('ATGGCRSF')">
                        {{ 'home.getInfo' | translate }}
                        <!-- <div class="info-message">
                            {{ 'home.standardReport.desc' | translate }}
                        </div> -->
                    </a>
                </div>
            </div>
        </div>
    </div>
    <!-- For Mobile View -->
    <div class="zanna_div row mobile-view">
        <div class="d_12_12 col" style="padding-left: 0px;">
            <carousel [cellWidth]="cellWidth" [height]="200" [loop]="true" [arrows]="false" [transitionDuration]="500"
                [autoplay]="true">
                <div class="carousel-cell">
                    <div class="zanna_div table-head gradient"></div>
                    <div class="card-details">
                        <div class="heading">
                            <strong>{{ 'home.companyProfile' | translate }}</strong>
                        </div>
                        <div class="description">
                            <p>{{ 'home.companyProfile.desc' | translate }}</p>
                        </div>
                        <div class="btn-links">
                            <div class="get-info-link">
                                <a id="blob-src-test-mobile" (click)="sampleReportForMoile('AFGROC')" download>{{
                                    'home.getInfo' | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="carousel-cell">
                    <div class="zanna_div table-head gradient"></div>
                    <div class="card-details">
                        <div class="heading">
                            <strong>{{ 'home.riskCheck' | translate }}</strong>
                        </div>
                        <div class="description">
                            <p>{{ 'home.riskCheck.desc' | translate }}</p>
                        </div>
                        <div class="btn-links">
                            <div class="get-info-link">
                                <a id="blob-src-test-mobile" (click)="sampleReportForMoile('SGPRSK')" download>{{
                                    'home.getInfo' | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-cell">
                    <div class="zanna_div table-head gradient"></div>
                    <div class="card-details">
                        <div class="heading">
                            <strong>{{ 'home.standardReport' | translate }}</strong>
                        </div>
                        <div class="description">
                            <p>{{ 'home.standardReport.desc' | translate }}</p>
                        </div>
                        <div class="btn-links">
                            <div class="get-info-link">
                                <a id="blob-src-test-mobile" (click)="sampleReportForMoile('SGPSML')" download>{{
                                    'home.getInfo' | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-cell">
                    <div class="zanna_div table-head gradient"></div>
                    <div class="card-details">
                        <div class="heading">
                            <strong>{{ 'home.premiumReport' | translate }}</strong>
                        </div>
                        <div class="description">
                            <p>{{ 'home.premiumReport.desc' | translate }}</p>
                        </div>
                        <div class="btn-links">
                            <div class="get-info-link">
                                <a id="blob-src-test-mobile" (click)="sampleReportForMoile('SGPEXT')" download>{{
                                    'home.getInfo' | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="carousel-cell">
                    <div class="zanna_div table-head gradient"></div>
                    <div class="card-details">
                        <div class="heading">
                            <strong>{{ 'home.ubo' | translate }}</strong>
                        </div>
                        <div class="description">
                            <p>{{ 'home.ubo.desc' | translate }}</p>
                        </div>
                        <div class="btn-links">
                            <div class="get-info-link">
                                <a id="blob-src-test-mobile" (click)="sampleReportForMoile('GUBO10')" download>{{
                                    'home.getInfo' | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-cell">
                    <div class="zanna_div table-head gradient"></div>
                    <div class="card-details">
                        <div class="heading">
                            <strong>{{ 'home.globalMonitoring' | translate }}</strong>
                        </div>
                        <div class="description">
                            <p>{{ 'home.globalMonitoring.desc' | translate }}</p>
                        </div>
                        <div class="btn-links">
                            <div class="get-info-link">
                                <a id="blob-src-test-mobile" (click)="sampleReportForMoile('GMORPT')" download>{{
                                    'home.getInfo' | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-cell">
                    <div class="zanna_div table-head gradient"></div>
                    <div class="card-details">
                        <div class="heading">
                            <strong>{{ 'home.globalCreditReport' | translate }}</strong>
                        </div>
                        <div class="description">
                            <p>{{ 'home.globalCreditReport.desc' | translate }}</p>
                        </div>
                        <div class="btn-links">
                            <div class="get-info-link">
                                <a id="blob-src-test-mobile" (click)="sampleReportForMoile('ATGGCRSF')" download>{{
                                    'home.getInfo' | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </carousel>
        </div>
    </div>
</article>