<div id="error-translation">
    <h1 matDialogTitle class="info-title">
        <mat-icon class="icon-info" >
            info
        </mat-icon>
        <span >{{ 'errorDialog.title' | translate }}</span>
    </h1>
    <div matDialogContent class="content-msg" *ngIf="data.errorMessage">
        {{ data.errorMessage.errorMsg }}
    </div>
    <div matDialogContent class="content-msg" *ngIf="!data.errorMessage">
        {{ confirmMessage }}
    </div>
    <div mat-dialog-actions class="pt-24" style="justify-content: center;">
        <button (click)="dialogRef.close(false)" class="button button__basic">OK</button>
    </div>
</div>