import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-downtime',
  templateUrl: './downtime.component.html',
  styleUrls: ['./downtime.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DownTimeComponent implements OnInit {
  mySet = new Set();
  constructor(public matDialogRef: MatDialogRef<DownTimeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
     data.map((entity: any, index: number) => {
      this.mySet.add(entity.data.countryName);
     });
  }

  ngOnInit() {
   
  }

}
