<div class="overflow-y">
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-12 left-section" style="white-space: break-spaces;">
            <b>{{'orderHistory.orderItemId' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-12 right-section" style="white-space: break-spaces;">
           {{cellData.itemId}}
        </div>
    </div>
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-12 left-section" style="white-space: break-spaces;">
            <b>{{'orderHistory.status' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-12 right-section" style="white-space: break-spaces;">
           {{cellData.itemStatus}}
        </div>
    </div>
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-12 left-section" style="white-space: break-spaces;">
            <b>{{'orderHistory.identification' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-12 right-section" style="white-space: break-spaces;">
           {{cellData.entityName}}
        </div>
    </div>
    
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-12 left-section" style="white-space: break-spaces;">
            <b>{{'orderHistory.product' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-12 right-section" style="white-space: break-spaces;">
           {{cellData.productDescription}}
        </div>
    </div>
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-12 left-section" style="white-space: break-spaces;">
            <b>{{'orderHistory.country' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-12 right-section" style="white-space: break-spaces;">
           {{cellData.countryName}}
        </div>
    </div>
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-12 left-section" style="white-space: break-spaces;">
            <b>{{'orderHistory.turnAroundTime' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-12 right-section" style="white-space: break-spaces;">
           {{cellData.speedDescription}}
        </div>
    </div>
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-12 left-section" style="white-space: break-spaces;">
            <b>{{'orderHistory.availableTill' | translate}} </b>
        </div>
        <div fxFlex="50" class="right p-t-12 right-section" style="white-space: break-spaces;">
            {{cellData.availableTill | date}}
        </div>
    </div>
</div>
