<section class="lightbox-wrap search-result"  [hidden]="!loading" style="z-index: 9999;">
    <article class="loader">
        <div class="section-loading-screen">
            <div>
                Loading...
                <span class="section-loading-screen-bar">
                    <span></span>
                </span>
            </div>
        </div>
    </article>
</section>