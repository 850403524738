import { Component, HostListener, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'add-manually-dialog-box',
    templateUrl: './add-manually.component.html',
    styleUrls: ['add-manually.component.scss']
})

export class AddManuallyDialogComponent {
    isMobile: boolean = false;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if(event.target.innerWidth > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
	}
    public confirmMessage: any = '';
    paramData: any;
    searchFormCountry!: FormGroup;
    
    constructor(public dialogRef: MatDialogRef<AddManuallyDialogComponent>,
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {
        
		if(window.screen.width > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
        this.paramData = data;
        this.searchFormCountry = this.formBuilder.group({
			countryCode: ['Singapore', Validators.required],
			entity_name: ['', Validators.maxLength(100)],
			entity_no: ['', [Validators.required, Validators.maxLength(15)]],
			file: ['',Validators.maxLength(40)] 
		});
        this.searchFormCountry.controls['countryCode'].disable();
    }

    validateId() {
        this.dialogRef.close(this.searchFormCountry.value);
    }

    close() {
        this.dialogRef.close();
    }
}