<ng-template #deleteUpdatePopup>
    <ul>
        <li class="update-delete-popover" (click)="onUpdate($event)"
            [ngClass]="(params.data.entityType == 'C' || params.data.entityType == 'I' ) ? '' : 'disable-option'">
            <mat-icon class="download-icon">
                create
            </mat-icon>
            <a class="alignment">{{ 'gridActions.updateEntity' | translate }}</a>
        </li>
        <li class="update-delete-popover" (click)="onDelete($event)"
            [ngClass]="(params.data.entityType == 'C' || params.data.entityType == 'I' ) ? '' : 'disable-option'">
            <mat-icon class="download-icon">
                delete_forever
            </mat-icon>
            <a class="alignment">{{ 'gridActions.deleteEntity' | translate }}</a>
        </li>
    </ul>
</ng-template>

<div style="display: flex;" *ngIf="params.label == 'monitor-records'">
    <div style="margin-right: 5px;" *ngIf="isMobile">
        <mat-icon class="download-icon" (click)="expand($event)"
            *ngIf="params.data.reportType != 'a_report' && hide">remove_red_eye</mat-icon>
        <mat-icon style="color: white" class="download-icon" *ngIf="params.data.reportType != 'a_report' && !hide">
        </mat-icon>
        <mat-icon style="color: white" *ngIf="params.data.reportType != '!a_report' && hide">
        </mat-icon>
    </div>
    <div style="margin-right: 5px;" *ngIf="isMobile">
        <mat-icon svgIcon="icon-download" class="download-icon" *ngIf="params.data.reportType == 'a_report' && hide"
            (click)="downloadReport('pdf')"></mat-icon>
        <mat-icon svgIcon="icon-download" class="download-icon" *ngIf="params.data.reportType == 'b_delta' && hide"
            (click)="downloadReport('pdf')"></mat-icon>
        <mat-icon class="disabled-download-icon" *ngIf="params.data.isAvailable == 'N' || !hide">
            get_app
        </mat-icon>
    </div>
    <div style="margin-right: 30px;" (click)="expandData()" *ngIf="isMobile">
        <mat-icon class="download-icon" *ngIf="!params.data.expanded">keyboard_arrow_down</mat-icon>
        <mat-icon class="download-icon" *ngIf="params.data.expanded">keyboard_arrow_up</mat-icon>
    </div>
    <div class="Row">
        <div class="Column">
            <!-- <mat-icon class="download-icon" (click)="expand($event)"
                *ngIf="params.data.reportType != 'a_report' && hide">remove_red_eye</mat-icon>
            <mat-icon style="color: white" class="download-icon" *ngIf="params.data.reportType != 'a_report' && !hide">
            </mat-icon> -->
        </div>
        <div class="Column">
            <mat-icon svgIcon="icon-download" class="download-icon" *ngIf="params.data.reportType == 'a_report' && hide"
                (click)="downloadReport('pdf')"></mat-icon>
            <mat-icon svgIcon="icon-download" class="download-icon" *ngIf="params.data.reportType == 'b_delta' && hide"
                (click)="downloadReport('pdf')"></mat-icon>
            <mat-icon class="disabled-download-icon" *ngIf="params.data.isAvailable == 'N' || !hide">
                get_app
            </mat-icon>
        </div>
        <div class="Column" *ngIf="!isMobile"><mat-icon svgIcon="icon-dots" class="download-icon" container="body"
                [ngbPopover]="globalMonitoringPopup" placement="bottom-right"></mat-icon></div>
    </div>
</div>

<!-- <div style="display: flex;" *ngIf="params.label == 'monitor-all-records'">
    <div class="desktop-view">
        <img class="icon-dots" src="/assets/img/icon-dots.svg" container="body" [ngbPopover]="deleteUpdatePopup"
            placement="bottom-right">
    </div>
    <div style="margin-right: 12px;" (click)="expand($event)" class="align-icon" *ngIf="isMobile">
        <mat-icon class="expand-icon" *ngIf="!params.data.expanded">keyboard_arrow_down</mat-icon>
        <mat-icon class="expand-icon" *ngIf="params.data.expanded">keyboard_arrow_up</mat-icon>
    </div>
</div>

<div *ngIf="isMobile && params.label == 'bws-alert-action'">
    <div (click)="expand($event)" class="align-icon">
        <mat-icon class="expand-icon" *ngIf="!params.data.expanded">keyboard_arrow_down</mat-icon>
        <mat-icon class="expand-icon" *ngIf="params.data.expanded">keyboard_arrow_up</mat-icon>
    </div>
</div>
<div *ngIf="isMobile && params.label == 'bws-gaz-action'">
    <div (click)="expand($event)" class="align-icon">
        <mat-icon class="expand-icon" *ngIf="!params.data.expanded">keyboard_arrow_down</mat-icon>
        <mat-icon class="expand-icon" *ngIf="params.data.expanded">keyboard_arrow_up</mat-icon>
    </div>
</div>
<div *ngIf="isMobile && params.label == 'cms-alert-action'">
    <div (click)="expand($event)" class="align-icon">
        <mat-icon class="expand-icon" *ngIf="!params.data.expanded">keyboard_arrow_down</mat-icon>
        <mat-icon class="expand-icon" *ngIf="params.data.expanded">keyboard_arrow_up</mat-icon>
    </div>
</div>

<div *ngIf="isMobile && params.label == 'lms-alert-action'">
    <div (click)="expand($event)" class="align-icon">
        <mat-icon class="expand-icon" *ngIf="!params.data.expanded">keyboard_arrow_down</mat-icon>
        <mat-icon class="expand-icon" *ngIf="params.data.expanded">keyboard_arrow_up</mat-icon>
    </div>
</div>
<div *ngIf="isMobile && params.label == 'daily-new-suits-alert-action'">
    <div (click)="expand($event)" class="align-icon">
        <mat-icon class="expand-icon" *ngIf="!params.data.expanded">keyboard_arrow_down</mat-icon>
        <mat-icon class="expand-icon" *ngIf="params.data.expanded">keyboard_arrow_up</mat-icon>
    </div>
</div> -->

<ng-template #globalMonitoringPopup>
    <ul>
        <li class="popover-list" *ngIf="checkValid(params.data) && params.data.itemStatus != 'Cancelled'"
            (click)="requestToJp()">
            <a>{{ 'gridActions.requestJPTranslation' | translate }}</a>
        </li>
        <span *ngIf="params.data.reportType === 'a_report'">
            <li [ngClass]="(params.data.reportType !== 'b_delta' ) ? 'popover-list' : 'popover-list disable-option'"
                (click)="downloadReport('json')">
                <a>{{ 'gridActions.collectJSON' | translate }}</a>
            </li>
            <li [ngClass]="(params.data.reportType === 'a_report'|| params.data.reportType !== 'b_delta') ? 'popover-list' : 'popover-list disable-option'"
                (click)="downloadReport('pdf')">
                <a>{{ 'gridActions.collectPDF' | translate }}</a>
            </li>
        </span>
        <span *ngIf="params.data.reportType === 'b_delta'">
            <li [ngClass]="(params.data.reportType === 'b_delta') ? 'popover-list' : 'popover-list disable-option'"
                (click)="downloadReport('json')">
                <a>{{ 'gridActions.collectJSONupdatedReport' | translate }}</a>
            </li>
            <!-- <li [ngClass]="(params.data.reportType !== 'b_delta') ? 'popover-list' : 'popover-list disable-option'"
                (click)="downloadReport('json')">
                <a>{{ 'gridActions.collectJSONdelta' | translate }}</a>
            </li> -->
            <li [ngClass]="(params.data.reportType === 'b_delta' && hide ) ? 'popover-list' : 'popover-list disable-option'"
                (click)="downloadReport('pdf')">
                <a>{{ 'gridActions.collectPDFupdatedReport' | translate }}</a>
            </li>
            <!-- <li [ngClass]="(params.data.reportType === 'b_delta' && hide ) ? 'popover-list' : 'popover-list disable-option'"
                (click)="downloadDeltaReport('delta_pdf')">
                <a>{{ 'gridActions.collectPDFdelta' | translate }}</a>
            </li> -->
        </span>
    </ul>
</ng-template>