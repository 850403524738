import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'app-cart-data',
  templateUrl: './cart-data.component.html',
  styleUrls: ['./cart-data.component.scss']
})
export class CartDataCellRenderer implements ICellRendererAngularComp {
  public cellData: any;
  public currency: any;
  agInit(params: any): void {
    this.cellData = params.data;
    params.columnApi.columnModel.columnDefs.find((obj: any) => {
      if (obj.ColId == 'price_col') {
        this.currency = localStorage.getItem('currency');
      }
    });
  }

  refresh(params: any): boolean {
    return false;
  }
}