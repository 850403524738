import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'confirm-delete-dialog-box',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['confirm-dialog.component.scss']
})

export class ConfimDeleteDialogComponent {
    public confirmMessage: any = '';

    constructor(public dialogRef: MatDialogRef<ConfimDeleteDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    }
}