<!-- <div *ngIf="title == 'Error'">
    <div class="errorMessage">
      <ul>
     <li class="icons"><span class="material-icons">
           block
          </span></li>
     <li>
       <p class="MainText">{{ title }}</p>
       <p class="ErrorText">{{ message }} </p>
     </li>
     <li class="close"><span class="material-icons">clear
      </span>
      </ul>
    </div> 
  </div> -->
   
  <div>
    <div class="sucessMessage">
      <ul>
     <li class="icons"><span class="material-icons highlight_off" style="font-size: 19px !important; margin-bottom: 20px !important;">
        highlight_off
    </span></li>
    <li>
       <p class="MainText">{{ title }}</p>
       <p class="ErrorText">{{ message }} <a (click)="tryToSubmit()">Try again</a></p>
    </li>
    <li class="close" style="margin-bottom: 11px !important;"><span class="material-icons" style="font-size: 19px !important;">clear
      </span>
      </ul>
    </div>  
  </div>