import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { AgFilterComponent, IFilterAngularComp } from '@ag-grid-community/angular';
import { IDoesFilterPassParams, IFilterParams, RowNode } from "@ag-grid-community/core";

@Component({
    selector: 'custom-filter',
    templateUrl: './custom-filter.component.html',
    styleUrls: ['./custom-filter.component.scss']
})

export class CustomFilterComponent implements AgFilterComponent  {

    public params: IFilterParams;
    public valueGetter: (rowNode: RowNode) => any;
    public text: string = '';

    @ViewChild('input', { read: ViewContainerRef }) public input: any;

    agInit(params: IFilterParams): void {
        this.params = params;
        this.valueGetter = params.valueGetter;
    }

    isFilterActive(): boolean {
        return this.text !== null && this.text !== undefined && this.text !== '';
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean {
        return this.text.toLowerCase()
            .split(" ")
            .every((filterWord) => {
                return this.valueGetter(params.node).toString().toLowerCase().indexOf(filterWord) >= 0;
            });
    }

    getModel(): any {
        return { value: this.text };
    }

    setModel(model: any): void {
        this.text = model ? model.value : '';
    }

    onChange(newValue: any): void {
        if (this.text !== newValue) {
            this.text = newValue;
            this.params.filterChangedCallback();
        }
    }
}