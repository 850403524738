import { Component, HostListener, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CartActionBottomSheet } from 'src/app/action-bottom-sheet/cart-action-bottom-sheet.component';

@Component({
	selector: 'app-expand-global-grid-cell-renderer',
	templateUrl: './expand-global-grid-cell-renderer.component.html',
	styleUrls: ['./expand-global-grid-cell-renderer.component.scss']
})
export class ExpandGlobalGridCellRendererComponent implements ICellRendererAngularComp {

	isMobile: boolean = false;
	isBWSGazzExpand: boolean = false;
	hide: boolean = false;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if (event.target.innerWidth > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
	}
	public params: any;
	option: string | undefined;
	showTranslation: boolean = false;

	constructor(
		private _bottomSheet: MatBottomSheet,
		public iconRegistry: MatIconRegistry,
		public sanitizer: DomSanitizer
	) {
		if (window.screen.width > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
		iconRegistry
			.addSvgIcon('payment', sanitizer.bypassSecurityTrustResourceUrl('assets/img/payment.svg'))
			.addSvgIcon('shopping', sanitizer.bypassSecurityTrustResourceUrl('assets/img/shopping.svg'))
			.addSvgIcon('payment-green', sanitizer.bypassSecurityTrustResourceUrl('assets/img/payments-green.svg'))
			.addSvgIcon('payment-grey', sanitizer.bypassSecurityTrustResourceUrl('assets/img/payments-grey.svg'))
			.addSvgIcon('icon-download', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icon-download.svg'))
			.addSvgIcon('icon-dots', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icon-dots.svg'));
	}

	openBottomSheet(data: any): void {
		this.params.template = data;
		const bottomSheetRef = this._bottomSheet.open(CartActionBottomSheet, {
			panelClass: '100%',
			data: this.params,
		});
		bottomSheetRef.afterDismissed().subscribe((result: any) => {
			if (result == "deleted") {
				this.onDelete("event");
			} else if (result == "jp_translation") {
				this.requestToJp()
			} else if (result == "download" || result == "json" || result == 'pdf') {
				this.downloadReport(result)
			}
			else if (result == 'delta_pdf') {
				result = 'pdf'
				this.downloadDeltaReport(result)
			}
			else if (result == "updated") {
				this.onUpdate("event");
			}
		});
	}

	agInit(params: any): void {
		this.params = params;
		let alertTriggers = this.params.data.alertTriggers;
		this.hide = !alertTriggers.includes('No New Updates / Changes');
		if (!this.params.data.parentCartSeqNo) {
			this.showTranslation = this.params.api.getModel().rowsToDisplay.some((obj: any) => (obj.data.parentCartSeqNo == this.params.data.cartSeqNo));
		}
	}

	refresh(): boolean {
		return false;
	}

	checkValid(data: any) {
		return data.translation;
	}

	validJSONCopy(data: any) {
		var text = (data.productDescription).split(" ").splice(-1);
		if (text == 'Translation') {
			return false;
		} else {
			return true;
		}
	}

	onDelete(ev: any) {
		this.params['label'] = 'delete';
		this.params.onClick(this.params);
	}

	expand(ev: any) {
		this.params['label'] = 'expand';
		this.params.onClick(this.params);
	}
	expandData() {
		this.params['label'] = 'expand-data';
		this.params.onClick(this.params);
	}

	onUpdate(ev: any) {
		this.params['label'] = 'update';
		this.params.onClick(this.params);
	}

	requestToJp() {
		this.params['label'] = 'jp_translation';
		this.params.onClick(this.params);
	}

	downloadReport(event: any) {
		this.params['actionEvent'] = event;
		this.params.onClick(this.params);
	}

	downloadDeltaReport(event: any) {
		this.params['actionEvent'] = event;
		this.params.onClick(this.params);
	}

	viewReport(event: any) {
		this.params['actionEvent'] = event;
		this.params.onClick(this.params);
	}

	downloadReportForMobile(event: any) {
		this.params['actionEvent'] = event;
		this.params.onClick(this.params);
	}

}
