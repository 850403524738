import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'app-monitor-result-grid-data',
  templateUrl: './monitor-result-grid-data.component.html',
  styleUrls: ['./monitor-result-grid-data.component.scss']
})
export class MonitorResultDataCellRenderer implements ICellRendererAngularComp {
  public cellData: any;
  agInit(params: any): void {
    this.cellData = params.data;
    this.cellData.address = "BLK "+ params.data.blkHse+ " "+ params.data.strNam + " # "+params.data.flr + "-"+ params.data.unt+ " "+ params.data.bld + " " + params.data.posCod;
  }

  refresh(params: any): boolean {
    return false;
  }
}