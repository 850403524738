import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'error-dialog-box',
    templateUrl: './error-dialog.component.html',
    styleUrls: ['error-dialog.component.scss']
})

export class ErrorDialogComponent {
    tryAgain: boolean = true;
    isOk: boolean = false;
    constructor(public dialogRef: MatDialogRef<ErrorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {

        if (this.data.tryAgain == false) {
            this.tryAgain = data.tryAgain;
        }
        if (this.data.balanceInfo) {
            this.tryAgain = false;
        }

        if(this.data.isOk){
            this.isOk = this.data.isOk;
        }
    }

    formatNumber(num : any) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
}