import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'headerName'
})
export class HeaderNamePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value === 'AlertDate') {
      value = value.replace('AlertDate', 'Report Delivery Date');
    }
    if (value === 'AlertType') {
      value = value.replace('AlertType', 'Alert Type');
    }
    if (value === 'Country') {
      value = value.replace('Country', 'Country');
    }
    if (value === 'EntityName') {
      value = value.replace('EntityName', 'Entity Name');
    }
    if (value === 'Item_Id') {
      value = value.replace('Item_Id', 'Order Item ID');
    }
    return value;
  }

}
