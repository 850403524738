import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from "../shared-module/toast/toast.service";
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MonitoringService } from '../monitoring.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ErrorDialogComponent } from 'src/app/error-dialog-box/error-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx';
import { GridOptions, GridApi } from 'ag-grid-community';
import { MyNoRowsComponent } from '../shared-module/search-result/no-overlay.component';

@Component({
	selector: 'app-payment-alert',
	templateUrl: './payment-monitoring-service-alert.component.html',
	styleUrls: ['./payment-monitoring-service-alert.component.scss']
})
export class PaymentMonitoringServiceComponent implements OnInit {
	isMobile: boolean = false;
	isExpandList: boolean = true;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if(event.target.innerWidth > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
	}
	title: any = "Monitoring";
	paymentDetails: any;
	entitlement: any;
	corporate: any = [];
	individual: any = [];
	columnDefs: any = [];
	columnDefsIndividual: any = [];
	individualColumnDefs: any = [];
	domLayout: any = "autoHeight";
	empty: any = [];
	entityId: any;
	startDate: any;
	endDate: any;
	count: any = 0;
	exportNBB: any;
	exportNBL: any;
	category: any = "ALL";
	greatorDaysGretorAmount: any = '> 90 days with total outstanding amount > S$1,000';
	gretorDaysLessEqualAmount: any = '> 90 days with total outstanding amount ≤ S$1,000';
	LessEqualDaysGretorAmount: any = '≤ 90 days with total outstanding amount > S$1,000';
	LessEqualDaysLessEqualAmount: any = '≤ 90 days with total outstanding amount ≤ S$1,000';
	NegativeBlackList: any = "New Records Found in Negative / Blacklist";
	expandCollapseButtonValue: String = "Collapse all rows"
	gridOptions: any;

	constructor(
		public _notificationservice: NotificationService,
		public router: ActivatedRoute,
		public iconRegistry: MatIconRegistry,
		public sanitizer: DomSanitizer,
		private dialog: MatDialog,
		public monitoringService: MonitoringService,
		public translateService: TranslateService,
		public loaderService: LoaderService,
	) {
		if(window.screen.width > 1000) {
			this.isMobile = false;
			this.getGridDefination();
			this.getColDefs();
			// this.getIndividualColDefs();
		} else {
			this.isMobile = true;
			this.getGridDefination();
			this.getColDefs();
			// this.getIndividualColDefs();
		}
		iconRegistry
      		.addSvgIcon('no-record', sanitizer.bypassSecurityTrustResourceUrl('assets/img/no-results.svg'))
	}

	ngOnInit(): void {
		this.entityId = localStorage.getItem('entityId');
		this.startDate = localStorage.getItem('dateFrom');
		this.endDate = localStorage.getItem('dateTo');
		this.loadRecords();
	}

	onPrint(){
		window.print();
	}
	

	loadRecords() {
		this.loaderService.isLoading.next(true);
		this.monitoringService.getPaymentAlarts("NBB", this.entityId, this.endDate, this.startDate, this.category).subscribe((data: any) => {
			this.loaderService.isLoading.next(false);
			if (data?.success) {
				this.paymentDetails = data.data;
				this.count = this.paymentDetails?.count;
				this.entitlement = this.paymentDetails.entitlement;
				this.getExportData();
				for (var index = 0; index < this.paymentDetails.list.nbbpayment.corporate.length; index++) {
					for (var i = 0; i < this.paymentDetails.list.nbbpayment.corporate[index].alertData.length; i++) {
						this.paymentDetails.list.nbbpayment.corporate[index].alertData[i].isExpand = true;						
					}					
				}
				for (var index = 0; index < this.paymentDetails.list.nbbpayment.individual.length; index++) {
					for (var i = 0; i < this.paymentDetails.list.nbbpayment.individual[index].alertData.length; i++) {
						this.paymentDetails.list.nbbpayment.individual[index].alertData[i].isExpand = true;						
					}					
				}
			} else {
				this.openTryAgainPopup()
			}
		}, (err: any) => {
			this.loaderService.isLoading.next(false);
			this.openTryAgainPopup();
		});
	}

	getExportData() {
		if(this.entitlement == 'Detailed') {
			this.loaderService.isLoading.next(true);
			this.monitoringService.exportToExcel("NBB", this.endDate, this.startDate, "ALL", this.entityId).subscribe((data: any) => {
				this.loaderService.isLoading.next(false);
				if (data?.success) {
					this.exportNBB = data?.data?.NBB_DETAILED;
				}
			},
			(err: any) => {
				this.loaderService.isLoading.next(false);
			});
			this.monitoringService.exportToExcel("NBB", this.endDate, this.startDate, "NBL", this.entityId).subscribe((data: any) => {
				if (data?.success) {
					this.exportNBL = data?.data?.NBB_NBL;
				}
			},
			(err: any) => {
				this.loaderService.isLoading.next(false);
			});
		} else {
			this.loaderService.isLoading.next(false);
			this.monitoringService.exportToExcel("NBB", this.endDate, this.startDate, "ALL", this.entityId).subscribe((data: any) => {
				this.loaderService.isLoading.next(false);
				if (data?.success) {
					this.exportNBB = data?.data?.NBB_BASIC;
				}
			},
			(err: any) => {
				this.loaderService.isLoading.next(false);
			});
		}		
	}
	ExpandAll(data:any){
		if(data){
			this.isExpandList = false;	
			for (var index = 0; index < this.paymentDetails.list.nbbpayment.corporate.length; index++) {
				for (var i = 0; i < this.paymentDetails.list.nbbpayment.corporate[index].alertData.length; i++) {
					this.paymentDetails.list.nbbpayment.corporate[index].alertData[i].isExpand = false;						
				}					
			}
			for (var index = 0; index < this.paymentDetails.list.nbbpayment.individual.length; index++) {
				for (var i = 0; i < this.paymentDetails.list.nbbpayment.individual[index].alertData.length; i++) {
					this.paymentDetails.list.nbbpayment.individual[index].alertData[i].isExpand = false;						
				}					
			}
			this.expandCollapseButtonValue = "Expand all rows"
		}else{
			this.isExpandList = true;
			for (var index = 0; index < this.paymentDetails.list.nbbpayment.corporate.length; index++) {
				for (var i = 0; i < this.paymentDetails.list.nbbpayment.corporate[index].alertData.length; i++) {
					this.paymentDetails.list.nbbpayment.corporate[index].alertData[i].isExpand = true;						
				}					
			}
			for (var index = 0; index < this.paymentDetails.list.nbbpayment.individual.length; index++) {
				for (var i = 0; i < this.paymentDetails.list.nbbpayment.individual[index].alertData.length; i++) {
					this.paymentDetails.list.nbbpayment.individual[index].alertData[i].isExpand = true;						
				}					
			}
			this.expandCollapseButtonValue = "Collapse all rows"
		}
	}

	export() {
		if(this.entitlement == 'Detailed') {
			let element = document.getElementById('excel-table-nbb');
          const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
			var wscols = [
				{wch:50},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30}
			];	
			ws['!cols'] = wscols;
			const wb: XLSX.WorkBook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, 'Payment(Detailed)');
			let element1 = document.getElementById('excel-table-nbl');
          const ws1: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element1, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
			var wscols1 = [
				{wch:40},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
				{wch:30},
			];	
			ws1['!cols'] = wscols1;
			XLSX.utils.book_append_sheet(wb, ws1, 'Payment(NL)');
			XLSX.writeFile(wb, 'NBB_ExcelSheet.xlsx');
		} else {
			let element = document.getElementById('excel-table');
          const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd/mm/yyyy;@', cellDates: true, raw: true });
			var wscols = [
				{wch:40},
				{wch:30},
				{wch:30}
			];	
			ws['!cols'] = wscols;
			const wb: XLSX.WorkBook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, 'Payment(Basic)');
			XLSX.writeFile(wb, 'NBB_ExcelSheet.xlsx');
		}
	}

	openTryAgainExportPopup() {
		this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
			let dialogRef = this.dialog.open(ErrorDialogComponent, {
				disableClose: true,
				width: this.isMobile?'auto':'37vw',
				data: {
					errorMessage: value['errorMsg.pleaseTryAgain']
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					this.loadRecords()
				}
			});
		})
	}
	openTryAgainPopup() {
		this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
			let dialogRef = this.dialog.open(ErrorDialogComponent, {
				disableClose: true,
				width: this.isMobile?'auto':'37vw',
				data: {
					errorMessage: value['errorMsg.pleaseTryAgain']
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					this.loadRecords()
				}
			});
		})
	}
	expandCorporate(index:any,i:any){
		this.paymentDetails.list.nbbpayment.corporate[index].alertData[i].isExpand = !this.paymentDetails.list.nbbpayment.corporate[index].alertData[i].isExpand;	
	}
	expandIndividual(index:any,i:any){
		this.paymentDetails.list.nbbpayment.individual[index].alertData[i].isExpand = !this.paymentDetails.list.nbbpayment.individual[index].alertData[i].isExpand;	
	}
	getGridDefination() {
		this.gridOptions = <GridOptions>{
			onGridReady: () => {
				setTimeout(() => {
					this.gridOptions.api?.sizeColumnsToFit();
				}, 1000);
			 },
			onGridSizeChanged: () => {
				this.gridOptions.api.sizeColumnsToFit();
			},
			noRowsOverlayComponent: 'myNoRowsComponent',
			noRowsOverlayComponentParams: {
				"component": "detailed"
			},
			pagination: false,
			animateRows: true,
			suppressCellSelection: true,
			enableCellTextSelection: true,
			suppressContextMenu:true,
			defaultColDef: {
				flex: 1,
				wrapText: true,
				resizable: true,
				autoHeight: true,
				unSortIcon: true,
				sortable: true,
				suppressMenu: true,
				lockPosition: true,
				tooltipComponent: 'customTooltip'
			}
		};
		this.gridOptions.frameworkComponents = {
			myNoRowsComponent: MyNoRowsComponent
		};
	}

	getColDefs() {
		this.translateService.get(['monitor.fms.no', 'monitor.entityIdentification', 'monitor.fms.dtc',
		'monitor.fms.grade', 'monitor.fms.balance', 'monitor.fms.last_paid_amount']).subscribe(value => {
				this.columnDefs = [
					{
						field: 'srNo',
						headerName: value['monitor.fms.no'],
						tooltipField: 'clientFileRef',
						tooltipComponentParams: { type: 'fileRef' },
						sortable: false,
						hide:this.isMobile,
						width: 110,
						maxWidth: 110,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px', 'padding-left': '20px'}
					},
					{
						field: 'name',
						headerName: value["monitor.entityIdentification"],
						tooltipComponentParams: { type: 'fileRef' },
						sortable: false,
						width: 110,
						minWidth: 110,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' }
					},
					{
						field: 'dtc',
						headerName: value['monitor.fms.dtc'],
						tooltipComponentParams: { type: 'fileRef' },
						sortable: false,
						hide:this.isMobile,
						width: 110,
						minWidth: 110,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' }
					},
					{
						field: 'grade',
						headerName: value['monitor.fms.grade'],
						tooltipComponentParams: { type: 'fileRef' },
						sortable: false,
						hide:this.isMobile,
						width: 110,
						minWidth: 110,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' }
					},
					{
						field: 'balance',
						headerName: value['monitor.fms.balance'],
						// valueGetter: this.dateGetter,
						sortable: false,
						hide:this.isMobile,
						width: 110,
						minWidth: 110,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' },
					},
					{
						field: 'monitor',
						headerName: value['monitor.fms.last_paid_amount'],
						// valueGetter: this.dateGetter,
						sortable: false,
						hide:this.isMobile,
						width: 110,
						minWidth: 110,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' },
					}
				]
			});

			this.translateService.get(['monitor.fms.no', 'monitor.entityIdentification', 'monitor.fms.dtc',
		'monitor.fms.alternative_grade', 'monitor.fms.balance', 'monitor.fms.last_paid_amount']).subscribe(value => {
				this.columnDefsIndividual = [
					{
						field: 'srNo',
						headerName: value['monitor.fms.no'],
						tooltipField: 'clientFileRef',
						tooltipComponentParams: { type: 'fileRef' },
						sortable: false,
						hide:this.isMobile,
						width: 110,
						maxWidth: 110,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px', 'padding-left': '20px'}
					},
					{
						field: 'name',
						headerName: value["monitor.entityIdentification"],
						tooltipComponentParams: { type: 'fileRef' },
						sortable: false,
						width: 110,
						minWidth: 110,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' }
					},
					{
						field: 'dtc',
						headerName: value['monitor.fms.dtc'],
						tooltipComponentParams: { type: 'fileRef' },
						sortable: false,
						hide:this.isMobile,
						width: 110,
						minWidth: 110,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' }
					},
					{
						field: 'grade',
						headerName: value['monitor.fms.alternative_grade'],
						tooltipComponentParams: { type: 'fileRef' },
						sortable: false,
						hide:this.isMobile,
						width: 110,
						minWidth: 110,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' }
					},
					{
						field: 'balance',
						headerName: value['monitor.fms.balance'],
						// valueGetter: this.dateGetter,
						sortable: false,
						hide:this.isMobile,
						width: 110,
						minWidth: 110,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' },
					},
					{
						field: 'monitor',
						headerName: value['monitor.fms.last_paid_amount'],
						// valueGetter: this.dateGetter,
						sortable: false,
						hide:this.isMobile,
						width: 110,
						minWidth: 110,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px' },
					}
				]
			});
	} 
}
