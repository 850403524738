import { Component, OnInit, Inject, ViewEncapsulation, HostListener } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ErrorDialogComponent } from 'src/app/error-dialog-box/error-dialog.component';
import { LoaderService } from 'src/app/services/loader.service';
import { MonitoringService } from '../../monitoring.service';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';

@Component({
  selector: 'app-company-pick-alert',
  templateUrl: './company-pick-alert.component.html',
  styleUrls: ['./company-pick-alert.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManuallPickAlertComponent implements OnInit {
  isMobile: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth > 1000) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }
  selectAll: any = false;
  searchForm!: FormGroup;
  alerts: any = [];
  entity: any = [];
  alertsArray: any = [];
  addalertsArray: any = [];
  exceedLimit: any = [];
  disabled: boolean = false;
  isExpand: boolean = false;
  isLms: boolean = false;
  lmsAlreadyExist: boolean = false;
  isAddChangesToEdit: boolean = false;
  isAddchangesToRestore: boolean = false;
  isLmsMid: boolean = false;
  isSubscriptionEqual: boolean = false;
  showLmsDisclaimer: boolean = false;
  lmsCoast: any;
  infoDialogRef: MatDialogRef<InfoDialogComponent>;
  constructor(
    public service: MonitoringService,
    public matDialogRef: MatDialogRef<ManuallPickAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public loaderService: LoaderService,
    public dialog: MatDialog,
    public translateService: TranslateService,
    private formBuilder: FormBuilder) {
    if (window.screen.width > 1000) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    this.entity = data.results;
    for (var index = 0; index < this.entity.length; index++) {
      this.entity[index].isExpand = false;
    }
  }

  ngOnInit() {
    this.createAdvancedSearchForm();
    this.getAlertsData();
  }

  checkIsAlreadyExist() {
    this.loaderService.isLoading.next(true);
    this.service.GetEntitySubscription(this.entity?.entity_no).subscribe((data: any) => {
      var isAlreadyExist = data?.data?.response?.filter((alert: any) => alert.isSubscribed == true || alert.isSubscribed == '1');
      if (data?.data?.isDeleted) {
        this.isAddchangesToRestore = true;
        this.loaderService.isLoading.next(false);
        this.alerts.map((res: any) => {
          data?.data?.map((available: any) => {
            if (res.monitoringType == available.monitoringType) {
              if (res.accessControlDetails?.restoreName == "N") {
                res.disabled = true;
              } else {
                res.disabled = false;
              }
            }
          });
        });
      } else if (isAlreadyExist.length > 0) {
        this.isAddChangesToEdit = true;
        var lmsAlreadyExist = isAlreadyExist.find((record: any) => record.monitoringType == 'LMS');
        if (lmsAlreadyExist) {
          this.loaderService.isLoading.next(false);
          this.lmsAlreadyExist = true;
          this.showLmsDisclaimer = true;
        } else {
          this.loaderService.isLoading.next(false);
          this.infoDialogRef = this.dialog.open(InfoDialogComponent, {
            disableClose: true,
            width: '30vw',
          });
          this.translateService.get(['confirmAction.alreadyExist', 'confirmAction.EntityAlreadyExist']).subscribe(value => {
            this.infoDialogRef.componentInstance.action = encodeURIComponent((value['confirmAction.alreadyExist'])).replace(/%20/g, " ");
            this.infoDialogRef.componentInstance.confirmMessage = encodeURIComponent(value['confirmAction.EntityAlreadyExist']).replace(/%20/g, " ");
          });
          this.infoDialogRef.afterClosed().subscribe(res => {
            this.infoDialogRef.close(false);
          });
        }
        this.alerts.map((res: any) => {
          data?.data?.response?.map((available: any) => {
            if (res.monitoringType == available.monitoringType) {
              if (available?.isSubscribed == true || available?.isSubscribed == '1') {
                if (res.monitoringType == "LMS") {
                  res.link = "restricted";
                  res.check = true;
                } else {
                  if (res.monitoringType == "NBB") {
                    if (res.entitlement) {
                      if (res.entitlement == "Basic") {
                        res.link = "Basic_Upgrade_To_Detailed"
                      }
                    } else {
                      res.link = "Payment";
                    }
                  }
                  res.check = true;
                }
                if (res.monitoringType == "DNSA") {
                  res.link = "DNSA";
                }
                if (res.accessControlDetails?.removeName == "N") {
                  res.disabled = true;
                }
              } else if (available?.isSubscribed == false || available?.isSubscribed == null || available?.isSubscribed == '0') {
                res.check = false;
                if (res.monitoringType == "NBB") {
                  if (res.entitlement) {
                    res.link = "Non_Bank_Bereau_Records";
                  } else {
                    res.link = "Payment";
                  }
                }
                if (res.monitoringType == "DNSA") {
                  res.link = "DNSA";
                }
                if (res.accessControlDetails?.addName == "N") {
                  res.disabled = true;
                }
              }
            }
          });
        });
        this.checkEnabled();
      } else {
        this.alerts.map((res: any) => {
          if (res.accessControlDetails?.addName == "N") {
            res.disabled = true;
          }
        });
        this.loaderService.isLoading.next(false);
      }
    }, (err: any) => {
      this.loaderService.isLoading.next(false);
    });
  }

  getAlertsData() {
    this.loaderService.isLoading.next(true);
    this.service.getAlertsSummary().subscribe((data: any) => {
      this.loaderService.isLoading.next(false);
      if (data.success) {
        this.alerts = data?.data?.summaryDetails.map((record: any) => ({
          ...record,
          check: false,
          expanded: false,
          disabled: false
        }));

        if (this.entity?.add) {
          this.alerts = this.alerts.filter((e: any) => e.monitoringType !== 'NBB' && e.monitoringType !== 'DNSA');
          this.checkIsAlreadyExist();
        } else if (this.entity?.restore) {
          this.alerts = this.alerts.filter((e: any) => e.monitoringType !== 'NBB' && e.monitoringType !== 'DNSA');
        }

        this.alerts.map((res: any) => {
          if (res.totalNamesSubscribed < 1 && res.monitoringType != "NBB" && res.monitoringType != "LMS") {
            res.check = false;
            res.link = "signUpForPlan";
          } else if (res.remainingBalance < 1 && res.monitoringType != "NBB" && res.monitoringType != "LMS") {
            res.check = false;
            res.link = "upgradePlan";
          }
        })

        this.alerts.map((res: any) => {
          this.entity?.availableAlerts?.map((available: any) => {
            if (res.monitoringType == available.monitoringType) {
              if (available.isSubscribed == true || available.isSubscribed == '1') {
                if (res.monitoringType == "LMS") {
                  res.link = "restricted";
                  res.check = true;
                } else {
                  if (res.accessControlDetails?.removeName == "N") {
                    res.disabled = true;
                  }
                  if (res.monitoringType == "NBB") {
                    if (res.entitlement) {
                      if (res.entitlement == "Basic") {
                        res.link = "Basic_Upgrade_To_Detailed"
                      }
                    } else {
                      res.link = "Payment";
                    }
                  }
                  res.check = true;
                }
                if (res.monitoringType == "DNSA") {
                  res.link = "DNSA";
                }
              } else if (available.isSubscribed == false) {
                res.check = false;
                if (res.monitoringType == "NBB") {
                  if (res.entitlement) {
                    res.link = "Non_Bank_Bereau_Records";
                  } else {
                    res.link = "Payment";
                  }
                }
                if (res.monitoringType == "DNSA") {
                  res.link = "DNSA";
                }
                if (this.entity.edit) {
                  if (res.accessControlDetails?.addName == "N") {
                    res.disabled = true;
                  }
                } else if (this.entity?.restore) {
                  if (res.accessControlDetails?.restoreName == "N") {
                    res.disabled = true;
                  }
                }
              } else if (available.isSubscribed == null) {
                res.check = false;
                if (res.monitoringType == "NBB") {
                  if (res.entitlement) {
                    res.link = "Non_Bank_Bereau_Records";
                  } else {
                    res.link = "Payment";
                  }
                }
                if (res.monitoringType == "DNSA") {
                  res.link = "DNSA";
                }
                if (this.entity.edit == false) {
                  if (res.accessControlDetails?.addName == "N") {
                    res.disabled = true;
                  }
                } else if (this.entity?.restore) {
                  if (res.accessControlDetails?.restoreName == "N") {
                    res.disabled = true;
                  }
                }
              }
              this.checkEnabled();
            }
          });
        });

        this.alerts.map((data: any) => {
          if (data.monitoringType == 'FMS') {
            data.check = false;
            data.link = "unAvailable";
          }

        });
      } else {
        this.openTryAgainAlertsPopup();
        this.loaderService.isLoading.next(false);
      }

    }, (err: any) => {
      this.openTryAgainAlertsPopup();
      this.loaderService.isLoading.next(false);
    });
  }
  createAdvancedSearchForm() {
    this.searchForm = this.formBuilder.group({
      clientRefNo: '',
      orderNo: '',
      itemNo: '',
      startDate: '',
      endDate: ''
    });
  }

  addAlerts() {
    this.alertsArray = [];
    var data: any = {
      "EntityId": this.entity?.entityId,
      "EntityName": this.entity?.entityName ? this.entity.entityName : "",
      "ClientFileReference": this.entity?.clientFileReference ? this.entity?.clientFileReference : "",
      "EntityType": "C",
      "CountryCode": this.entity?.countryName?.countryCode ? this.entity?.countryName?.countryCode : "",
      "IsSubscriptionEqual": this.isSubscriptionEqual
    }

    this.alerts.map((res: any) => {
      var add = {
        "MonitoringName": res.monitoringType,
        "MonitoringValue": res.check
      };
      this.alertsArray.push(add);
    });
    data["MonitoringTypeList"] = this.alertsArray;

    this.loaderService.isLoading.next(true);
    if (this.entity.edit == false) {
      this.addalertsArray = [];
      var addData: any = {
        "EntityId": this.entity.entity_no,
        "EntityName": this.entity?.entity_name ? this.entity.entity_name : "",
        "EntityStatus": "",
        "ClientFileReference": this.entity?.file ? this.entity.file : "",
        "EntityType": "C",
        "CountryCode": "SGP",
        "IsSubscriptionEqual": this.isSubscriptionEqual
      }

      this.alerts.map((res: any) => {
        var add = {
          "MonitoringName": res.monitoringType,
          "MonitoringValue": res.check
        };
        this.addalertsArray.push(add);
      });
      if (this.isAddChangesToEdit) {
        addData["MonitoringTypeList"] = this.addalertsArray;
        this.service.updateMonitoredList(addData).subscribe((data: any) => {
          if (typeof data == 'string' && JSON.parse(data)?.success) {
            data = JSON.parse(data);
            localStorage.setItem('ACTION', "EDITED");
            this.matDialogRef.close(true);
            this.loaderService.isLoading.next(false);
          } else {
            this.loaderService.isLoading.next(false);
            this.openTryAgainPopup();
          }
        }, (err: any) => {
          this.loaderService.isLoading.next(false);
          this.openTryAgainPopup();
        });
      } else if (this.isAddchangesToRestore) {
        addData["MonitoringTypeList"] = this.addalertsArray;
        this.service.restoreEntity(addData).subscribe((res: any) => {
          if (typeof res == 'string' && JSON.parse(res)?.success) {
            res = JSON.parse(res);
            localStorage.setItem('ACTION', "RESTORE");
            this.matDialogRef.close(this.entity);
            this.loaderService.isLoading.next(false);
          } else {
            this.loaderService.isLoading.next(false);
            this.openTryAgainPopup();
          }
        }, (err: any) => {
          this.loaderService.isLoading.next(false);
          this.openTryAgainPopup();
        });
      } else {
        addData["MonitoringTypeList"] = this.addalertsArray;
        this.service.addCorporateEntity(addData).subscribe((data: any) => {
          if (typeof data == 'string' && JSON.parse(data)?.success) {
            data = JSON.parse(data);
            this.loaderService.isLoading.next(false);
            localStorage.setItem('ACTION', "ADDED");
            this.matDialogRef.close(true);
          } else if (JSON.parse(data)?.errors[0].errorMsg == "Entity number already exist") {
            this.loaderService.isLoading.next(false);
            if (this.isLms) {
              this.lmsAlreadyExist = true;
            } else {
              this.infoDialogRef = this.dialog.open(InfoDialogComponent, {
                disableClose: true,
                width: this.isMobile ? 'auto' : '30vw',
              });
              this.translateService.get(['confirmAction.alreadyExist', 'confirmAction.EntityAlreadyExist']).subscribe(value => {
                this.infoDialogRef.componentInstance.action = encodeURIComponent(value['confirmAction.alreadyExist']).replace(/%20/g, " ");
                this.infoDialogRef.componentInstance.confirmMessage = encodeURIComponent(value['confirmAction.EntityAlreadyExist']).replace(/%20/g, " ");
              });
              this.infoDialogRef.afterClosed().subscribe(res => {
                this.matDialogRef.close(false);
              });
            }
          } else {
            this.loaderService.isLoading.next(false);
            this.openTryAgainPopup();
          }
        }, (err: any) => {
          this.loaderService.isLoading.next(false);
          this.openTryAgainPopup();
        });
      }
    } else {
      if (this.entity?.restore) {
        this.service.restoreEntity(data).subscribe((res: any) => {
          if (typeof res == 'string' && JSON.parse(res)?.success) {
            res = JSON.parse(res);
            this.matDialogRef.close(this.entity);
            this.loaderService.isLoading.next(false);
          } else {
            this.loaderService.isLoading.next(false);
            this.openTryAgainPopup();
          }
        }, (err: any) => {
          this.loaderService.isLoading.next(false);
          this.openTryAgainPopup();
        });
      } else {
        this.service.updateMonitoredList(data).subscribe((data: any) => {
          if (typeof data == 'string' && JSON.parse(data)?.success) {
            data = JSON.parse(data);
            this.loaderService.isLoading.next(false);
            this.matDialogRef.close(this.alerts);
            this.loaderService.isLoading.next(false);
          } else {
            this.loaderService.isLoading.next(false);
            this.openTryAgainPopup();
          }
        }, (err: any) => {
          this.loaderService.isLoading.next(false);
          this.openTryAgainPopup();
        });
      }
    }

  }

  openTryAgainPopup() {
    this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
      let dialogRef = this.dialog.open(ErrorDialogComponent, {
        disableClose: true,
        width: this.isMobile ? 'auto' : '37vw',
        data: {
          errorMessage: value['errorMsg.pleaseTryAgain']
        }
      });
      dialogRef.afterClosed().subscribe((res: any) => {
        if (res) {
          this.addAlerts();
        }
      });
    })
  }

  openTryAgainAlertsPopup() {
    this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
      let dialogRef = this.dialog.open(ErrorDialogComponent, {
        disableClose: true,
        width: this.isMobile ? 'auto' : '37vw',
        data: {
          errorMessage: value['errorMsg.pleaseTryAgain']
        }
      });
      dialogRef.afterClosed().subscribe((res: any) => {
        if (res) {
          this.getAlertsData();
        }
      });
    })
  }

  checkEnabled() {
    var selectDisabled = this.alerts.find((obj: any) => (obj.check === true));
    if (selectDisabled == undefined) {
      if (this.entity.edit == false) {
        this.disabled = true;
      } else if (this.entity?.restore) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    } else {
      this.disabled = false;
    }
    this.checkIsSameSubscription();
  }

  onSelectAllCheck() {
    if (this.selectAll) {
      this.alerts.map((alert: any) => {
        if (!alert.link && !alert.disabled && alert.monitoringType != "NBB" && alert.monitoringType != "DNSA") {
          alert.check = true;
          if (alert.monitoringType == "LMS") {
            this.isLms = true;
          }
        }

      })
    }
    else {
      this.alerts.map((alert: any) => {
        if (!alert.link && !alert.disabled && alert.monitoringType != "NBB" && alert.monitoringType != "DNSA") {
          alert.check = false;
          if (alert.monitoringType == "LMS") {
            this.isLms = false;
          }
        }
      })
    }
    this.checkEnabled();
  }

  onAlertCheck(alert: any) {
    if (!alert.check) {
      this.selectAll = false;
      this.equalSubscription(false);
    } else {
      this.equalSubscription(true);
    }
    if (alert.check && alert.monitoringType == "LMS") {
      this.isLms = true;
    } else if (!alert.check && alert.monitoringType == "LMS") {
      this.isLms = false;
    }
    this.checkEnabled();
  }

  checkIsSameSubscription() {
    var filtered = this.alerts.filter((e: any) => e.check == true);
    if (filtered) {
      filtered.map((res: any) => {
        if (res.subscriptionId && !res.link)
          filtered.map((validate: any) => {
            if (res.monitoringType != validate.monitoringType) {
              if (res.subscriptionId == validate.subscriptionId)
                this.isSubscriptionEqual = true;
            }
          });
      });
    }
  }

  equalSubscription(isChecked: boolean) {
    this.alerts.map((res: any) => {
      if (res.subscriptionId && !res.link)
        this.alerts.map((validate: any) => {
          if (res.monitoringType != validate.monitoringType) {
            if (res.subscriptionId == validate.subscriptionId)
              res.check = isChecked;
          }
        });
      if (this.alerts.filter((e: any) => e.check == true).length == this.alerts.length) {
        this.selectAll = true;
      }
    });
  }

  lmsDisclaimer() {
    if (!this.lmsCoast) {
      this.loaderService.isLoading.next(true);
      this.service.checkIsLmsMid(this.entity?.entity_no).subscribe((data: any) => {
        if (data?.data?.Message == "MID Not Found.") {
          this.isLmsMid = true;
          this.loaderService.isLoading.next(true);
          this.service.getLmsCoast().subscribe((data: any) => {
            this.loaderService.isLoading.next(false);
            this.showLmsDisclaimer = true;
            if (data.success) {
              this.lmsCoast = data.data.productPrice;
            }
          }, (err: any) => {
            this.showLmsDisclaimer = true;
            this.loaderService.isLoading.next(false);
          });
        } else {
          this.showLmsDisclaimer = true;
          this.loaderService.isLoading.next(false);
        }
      }, (err: any) => {
        this.showLmsDisclaimer = true;
        this.loaderService.isLoading.next(false);
      });
    } else {
      this.showLmsDisclaimer = true;
    }
  }

  hideDisclaimer() {
    this.showLmsDisclaimer = false;
    this.lmsAlreadyExist = false;
  }
  expand(index: any) {
    this.entity[index].isExpand = !this.entity[index].isExpand;
  }
  expandNotBulk() {
    this.isExpand = !this.isExpand;
  }
}
