import { Component, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { GridApi, GridOptions } from 'ag-grid-community';
import { Router } from '@angular/router';
import { CustomToolTipComponent } from '../custom-tooltip/custom-tooltip.component';
import { GridCellRendererComponent } from '../grid-cell-renderer/grid-cell-renderer.component';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../services/loader.service';
import { HomeService } from '../home/home.service';
import { MatDialog } from '@angular/material/dialog';
import { NoResultDialogComponent } from '../no-result-dialog-box/no-result-dialog.component';
import { ErrorDialogComponent } from '../error-dialog-box/error-dialog.component';
import { OrderHistoryService } from '../order-history/order-history.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { OrderDataCellRenderer } from './order-details-grid/order-data.component';
import { C } from '@angular/cdk/keycodes';

@Component({
	selector: 'app-thank-you',
	templateUrl: './thank-you.component.html',
	styleUrls: ['./thank-you.component.scss']
})

export class ThankYouComponent implements AfterViewInit, OnDestroy {
	isMobile: boolean = false;
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if (event.target.innerWidth > 1000) {
			this.isMobile = false;
			this.getColDefs();
		} else {
			this.isMobile = true;
			this.getColDefs();
		}
	}
	columnDefs: any = [];
	cartList: any = [];
	orderResponse: any = [];
	totalPrice: any = 0.0;
	balancePoints: any;
	gridOptions: any;
	icons: any;
	tooltipShowDelay: any;
	paymentMethod: any = '';
	currency: any = '';
	domLayout: any = "autoHeight";
	countryList: any = [];
	cartListOriginal: any = [];
	balanceDetails: any;
	detailCellRenderer: any;
	detailRowHeight: any;
	title: any = "Report Buying";
	gridColumnApi: any;
	private paginationPageSize = 5;
	private totalPages = 0;
	get PaginationPageSize(): number {
		return this.paginationPageSize;
	}

	get gridAPI(): GridApi {
		return this.gridOptions.api;
	}

	get TotalPages(): number {
		return this.totalPages;
	}
	constructor(private router: Router, public translateService: TranslateService,
		private loaderService: LoaderService, private homeService: HomeService,
		private dialog: MatDialog, private orderService: OrderHistoryService,
		private toaster: ToastrService) {

		window.scroll(0, 0);
		this.currency = localStorage.getItem('currency');
		if (window.screen.width > 1000) {
			this.isMobile = false;
		} else {
			this.isMobile = true;
		}
		this.getColDefs();

		this.gridOptions = <GridOptions>{
			onGridReady: () => {
				this.paymentMethod = localStorage.getItem('paymentMethod');
				this.gridOptions.api?.sizeColumnsToFit();
				this.gridOptions.api.forEachNode((node: any) => node.setSelected(true));
				this.totalPages = this.gridOptions.api?.paginationGetTotalPages();
				let columnDefs = this.gridOptions?.api?.getColumnDefs();
				this.translateService.get(['thankYou.price']).subscribe(value => {
					columnDefs.find((obj: any) => {
						if (obj.ColId == 'price_col') {
							obj.headerName = value['thankYou.price'] + ' (' + (this.currency ? this.currency : '') + ')';
						}
					});
				});
				this.gridOptions?.api.getModel().rowsToDisplay.forEach((element: any) => {
					let item = this.orderResponse.find((obj: any) => (obj.cartSeqNo == element.data.cartSeqNo));
					element.setDataValue('cartSeqNo', item.itemId);
				});
				this.gridOptions.api.setColumnDefs(columnDefs);
				this.gridOptions.api.refreshHeader({ forrce: true });
				if (this.cartList.length > 5) {
					this.gridAPI.setDomLayout('normal');
					let element: any = document.getElementById('thankYouGrid');
					if (element) {
						element.style.height = '51vh';
					}
				}
			},
			overlayNoRowsTemplate: '<h4>No record found.</h4>',
			pagination: true,
			animateRows: true,
			paginationPageSize: 10,
			suppressCellSelection: true,
			enableCellTextSelection: true,
			suppressMenuHide: true,
			defaultColDef: {
				flex: 1,
				wrapText: true,
				resizable: true,
				autoHeight: true,
				unSortIcon: true,
				sortable: true,
				suppressMenu: true,
				lockPosition: true,
				tooltipComponent: 'customTooltip'
			}
		};

		this.tooltipShowDelay = 0;

		this.icons = {
			sortUnSort: '<img src="../../assets/img/sort.svg">',
			sortAscending: '<img src="../../assets/img/sort-asc.svg">',
			sortDescending: '<img src="../../assets/img/sort-desc.svg">'
		};
		this.detailCellRenderer = 'myDetailCellRenderer';
		this.detailRowHeight = 200;
		this.gridOptions.frameworkComponents = {
			"cellRenderer": GridCellRendererComponent,
			"myDetailCellRenderer": OrderDataCellRenderer,
			"customTooltip": CustomToolTipComponent
		};

		this.cartList = this.router.getCurrentNavigation()?.extras.state?.dataList;
		this.cartListOriginal = this.router.getCurrentNavigation()?.extras.state?.dataList;
		this.orderResponse = this.router.getCurrentNavigation()?.extras.state?.orderResponse;
		this.countryList = this.router.getCurrentNavigation()?.extras.state?.countryList;
		this.paymentMethod = this.router.getCurrentNavigation()?.extras.state?.paymentMethod;
		this.balanceDetails = this.router.getCurrentNavigation()?.extras.state?.balanceDetails;
		if (this.balanceDetails) {
			this.balancePoints = this.balanceDetails.balancePoints;
		}
		if (!this.orderResponse) {
			this.goToHome();
		}
		if (!this.cartList) {
			this.goToHome();
		} else {
			let count = 0;
			this.cartListOriginal.forEach((emt: any) => {
				if (!(this.orderResponse.some((e: any) => e.cartSeqNo === emt.cartSeqNo))) {
					count++;
				}
			});
			this.cartList = this.cartList.filter((elemnt: any) => (this.orderResponse.some((e: any) => e.cartSeqNo === elemnt.cartSeqNo)));
			let self = this;
			setTimeout(() => {
				self.cartList.forEach((element: any) => {
					element['seqNo'] = element.cartSeqNo;
					self.totalPrice = parseFloat(self.totalPrice) + parseFloat(element.sellingPrice);
					self.totalPrice = parseFloat(self.totalPrice).toFixed(2);
				});
			}, 500);
			if (count > 0) {
				this.translateService.get(['thankYou.transactionFailedError', 'thankYou.errorTitle']).subscribe(value => {
					this.toaster.error(count + ' ' + encodeURIComponent(value['thankYou.transactionFailedError']).replace(/%20/g, " "), encodeURIComponent(value['thankYou.errorTitle']).replace(/%20/g, " "),
						{
							timeOut: 0,
							extendedTimeOut: 0,
							tapToDismiss: true
						}).onTap.pipe(take(1)).subscribe(() => this.closeToaster());
				});
			}
		}
	}

	onGridReady(params: any) {
		this.gridColumnApi = params.columnApi;
	}

	ngAfterViewInit(): void {
		this.gridAPI.resetRowHeights();
	}

	ngOnDestroy(): void {
		this, this.toaster.clear();
	}

	closeToaster() {
		this.toaster.clear();
	}

	getColDefs() {
		this.translateService.get(['thankYou.orderItemId', 'thankYou.country', 'thankYou.identification',
			'thankYou.language', 'thankYou.product', 'thankYou.variationSpeed', 'thankYou.price',
			'thankYou.action']).subscribe(value => {
				this.columnDefs = [
					{
						field: 'cartSeqNo',
						headerName: this.isMobile ? 'Select All' : value['thankYou.orderItemId'],
						floatingFilter: this.isMobile ? false : true,
						hide: this.isMobile,
						filter: 'agTextColumnFilter',
						width: 120,
						minWidth: 120,
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px', 'font-size': '14px' }
					},
					{
						field: 'countryName',
						headerName: value['thankYou.country'],
						tooltipField: 'address',
						floatingFilter: this.isMobile ? false : true,
						hide: this.isMobile,
						filter: 'agTextColumnFilter',
						width: 130,
						minWidth: 130,
						filterParams: {
							trimInput: true
						},
						cellRenderer: function (param: any) {
							return param.data?.parentCountryName ? param.data?.parentCountryName : param.data?.countryName;
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px', 'font-size': '14px' }
					},
					{
						field: 'entityName',
						headerName: value['thankYou.identification'],
						tooltipField: 'entityName',
						tooltipComponentParams: { type: 'fileRef' },
						floatingFilter: this.isMobile ? false : true,
						filter: 'agTextColumnFilter',
						width: this.isMobile ? '60' : 300,
						minWidth: this.isMobile ? '60' : 300,
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px', 'font-size': '14px' }
					},
					{
						field: 'productLanguage',
						headerName: value['thankYou.language'],
						floatingFilter: this.isMobile ? false : true,
						hide: this.isMobile,
						filter: 'agTextColumnFilter',
						width: 120,
						minWidth: 120,
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px', 'font-size': '14px' }
					},
					{
						field: 'productDescription',
						headerName: value['thankYou.product'],
						width: 150,
						minWidth: 150,
						floatingFilter: this.isMobile ? false : true,
						hide: this.isMobile,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px', 'font-size': '14px' }
					},
					{
						field: 'speedDescription',
						headerName: value['thankYou.variationSpeed'],
						floatingFilter: this.isMobile ? false : true,
						hide: this.isMobile,
						filter: 'agTextColumnFilter',
						width: 180,
						minWidth: 180,
						filterParams: {
							trimInput: true
						},
						cellRenderer: function (param: any) {
							return param.data.expectedDeliveryDate ? (param.data.speedDescription + '<br/><span style="font-size:13px; color: #8C8C8C">Expected delivery date:</span><br/>' + moment(param.data.expectedDeliveryDate).format('DD MMM YYYY')) : param.data.speedDescription;
						},
						comparator: (valueA: number, valueB: number, nodeA: any, nodeB: any, isInverted: any) => {
							if (nodeA.data.turnAroundTimeBySupplier == nodeB.data.turnAroundTimeBySupplier) return 0;
							return (nodeA.data.turnAroundTimeBySupplier > nodeB.data.turnAroundTimeBySupplier) ? 1 : -1;
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px', 'font-size': '14px' }
					},
					{
						field: 'sellingPrice',
						headerName: value['thankYou.price'],
						ColId: 'price_col',
						width: 90,
						minWidth: 90,
						suppressMenu: false,
						filter: 'agNumberColumnFilter',
						floatingFilter: this.isMobile ? false : true,
						hide: this.isMobile,
						filterParams: {
							allowedCharPattern: '\\d\\-\\,', // note: ensure you escape as if you were creating a RegExp from a string
							numberParser: (text: any) => {
								return text == null ? null : parseFloat(text.replace(','));
							}
						},
						cellStyle: { 'direction': 'rtl', 'overflow': 'visible', 'text-align': 'left', 'padding': '13px', 'font-size': '14px' },
						valueFormatter: (params: any) => {
							return this.currencyFormatter(params.data?.sellingPrice)
						}
					},
					{
						headerName: value['thankYou.action'],
						cellRenderer: "cellRenderer",
						sortable: false,
						hide: this.isMobile,
						cellRendererParams: {
							label: 'thank-you',
							onClick: this.cartActionItems.bind(this),
						},
						cellStyle: {
							'overflow': 'visible', 'text-align': 'left', 'padding': '13px', 'font-size': '14px'
						},
						width: 80,
						maxWidth: 80
					}
				]
				this.columnDefs.find((obj: any) => {
					if (obj.ColId == 'price_col') {
						obj.headerName = value['thankYou.price'] + ' (' + (this.currency ? this.currency : '') + ')';
					}
				});
				let self = this
				setTimeout(() => {
					this.gridOptions.api.setColumnDefs(this.columnDefs);
					this.gridOptions.api.refreshHeader({ forrce: true });
					this.gridOptions.api?.sizeColumnsToFit();
					if (!this.isMobile) {
						const ratingNameColumn = self.gridColumnApi.getAllColumns().find((x: { colDef: { headerName: string; }; }) => x.colDef.headerName == 'Action');
						console.log("line:346", ratingNameColumn)
						self.gridColumnApi.setColumnVisible(ratingNameColumn, false);
						self.orderResponse.forEach(function (oRes: any) {
							if (oRes.isTranslationAvailable) {
								self.gridColumnApi.setColumnVisible(ratingNameColumn, true);
							}
						});
					} else {

					}

				}, 1000);
			});
	}

	onFirstDataRendered(params: any) {
		this.gridOptions.api.forEachNode((node: any) => {
			node.expanded = true;
		});
	}

	onFilterChanged(event: any) {
		if (this.gridOptions.api.getDisplayedRowCount() == 0) {
			this.gridAPI.showNoRowsOverlay();
		} else {
			this.gridAPI.hideOverlay();
		}
	}

	goToHome() {
		this.router.navigate(['/home']);
	}

	goToOrderHistory() {
		this.router.navigate(['/order-history']);
	}

	currencyFormatter(currency: any) {
		var sansDec = currency.toFixed(2);
		var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		return `${formatted}`;
	}

	cartActionItems(ev: any) {
		if (ev.label === 'expand') {
			ev.node.setExpanded(!ev.node.expanded);
			this.gridAPI.refreshCells(ev);
		}
		if (ev.label === 'jp_translation') {
			let country = this.countryList.find((obj: any) => (obj.countryName == ev.data.countryName));
			this.loaderService.isLoading.next(true);
			let categoryId = ev.data.categoryId;
			this.homeService.getTranslations(country.countryCode, categoryId).subscribe((data: any) => {
				if (data.data != null) {
					let translateObject = data.data.find((item: any) => (item.translationLanguage == "Japanese"));
					translateObject.reportTypeData = JSON.parse(translateObject?.reportTypeData);
					translateObject.reportTypeData.find((res: any) => {
						if ((ev.data.productOfferingId) == (res.ReportOfferingId)) {
							let obj = {
								"ProductOfferingId": res.TranslationOfferingId,
								"OrderType": "SINGLE",
								"SellingPrice": parseFloat(res.ProductPrice)
							}
							let arr = [];
							arr.push(obj);
							this.loaderService.isLoading.next(true);
							this.orderService.addChildToCart(arr, ev.data.cartSeqNo).subscribe((data: any) => {
								if (data.errors?.length > 0) {
									this.loaderService.isLoading.next(false);
									this.translateService.get(['errorMsg.translationFailed']).subscribe(value => {
										this.dialog.open(ErrorDialogComponent, {
											width: '37vw',
											disableClose: true,
											data: {
												errorMessage: value['errorMsg.translationFailed']
											}
										});
									});
								} else {
									if (data.success) {
										this.loaderService.isLoading.next(false);
										this.router.navigate(['/home']);
									} else {
										this.loaderService.isLoading.next(false);
										this.translateService.get(['errorMsg.translationFailed']).subscribe(value => {
											this.dialog.open(ErrorDialogComponent, {
												width: '37vw',
												disableClose: true,
												data: {
													errorMessage: value['errorMsg.translationFailed']
												}
											});
										});
									}
								}
							}, err => {
								this.loaderService.isLoading.next(false);
								this.translateService.get(['errorMsg.translationFailed']).subscribe(value => {
									this.dialog.open(NoResultDialogComponent, {
										width: '37vw',
										data: {
											errorMessage: value['errorMsg.translationFailed']
										}
									});
								});
							});
						}
					})
				} else {
					this.loaderService.isLoading.next(false);
					this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
						this.dialog.open(NoResultDialogComponent, {
							width: '37vw',
							data: {
								errorMessage: value['errorMsg.pleaseTryAgain']
							}
						});
					});
				}
			}, err => {
				this.loaderService.isLoading.next(false);
				this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
					this.dialog.open(NoResultDialogComponent, {
						width: '37vw',
						data: {
							errorMessage: value['errorMsg.pleaseTryAgain']
						}
					});
				});
			});
		}
	}

	changeLanguage(ev: any) {
		this.getColDefs();
	}
}
