import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'bulk-grid-cell-renderer',
	template: `<div style="display: flex;">
				<div>
					<img  src="../../assets/img/sample.svg" container="body" (click)="reportSample()" placement="bottom-right">
				</div>
				</div>`,
	styles: ['.link { color:#426DA9; text-decoration: underline; cursor: pointer;}', '.green { background-color: #5CD08E; color: white;}', '.yellow { background-color: #FEBA00; color: white;}', '.pink { background-color: #F66C86; color: white;}', '.text {font-size: 14px;}']
})

export class SampleCellRendererComponent implements ICellRendererAngularComp {

	public params: any;
	option: string | undefined;
	showTranslation: boolean = false;

	constructor() { }

	agInit(params: any): void {
		this.params = params;
		if (!this.params.data.parentCartSeqNo) {
			this.showTranslation = this.params.api.getModel().rowsToDisplay.some((obj: any) => (obj.data.parentCartSeqNo == this.params.data.cartSeqNo));
		}
	}

	refresh(): boolean {
		return false;
	}

	checkValid(data: any) {
		return data.translation;
	}

	
	onDelete(ev: any) {
		this.params['label'] = 'delete';
		this.params.onClick(this.params);
	}

	addToCart(ev: any) {
		this.params['label'] = 'addToCart';
		this.params.onClick(this.params);
	}
	
	reportSample() {
		this.params['label'] = 'sample';
		this.params.onClick(this.params);
	}

}