import { Component, Inject, ViewEncapsulation, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { GridApi, GridOptions } from 'ag-grid-community';
import { CustomFilterComponent } from '../../custom-filter/custom-filter.component';
import { TranslateService } from '@ngx-translate/core';
import { BulkUploadGridService } from '../bulk-upload-grid.service';
import { BulkGridCellRendererComponent } from '../bulk-grid-cell-renderer/bulk-grid-cell-renderer.component';
import { LoaderService } from 'src/app/services/loader.service';
import { interval, Subscription } from 'rxjs';
import { DocumentViewComponent } from 'src/app/document-view/document-view.component';
import { SampleCellRendererComponent } from './sample-cell-renderer.component';
import { ErrorDialogComponent } from 'src/app/error-dialog-box/error-dialog.component';
import * as moment from 'moment';
declare const $: any;

@Component({
	selector: 'app-match-result',
	templateUrl: './match-result.html',
	styleUrls: ['./match-result.scss'],
	encapsulation: ViewEncapsulation.None
})

export class MatchResultComponent implements OnInit, AfterViewInit, OnDestroy {

	searchColumnDefs: any = [];
	gridOptions: any;
	private gridApi: any;
	disabledFlag: boolean = false;
	errorMsgFlag: boolean = false;
	isRowSelected: boolean = false;
	country: any;
	selectedResult: any = {};
	searchResultList: any = [];
	mySubscription: Subscription;
	availability: any;
	gridColumnApi: any;
	icons: any;
	domLayout: any = "autoHeight";
	searchForm!: FormGroup;
	formData: any;
	private paginationPageSize = 5;
	private totalPages = 0;

	get PaginationPageSize(): number {
		return this.paginationPageSize;
	}

	get gridAPI(): GridApi {
		return this.gridOptions.api;
	}

	get TotalPages(): number {
		return this.totalPages;
	}

	constructor(public matDialogRef: MatDialogRef<MatchResultComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, private dialog: MatDialog, public loaderService: LoaderService,
		public translateService: TranslateService, public uploadService: BulkUploadGridService) {

		this.getColumnDefs();

		if (this.data.isMatch) {
			this.country = this.data?.results?.countryName;
			this.loadData(this.data.results);
			this.getColumnDefs();
			this.gridOptions = <GridOptions>{
				onGridReady: () => {
					this.gridOptions.api?.sizeColumnsToFit();
					this.totalPages = this.gridOptions.api?.paginationGetTotalPages();
				},
				onGridSizeChanged: () => {
					this.gridOptions.api.sizeColumnsToFit();
				},
				overlayNoRowsTemplate: '<h4> No Search results found. </h4>',
				rowSelection: 'multiple',
				pagination: true,
				paginationPageSize: 10,
				animateRows: true,
				enableCellTextSelection: true,
				suppressCellSelection: true,
				rowMultiSelectWithClick: true,
				defaultColDef: {
					flex: 1,
					wrapText: true,
					resizable: true,
					autoHeight: true,
					unSortIcon: true,
					sortable: true,
					suppressMenu: true,
					lockPosition: true
				}
			};
		} else {
			this.searchResultList = this.data.results;
			this.getAvailabilityDetails(this.data.results);
			this.getColumnDefsNoMatch();
			this.gridOptions = <GridOptions>{
				onGridReady: () => {
					this.gridOptions.api?.sizeColumnsToFit();
				},
				onGridSizeChanged: () => {
					this.gridOptions.api.sizeColumnsToFit();
				},
				overlayNoRowsTemplate: '<h4> No Search results found. </h4>',
				rowSelection: 'single',
				pagination: false,
				animateRows: true,
				enableCellTextSelection: true,
				suppressCellSelection: true,
				defaultColDef: {
					flex: 1,
					wrapText: true,
					resizable: true,
					autoHeight: true,
					unSortIcon: true,
					sortable: true,
					suppressMenu: true,
					lockPosition: true
				}
			};
		}

		

		this.icons = {
			sortUnSort: '<img src="../../assets/img/sort.svg">',
			sortAscending: '<img src="../../assets/img/sort-asc.svg">',
			sortDescending: '<img src="../../assets/img/sort-desc.svg">'
		};

		this.gridOptions.frameworkComponents = {
			"customFloatingFilter": CustomFilterComponent,
			"bulkCellRenderer": BulkGridCellRendererComponent,
			"sampleCellRendererComponent": SampleCellRendererComponent
		};
	}

	ngOnInit() {
	}

	loadData(data: any) {
		this.loaderService.isLoading.next(true);
		this.uploadService.bulkUploadMatchesList(data?.bulkOrderNumber, data?.searchId).subscribe(
			(res: any) => {
				this.loaderService.isLoading.next(false);
				if(res.success == true){
					res.data.map((record: any) => {
						record.country = this.country;
					})
					this.searchResultList = res.data;
			  }
			  else{
			  }
			},
			(err: any) => {
				this.loaderService.isLoading.next(false);
			}
		);  
	}

	getAvailabilityDetails(data: any) {
		this.loaderService.isLoading.next(true);
		this.uploadService.getAvailabilityDetails(data?.bulkOrderNumber, data?.searchId, data?.scr).subscribe( 
			(result: any) => {
				this.loaderService.isLoading.next(false);
			  if(result.success == true){
				this.availability = result?.data;
			  }
			  else{
			  }
			},
			(err: any) => {
				this.loaderService.isLoading.next(false);
			}
		);  
	}

	ngAfterViewInit(): void {
		this.gridAPI.resetRowHeights();
	}

	onGridReady(params: any) {
		this.gridColumnApi = params.columnApi;
		this.gridApi = params.api;
		params.api.sizeColumnsToFit();
	}

	addressFormator(param: any) {
		return "BLK "+ param.data.blkHse+ " "+ param.data.strNam + " # "+param.data.flr + "-"+ param.data.unt+ " "+ param.data.bld + " " + param.data.posCod;						
	}

	countryGetter(params: any) {
		return this.country;
	}

	dateGetter(params: any) {
		if(params.data.reportTS != null) {
			return moment(params.data.reportTS).format('DD MMM YYYY');
		} else if(params.data.matchReliabilityCode == null) {
			return 'Freshly commissioned';
		}	
	}

	getColumnDefs() {
		this.translateService.get(['bulk.upload.identification','bulk.upload.product', 'bulk.upload.country', 'bulk.upload.turnAroundTime', 'bulk.upload.score',
			'bulk.upload.action']).subscribe(value => {
				this.searchColumnDefs = [
					{
						field: 'RowSelect',
						headerName: ' ',
						checkboxSelection: true,
						headerCheckboxSelection: true,
						headerCheckboxSelectionFilteredOnly: true,
						sortable: false,
						width: 60,
						maxWidth: 60,
						cellRenderer: function (param: any) {
							return param.node.setSelected(param.data.isAdded);
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding-top': '4px'}
					},
					{
						field: 'name',
						headerName: value['bulk.upload.identification'],
						tooltipField: 'entityName',
						width: 220,
						minWidth: 220,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'country',
						headerName: value['bulk.upload.country'],
						width: 140,
						minWidth: 140,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'address',
						headerName: value['bulk.upload.address'],
						width: 150,
						minWidth: 150,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellRenderer: function (param: any) {
							var row = "";
							if(param.data.address?.street)
								row = param.data.address?.street+"</br>";
							if(param.data.address?.city)
								row = row + param.data.address?.city + " ";
							if(param.data.address?.postcode) 
								row = row + param.data.address?.postcode;
							return row;
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'status',
						headerName: value['bulk.upload.status'],
						tooltipField: 'address',
						width: 150,
						minWidth: 150,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							trimInput: true
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'matchReliabilityCode',
						headerName: value['bulk.upload.score'],
						width: 100,
						minWidth: 100,
						sortable: false,
						cellRenderer: function (param: any) {
							if(param.data.matchReliabilityCode >= 81)
								return '<button class="button green">' + param.data.matchReliabilityCode +'</button>';
							else if(param.data.matchReliabilityCode >= 61 && param.data.matchReliabilityCode <= 80)
								return '<button class="button yellow">' + param.data.matchReliabilityCode +'</button>';
							else
								return '<button class="button pink">' + param.data.matchReliabilityCode +'</button>';
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'center', 'padding': '13px 18px' }
					}
				]
			});
	}

	getColumnDefsNoMatch() {
		this.translateService.get(['variationTable.reportType','variationTable.turnaroundTime', 'variationTable.reportFreshness', 'variationTable.sample', 'bulk.upload.score',
			'bulk.upload.action']).subscribe(value => {
				this.searchColumnDefs = [
					{
						field: 'RowSelect',
						headerName: ' ',
						checkboxSelection: true,
						headerCheckboxSelection: false,
						headerCheckboxSelectionFilteredOnly: true,
						sortable: false,
						width: 60,
						maxWidth: 60,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding-top': '4px'}
					},
					{
						field: 'reportType',
						headerName: value['variationTable.reportType'],
						sortable: false,
						floatingFilter: false,
						width: 220,
						minWidth: 220,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'speedDescription',
						headerName: value['variationTable.turnaroundTime'],
						sortable: false,
						width: 140,
						minWidth: 140,
						floatingFilter: false,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'reportTS',
						headerName: value['variationTable.reportFreshness'],
						width: 150,
						minWidth: 150,
						sortable: false,
						floatingFilter: false,
						valueGetter: this.dateGetter,
						cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
					},
					{
						field: 'productOfferingId',
						headerName: value['variationTable.sample'],
						width: 90,
						minWidth: 90,
						sortable: false,
						cellRenderer: "sampleCellRendererComponent",
						cellRendererParams: {
							onClick: this.onActionClick.bind(this),
							label: 'availability-sample'
						},
						cellStyle: { 'overflow': 'visible', 'text-align': 'center', 'padding': '13px 18px', 'padding-left': '36px', 'margin-left': 'auto', 'margin-right': 'auto' }
					},
				]
			});
	}

	onSelectionChanged(event: any) {
		let rows = this.gridOptions.api.getSelectedNodes();
		if (rows.length == 0) {
			this.isRowSelected = false;
			this.disabledFlag = true;
		}
	}

	onActionClick(ev: any) {
		if (ev.label === 'sample') {
			this.gridApi.refreshCells();
			this.loaderService.isLoading.next(true);
			this.uploadService.sampleReport(ev.data?.productOfferingId).subscribe(res => {
				this.dialog.open(DocumentViewComponent, {
					width: '80vw',
					data: {
						response: res,
						format: 'PDF'
					}
				});
				this.loaderService.isLoading.next(false);
			}, err => {
				this.loaderService.isLoading.next(false);
			});
			
		}
	}
	onRowSearchSelected(params: any) {
		if(params.data.isAdded) {
			params.node.selected = false;
		}
		if (params.node.selected) {
			this.disabledFlag = false;
			this.isRowSelected = true;
			this.errorMsgFlag = false;
			$('.ag-theme-alpine').removeClass('chkbox');
			$('.ag-checkbox-input-wrapper').removeClass('chkbox');
			this.selectedResult = params.data;
		}
		if (!params.node.selected) {
			if (this.selectedResult) {
				if (this.selectedResult.gbin) {
					this.selectedResult = {};
				}
			}
		}
	}

	onFilterChanged(event: any) {
		if (this.gridOptions.api.getDisplayedRowCount() == 0) {
			this.gridAPI.showNoRowsOverlay();
		} else {
			this.gridAPI.hideOverlay();
		}
	}

	saveEntityTryAgain() {
		this.loaderService.isLoading.next(false);
		this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
			let dialogRef = this.dialog.open(ErrorDialogComponent, {
				disableClose: true,
				width: '37vw',
				data: {
					errorMessage: value['errorMsg.pleaseTryAgain']
				}
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					this.resultSelect();
				}
			});
		});
	}

	resultSelect() {
		if (this.isRowSelected) {	
			let selectedNodes = this.gridApi.getSelectedNodes();
    		this.selectedResult = selectedNodes.map((node: any) => node.data);
			var entityNames: any = [];
			this.selectedResult?.map((entity: any) => {
				var obj = {
					"EntityName": entity.name,
					"Scr": entity.scr,
				}
				entityNames.push(obj);
			});


			var details = { 
				"BulkOrderNumber": this.data.results.bulkOrderNumber,
				"SearchId": this.data.results.searchId,
				"EntityNames": entityNames
			}
			this.loaderService.isLoading.next(true);
			this.uploadService.saveEntityDetails(details).subscribe(
				(res: any) => {
					if(res.success) {
						this.loaderService.isLoading.next(false);
						this.matDialogRef.close(this.selectedResult);
					} else {
						this.saveEntityTryAgain();
					}
				},
				(err: any) => {
					this.saveEntityTryAgain();
				}
			);
		} else {
			this.errorMsgFlag = true;
			this.disabledFlag = true;
			$('.ag-theme-alpine').addClass('chkbox');
			$('.ag-checkbox-input-wrapper').addClass('chkbox');
		}
	}

	UpdateAvailabilityDetails() {
		if (this.isRowSelected) {
			let selectedNodes = this.gridApi.getSelectedNodes();
    		this.selectedResult = selectedNodes.map((node: any) => node.data);
			var Obj = {
				"BulkReportOrderId": this.data.results.id,
				"Scr": this.selectedResult[0].scr,
				"AvailabilityTatSupplier": String(this.selectedResult[0].turnAroundTimeBySupplier),
				"AvailabilityProductPrice": Number(this.selectedResult[0].productPrice),
				"AvailabilitySpeedDescription": this.selectedResult[0].speedDescription,
				"AvailabilitySpeedCode": this.selectedResult[0].speedCode,
				"AvailabilityReportType": this.selectedResult[0].reportType,
				"AvailabilityOfferingId": this.selectedResult[0].productOfferingId,
				"Status": "Match_Found"
			};
			this.loaderService.isLoading.next(true);
			this.uploadService.UpdateAvailabilityDetails(Obj).subscribe(
				(res) => {
					this.loaderService.isLoading.next(false);
					this.matDialogRef.close(Obj);
				},
				(err) => {
					this.loaderService.isLoading.next(false);
					this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
						let dialogRef = this.dialog.open(ErrorDialogComponent, {
							disableClose: true,
							width: '37vw',
							data: {
								errorMessage: value['errorMsg.pleaseTryAgain']
							}
						});
						dialogRef.afterClosed().subscribe(res => {
							if (res) {
								this.UpdateAvailabilityDetails();
							}
						});
					})
				}
			);
		} else {
			this.errorMsgFlag = true;
			this.disabledFlag = true;
			$('.ag-theme-alpine').addClass('chkbox');
			$('.ag-checkbox-input-wrapper').addClass('chkbox');
		}
	}

	ngOnDestroy() {
		$('.ag-theme-alpine').removeClass('chkbox');
		$('.ag-checkbox-input-wrapper').removeClass('chkbox');
		if(this.mySubscription)
		this.mySubscription.unsubscribe();
	}
}