import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'app-order-data',
  templateUrl: './order-data.component.html',
  styleUrls: ['./order-data.component.scss']
})
export class OrderDataCellRenderer implements ICellRendererAngularComp {
  public cellData: any;
  public currency: any;
  agInit(params: any): void {
    this.cellData = params.data;
    params.columnApi.columnModel.columnDefs.find((obj: any) => {
      if (obj.ColId == 'price_col') {
        this.currency = localStorage.getItem('currency');
      }
    });
    console.log("orderData,celldata",this.cellData)
  }

  refresh(params: any): boolean {
    return false;
  }
}