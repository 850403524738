<div id="info">
    <h1 matDialogTitle class="info-title">
        <mat-icon class="icon-info" *ngIf="!data.gdnFailed">
            info
        </mat-icon>
        <mat-icon class="icon-error" *ngIf="data.gdnFailed">
            info
        </mat-icon>
        <span *ngIf="data.gdnFailed">{{ 'errorDialog.title' | translate }}</span>
        <span  *ngIf="!data.gdnFailed"> No results found</span>
    </h1>
    <div matDialogContent class="content-msg">
        {{ data.infoMessage }}
        <a *ngIf="data.manualSearch" style="cursor: pointer; text-decoration: underline;" (click)="dialogRef.close('proceed')">
            {{ 'offlineSearchResult.offlineLinkHere' | translate }}
        </a>
    </div>
    <div mat-dialog-actions class="pt-24" style="justify-content: center;">
        <button *ngIf="!data.offlineFlow" (click)="dialogRef.close(false)" class="button button__basic">OK</button>
        <button *ngIf="data.offlineFlow" (click)="dialogRef.close(false)" class="button btn button__basic">
            {{ 'searchResults.btn.cancel' | translate }}
        </button>
        <button *ngIf="data.offlineFlow" (click)="dialogRef.close('proceed')" class="button button__primary ml-22" style="margin-left: 20px;">Proceed</button>
    </div>
</div>