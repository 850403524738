<span id="print-section" >
<app-header (langChange)="changeLanguageGrid($event)" [title]="title"></app-header>
<app-toast class="position"></app-toast>
<main class="p1">
    <section>
        <article>
            <div class="zanna_div row mt-60">
                <h1 class="M(0) Lh(1) eds-heading heading-xl-caps print">
                    {{ 'monitor.cms.title' | translate }}
                    <button type="button" class="button button__primary userManual-btn" [useExistingCss]="true" printSectionId="print-section" ngxPrint *ngIf="!isMobile">
                        <mat-icon class="material-icon icon-color">printer</mat-icon>
                        &nbsp;{{ 'monitor.cms.print_page' | translate }}
                    </button>
                    <button type="button" class="button button__primary " [ngClass]="( count == 0 && paymentAlertsCount == 0 ) ? 'disabled' : 'userManual-btn'" (click)="export()" *ngIf="!isMobile">
                        <mat-icon class="material-icon icon-color">get_app</mat-icon>
                        &nbsp;{{ 'monitor.bws.export' | translate }}
                    </button>
                </h1>
                <p class="p-subTitle">
                    {{ 'monitor.cms.subtitle' | translate }} {{ count }} {{ 'monitor.cms.subtitle1' | translate }} {{ currentDate }}
                </p>
                <p class="p-subTitle">
                    {{ 'monitor.cms.export_note' | translate }}
                </p>  
                <p *ngIf="isBasic" class="p-subtitle" style="text-decoration: underline; margin-top: 6px;">
                    <a href="mailto:cs@experian.com"> {{ 'monitor.cms.contact_manager' | translate }} </a>
                </p>
            </div> 
            <div class="expand-btn-area" *ngIf="isMobile && (newDefendantList.length>1 || newPlantiffList.length>1 || hearingPlantiffList?.length > 1 || hearingDefendantList.length>1 )">
                <button type="button" class="expand-btn btn btn-primary" (click)="ExpandAll(allExpand)" [disabled]="btnDisabled">
                    <mat-icon *ngIf="!allExpand">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="allExpand">keyboard_arrow_up</mat-icon>
                    {{expandCollapseButtonValue}}
                </button>
            </div> 
			<div *ngIf="count > 0">
                <div class="mb-36 row" *ngIf="newDefendantList?.length > 0 || newPlantiffList?.length > 0">
                    <div class="data-grid-strip">
                        <span style="color: #fff !important;">
                            {{ 'monitor.cms.cms_alerts' | translate }}
                        </span>        
                    </div>
                    <mat-card *ngIf="newDefendantList?.length > 0">
                        {{ 'monitor.defendant_section' | translate }}
                    </mat-card>
                    <ag-grid-angular class="ag-theme-alpine" id="alartsGrid" [rowData]="newDefendantList" [columnDefs]="columnDefs" [animateRows]="true"
                        [masterDetail]="true" [detailRowHeight]="CMSNewDefendantHeight" [detailCellRenderer]="CMSNewDefendant" (firstDataRendered)="onFirstDataRendered1($event)"
                        (gridReady)="onNewDefendantGridReady($event)" [gridOptions]="newDefendantGridOptions" 
                        [domLayout]="domLayout" *ngIf="newDefendantList?.length > 0">
                    </ag-grid-angular>
                    <mat-card *ngIf="newPlantiffList?.length > 0">
                        {{ 'monitor.plaintiff_section' | translate }}
                    </mat-card>          
                    <ag-grid-angular class="ag-theme-alpine" id="alartsGrid" [rowData]="newPlantiffList" [columnDefs]="columnDefs" [animateRows]="true"
                    [masterDetail]="true" [detailRowHeight]="CMSNewPlandiffHeight" [detailCellRenderer]="CMSNewPlandiff" (firstDataRendered)="onFirstDataRendered2($event)"
                        (gridReady)="onNewPlantiffGridReady($event)" [gridOptions]="newPlantiffGridOptions" 
                        [domLayout]="domLayout" *ngIf="newPlantiffList?.length > 0">
                    </ag-grid-angular>
                </div>
                <div class="zanna_div row mb-36 mt-60" *ngIf="hearingDefendantList?.length > 0 || hearingPlantiffList?.length > 0">
                    <div class="data-grid-strip">
                        <span style="color: #fff !important;">
                            {{ 'monitor.occurences_hearing' | translate }}
                        </span>        
                    </div>
                    <mat-card *ngIf="hearingDefendantList?.length > 0">
                        {{ 'monitor.defendant_section' | translate }}
                    </mat-card>
                    <ag-grid-angular class="ag-theme-alpine" id="alartsGrid" [rowData]="hearingDefendantList" [columnDefs]="columnDefs" [animateRows]="true"
                    [masterDetail]="true" [detailRowHeight]="CMSHearingDefendantHeight" [detailCellRenderer]="CMSHearingDefendant" (firstDataRendered)="onFirstDataRendered3($event)"
                        (gridReady)="onHearingDefendantGridReady($event)" [gridOptions]="hearingDefendantGridOptions" 
                        [domLayout]="domLayout" *ngIf="hearingDefendantList?.length > 0">
                    </ag-grid-angular>
                    <mat-card *ngIf="hearingPlantiffList?.length > 0">
                        {{ 'monitor.plaintiff_section' | translate }}
                    </mat-card>          
                    <ag-grid-angular class="ag-theme-alpine" id="alartsGrid" [rowData]="hearingPlantiffList" [columnDefs]="columnDefs" [animateRows]="true"
                        [masterDetail]="true" [detailRowHeight]="CMSHearingPlandiffHeight" [detailCellRenderer]="CMSHearingPlandiff" (firstDataRendered)="onFirstDataRendered4($event)"
                        (gridReady)="onHearingPlantiffGridReady($event)" [gridOptions]="hearingPlantiffGridOptions" 
                        [domLayout]="domLayout" *ngIf="hearingPlantiffList?.length > 0">
                    </ag-grid-angular >
                </div>
            </div>
            <div *ngIf="count == 0">
                <div class="data-grid-strip">
                    <span style="color: #fff !important;">
                        {{ 'monitor.cms.cms_alerts' | translate }}
                    </span>        
                </div>
                <mat-card>
                    {{ 'monitor.defendant_section' | translate }}
                </mat-card>
                <ag-grid-angular class="ag-theme-alpine" id="alartsGrid" [rowData]="newDefendantList" [columnDefs]="columnDefs" [animateRows]="true"
                    [masterDetail]="true" [detailRowHeight]="CMSNewDefendantHeight" [detailCellRenderer]="CMSNewDefendant" (firstDataRendered)="onFirstDataRendered1($event)"
                    (gridReady)="onNewDefendantGridReady($event)" [gridOptions]="newDefendantGridOptions" 
                    [domLayout]="domLayout" >
                </ag-grid-angular>
            </div>
        </article>
        <article *ngIf="paymentAlertsCount > 0">
            <div class="zanna_div row">
                <h1 class="M(0) Lh(1) eds-heading heading-xl-caps print">
                    {{ 'monitor.fms.title1' | translate }}
                </h1>
                <p class="p-subTitle">
                    {{ 'monitor.fms.subtitle1' | translate }} {{ paymentAlertsCount }} {{ 'monitor.fms.subtitle2' | translate }}
                </p> 
            </div> 

            <div *ngIf="paymentCat1">
                <div class="mt-60" *ngFor="let category of paymentCat1">
                    <div class="data-grid-strip">
                        <span style="color: #fff !important;">{{ category.CategoryName }}</span>
                    </div>
                    <table class="table table-variation" style="width: 100%;">
                        <tr>
                            <th class="table-border-header" style="width: 50px !important;">{{ 'monitor.fms.no' | translate }}</th>
                            <th class="table-border-header basic border-right">
                                    {{ 'monitor.entityIdentification' | translate }}
                            </th>
                        </tr>
                        <tr *ngFor="let row of category.AlertData; let i = index">
                            <td class="table-border-data" style="word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;">
                                {{ i+1 }}
                            </td>
                            <td class="table-border-data border-right" style="word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;">
                                <span class="delivery-date-format">
                                    {{ 'monitor.entityNo' | translate }}. {{ row?.EntityId}} 
                                </span><br>
                                <!-- <span class="delivery-date-format" *ngIf="category.CategoryName == NegativeBlackList">
                                    {{ 'monitor.fms.creditor_name' | translate }}: {{ row?.CustomerName }}
                                </span><br *ngIf="category.CategoryName == NegativeBlackList"> -->
                                <span class="delivery-date-format">
                                    {{ row?.EntityName }}
                                </span><br>
                            </td>
                        </tr>
                    </table>
                    <br *ngIf="!isMobile">
                    <br *ngIf="!isMobile">
                </div>
            </div>

            <div *ngIf="paymentCat2">
                <div class="mt-60" *ngFor="let category of paymentCat2">
                    <div class="data-grid-strip">
                        <span style="color: #fff !important;">{{ category.CategoryName }}</span>
                    </div>
                    <table class="table table-variation" style="width: 100%;">
                        <tr>
                            <th class="table-border-header" style="width: 50px !important;">{{ 'monitor.fms.no' | translate }}</th>
                            <th class="table-border-header basic border-right">
                                    {{ 'monitor.entityIdentification' | translate }}
                            </th>
                        </tr>
                        <tr *ngFor="let row of category.AlertData; let i = index">
                            <td class="table-border-data" style="word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;">
                                {{ i+1 }}
                            </td>
                            <td class="table-border-data border-right" style="word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;">
                                <span class="delivery-date-format">
                                    {{ 'monitor.entityNo' | translate }}. {{ row?.EntityId}} 
                                </span><br>
                                <!-- <span class="delivery-date-format" *ngIf="category.CategoryName == NegativeBlackList">
                                    {{ 'monitor.fms.creditor_name' | translate }}: {{ row?.CustomerName }}
                                </span><br *ngIf="category.CategoryName == NegativeBlackList"> -->
                                <span class="delivery-date-format">
                                    {{ row?.EntityName }}
                                </span><br>
                            </td>
                        </tr>
                    </table>
                    <br *ngIf="!isMobile">
                    <br *ngIf="!isMobile">
                </div>
            </div>
            
            <div *ngIf="paymentCat3">
                <div class="mt-60" *ngFor="let category of paymentCat3">
                    <div class="data-grid-strip">
                        <span style="color: #fff !important;">{{ category.CategoryName }}</span>
                    </div>
                    <table class="table table-variation" style="width: 100%;">
                        <tr>
                            <th class="table-border-header" style="width: 50px !important;">{{ 'monitor.fms.no' | translate }}</th>
                            <th class="table-border-header basic border-right">
                                    {{ 'monitor.entityIdentification' | translate }}
                            </th>
                        </tr>
                        <tr *ngFor="let row of category.AlertData; let i = index">
                            <td class="table-border-data" style="word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;">
                                {{ i+1 }}
                            </td>
                            <td class="table-border-data border-right" style="word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;">
                                <span class="delivery-date-format">
                                    {{ 'monitor.entityNo' | translate }}. {{ row?.EntityId}} 
                                </span><br>
                                <!-- <span class="delivery-date-format" *ngIf="category.CategoryName == NegativeBlackList">
                                    {{ 'monitor.fms.creditor_name' | translate }}: {{ row?.CustomerName }}
                                </span><br *ngIf="category.CategoryName == NegativeBlackList"> -->
                                <span class="delivery-date-format">
                                    {{ row?.EntityName }}
                                </span><br>
                            </td>
                        </tr>
                    </table>
                    <br *ngIf="!isMobile">
                    <br *ngIf="!isMobile">
                </div>
            </div>
            
            <div *ngIf="paymentCat4">
                <div class="mt-60" *ngFor="let category of paymentCat4">
                    <div class="data-grid-strip">
                        <span style="color: #fff !important;">{{ category.CategoryName }}</span>
                    </div>
                    <table class="table table-variation" style="width: 100%;">
                        <tr>
                            <th class="table-border-header" style="width: 50px !important;">{{ 'monitor.fms.no' | translate }}</th>
                            <th class="table-border-header basic border-right">
                                    {{ 'monitor.entityIdentification' | translate }}
                            </th>
                        </tr>
                        <tr *ngFor="let row of category.AlertData; let i = index">
                            <td class="table-border-data" style="word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;">
                                {{ i+1 }}
                            </td>
                            <td class="table-border-data border-right" style="word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;">
                                <span class="delivery-date-format">
                                    {{ 'monitor.entityNo' | translate }}. {{ row?.EntityId}} 
                                </span><br>
                                <!-- <span class="delivery-date-format" *ngIf="category.CategoryName == NegativeBlackList">
                                    {{ 'monitor.fms.creditor_name' | translate }}: {{ row?.CustomerName }}
                                </span><br *ngIf="category.CategoryName == NegativeBlackList"> -->
                                <span class="delivery-date-format">
                                    {{ row?.EntityName }}
                                </span><br>
                            </td>
                        </tr>
                    </table>
                    <br *ngIf="!isMobile">
                    <br *ngIf="!isMobile">
                </div>
            </div>
            
            <div *ngIf="paymentCat5">
                <div class="mt-60" *ngFor="let category of paymentCat5">
                    <div class="data-grid-strip">
                        <span style="color: #fff !important;">{{ category.CategoryName }}</span>
                    </div>
                    <table class="table table-variation" style="width: 100%;">
                        <tr>
                            <th class="table-border-header" style="width: 50px !important;">{{ 'monitor.fms.no' | translate }}</th>
                            <th class="table-border-header basic border-right">
                                    {{ 'monitor.entityIdentification' | translate }}
                            </th>
                        </tr>
                        <tr *ngFor="let row of category.AlertData; let i = index">
                            <td class="table-border-data" style="word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;">
                                {{ i+1 }}
                            </td>
                            <td class="table-border-data border-right" style="word-break: break-word;padding: 10px 22px !important;vertical-align: baseline;">
                                <span class="delivery-date-format">
                                    {{ 'monitor.entityNo' | translate }}. {{ row?.EntityId}} 
                                </span><br>
                                <!-- <span class="delivery-date-format" *ngIf="category.CategoryName == NegativeBlackList">
                                    {{ 'monitor.fms.creditor_name' | translate }}: {{ row?.CustomerName }}
                                </span><br *ngIf="category.CategoryName == NegativeBlackList"> -->
                                <span class="delivery-date-format">
                                    {{ row?.EntityName }}
                                </span><br>
                            </td>
                        </tr>
                    </table>
                    <br *ngIf="!isMobile">
                    <br *ngIf="!isMobile">
                </div>
            </div>
            
            
        </article>
        <article>
            <div class="zanna_div row" *ngIf="(!entityId && summary) && (count != 0 || paymentAlertsCount != 0)">
                <div class="data-grid-strip">
                    <span style="color: #fff !important;" class="fs-2">
                        {{'cart.summary' | translate}}
                    </span>        
                </div>
                <mat-card style="padding: 20px;background: white;border-bottom: 0.5px solid #b7b1b1;" *ngIf="!isMobile">
                    <div fxLayout="col">
                        <div fxFlex="30">
                            <div style="padding: 10px;"> {{ 'monitor.bws.total_names_subscribed' | translate }} </div>
                        </div>
                        <div fxFlex="4"></div>
                        <div fxFlex="60">
                            <div style="padding: 10px;"> {{ summary?.totalNamesSubscribed}}</div>
                        </div>
                    </div>

                    <div fxLayout="col">
                        <div fxFlex="30">
                            <div style="padding: 10px;"> {{ 'monitor.bws.number_of_names_subscribed' | translate }} </div>
                        </div>
                        <div fxFlex="4"></div>
                        <div fxFlex="60">
                            <div style="padding: 10px;"> {{ summary?.monitoredNames}}</div>
                        </div>
                    </div>

                    <div fxLayout="col">
                        <div fxFlex="30">
                            <div style="padding: 10px;"> {{ 'monitor.bws.remaining_Balance' | translate }}</div>
                        </div>
                        <div fxFlex="4"></div>
                        <div fxFlex="60">
                            <div style="padding: 10px;"> {{ summary?.remainingBalance}} </div>
                        </div>
                    </div>

                    <div fxLayout="col">
                        <div fxFlex="30">
                            <div style="padding: 10px;">{{ 'monitor.bws.revision_name' | translate }}</div>
                        </div>
                        <div fxFlex="4"></div>
                        <div fxFlex="60">
                            <div style="padding: 10px;"> {{ summary?.lastRevisionOfNames | date: 'dd/MM/yyyy' }} </div>
                        </div>
                    </div>

                    <div fxLayout="col">
                        <div fxFlex="30">
                            <div style="padding: 10px;"> {{ 'monitor.bws.sub_info' | translate }}  </div>
                        </div>
                        <div fxFlex="4"></div>
                        <div fxFlex="60">
                            <div style="padding: 10px;"> </div>
                        </div>
                    </div>

                    <!-- <div fxLayout="col">
                        <div fxFlex="30">
                            <div style="padding: 20px 10px 10px 10px;"> {{ 'monitor.cms.names_with_litigation' | translate }}</div>
                        </div>
                        <div fxFlex="4"></div>
                        <div fxFlex="60">
                            <div style="padding: 20px 10px 10px 10px;"> <span> - </span> </div>
                        </div>
                    </div>

                    <div fxLayout="col">
                        <div fxFlex="30">
                            <div style="padding: 10px;"> {{ 'monitor.cms.names_without_litigation' | translate }} </div>
                        </div>
                        <div fxFlex="4"></div>
                        <div fxFlex="60">
                            <div style="padding: 10px;"> <span > - </span> </div>
                        </div>
                    </div> -->
                </mat-card>
                <mat-card class="fs-2" style="padding: 20px;background: white;border-bottom: 0.5px solid #b7b1b1;" *ngIf="isMobile">
                    <div fxLayout="col">
                        <div fxFlex="80">
                            <div style="padding: 10px;"> {{ 'monitor.bws.total_names_subscribed' | translate }} </div>
                        </div>                        
                        <div fxFlex="20">
                            <div style="padding: 10px;text-align: right;"> {{ summary?.totalNamesSubscribed}}</div>
                        </div>
                    </div>

                    <div fxLayout="col">
                        <div fxFlex="80">
                            <div style="padding: 10px;"> {{ 'monitor.bws.number_of_names_subscribed' | translate }} </div>
                        </div>                        
                        <div fxFlex="20">
                            <div style="padding: 10px;text-align: right;"> {{ summary?.monitoredNames}}</div>
                        </div>
                    </div>

                    <div fxLayout="col">
                        <div fxFlex="80">
                            <div style="padding: 10px;"> {{ 'monitor.bws.remaining_Balance' | translate }}</div>
                        </div>                        
                        <div fxFlex="20">
                            <div style="padding: 10px;text-align: right;"> {{ summary?.remainingBalance}} </div>
                        </div>
                    </div>

                    <div fxLayout="col">
                        <div fxFlex="80">
                            <div style="padding: 10px;">{{ 'monitor.bws.revision_name' | translate }}</div>
                        </div>                        
                        <div fxFlex="20">
                            <div style="padding: 10px;text-align: right;">{{ summary?.lastRevisionOfNames | date: 'dd/MM/yyyy' }} </div>
                        </div>
                    </div>

                    <div fxLayout="col">
                        <div fxFlex="80">
                            <div style="padding: 10px;"> {{ 'monitor.bws.sub_info' | translate }}  </div>
                        </div>                        
                        <div fxFlex="20">
                            <div style="padding: 10px;text-align: right;"> </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </article>
    </section>
</main>
</span>
<!-- CMS PAYMENT TEMPLATE -->
<div style="display: none;" >   
    <table id="excel-table-cms-payment">
      <tr>
        <th> Entity Number </th>
        <th> Entity Name </th>
        <th> Client File Ref. </th>
        <th> Entity Type </th>
        <th> Record Type </th>
        <th> Date of Alert </th>
      </tr>
      <tr *ngFor="let item of exportCmsPayment">
        <td> {{ item.EntityId }} </td>
        <td> {{ item.EntityName }} </td>
        <td> {{ item.ClientFileReference }} </td>
        <td> <span *ngIf="item.EntityType == 'C'"> Company</span>  <span *ngIf="item.EntityType == 'I'"> Individual</span> </td>
        <td> {{ item.RecordType }} </td>
        <td> {{ item.AlertDate | date: 'dd/MM/yyyy' }} </td>
      </tr>
    </table>
  </div>

  <div style="display: none;" >   
    <table id="excel-table-cms-basic">
      <tr>
        <th> Entity Number </th>
        <th> Entity Name </th>
        <th> Client File Ref. </th>
        <th> Entity Type </th>
        <th> Alert Trigger</th>
        <th> Court Type </th>
        <th> Year </th>
        <th> Filling Date </th>
        <th> Case No </th>
        <th> Case Status </th>
        <th> Transfer </th>
        <th> Party Nature </th>
        <th> Defendant/Plaintiff/Claimant Name </th>
        <th> Date of Alert </th>
      </tr>
      <tr *ngFor="let item of exportCmsLitigationBasic">
        <td> {{ item.EntityId }} </td>
        <td> {{ item.EntityName }} </td>
        <td> {{ item.FileReference }} </td>
        <td> <span *ngIf="item.EntityType == 'C'"> Company</span>  <span *ngIf="item.EntityType == 'I'"> Individual</span> </td>
        <td> {{ item.ChangeType }} </td>
        <td> {{ item.CourtType }} </td>
        <td> {{ item.CaseYear }} </td>
        <td> {{ item.FilingDate }} </td>
        <td> {{ item.CaseNo }} </td>
        <td> {{ item.CaseStatus }} </td>
        <td> {{ item.Transfer }} </td>
        <td> {{ item.PartyNature }} </td>
        <td> {{ item.PartyName }} </td>
        <td> {{ item.ChangeDate }} </td>
      </tr>
    </table>
  </div>

  <div style="display: none;" >   
    <table id="excel-table-cms-enhanced">
      <tr>
        <th> Entity Number </th>
        <th> Entity Name </th>
        <th> Client File Ref. </th>
        <th> Entity Type </th>
        <th> Alert Trigger </th>
        <th> Court Type </th>
        <th> Year </th>
        <th> Filling Date </th>
        <th> Case No </th>
        <th> Case Status </th>
        <th> Transfer </th>
        <th> Cause </th>
        <th> Party Nature </th>
        <th> Amount </th>
        <th> Defendant/Plaintiff Name </th>
        <th> Date of Alert </th>
      </tr>
      <tr *ngFor="let item of exportCmsLitigationEnhanced">
        <td> {{ item.EntityId }} </td>
        <td> {{ item.EntityName }} </td>
        <td> {{ item.FileReference }} </td>
        <td> <span *ngIf="item.EntityType == 'C'"> Company</span>  <span *ngIf="item.EntityType == 'I'"> Individual</span> </td>
        <td> {{ item.ChangeType }} </td>
        <td> {{ item.CourtType }} </td>
        <td> {{ item.CaseYear }} </td>
        <td> {{ item.FilingDate }} </td>
        <td> {{ item.CaseNo }} </td>
        <td> {{ item.CaseStatus }} </td>
        <td> {{ item.Transfer }} </td>
        <td> {{ item.Cause }} </td>
        <td> {{ item.PartyNature }} </td>
        <td> {{ item.Amount }} </td>
        <td> {{ item.PartyName }} </td>
        <td> {{ item.ChangeDate }} </td>
      </tr>
    </table>
  </div>

 <!-- SUMMARY EXPORT TEMPLATE -->
 
 <div style="display: none;" *ngIf="summary">   
    <table id="summary-excel-table">
        <tr>
            <th>Total No of Names Subscribed</th>
            <th>No of Names Monitored</th>
            <th>Remaining Balance</th>
            <th>Last Revision of Names</th>
            <th>Info Date</th>
        </tr>
        <tr>
            <td> {{ summary?.totalNamesSubscribed}} </td>
            <td> {{ summary?.monitoredNames}} </td>
            <td> {{ summary?.remainingBalance}} </td>
            <td> {{ summary?.lastRevisionOfNames | date: 'dd/MM/yyyy' }} </td>
            <td> {{ summary?.infoDate | date: 'dd/MM/yyyy' }} </td> 
        </tr>
    </table>
  </div>
<app-footer></app-footer>