import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'info-dialog-box',
    templateUrl: './info-dialog.component.html',
    styleUrls: ['info-dialog.component.scss']
})

export class InfoDialogComponent {
    public confirmMessage: any = '';
    public action: any = '';

    constructor(public dialogRef: MatDialogRef<InfoDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any) {

    }
}