<div class="overflow-y">
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-12 left-section" style="white-space: break-spaces;">
            <b>{{'cart.product' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-12 right-section" style="white-space: break-spaces;">
           {{cellData.productDescription}}
        </div>
    </div>
    
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-12 left-section" style="white-space: break-spaces;">
            <b>{{'cart.turnaroundTime' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-12 right-section" style="white-space: break-spaces;">
           {{cellData.speedDescription}}
           <br/><span *ngIf="cellData.expectedDeliveryDate" style="font-size:13px; color: #8C8C8C">Expected delivery date:</span><br/> {{cellData.expectedDeliveryDate | date}};
        </div>
    </div>
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-12 left-section" style="white-space: break-spaces;">
            <b>{{'cart.country' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-12 right-section" style="white-space: break-spaces;">
           {{cellData.countryName}}
        </div>
    </div>
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-12 left-section" style="white-space: break-spaces;">
            <b>{{'cart.language' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-12 right-section" style="white-space: break-spaces;">
           {{cellData.productLanguage}}
        </div>
    </div>
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-12 left-section" style="white-space: break-spaces;">
            <b>{{'cart.price' | translate}} ({{currency}})</b>
        </div>
        <div fxFlex="50" class="right p-t-12 right-section" style="white-space: break-spaces;">
            {{cellData.sellingPrice}}
        </div>
    </div>
</div>
