<div class="overflow-y">
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
            <b>{{'monitor.monitorRecords.availableAlerts' | translate}}</b>
        </div>
        <div class="p-t-2" style="white-space: break-spaces;flex: 1 1 100%">
            <div class="align-alert-type">
                <span *ngFor="let alert of cellData.availableAlerts">
                    <span container="body"> <!-- *ngIf="alert.monitoringType !='NBB'" -->
                        <div>
                            <mat-icon  [ngClass]="alert.isSubscribed == '1' ? 'subscribe': 'not-subscribe'" 
                            container="body" placement="top">{{alert.icon}} </mat-icon>
                        </div>
                        <div [ngClass]="alert.isSubscribed == '1' ? 'subscribe-names': 'not-subscribe-names'">
                            {{ alert.monitoringType }}
                        </div>
                    </span>                    
                </span>
            </div>
        </div>
    </div>
    
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
            <b>{{'monitor.monitorRecords.entityStatus' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
           {{cellData.entityStatus}}
        </div>
    </div>
    <div fxLayout="col" class="f-s-adjustment">
        <div fxFlex="50" class="left p-t-2 left-section" style="white-space: break-spaces;">
            <b>{{'monitor.clientFileRef' | translate}}</b>
        </div>
        <div fxFlex="50" class="right p-t-2 right-section" style="white-space: break-spaces;">
           {{cellData.clientFileReference}}
        </div>
    </div>
</div>
