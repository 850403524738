<div class="master-deatils" *ngIf="!isMobile">
   <div class="flex-container">
      <div *ngFor="let item of Data.Header | keyvalue">
         <div class="flex-item">{{item.key | headerName}}</div>
         <div class="flex-item">{{checkData(item.value)}}</div>
      </div>
   </div>

   <div *ngFor="let i of Data.SubSections" class="row">
      <div class="alignment">
         <label style="margin-top: 25px;"><b>{{ 'global_monitor.alert.change_details' | translate }}:
               {{i.Title}}</b></label>
      </div>
      <div class="alignment" style="white-space: initial;">
         <div fxLayout="row" *ngIf="i.Message === null">
            <div fxFlex="60">
               <div class="mb-10"><b> {{ 'global_monitor.alert.previous' | translate }}</b></div>
               <div class="mb-10" style="flex-direction: column;" *ngFor="let j of i.Previous">
                  <div>{{getArray(j) }}</div>
                  <table>
                     <thead>
                        <th hidden>Key</th>
                        <th hidden>Value</th>
                     </thead>
                     <tbody>
                        <tr *ngFor="let item of j | keepOrder">
                           <td class="key">{{item.key}}</td>
                           <td class="value">{{checkData(item.value) }}</td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>
            <div fxFlex="4"></div>
            <div fxFlex="60">
               <div class="mb-10"> <b>{{ 'global_monitor.alert.new' | translate }}</b></div>
               <div class="mb-10 " style="flex-direction: column;" *ngFor="let j of i.New">
                  <div>{{getArray(j) }}</div>
                  <table>
                     <thead>
                        <th hidden>Key</th>
                        <th hidden>Value</th>
                     </thead>
                     <tbody>
                        <tr *ngFor="let item of j | keepOrder">
                           <td class="key">{{item.key}}</td>
                           <td class="value">{{checkData(item.value) }}</td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>

         </div>
         <div fxLayout="col" *ngIf="i.Message !== null">
            <div fxFlex="30">
               <div class="mb-10" style="flex-direction: column;"> {{ i.Message }}</div>
            </div>
         </div>
      </div>
      <hr />
   </div>
</div>

<div class="overflow-y" *ngIf="isMobile">
   <div fxLayout="col" class="f-s-adjustment" *ngFor="let item of Data.Header | keyvalue">
      <div fxFlex="50" class="left p-t-12 left-section" style="white-space: break-spaces;">
         <b>{{item.key | headerName}}</b>
      </div>
      <div fxFlex="50" class="right p-t-12 right-section" style="white-space: break-spaces;">
         {{checkData(item.value)}}
      </div>
   </div>
   <div fxLayout="col" class="f-s-adjustment" *ngFor="let i of Data.SubSections">
      <div class="alignment-mobile">
         <label style="margin-top: 25px;"><b>{{ 'global_monitor.alert.change_details' | translate }}:
               {{i.Title}}</b></label>
         <div *ngIf="i.Message === null">
            <div class="mb-10"><b> {{ 'global_monitor.alert.previous' | translate }}</b></div>
            <div class="mb-10" style="flex-direction: column;" *ngFor="let j of i.Previous">
               <div>{{getArray(j) }}</div>
               <table class="table-mobile">
                  <thead>
                     <th hidden>Key</th>
                     <th hidden>Value</th>
                  </thead>
                  <tbody>
                     <tr *ngFor="let item of j | keyvalue; let i = index">
                        <td>{{item.key}}</td>
                        <td>{{checkData(item.value) }}</td>
                     </tr>
                  </tbody>
               </table>
            </div>

            <div class="mb-10"> <b>{{ 'global_monitor.alert.new' | translate }}</b></div>
            <div class="mb-10 " style="flex-direction: column;" *ngFor="let j of i.New">
               <div>{{getArray(j) }}</div>
               <table class="table-mobile">
                  <thead>
                     <th hidden>Key</th>
                     <th hidden>Value</th>
                  </thead>
                  <tbody>
                     <tr *ngFor="let item of j | keyvalue">
                        <td>{{item.key}}</td>
                        <td>{{checkData(item.value) }}</td>
                     </tr>
                  </tbody>
               </table>
            </div>
         </div>
         <div fxLayout="col" *ngIf="i.Message !== null">
            <div fxFlex="30">
               <div class="mb-10" style="flex-direction: column;"> {{ i.Message }}</div>
            </div>
         </div>

         <div class="hrDiv"></div>
      </div>
   </div>
</div>