
<span id="print-section" >
<app-header (langChange)="changeLanguageGrid($event)" [title]="title"></app-header>
<app-toast class="position"></app-toast>
<main class="p1">
    <section>
        <article>
            <div class="zanna_div row mt-60">
                <h1 class="M(0) Lh(1) eds-heading heading-xl-caps print">
                    {{ 'monitor.dnsa.title' | translate }}
                    <button type="button" class="button button__primary userManual-btn" [useExistingCss]="true" printSectionId="print-section" ngxPrint *ngIf="!isMobile">
                        <mat-icon class="material-icon icon-color">printer</mat-icon>
                        &nbsp;{{ 'monitor.bws.print_page' | translate }}
                    </button>
                    <button type="button" class="button button__primary" [ngClass]="( !alartList || alartList?.length == 0) ? 'disabled' : 'userManual-btn'"(click)="exportAsExcelFile()" *ngIf="!isMobile">
                        <mat-icon class="material-icon icon-color">get_app</mat-icon>
                        &nbsp;{{ 'monitor.bws.export' | translate }}
                    </button>
                </h1>
                <p class="p-subTitle">
                    {{ 'monitor.cms.subtitle' | translate }} {{ count }} {{ 'monitor.dnsa.heading' | translate }} {{ currentDate }}.
                </p>      
            </div> 
            <div class="expand-btn-area"  *ngIf="isMobile && alartList?.length > 1">
                <button type="button" class="expand-btn btn btn-primary" (click)="ExpandAll(allExpand)">
                    <mat-icon *ngIf="!allExpand">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="allExpand">keyboard_arrow_up</mat-icon>
                    {{expandCollapseButtonValue}}
                </button>
            </div> 
            <div class="zanna_div row" *ngFor="let suit of alartList;let i=index">
                <div >
                    <div class="data-grid-strip">
                        <span style="color: #fff !important;">
                            {{ 'monitor.dnsa.subtitle' | translate }} {{ suit?.UserId}}
                        </span>        
                    </div>
                    <ag-grid-angular class="ag-theme-alpine" id="alartsGrid" [rowData]="suit.Records ? suit.Records : null" [columnDefs]="columnDefs" [animateRows]="true"
                        (gridReady)="onGridReady($event)" [masterDetail]="true" [detailRowHeight]="gridHeight" [gridOptions]="gridOptions" [detailCellRenderer]="detailCellRenderer"
                        [domLayout]="domLayout" (firstDataRendered)="onFirstDataRendered($event)">
                    </ag-grid-angular>
                </div>
            </div>
            <!-- No Rows Template -->
            <div class="zanna_div row" *ngIf="!alartList || alartList?.length == 0">
                <div >
                    <div class="data-grid-strip">
                        <span style="color: #fff !important;">
                            {{ 'monitor.dnsa.no_reocrds_header' | translate }} 
                        </span>        
                    </div>
                    <ag-grid-angular class="ag-theme-alpine" id="alartsGrid" [rowData]="empty" [columnDefs]="columnDefs" [animateRows]="true"
                        (gridReady)="onGridReady($event)" [masterDetail]="true" [detailRowHeight]="gridHeight" [gridOptions]="gridOptions" [detailCellRenderer]="detailCellRenderer"
                        [domLayout]="domLayout" (firstDataRendered)="onFirstDataRendered($event)">
                    </ag-grid-angular>
                </div>
            </div>
            
        </article>
    </section>
</main>
</span>
<div style="display: none;">   
    <table id="excel-table">
      <tr>
        <th> User ID </th>
        <th> Search Date </th>
        <th> Search Type </th>
        <th> Search Name </th>
        <th> Search ID </th>
        <th> New Suit(s) Found </th>
        <th> Last Updated in Experian database </th>

      </tr>
      <tr *ngFor="let item of exportList">
        <td> {{ item.UserId }} </td>
        <td> {{ item.SearchDate }} </td>
        <td> {{ item.SearchType }} </td>
        <td> {{ item.SearchName }} </td>
        <td> {{ item.SearchId }} </td>
        <td> {{ item.NewSuitNo }} </td>
        <td> {{ item.LastUpdatedDate }} </td>
      </tr>
    </table>
  </div>
<app-footer></app-footer>